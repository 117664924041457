import {Component, ViewChild, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzModalService} from 'ng-zorro-antd/modal';
import {selfHttp} from '../../../httpservice/http.service';
import {HttpHeaders} from '@angular/common/http';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NzTreeComponent} from 'ng-zorro-antd/tree';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})


export class UsersComponent implements OnInit {
  @ViewChild('nzTreeComponent') nzTreeComponent: NzTreeComponent;
  validateForm: FormGroup;
  //权限相关
  addAccess = false;
  editAccess = false;
  deleteAccess = false;
  //查询相关
  checkChild: any = false;
  keyword: string = '';
  size = 5;
  page: number = 1;
  total: number = 0;

  //操作相关
  selectOptions = [];
  operateOptions = [];
  operateItem = null;
  addIsVisible = false;
  editIsVisible = false;
  accessCodes: any = [];
  currentUser: any;

  objects = [];
  public objectInfo: any;
  public options: any;

  constructor(public router: Router,
              public fb: FormBuilder,
              public http: selfHttp,
              public modalService: NzModalService,
              public msg: NzMessageService,
  ) {
    this.loadObjectData();
    this.loadAccess();
    this.buildForm();
  }

  buildForm() {
    this.validateForm = this.fb.group({
      id: [0],
      user_name: [null, [Validators.required]],
      role_id: [null, [Validators.required]],
      mobile: [null, [Validators.required]],
      status: [true],
    });
  }

  ngOnInit(): void {
  }

  loadObjectData() {
    this.http.get('http://oss-api.nositek.com/oss-api/pc/users', {
      page: this.page,
      size: this.size,
      keyword: this.keyword,
    }, res => {
      if (res.data['rows'] != null && res.data['rows'].length > 0) {
        this.objects = res.data['rows'];
        this.total = Number(res.data['total']);
      } else {
        this.objects = [];
        this.total = 0;
        this.msg.info('暂无数据');
      }
    });
  }

  // 复选框
  onChange(data: any, checked: any) {
    this.objectInfo = data;
    if (checked === 'single') {
      if (data.checked) {
        this.operateOptions.push(data.id);
      } else {
        let index = this.operateOptions.indexOf(data.id);
        this.operateOptions.splice(index, 1);
      }
    }
  }

// 重置
  reset() {
    this.page = 1;
    this.size = 10;
    this.keyword = '';
    this.loadObjectData();
  }

  //页码切换
  indexChange() {
    console.log(this.page, this.size);
    this.loadObjectData();
  }

  /*
  添加系统用户
   */
  preAdd() {
    this.addIsVisible = true;
    this.loadOptionData();
  }

  loadOptionData() {
    this.http.get('http://oss-api.nositek.com/oss-api/pc/roles', {}, res => {
      if (res.data['rows'] != null) {
        this.selectOptions = res.data['rows'];
      } else {
        this.selectOptions = [];
        this.msg.info('暂无数据');
      }

    });
  }

  //添加取消
  addCancel() {
    this.addIsVisible = false;
    this.validateForm.reset();
    this.validateForm.patchValue({
      status: true,
    });
  }

  //添加提交
  addCommit() {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    let enable: number;
    if (this.validateForm.value.status) {
      enable = 1;
    } else {
      enable = 0;
    }
    if (this.validateForm.value.user_name && this.validateForm.value.mobile && this.validateForm.value.role_id) {
      this.http.post('http://oss-api.nositek.com/oss-api/pc/users', {
        'user_name': this.validateForm.value.user_name,
        'role_id': this.validateForm.value.role_id,
        'mobile': this.validateForm.value.mobile,
        'status': enable,
      }, res => {
        console.log(res)
        if (res.code == 2000) {
          this.msg.success('添加成功');
        } else if (res.code == 100003) {
          this.msg.info(res.msg)
        } else {
          this.msg.error(res.msg)
        }
        this.validateForm.reset();
        this.validateForm.patchValue({
          status: true,
        });
        this.addIsVisible = false;
        this.loadObjectData();
      });
    }
  }

  /*
  修改系统用户
   */
  preEdit() {
    if (this.operateOptions.length == 0) {
      this.msg.info('请选择数据！');
      return;
    } else if (this.operateOptions.length > 1) {
      this.msg.info('请逐条记录操作！');
      return;
    }
    this.loadOptionData();
    let index = this.objects.map(item => item.id).indexOf(this.operateOptions[0]);
    this.operateItem = this.objects[index];
    this.validateForm.patchValue({
      id: this.operateItem.id,
      user_name: this.operateItem.name,
    });
    if (this.operateItem.status == 1) {
      this.validateForm.patchValue({
        'status': true
      });
    } else {
      this.validateForm.patchValue({
        'status': false
      });
    }
    this.validateForm.patchValue({
      user_name: this.operateItem.user_name,
      role_id: this.operateItem.role_id,
      mobile: this.operateItem.mobile,
    })

    this.editIsVisible = true;
  }

  //修改取消
  editCancel() {
    this.editIsVisible = false;
    this.operateOptions = [];
    this.loadObjectData();
    this.validateForm.reset();
    this.validateForm.patchValue({
      status: true,
    });
  }

  //修改提交
  editCommit() {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    let enable: number;
    if (this.validateForm.value.status) {
      enable = 1;
    } else {
      enable = 0;
    }
    if (this.validateForm.value.user_name && this.validateForm.value.mobile && this.validateForm.value.role_id) {
      this.http.post('http://oss-api.nositek.com/oss-api/pc/users', {
        'id': Number(this.objectInfo.id),
        'user_name': this.validateForm.value.user_name,
        'role_id': this.validateForm.value.role_id,
        'mobile': this.validateForm.value.mobile,
        'status': enable,
      }, res => {
        if (res.code == 2000) {
          this.msg.success('编辑成功');
        } else if (res.code == 100003) {
          this.msg.info(res.msg)
        } else {
          this.msg.error(res.msg)
        }
        this.validateForm.reset();
        this.validateForm.patchValue({
          status: true,
        });
        this.editIsVisible = false;
        this.loadObjectData();
      });
    }

  }


  /*
  删除用户
   */
  preDelete() {
    if (this.operateOptions.length == 0) {
      this.msg.info('请选择数据！');
      return;
    }
    this.modalService.confirm({
      nzTitle: '<i>确定删除数据 ？</i>',
      nzContent: '<b></b>',
      nzOkText: '确定',
      nzCancelText: '取消',
      nzOnOk: () => this.deleteCommit(),
      nzOnCancel: () => this.deleteCancel()
    });
  }

  // 删除提交
  deleteCommit() {
    this.options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        ids: this.operateOptions
      }
    };
    this.http.delete('http://oss-api.nositek.com/oss-api/pc/users', this.options, res => {
      if (res.code == 2000) {
        this.msg.success('删除成功');
      } else {
        this.msg.info(res.msg);
      }
      this.loadObjectData();
    });
    this.operateOptions = [];
  }

  // 删除取消
  deleteCancel() {
    this.operateOptions = [];
    this.loadObjectData();
  }


  add_dept = false;
  edit_dept = false;
  delete_dept = false;
  add_user = true;
  edit_user = false;
  delete_user = false;

  loadAccess() {
    console.log('加载页面操作权限');
    // let tempArr = JSON.parse(localStorage.getItem('access'));
    // console.log(tempArr);
    // this.add_dept = tempArr.findIndex(v => v.name == 'add_dept') != -1;
    // this.edit_dept = tempArr.findIndex(v => v.name == 'edit_dept') != -1;
    // this.delete_dept = tempArr.findIndex(v => v.name == 'delete_dept') != -1;
    // this.add_user = tempArr.findIndex(v => v.name == 'add_user') != -1;
    // this.edit_user = tempArr.findIndex(v => v.name == 'edit_user') != -1;
    // this.delete_user = tempArr.findIndex(v => v.name == 'delete_user') != -1;
  }
}

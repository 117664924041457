import {Component, ViewChild, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzModalService} from 'ng-zorro-antd/modal';
import {selfHttp} from '@src/app/httpservice/http.service';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NzTreeComponent} from 'ng-zorro-antd/tree';
import {NzUploadFile} from "ng-zorro-antd/upload";


@Component({
  selector: 'add-subscribe',
  templateUrl: './add-subscribe.component.html',
  styleUrls: ['./add-subscribe.component.css']
})


export class AddSubscribeComponent implements OnInit {
  @ViewChild('nzTreeComponent') nzTreeComponent: NzTreeComponent;
  selectedValue: any;
  baseValidateForm: FormGroup;
  itemValidateForm: FormGroup;
  agreementValidateForm: FormGroup;
  productConfigValidateForm: FormGroup;
  //权限相关
  addAccess = false;
  editAccess = false;
  deleteAccess = false;
  //查询相关
  checkChild: any = false;
  keyword: string = '';
  size = 5;
  page: number = 1;
  total: number = 0;

  //操作相关
  selectOptions = [];
  operateOptions = [];
  operateItem = null;
  viewIsVisible = false;
  addIsVisible = false;
  editIsVisible = false;
  preViewIsVisible = false;
  accessCodes: any = [];
  currentUser: any;

  objects = [];
  public objectInfo: any;
  public options: any;
  trades = [];
  values: string[] | null = null;


  ossEndPoint = 'http://ekyun-images.oss-cn-hangzhou.aliyuncs.com/oss-backend/'
  smsTypeOptions = [{value: 1, label: '文本短信'}, {value: 2, label: '视频短信'}]
  scaleOptions = [
    {value: 1530, label: '0-20人'},
    {value: 1531, label: '20-99人'},
    {value: 1532, label: '100-499人'},
    {value: 1533, label: '500-999人'},
    {value: 1534, label: '1000-4999人'},
    {value: 1535, label: '5000-9999人'},
    {value: 1536, label: '10000人以上'},
  ]
  agreementTypeOptions = [{value: 1, label: '开户合同'}, {value: 2, label: '续费合同'}]

  constructor(public router: Router,
              public fb: FormBuilder,
              public http: selfHttp,
              public modalService: NzModalService,
              public msg: NzMessageService,
  ) {
    this.buildForm();
  }


  onCascaderChanges(values: string[]): void {
    console.log(values, this.values);
  }

  ngOnInit(): void {
    this.arrayList.clear();
    this.loadAccessTemplateOptions();
    this.loadTrades();
  }


  buildForm() {
    this.baseValidateForm = this.fb.group({
      logo: [null],
      company_name: [null, [Validators.required]],
      credit_code: [null, [Validators.required]],
      admin_name: [null, [Validators.required]],
      mobile: [null, [Validators.required]],
      password: [null, [Validators.required]],
      trades: [[]],//所属行业
      scale: [null],//企业规模
      template_id: [null, [Validators.required]],
      address: [null],
      check_password: [null, [Validators.required, this.confirmationValidator]],
    });
    this.productConfigValidateForm = this.fb.group({
      sms_config: [],
      sdk_app_id: [],
      vital_crm_number: [],
      vital_seat_number: [],
      vital_phone_number: [],
      vital_msg_number: [],
      vital_call_number: [],
      vital_crm_price: [],
      vital_seat_price: [],
      vital_phone_price: [],
      vital_msg_price: [],
      vital_call_price: [],
      vital_price: [],
      discounted_vital_price: [],
    });
    this.itemValidateForm = this.fb.group({
      arrayList: this.fb.array([]),
      service_id: [null, [Validators.required]],
      product_id: [null, [Validators.required]],
      product_unit: [null],
      product_number: [0, [Validators.required]],
      effective_time: [0, [Validators.required]],
      discount: [100, [Validators.required]],
      single_price: [0],
      discounted_single_price: [0, [Validators.required]],
      vital_price: [0, [Validators.required]],
      discounted_vital_price: [0, [Validators.required]],
      scene_bind: [],
      productOptions: [[]]
    });
    this.agreementValidateForm = this.fb.group({
      title: [null, [Validators.required]],
      agreement_type: [1],
      company_name: [null],
      signing_date: [null, [Validators.required]],
      vital_price: [null],
      effective_dates: [[], [Validators.required]],
      company_charger: [null, [Validators.required]],
      mobile: [null, [Validators.required]],
      email: [null],
      address: [null],
      remark: [null],
      appendix_url: [null],
      sk_charger_id: [null, [Validators.required]],
      sales_person_id: [null],
    });
  }

  /**
   表单校验函数
   */
  updateConfirmValidator(): void {
    /** wait for refresh value */
    Promise.resolve().then(() => this.baseValidateForm.controls.check_password.updateValueAndValidity());
  }

  confirmationValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return {required: true};
    } else if (control.value !== this.baseValidateForm.controls.password.value) {
      return {confirm: true, error: true};
    }
    return {};
  }


  /**
   组件处理函数
   */

    //OCR识别
  ocrLoading = false

  handleImageOCR(object: any) {
    console.log(object)
    if (object.type == "start") {
      this.ocrLoading = true;
      const formData = new FormData();
      formData.append('file', object.file.originFileObj);
      this.http.post('http://localhost:8899:18467/nx/image/ocr', formData, res => {
        if (res.code == 2000) {
          console.log(res)
          this.baseValidateForm.patchValue({
            company_name: res.data['business_name'],
            credit_code: res.data['unified_social_credit_code'],
            address: res.data['address']
          })
          this.agreementValidateForm.patchValue({
            company_name: res.data['business_name'],
          })
        }
        this.ocrLoading = false;
      });
    }
  }

  //上传logo
  tmpUrl = ""
  uploadingLogo = false;

  handleChangeLogo(object: any): void {
    const formData = new FormData();
    console.log(object)
    if (object.type == "start") {
      formData.append('image', object.file.originFileObj);
      this.http.post('http://oss-api.nositek.com/oss-api/public/upload/images', formData, res => {
        if (res.code != 2000) {
          this.msg.error(res.message);
        } else {
          this.msg.success('上传成功');
          this.baseValidateForm.patchValue({
            logo: this.ossEndPoint + res.data['image_name']
          })
          this.tmpUrl = this.ossEndPoint + res.data['image_name']
        }
        this.uploadingLogo = false;
      });
    }
  }

  // 上传文件
  public fileList: any = [];
  uploading = false;
  beforeUpload = (file: NzUploadFile): boolean => {
    this.fileList = [];
    this.fileList.push(file);
    return false;
  };

  handleUpload(): void {
    const formData = new FormData();
    formData.append('file', this.fileList[0]);
    this.http.post('http://oss-api.nositek.com/oss-api/public/upload/files', formData, res => {
      if (res.code != 2000) {
        this.msg.error(res.message);
      } else {
        this.msg.success('上传成功');
        this.agreementValidateForm.patchValue({
          appendix_url: this.ossEndPoint + res.data['file_name']
        })
      }
      this.uploading = false;
      this.fileList = [];
    });
  }


  smsDisabled: boolean = true;
  tcccDisabled: boolean = true;

  appreciationPrice: number = 0;
  //动态表格
  //获取数组对象
  get arrayList() {
    return this.itemValidateForm.get('arrayList') as FormArray;
  }

  //创建一行组件
  createRow() {
    return this.fb.group({
      service_id: [''],
      product_id: [''],
      product_unit: [''],
      product_number: [1],
      effective_time: [0],
      discount: [100],
      single_price: [null],
      vital_price: [null],
      discounted_single_price: [null],
      discounted_vital_price: [null],
      scene_bind: [null],
      productOptions: [[]],
      disabled: false,
    });
  }

  //增加一行事件
  addBtn() {
    this.arrayList.push(this.createRow());
  }


  //todo:删除事件计算总统计数量
  // 删除一行事件
  delBtn(index: number) {
    if (this.arrayList.controls[index]['value']['product_number'] || this.arrayList.controls[index]['value']['discount']) {
      let currentItem = this.arrayList.controls[index]['value']
      let currentVitalPrice = Number((this.productConfigValidateForm.value.vital_price - currentItem['vital_price']).toFixed(3));
      let currentDiscountedVitalPrice = Number((this.productConfigValidateForm.value.discounted_vital_price - currentItem['discounted_vital_price']).toFixed(3));

      let currentVitalCRMNumber: number = this.productConfigValidateForm.value.vital_crm_number;
      let currentVitalSeatNumber: number = this.productConfigValidateForm.value.vital_seat_number;
      let currentVitalPhoneNumber: number = this.productConfigValidateForm.value.vital_phone_number;
      let currentVitalMsgNumber: number = this.productConfigValidateForm.value.vital_msg_number;
      let currentVitalCallNumber: number = this.productConfigValidateForm.value.vital_call_number;
      let currentVitalCrmPrice: number = this.productConfigValidateForm.value.vital_crm_price;
      let currentVitalSeatPrice: number = this.productConfigValidateForm.value.vital_seat_price;
      let currentVitalPhonePrice: number = this.productConfigValidateForm.value.vital_phone_price;
      let currentVitalMsgPrice: number = this.productConfigValidateForm.value.vital_msg_price;
      let currentVitalCallPrice: number = this.productConfigValidateForm.value.vital_call_price;

      switch (currentItem['scene_bind']) {
        case 1:
          currentVitalCRMNumber = Number(this.productConfigValidateForm.value.vital_crm_number - currentItem['product_number']);
          currentVitalCrmPrice = Number((this.productConfigValidateForm.value.vital_crm_price - currentItem['discounted_vital_price']).toFixed(3));
          break;
        case 11:
          currentVitalMsgNumber = Number(this.productConfigValidateForm.value.vital_msg_number - currentItem['product_number']);
          currentVitalMsgPrice = Number((this.productConfigValidateForm.value.vital_msg_price - currentItem['discounted_vital_price']).toFixed(3));
          break;
        case 20:
          currentVitalSeatNumber = Number(this.productConfigValidateForm.value.vital_seat_number - currentItem['product_number']);
          currentVitalSeatPrice = Number((this.productConfigValidateForm.value.vital_seat_price - currentItem['discounted_vital_price']).toFixed(3));
          break;
        case 21:
          currentVitalPhoneNumber = Number(this.productConfigValidateForm.value.vital_phone_number - currentItem['product_number']);
          currentVitalPhonePrice = Number((this.productConfigValidateForm.value.vital_phone_price - currentItem['discounted_vital_price']).toFixed(3));
          break;
        case 22:
          currentVitalCallNumber = Number(this.productConfigValidateForm.value.vital_call_number - currentItem['product_number']);
          currentVitalCallPrice = Number((this.productConfigValidateForm.value.vital_call_price - currentItem['discounted_vital_price']).toFixed(3));
          break;
      }

      this.productConfigValidateForm.patchValue({
        vital_crm_number: currentVitalCRMNumber,
        vital_seat_number: currentVitalSeatNumber,
        vital_phone_number: currentVitalPhoneNumber,
        vital_msg_number: currentVitalMsgNumber,
        vital_call_number: currentVitalCallNumber,
        vital_crm_price: currentVitalCrmPrice,
        vital_seat_price: currentVitalSeatPrice,
        vital_phone_price: currentVitalPhonePrice,
        vital_msg_price: currentVitalMsgPrice,
        vital_call_price: currentVitalCallPrice,
        vital_price: currentVitalPrice,
        discounted_vital_price: currentDiscountedVitalPrice,

      })
      this.vitalCRMNumber = currentVitalCRMNumber;
      this.vitalSeatNumber = currentVitalSeatNumber;
      this.vitalPhoneNumber = currentVitalPhoneNumber;
      this.vitalMsgNumber = currentVitalMsgNumber;
      this.vitalCallNumber = currentVitalCallNumber;
      this.vitalCrmPrice = currentVitalCrmPrice;
      this.vitalSeatPrice = currentVitalSeatPrice;
      this.vitalPhonePrice = currentVitalPhonePrice;
      this.vitalCallPrice = currentVitalCallPrice;
      this.vitalMsgPrice = currentVitalMsgPrice;
      this.vitalPrice = currentVitalPrice;
      this.discountedVitalPrice = currentDiscountedVitalPrice;
      this.arrayList.removeAt(index);
      this.smsDisabled = true;
      this.tcccDisabled = true;
      this.arrayList.controls.forEach(item => {
        if (item['value']['scene_bind'] == 11) {
          this.smsDisabled = false;
        }
        if (item['value']['scene_bind'] == 20 || item['value']['scene_bind'] == 21 || item['value']['scene_bind'] == 22) {
          this.tcccDisabled = false;
        }
      });
      if (this.smsDisabled) {
        this.productConfigValidateForm.patchValue({
          sms_type: 2,
          sms_config: null,
        })
      }
      if (this.tcccDisabled) {
        this.productConfigValidateForm.patchValue({
          sdk_app_id: null,
          staff_num: null,
          phones_num: null
        })

      }
    }
  }

  //保存数据
  saveData() {
    this.packageItems = []
    this.arrayList.controls.forEach((item, index) => {
      console.log(item)
      this.packageItems.push(item['value'])
    })
    console.log(this.packageItems)
  }

  //清空数据
  clearData() {
    this.arrayList.controls.forEach(item => {
      item.patchValue({
        service_id: '',
        product_id: '',
        product_number: '',
      });
    });
  }


  ossUserOptions = [];

  loadUserOptions() {
    this.ossUserOptions = [];
    this.http.get('http://oss-api.nositek.com/oss-api/pc/users', {}, res => {
      if (res) {
        this.ossUserOptions = res.data['rows'];
      } else {
        this.msg.info('暂无数据')
      }
    });
  }

  accessTemplateOptions = []

  loadAccessTemplateOptions() {
    this.accessTemplateOptions = [];
    this.http.get('http://oss-api.nositek.com/oss-api/pc/access_template', {online: 1}, res => {
      if (res) {
        this.accessTemplateOptions = res.data['rows'];
      } else {
        this.msg.info('暂无数据')
      }
    });
  }

  loadTrades() {
    this.trades = [];
    this.http.get('http://oss-api.nositek.com/oss-api/pc/company/trades', {}, res => {
      if (res) {
        this.trades = res.data['rows'];
        console.log(this.trades)
      } else {
        this.trades = [];
      }
    });
  }


  loadOperateServiceData() {
    this.operateServiceOptions = [];
    this.http.get('http://oss-api.nositek.com/oss-api/pc/services', {}, res => {
      if (res.data['rows'] != null && res.data['rows'].length > 0) {
        this.operateServiceOptions = res.data['rows'];
      } else {
        this.msg.info('暂无数据');
      }
    });
  }

  loadOperateProductData(object: any, index: number) {
    this.http.get('http://oss-api.nositek.com/oss-api/pc/products', {service_id: object}, res => {
      if (res.data['rows'] != null && res.data['rows'].length > 0) {
        this.operateProductOptions = res.data['rows'];
        this.arrayList.controls[index].patchValue({
          product_id: null,
          product_unit: null,
          product_number: 1,
          effective_time: 0,
          discount: 100,
          single_price: null,
          vital_price: null,
          discounted_vital_price: null,
          discounted_single_price: null,
          productOptions: this.operateProductOptions,
        })
      } else {
        this.msg.info('暂无数据');
      }
    });
  }

  /*
  功能处理函数
   */

  preView() {
    this.preViewIsVisible = true
  }

  preViewCancel() {
    this.preViewIsVisible = false
  }

  preViewOk() {
    this.preViewIsVisible = false
  }


  add_dept = false;
  edit_dept = false;
  delete_dept = false;
  add_user = true;
  edit_user = false;
  delete_user = false;

  loadAccess() {
    console.log('加载页面操作权限');
    // let tempArr = JSON.parse(localStorage.getItem('access'));
    // console.log(tempArr);
    // this.add_dept = tempArr.findIndex(v => v.name == 'add_dept') != -1;
    // this.edit_dept = tempArr.findIndex(v => v.name == 'edit_dept') != -1;
    // this.delete_dept = tempArr.findIndex(v => v.name == 'delete_dept') != -1;
    // this.add_user = tempArr.findIndex(v => v.name == 'add_user') != -1;
    // this.edit_user = tempArr.findIndex(v => v.name == 'edit_user') != -1;
    // this.delete_user = tempArr.findIndex(v => v.name == 'delete_user') != -1;
  }

  current = 0;


  fetchProductUnit(optionId: any, index: number) {
    if (optionId) {
      let i = this.operateProductOptions.map(item => item.id).indexOf(optionId);
      this.arrayList.controls[index].patchValue({
        product_unit: this.operateProductOptions[i].product_unit,
        single_price: this.operateProductOptions[i].single_price,
        product_number: 1,
        effective_time: 0,
        discount: 100,
        discounted_price: this.operateProductOptions[i].single_price,
        vital_price: this.operateProductOptions[i].single_price,
        vital_discounted_price: this.operateProductOptions[i].single_price,
        scene_bind: this.operateProductOptions[i].scene_bind,
      })
    }
  }

  vitalPrice: any = 0;
  discountedVitalPrice: any = 0;
  vitalCRMNumber: any = 0;
  vitalSeatNumber: any = 0;
  vitalPhoneNumber: any = 0;
  vitalMsgNumber: any = 0;
  vitalCallNumber: any = 0;
  vitalCrmPrice: any = 0;
  vitalSeatPrice: any = 0;
  vitalPhonePrice: any = 0;
  vitalMsgPrice: any = 0;
  vitalCallPrice: any = 0;

  agreementNo: string = '';
  companyName: string = ''
  signingDate: string = ''



  // todo:添加事件计算总统计数量
  inputChange(index: number) {
    // switch (formControlName) {
    //   case 'productNumber':
    //     let validProductNumber: string = ""+this.arrayList.controls[index]['value']['product_number'];
    //     console.log(validProductNumber)
    //     //限制数字输入
    //     //先把非数字的都替换掉，除了数字和.
    //     validProductNumber = validProductNumber.replace(/[^\d.]/g, "");
    //     //必须保证第一个为数字而不是.
    //     validProductNumber = validProductNumber.replace(/^\./g, "");
    //     //保证只有出现一个.而没有多个.
    //     validProductNumber = validProductNumber.replace(/\.{2,}/g, "");
    //     //保证.只出现一次，而不能出现两次以上
    //     validProductNumber = validProductNumber.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
    //     this.arrayList.controls[index].patchValue({
    //         product_number: validProductNumber
    //       })
    //     break;
    //   case 'discount':
    //     let validDiscount: any = this.arrayList.controls[index]['value']['product_number'];
    //     //限制数字输入
    //     //先把非数字的都替换掉，除了数字和.
    //     validDiscount = validDiscount.replace(/[^\d.]/g, "");
    //     //必须保证第一个为数字而不是.
    //     validDiscount = validDiscount.replace(/^\./g, "");
    //     //保证只有出现一个.而没有多个.
    //     validDiscount = validDiscount.replace(/\.{2,}/g, "");
    //     //保证.只出现一次，而不能出现两次以上
    //     validDiscount = validDiscount.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
    //     this.arrayList.controls[index].patchValue({
    //       discount: validDiscount
    //     })
    //     break;
    //
    // }
    this.arrayList.controls.forEach(item => {
      if (item['value']['scene_bind'] == 11) {
        this.smsDisabled = false;
      }
      if (item['value']['scene_bind'] == 20 || item['value']['scene_bind'] == 21 || item['value']['scene_bind'] == 22) {
        this.tcccDisabled = false;
      }
    });

    let currentItem = this.arrayList.controls[index]['value']
    if (currentItem['single_price']) {
      if (currentItem['product_number'] || currentItem['discount']) {
        //处理单行数据
        this.arrayList.controls[index].patchValue({
          vital_price: Number((currentItem['single_price'] * currentItem['product_number']).toFixed(3)),
          discounted_single_price: Number((currentItem['single_price'] * currentItem['discount'] / 100).toFixed(3)),
          discounted_vital_price: Number((currentItem['single_price'] * currentItem['product_number'] * currentItem['discount'] / 100).toFixed(3)),
        })
        /*
        处理汇总统计数据
         */
        //所有行都需要汇总计算总价和折后总价
        let vitalPrice: number = 0;
        let discountedVitalPrice: number = 0;
        //特殊行（视频短信包和外呼语音包）需要分别统计总量和总钱
        let vitalCRMNumber = 0;
        let vitalSeatNumber = 0;
        let vitalPhoneNumber = 0;
        let vitalMsgNumber: number = 0;
        let vitalCallNumber: number = 0;
        let vitalCRMPrice: number = 0;
        let vitalSeatPrice: number = 0;
        let vitalPhonePrice: number = 0;
        let vitalMsgPrice: number = 0;
        let vitalCallPrice: number = 0;

        this.arrayList.controls.forEach((item, index) => {
          vitalPrice = Number((vitalPrice + parseFloat(item['value']['vital_price'])).toFixed(3))
          discountedVitalPrice = Number((discountedVitalPrice + parseFloat(item['value']['discounted_vital_price'])).toFixed(3))
          switch (item['value']['scene_bind']) {
            case 1://统计CRM座席数量
              vitalCRMNumber = Number(vitalCRMNumber + parseFloat(item['value']['product_number']))
              vitalCRMPrice = Number((vitalCRMPrice + parseFloat(item['value']['discounted_vital_price'])).toFixed(3))
              break;
            case 11: //统计视频短信包
              vitalMsgNumber = Number(vitalMsgNumber + parseFloat(item['value']['product_number']))
              vitalMsgPrice = Number((vitalMsgPrice + parseFloat(item['value']['discounted_vital_price'])).toFixed(3))
              break;
            case 20://统计外呼座席数量
              vitalSeatNumber = Number(vitalSeatNumber + parseFloat(item['value']['product_number']))
              vitalSeatPrice = Number((vitalSeatPrice + parseFloat(item['value']['discounted_vital_price'])).toFixed(3))
              break;
            case 21://统计外呼号码数量
              vitalPhoneNumber = Number(vitalPhoneNumber + parseFloat(item['value']['product_number']))
              vitalPhonePrice = Number((vitalPhonePrice + parseFloat(item['value']['discounted_vital_price'])).toFixed(3))
              break;
            case 22://统计外呼语音包
              vitalCallNumber = Number(vitalCallNumber + parseFloat(item['value']['product_number']))
              vitalCallPrice = Number((vitalCallPrice + parseFloat(item['value']['discounted_vital_price'])).toFixed(3))
              break;
          }
        })
        this.productConfigValidateForm.patchValue({
          vital_crm_number: vitalCRMNumber,
          vital_seat_number: vitalSeatNumber,
          vital_phone_number: vitalPhoneNumber,
          vital_msg_number: vitalMsgNumber,
          vital_call_number: vitalCallNumber,
          vital_crm_price: vitalCRMPrice,
          vital_seat_price: vitalSeatPrice,
          vital_phone_price: vitalPhonePrice,
          vital_call_price: vitalCallPrice,
          vital_msg_price: vitalMsgPrice,
          vital_price: vitalPrice,
          discounted_vital_price: discountedVitalPrice,
        })
        this.agreementValidateForm.patchValue({
          vital_price: discountedVitalPrice,
        })
        this.vitalCRMNumber = vitalCRMNumber;
        this.vitalSeatNumber = vitalSeatNumber;
        this.vitalPhoneNumber = vitalPhoneNumber;
        this.vitalMsgNumber = vitalMsgNumber;
        this.vitalCallNumber = vitalCallNumber;
        ;
        this.vitalCrmPrice = vitalCRMPrice;
        this.vitalSeatPrice = vitalSeatPrice;
        this.vitalPhonePrice = vitalPhonePrice;
        this.vitalMsgPrice = vitalMsgPrice
        this.vitalCallPrice = vitalCallPrice;
        this.vitalPrice = vitalPrice;
        this.discountedVitalPrice = discountedVitalPrice;
      }
    }

  }

  companyNameChange(object: any) {
    // if (object){
    this.agreementValidateForm.patchValue({
      company_name: this.baseValidateForm.value.company_name
    })
    // }
  }

  pre() {
    this.current -= 1;
  }

  next() {
    switch (this.current) {
      case 0 : {
        for (const i in this.baseValidateForm.controls) {
          this.baseValidateForm.controls[i].markAsDirty();
          this.baseValidateForm.controls[i].updateValueAndValidity();
        }
        if (this.baseValidateForm.value.company_name && this.baseValidateForm.value.credit_code && this.baseValidateForm.value.admin_name &&
          this.baseValidateForm.value.mobile && this.baseValidateForm.value.password && this.baseValidateForm.value.check_password &&
          this.baseValidateForm.value.template_id) {
          this.loadOperateServiceData();
          this.current += 1;
        }
        break;
      }
      case 1: {
        this.loadUserOptions();
        this.current += 1;
        break;
      }
      case 2: {

        break;
      }
      default:
        console.log('放行')
    }

  }

  operateType: number = -1

  done(): void {
    for (const i in this.agreementValidateForm.controls) {
      this.agreementValidateForm.controls[i].markAsDirty();
      this.agreementValidateForm.controls[i].updateValueAndValidity();
    }
    if (this.agreementValidateForm.value.title && this.agreementValidateForm.value.signing_date &&
      this.agreementValidateForm.value.effective_dates && this.agreementValidateForm.value.company_charger &&
      this.agreementValidateForm.value.mobile && this.agreementValidateForm.value.sk_charger_id) {
      this.current += 1;
    }
    this.operateType = 1
    this.packageItems = []
    this.arrayList.controls.forEach((item, index) => {
      this.packageItems.push(item['value'])
    })

    let sdkAppId = Number(this.productConfigValidateForm.value.sdk_app_id)
    this.productConfigValidateForm.patchValue({
      sdk_app_id: sdkAppId
    })

    // if (this.validateForm.value.admin_name && this.validateForm.value.mobile && this.validateForm.value.role_id) {
    this.http.post('http://oss-api.nositek.com/oss-api/pc/sk-account', {
      operate_type: this.operateType,
      company: this.baseValidateForm.value,
      product_vital: this.productConfigValidateForm.value,
      product_items: this.packageItems,
      agreement: this.agreementValidateForm.value,
    }, res => {
      if (res.code == 2000) {
        this.signingDate = res.data['signing_date'];
        this.companyName = this.agreementValidateForm.value['company_name'];
        this.agreementNo = res.data['row'];
        console.log(this.signingDate)
        this.successIsVisible = true;
        this.failedIsVisible = false;
      } else {
        this.failedIsVisible = true;
        this.successIsVisible = false;
      }
      // }
    })
  }

  save(): void {
    for (const i in this.agreementValidateForm.controls) {
      this.agreementValidateForm.controls[i].markAsDirty();
      this.agreementValidateForm.controls[i].updateValueAndValidity();
    }
    if (this.agreementValidateForm.value.title && this.agreementValidateForm.value.signing_date &&
      this.agreementValidateForm.value.effective_dates && this.agreementValidateForm.value.company_charger &&
      this.agreementValidateForm.value.mobile && this.agreementValidateForm.value.sk_charger_id) {
      this.current += 1;
    }
    this.operateType = 0
    this.packageItems = []
    this.arrayList.controls.forEach((item, index) => {
      this.packageItems.push(item['value'])
    })


    // if (this.validateForm.value.admin_name && this.validateForm.value.mobile && this.validateForm.value.role_id) {
    this.http.post('http://oss-api.nositek.com/oss-api/pc/sk-account', {
      operate_type: this.operateType,
      company: this.baseValidateForm.value,
      product_detail: this.productConfigValidateForm.value,
      items: this.packageItems,
      agreement: this.agreementValidateForm.value,
    }, res => {
      console.log(res)
      if (res.code == 2000) {
        this.failedIsVisible = true;
        this.msg.success('添加成功');
      } else if (res.code == 100003) {
        this.failedIsVisible = false;
        this.msg.info(res.msg)
      } else {
        this.failedIsVisible = false;
        this.msg.error(res.msg)
      }
    })
  }

  return() {
    this.router.navigateByUrl("/sidebar/subscribe")
  }

  continue() {
    this.arrayList.clear();
    this.baseValidateForm.reset();
    this.productConfigValidateForm.reset();
    this.itemValidateForm.reset();
    this.agreementValidateForm.reset();
    this.agreementValidateForm.patchValue({
      agreement_type: 1
    })
    this.current = 0;
  }

  reEdit() {
    this.current = 0;
  }

  successIsVisible: boolean = false;
  failedIsVisible: boolean = false;
  operateServiceOptions = []
  operateProductOptions = []
  packageItems = []
  timeOptions = [
    {label: '不限制', value: 0},
    {label: '1个月', value: 1},
    {label: '2个月', value: 2},
    {label: '3个月', value: 3},
    {label: '4个月', value: 4},
    {label: '5个月', value: 5},
    {label: '半年', value: 6},
    {label: '7个月', value: 7},
    {label: '8个月', value: 8},
    {label: '9个月', value: 9},
    {label: '10个月', value: 10},
    {label: '11个月', value: 11},
    {label: '一年', value: 12},
  ]


}

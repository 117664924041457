import {Component, ViewChild, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzModalService} from 'ng-zorro-antd/modal';
import {selfHttp} from '../../../httpservice/http.service';
import {HttpHeaders} from '@angular/common/http';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NzTreeComponent} from 'ng-zorro-antd/tree';
import {NzUploadFile} from "ng-zorro-antd/upload";
import {Observable, Observer} from "rxjs";
import {NzCascaderOption} from "ng-zorro-antd";

const testOptions = [
  {
    value: 'zhejiang',
    label: 'Zhejiang',
    children: [
      {
        value: 'hangzhou',
        label: 'Hangzhou',
        children: [
          {
            value: 'xihu',
            label: 'West Lake',
            isLeaf: true
          }
        ]
      },
      {
        value: 'ningbo',
        label: 'Ningbo',
        isLeaf: true
      }
    ]
  },
  {
    value: 'jiangsu',
    label: 'Jiangsu',
    children: [
      {
        value: 'nanjing',
        label: 'Nanjing',
        children: [
          {
            value: 'zhonghuamen',
            label: 'Zhong Hua Men',
            isLeaf: true
          }
        ]
      }
    ]
  }
];

@Component({
  selector: 'subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.css']
})


export class SubscribeComponent implements OnInit {
  @ViewChild('nzTreeComponent') nzTreeComponent: NzTreeComponent;
  selectedValue: any;
  baseValidateForm: FormGroup;
  itemValidateForm: FormGroup;
  agreementValidateForm: FormGroup;
  editValidateForm: FormGroup;
  //权限相关
  addAccess = false;
  editAccess = false;
  deleteAccess = false;
  //查询相关
  checkChild: any = false;
  keyword: string = '';
  size = 5;
  page: number = 1;
  total: number = 0;

  //操作相关
  selectOptions = [];
  operateOptions = [];
  operateItem = null;
  viewIsVisible = false;
  addIsVisible = false;
  editIsVisible = false;
  preViewIsVisible = false;
  accessCodes: any = [];
  currentUser: any;

  appendixList: NzUploadFile[] = [];
  uploading = false;

  objects = [];
  public objectInfo: any;
  public options: any;


  constructor(public router: Router,
              public fb: FormBuilder,
              public http: selfHttp,
              public modalService: NzModalService,
              public msg: NzMessageService,
  ) {
    this.loadObjectData();
    this.loadAccess();
    this.buildForm();
  }

  nzOptions: NzCascaderOption[] = testOptions;
  values: string[] | null = null;

  onCascaderChanges(values: string[]): void {
    console.log(values, this.values);
  }

  ngOnInit(): void {
  }

  /*
审批
 */
  preEdit(object: any) {
    console.log(object)
    this.editIsVisible = true;
    this.editValidateForm.reset();
    this.editValidateForm.patchValue({
      id: object.id,
      status: true,
    });
  }

  //审批取消
  editCancel() {
    this.editIsVisible = false;
    this.loadObjectData();
    this.editValidateForm.reset();
  }

  //审批提交
  editCommit() {
    this.http.put('http://oss-api.nositek.com/oss-api/pc/sk-account', {
      id: this.editValidateForm.value.id,
      status: this.editValidateForm.value.status ? 1 : 2,
      remark: this.editValidateForm.value.remark,
    }, res => {
      if (res.code == 2000) {
        this.msg.success('审核成功');
      } else if (res.code == 100003) {
        this.msg.info(res.msg)
      } else {
        this.msg.error(res.msg)
      }
      this.loadObjectData();
    });

    this.editIsVisible = false;

  }

  preViewCancel() {
    this.preViewIsVisible = false
  }

  preViewOk() {
    this.preViewIsVisible = true
  }

  company_license_url;
  oss_save_url;
  loadingImg;
  tradeOptions: []

  // tradeSelected:[]
  buildForm() {
    this.editValidateForm =this.fb.group({
        id: [0],
        status: [true, [Validators.required]],
        remark: [null, [Validators.required]],
    })
    this.baseValidateForm = this.fb.group({
      company_name: [null, [Validators.required]],
      uniform_social_credit_code: [null, [Validators.required]],
      user_name: [null, [Validators.required]],
      mobile: [null, [Validators.required]],
      password: [null, [Validators.required]],
      check_password: [null, [Validators.required, this.confirmationValidator]],
      trades: [[], [Validators.required]],//所属行业
      enterprise_size: [null, [Validators.required]],//企业规模
      address: [null, [Validators.required]],
    });
    this.itemValidateForm = this.fb.group({
      arrayList: this.fb.array([]),
      service_id: [null, [Validators.required]],
      product_id: [null, [Validators.required]],
      product_unit: [null],
      product_number: [0, [Validators.required]],
      effective_time: [0, [Validators.required]],
      discount: [100, [Validators.required]],
      single_price: [0],
      discounted_price: [0, [Validators.required]],
      vital_price: [0, [Validators.required]],
      vital_discounted_price: [0, [Validators.required]],
      productOptions: [[]]
    })
    this.agreementValidateForm = this.fb.group({
      title: [null, [Validators.required]],
      amount: [null, [Validators.required]],
      customer_name: [null, [Validators.required]],
      customer_charger: [null, [Validators.required]],
      sk_charger: [null, [Validators.required]],
      salesperson: [null, [Validators.required]],
      signing_date: [null, [Validators.required]],
      effective_dates: [[], [Validators.required]],
      mobile: [null, [Validators.required]],
      email: [null, [Validators.required]],
      address: [null, [Validators.required]],
      remark: [null, [Validators.required]],
    });
  }

  beforeUploadImg = (file: NzUploadFile, _fileList: NzUploadFile[]) => {
    return new Observable((observer: Observer<boolean>) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        this.msg.error('You can only upload JPG file!');
        observer.complete();
        return;
      }
      const isLt2M = file.size! / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.msg.error('Image must smaller than 2MB!');
        observer.complete();
        return;
      }
      observer.next(isJpgOrPng && isLt2M);
      observer.complete();
    });
  };

  private getBase64(img: File, callback: (img: string) => void): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result!.toString()));
    reader.readAsDataURL(img);
  }

  handleImgChange(info: { file: NzUploadFile }): void {
    switch (info.file.status) {
      case 'uploading':
        this.loadingImg = true;
        break;
      case 'done':
        // Get this url from response in real world.
        this.getBase64(info.file!.originFileObj!, (img: string) => {
          this.loadingImg = false;
          this.company_license_url = img;
        });
        break;
      case 'error':
        this.msg.error('Network error');
        this.loadingImg = false;
        break;
    }
  }


  //点击更换头像的回调
  beforeUploadAppendix = (file: NzUploadFile): boolean => {
    // this.appendixList.push(file);
    this.appendixList = this.appendixList.concat(file);
    return false;
  };


  //点击上传头像的回调
  handleUploadAppendix(): void {
    const formData = new FormData();
    // tslint:disable-next-line:no-any
    this.appendixList.forEach((file: any) => {
      formData.append('files[]', file);
    });
    this.uploading = true;
    // You can use any AJAX library you like
    // const req = new HttpRequest('POST', 'https://www.mocky.io/v2/5cc8019d300000980a055e76', formData, {
    //   // reportProgress: true
    // });
    // formData.append('image', this.fileList2[0]);
    // this.uploadIsShow = false;
    // this.uploading = false;
    // let tmpUrl:string
    // this.http.post('http://oss-api.nositek.com/oss-api/loccay/image', formData, res => {
    //   tmpUrl = res.data;
    //   this.userAvatar = 'http://oss-api.nositek.com/oss-api/loccay/images/' + tmpUrl;
    //   this.fileList2 = [];
    //   this.http.put('http://oss-api.nositek.com/oss-api/pcusers/avatar', {
    //     id: this.validateForm.value.id,
    //     avatar: tmpUrl,
    //   }, res => {
    //     localStorage.setItem('avatar', tmpUrl);
    //     this.msg.success('上传成功');
    //   });
    // });
    // this.changeImageIsShow = true;
  }

  updateConfirmValidator(): void {
    /** wait for refresh value */
    Promise.resolve().then(() => this.baseValidateForm.controls.check_password.updateValueAndValidity());
  }

  confirmationValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return {required: true};
    } else if (control.value !== this.baseValidateForm.controls.password.value) {
      return {confirm: true, error: true};
    }
    return {};
  }

  loadObjectData() {
    this.http.get('http://oss-api.nositek.com/oss-api/pc/sk-account', {
      page: this.page,
      size: this.size,
      keyword: this.keyword,
    }, res => {
      if (res.data['rows'] != null && res.data['rows'].length > 0) {
        this.objects = res.data['rows'];
        this.total = Number(res.data['total']);
      } else {
        this.objects = [];
        this.total = 0;
        this.msg.info('暂无数据');
      }
    });
  }

  // 复选框
  onChange(data: any, checked: any) {
    this.objectInfo = data;
    if (checked === 'single') {
      if (data.checked) {
        this.operateOptions.push(data.id);
      } else {
        let index = this.operateOptions.indexOf(data.id);
        this.operateOptions.splice(index, 1);
      }
    }
  }

// 重置
  reset() {
    this.page = 1;
    this.size = 10;
    this.keyword = '';
    this.loadObjectData();
  }

  //页码切换
  indexChange() {
    console.log(this.page, this.size);
    this.loadObjectData();
  }


  preDetail(object: any) {
    this.router.navigateByUrl('/sidebar/subscribe-detail');
    // this.viewIsVisible = true
  }

  //添加取消
  closeDetail() {
    this.viewIsVisible = false;
  }


  /*
  添加系统用户
   */
  preAdd() {
    this.router.navigateByUrl('/sidebar/add-subscribe');
    // this.addIsVisible = true;
    // this.arrayList.clear();
    // this.itemValidateForm.reset()
    // this.loadOptionData();
    // this.loadOperateServiceData();
  }

  loadOptionData() {
    this.http.get('http://oss-api.nositek.com/oss-api/pc/roles', {}, res => {
      if (res.data['rows'] != null) {
        this.selectOptions = res.data['rows'];
      } else {
        this.selectOptions = [];
        this.msg.info('暂无数据');
      }

    });
  }

  //添加取消
  addCancel() {
    this.addIsVisible = false;
    // this.validateForm.reset();
    // this.validateForm.patchValue({
    //   status: true,
    // });
  }

  //添加提交
  addCommit() {
    // for (const i in this.validateForm.controls) {
    //   this.validateForm.controls[i].markAsDirty();
    //   this.validateForm.controls[i].updateValueAndValidity();
    // }
    // let enable: number;
    // if (this.validateForm.value.status) {
    //   enable = 1;
    // } else {
    //   enable = 0;
    // }
    // if (this.validateForm.value.user_name && this.validateForm.value.mobile && this.validateForm.value.role_id) {
    //   this.http.post('http://oss-api.nositek.com/oss-api/pc/users', {
    //     'user_name': this.validateForm.value.user_name,
    //     'role_id': this.validateForm.value.role_id,
    //     'mobile': this.validateForm.value.mobile,
    //     'status': enable,
    //   }, res => {
    //     console.log(res)
    //     if (res.code == 2000) {
    //       this.msg.success('添加成功');
    //     } else if (res.code == 100003) {
    //       this.msg.info(res.msg)
    //     } else {
    //       this.msg.error(res.msg)
    //     }
    //     this.validateForm.reset();
    //     this.validateForm.patchValue({
    //       status: true,
    //     });
    //     this.addIsVisible = false;
    //     this.loadObjectData();
    //   });
    // }
  }

  /*
  修改系统用户
   */
  // preEdit() {
  //   // if (this.operateOptions.length == 0) {
  //   //   this.msg.info('请选择数据！');
  //   //   return;
  //   // } else if (this.operateOptions.length > 1) {
  //   //   this.msg.info('请逐条记录操作！');
  //   //   return;
  //   // }
  //   // this.loadOptionData();
  //   // let index = this.objects.map(item => item.id).indexOf(this.operateOptions[0]);
  //   // this.operateItem = this.objects[index];
  //   // this.validateForm.patchValue({
  //   //   id: this.operateItem.id,
  //   //   user_name: this.operateItem.name,
  //   // });
  //   // if (this.operateItem.status == 1) {
  //   //   this.validateForm.patchValue({
  //   //     'status': true
  //   //   });
  //   // } else {
  //   //   this.validateForm.patchValue({
  //   //     'status': false
  //   //   });
  //   // }
  //   // this.validateForm.patchValue({
  //   //   user_name: this.operateItem.user_name,
  //   //   role_id: this.operateItem.role_id,
  //   //   mobile: this.operateItem.mobile,
  //   // })
  //   //
  //   // this.editIsVisible = true;
  // }
  //
  // //修改取消
  // editCancel() {
  //   // this.editIsVisible = false;
  //   // this.operateOptions = [];
  //   // this.loadObjectData();
  //   // this.validateForm.reset();
  //   // this.validateForm.patchValue({
  //   //   status: true,
  //   // });
  // }
  //
  // //修改提交
  // editCommit() {
  //   // for (const i in this.validateForm.controls) {
  //   //   this.validateForm.controls[i].markAsDirty();
  //   //   this.validateForm.controls[i].updateValueAndValidity();
  //   // }
  //   // let enable: number;
  //   // if (this.validateForm.value.status) {
  //   //   enable = 1;
  //   // } else {
  //   //   enable = 0;
  //   // }
  //   // if (this.validateForm.value.user_name && this.validateForm.value.mobile && this.validateForm.value.role_id) {
  //   //   this.http.post('http://oss-api.nositek.com/oss-api/pc/users', {
  //   //     'id': Number(this.objectInfo.id),
  //   //     'user_name': this.validateForm.value.user_name,
  //   //     'role_id': this.validateForm.value.role_id,
  //   //     'mobile': this.validateForm.value.mobile,
  //   //     'status': enable,
  //   //   }, res => {
  //   //     if (res.code == 2000) {
  //   //       this.msg.success('编辑成功');
  //   //     } else if (res.code == 100003) {
  //   //       this.msg.info(res.msg)
  //   //     } else {
  //   //       this.msg.error(res.msg)
  //   //     }
  //   //     this.validateForm.reset();
  //   //     this.validateForm.patchValue({
  //   //       status: true,
  //   //     });
  //   //     this.editIsVisible = false;
  //   //     this.loadObjectData();
  //   //   });
  //   // }
  //
  // }


  /*
  删除用户
   */
  preDelete() {
    if (this.operateOptions.length == 0) {
      this.msg.info('请选择数据！');
      return;
    }
    this.modalService.confirm({
      nzTitle: '<i>确定删除数据 ？</i>',
      nzContent: '<b></b>',
      nzOkText: '确定',
      nzCancelText: '取消',
      nzOnOk: () => this.deleteCommit(),
      nzOnCancel: () => this.deleteCancel()
    });
  }

  // 删除提交
  deleteCommit() {
    this.options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        ids: this.operateOptions
      }
    };
    this.http.delete('http://oss-api.nositek.com/oss-api/pc/users', this.options, res => {
      if (res.code == 2000) {
        this.msg.success('删除成功');
      } else {
        this.msg.info(res.msg);
      }
      this.loadObjectData();
    });
    this.operateOptions = [];
  }

  // 删除取消
  deleteCancel() {
    this.operateOptions = [];
    this.loadObjectData();
  }


  add_dept = false;
  edit_dept = false;
  delete_dept = false;
  add_user = true;
  edit_user = false;
  delete_user = false;

  loadAccess() {
    console.log('加载页面操作权限');
    // let tempArr = JSON.parse(localStorage.getItem('access'));
    // console.log(tempArr);
    // this.add_dept = tempArr.findIndex(v => v.name == 'add_dept') != -1;
    // this.edit_dept = tempArr.findIndex(v => v.name == 'edit_dept') != -1;
    // this.delete_dept = tempArr.findIndex(v => v.name == 'delete_dept') != -1;
    // this.add_user = tempArr.findIndex(v => v.name == 'add_user') != -1;
    // this.edit_user = tempArr.findIndex(v => v.name == 'edit_user') != -1;
    // this.delete_user = tempArr.findIndex(v => v.name == 'delete_user') != -1;
  }

  current = 0;

  index = 'First-content';

  pre(): void {
    this.current -= 1;
    this.changeContent();
  }

  next(): void {
    this.current += 1;
    this.changeContent();
  }

  changeContent(): void {
    switch (this.current) {
      case 0: {
        this.index = 'First-content';
        break;
      }
      case 1: {
        this.index = 'Second-content';
        break;
      }
      case 2: {
        this.index = 'third-content';
        break;
      }
      default: {
        this.index = 'error';
      }
    }
  }

  done(): void {
    console.log('done');
  }


  operateServiceOptions = []
  operateProductOptions = []
  packageItems = []
  timeOptions = [
    {label: '不限制', value: 0},
    {label: '1个月', value: 1},
    {label: '2个月', value: 2},
    {label: '3个月', value: 3},
    {label: '4个月', value: 4},
    {label: '5个月', value: 5},
    {label: '半年', value: 6},
    {label: '7个月', value: 7},
    {label: '8个月', value: 8},
    {label: '9个月', value: 9},
    {label: '10个月', value: 10},
    {label: '11个月', value: 11},
    {label: '一年', value: 12},
  ]

  loadOperateServiceData() {
    this.http.get('http://oss-api.nositek.com/oss-api/pc/services', {}, res => {
      if (res.data['rows'] != null && res.data['rows'].length > 0) {
        this.operateServiceOptions = res.data['rows'];
      } else {
        this.msg.info('暂无数据');
      }
    });
  }

  loadOperateProductData(object: any, index: number) {
    this.arrayList.controls[index].patchValue({
      product_id: null,
      product_unit: null,
      product_number: 1,
      effective_time: 0,
      discount: 100,
      single_price: null,
      discounted_price: null,
      vital_price: null,
      vital_discounted_price: null,
    })
    this.http.get('http://oss-api.nositek.com/oss-api/pc/products', {service_id: object}, res => {
      if (res.data['rows'] != null && res.data['rows'].length > 0) {
        this.operateProductOptions = res.data['rows'];
      } else {
        this.msg.info('暂无数据');
      }
    });
  }


  fetchProductUnit(optionId: any, index: number) {
    if (optionId) {
      let i = this.operateProductOptions.map(item => item.id).indexOf(optionId);
      this.arrayList.controls[index].patchValue({
        product_unit: this.operateProductOptions[i].product_unit,
        single_price: this.operateProductOptions[i].single_price,
        product_number: 1,
        effective_time: 0,
        discount: 100,
        discounted_price: this.operateProductOptions[i].single_price,
        vital_price: this.operateProductOptions[i].single_price,
        vital_discounted_price: this.operateProductOptions[i].single_price,
        productOptions: this.operateProductOptions,
      })
    }
  }

  toService() {
    this.router.navigateByUrl('/sidebar/services')
  }

  toProduct() {
    this.router.navigateByUrl('/sidebar/products')
  }

  inputChange(index: number) {
    let single_price = this.arrayList.controls[index]['value']['single_price']
    if (single_price) {
      if (this.arrayList.controls[index]['value']['product_number'] || this.arrayList.controls[index]['value']['discount']) {
        this.arrayList.controls[index].patchValue({
          discounted_price: (single_price * this.arrayList.controls[index]['value']['discount'] / 100).toFixed(3),
          vital_price: (single_price * this.arrayList.controls[index]['value']['product_number']).toFixed(3),
          vital_discounted_price: (single_price * this.arrayList.controls[index]['value']['product_number'] * this.arrayList.controls[index]['value']['discount'] / 100).toFixed(3),
        })
        let package_price = null
        let discounted_price = null
        this.arrayList.controls.forEach((item, index) => {
          package_price = package_price + parseFloat(item['value']['vital_price'])
          discounted_price = discounted_price + parseFloat(item['value']['vital_discounted_price'])
        })
        // this.validateForm.patchValue({
        //   package_price: package_price,
        //   discounted_price: discounted_price,
        // })
      }
    }

  }

  /**
   * 获取数组对象
   */
  get arrayList() {
    return this.itemValidateForm.get('arrayList') as FormArray;
  }

  /**
   * 创建一行组件
   */
  createRow() {
    return this.fb.group({
      service_id: [''],
      product_id: [''],
      product_unit: [''],
      product_number: [1],
      effective_time: [0],
      discount: [100],
      single_price: [null],
      discounted_price: [null],
      vital_price: [null],
      vital_discounted_price: [null],
      productOptions: [[]],
      disabled: false,
    });
  }

  /**
   * 增加一行事件
   */
  addBtn() {
    this.arrayList.push(this.createRow());
  }

  /**
   * 删除一行事件
   */
  delBtn(i: number) {
    this.arrayList.removeAt(i);
  }

  /**
   * 保存数据
   */
  saveData() {
    this.packageItems = []
    this.arrayList.controls.forEach((item, index) => {
      console.log(item)
      this.packageItems.push(item['value'])
    })
    console.log(this.packageItems)
  }

  /**
   * 清空数据
   */
  clearData() {
    this.arrayList.controls.forEach(item => {
      item.patchValue({
        service_id: '',
        product_id: '',
        product_number: '',
      });
    });
  }

}

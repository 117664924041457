import {Component, ViewChild, OnInit} from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {Router} from '@angular/router';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzModalService} from 'ng-zorro-antd/modal';
import {selfHttp} from "@src/app/httpservice/http.service";
import {HttpHeaders} from '@angular/common/http';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NzTreeComponent} from 'ng-zorro-antd/tree';
import {NzUploadFile} from "ng-zorro-antd/upload";

export interface TreeNodeInterface {
  id: number;
  parent_id: number;
  create_time: string;
  menu_name: string;
  title: string;
  path?: string;
  hidden?: boolean;
  expand?: boolean;
  icon?: string;
  sort?: number;
  level?: number;
  realized?: boolean;
  children?: TreeNodeInterface[];
  parent?: TreeNodeInterface;
}

@Component({
  selector: 'menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})


export class MenuComponent implements OnInit {
  @ViewChild('nzTreeComponent') nzTreeComponent: NzTreeComponent;
  validateForm: FormGroup;
  //权限相关
  addAccess = false;
  editAccess = false;
  deleteAccess = false;
  //查询相关
  checkChild: any = false;
  keyword: string = '';
  size = 5;
  page: number = 1;
  total: number = 0;
  ossEndPoint = 'http://ekyun-images.oss-cn-hangzhou.aliyuncs.com/oss-backend/'
  //操作相关
  selectOptions = [];
  operateOptions = [];
  operateItem = null;
  operateIsVisible = false;
  editIsVisible = false;

  operateModalName: string = '';

  objects = [];
  public options: any;
  listOfMapData: TreeNodeInterface[] = []
  mapOfExpandedData: { [id: number]: TreeNodeInterface[] } = {};

  constructor(public router: Router,
              public fb: FormBuilder,
              public http: selfHttp,
              public modalService: NzModalService,
              public msg: NzMessageService,
  ) {
    this.loadAccess();
    this.buildForm();
  }

  buildForm() {
    this.validateForm = this.fb.group({
      id: [0],
      parent_id: [0, [Validators.required]],
      menu_name: [null, [Validators.required]],
      title: [null, [Validators.required]],
      path: [null, [Validators.required]],
      hidden: [true, [Validators.required]],
      realized: [true, [Validators.required]],
      icon: [null, [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.http.get('http://oss-api.nositek.com/oss-api/pc/menus', {
      page: this.page,
      size: this.size,
      keyword: this.keyword,
    }, res => {
      if (res.data['rows'] != null && res.data['rows'].length > 0) {
        this.listOfMapData = res.data['rows'];
        this.listOfMapData.forEach(item => {
          this.mapOfExpandedData[item.id] = this.convertTreeToList(item);
        });
        this.total = Number(res.data['total']);
      } else {
        this.listOfMapData = [];
        this.total = 0;
        this.msg.info('暂无数据');
      }
    });

  }

  loadObjectData() {
    this.http.get('http://oss-api.nositek.com/oss-api/pc/menus', {
      page: this.page,
      size: this.size,
      keyword: this.keyword,
    }, res => {
      if (res.data['rows'] != null && res.data['rows'].length > 0) {
        this.listOfMapData = res.data['rows'];
        this.listOfMapData.forEach(item => {
          this.mapOfExpandedData[item.id] = this.convertTreeToList(item);
        });
        this.total = Number(res.data['total']);
      } else {
        this.listOfMapData = [];
        this.total = 0;
        this.msg.info('暂无数据');
      }
    });
  }

  // 复选框
  // onChange(data: any, checked: any) {
  //   this.objectInfo = data;
  //   if (checked === 'single') {
  //     if (data.checked) {
  //       this.operateOptions.push(data.id);
  //     } else {
  //       let index = this.operateOptions.indexOf(data.id);
  //       this.operateOptions.splice(index, 1);
  //     }
  //   }
  // }

// 重置
  reset() {
    this.page = 1;
    this.size = 10;
    this.keyword = '';
    this.loadObjectData();
  }

  //页码切换
  indexChange() {
    console.log(this.page, this.size);
    this.loadObjectData();
  }

  /*
新增/编辑服务
 */
  preOperate(operateType, object) {
    this.loadOptionData();
    this.validateForm.patchValue({
      id: 0,
      parent_id: 0,
      hidden: true,
      realized: true,
    });
    this.operateIsVisible = true;
    switch (operateType) {
      case 1://新增
        this.operateModalName = '新增菜单'
        break
      case 2://编辑
        this.operateModalName = '编辑菜单'
        this.validateForm.patchValue({
          id: object.id,
          parent_id: object.parent_id,
          menu_name: object.menu_name,
          title: object.title,
          path: object.path,
          hidden: object.hidden,
          icon: object.icon,
          realized: object.realized,
        });
    }
  }

  //添加取消
  operateCancel() {
    this.validateForm.reset();
    this.operateIsVisible = false;
  }


  //添加提交
  operateCommit() {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    if (this.validateForm.value.menu_name && this.validateForm.value.title && this.validateForm.value.path && this.validateForm.value.icon) {
      this.http.post('http://oss-api.nositek.com/oss-api/pc/menus', {
        id: this.validateForm.value.id,
        parent_id: this.validateForm.value.parent_id,
        menu_name: this.validateForm.value.menu_name,
        title: this.validateForm.value.title,
        path: this.validateForm.value.path,
        hidden: this.validateForm.value.hidden,
        icon: this.validateForm.value.icon,
        realized: this.validateForm.value.realized,
      }, res => {
        if (res.code == 2000) {
          if (this.validateForm.value.id == 0) {
            this.msg.success('添加成功');
          } else {
            this.msg.success('编辑成功');
          }
        } else if (res.code == 100003) {
          this.msg.info(res.msg)
        } else {
          this.msg.error(res.msg)
        }
        this.loadObjectData();
      });
      this.validateForm.reset();
      this.validateForm.patchValue({
        id: 0,
        status: true,
      });
      this.operateIsVisible = false;
    }
  }


  /*
  删除用户
   */
  preDelete(object) {
    if (object.children) {
      this.msg.warning('存在子级菜单，请勿删除!')
    } else {
      this.modalService.confirm({
        nzTitle: '<i>确定删除数据 ？</i>',
        nzContent: '<b></b>',
        nzOkText: '确定',
        nzCancelText: '取消',
        nzOnOk: () => this.deleteCommit(object.id),
        nzOnCancel: () => this.deleteCancel()
      });
    }

  }

  // 删除提交
  deleteCommit(id: number) {
    this.options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        id: id
      }
    };
    this.http.delete('http://oss-api.nositek.com/oss-api/pc/menus', this.options, res => {
      if (res.code == 2000) {
        this.msg.success('删除成功');
      } else {
        this.msg.info(res.msg);
      }
      this.loadObjectData();
    });
    this.operateOptions = [];
  }

  // 删除取消
  deleteCancel() {
    this.loadObjectData();
  }


  loadOptionData() {
    this.selectOptions = []
    this.http.get('http://oss-api.nositek.com/oss-api/pc/menus/select', {is_full_tree: 1}, res => {
      if (res.data['rows'] != null) {
        this.selectOptions = res.data['rows'];
      } else {
        this.selectOptions.unshift({id: 0, title: '根节点', isLeaf: true})
      }

    });
  }

  add_dept = false;
  edit_dept = false;
  delete_dept = false;
  add_user = true;
  edit_user = false;
  delete_user = false;

  loadAccess() {
    console.log('加载页面操作权限');
    // let tempArr = JSON.parse(localStorage.getItem('access'));
    // console.log(tempArr);
    // this.add_dept = tempArr.findIndex(v => v.name == 'add_dept') != -1;
    // this.edit_dept = tempArr.findIndex(v => v.name == 'edit_dept') != -1;
    // this.delete_dept = tempArr.findIndex(v => v.name == 'delete_dept') != -1;
    // this.add_user = tempArr.findIndex(v => v.name == 'add_user') != -1;
    // this.edit_user = tempArr.findIndex(v => v.name == 'edit_user') != -1;
    // this.delete_user = tempArr.findIndex(v => v.name == 'delete_user') != -1;
  }

  collapse(array: TreeNodeInterface[], data: TreeNodeInterface, $event: boolean): void {
    if (!$event) {
      if (data.children) {
        data.children.forEach(d => {
          const target = array.find(a => a.id === d.id)!;
          target.expand = false;
          this.collapse(array, target, false);
        });
      } else {
        return;
      }
    }
  }

  convertTreeToList(root: TreeNodeInterface): TreeNodeInterface[] {
    const stack: TreeNodeInterface[] = [];
    const array: TreeNodeInterface[] = [];
    const hashMap = {};
    stack.push({...root, level: 0, expand: false});

    while (stack.length !== 0) {
      const node = stack.pop()!;
      this.visitNode(node, hashMap, array);
      if (node.children) {
        for (let i = node.children.length - 1; i >= 0; i--) {
          stack.push({...node.children[i], level: node.level! + 1, expand: false, parent: node});
        }
      }
    }

    return array;
  }

  visitNode(node: TreeNodeInterface, hashMap: { [id: string]: boolean }, array: TreeNodeInterface[]): void {
    if (!hashMap[node.id]) {
      hashMap[node.id] = true;
      array.push(node);
    }
  }

  drop(event: CdkDragDrop<string[]>): void {
    console.log(event)
    moveItemInArray(this.listOfMapData, event.previousIndex, event.currentIndex);
  }

  //文件上传预处理
  public fileList: any = [];
  uploading = false;
  beforeUpload = (file: NzUploadFile): boolean => {
    this.fileList = [];
    this.fileList.push(file);
    return false;
  };

//文件上传
  handleUpload(): void {
    const formData = new FormData();
    formData.append('image', this.fileList[0]);
    this.http.post('http://oss-api.nositek.com/oss-api/public/upload/images', formData, res => {
      if (res.code != 2000) {
        this.msg.error(res.message);
      } else {
        this.msg.success('上传成功');
        this.validateForm.patchValue({
          icon: this.ossEndPoint + res.data['image_name']
        })
      }
      this.uploading = false;
      this.fileList = [];
    });
  }

}


import {Component, ViewChild, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzModalService} from 'ng-zorro-antd/modal';
import {selfHttp} from "@src/app/httpservice/http.service";
import {HttpHeaders} from '@angular/common/http';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NzTreeComponent} from 'ng-zorro-antd/tree';

@Component({
  selector: 'material',
  templateUrl: './material.component.html',
  styleUrls: ['./material.component.css']
})


export class MaterialComponent implements OnInit {
  @ViewChild('nzTreeComponent') nzTreeComponent: NzTreeComponent;
  selectedValue: any;
  validateForm: FormGroup;
  //权限相关
  addAccess = false;
  editAccess = false;
  deleteAccess = false;
  //查询相关
  checkChild: any = false;
  keyword: string = '';
  status = -1;
  size = 5;
  page: number = 1;
  total: number = 0;

  //操作相关
  selectOptions = [];
  operateOptions = [];
  operateItem = null;
  viewIsVisible = false;
  addIsVisible = false;
  editIsVisible = false;
  accessCodes: any = [];
  currentUser: any;

  objects = [];
  public objectInfo: any;
  public options: any;

  constructor(public router: Router,
              public fb: FormBuilder,
              public http: selfHttp,
              public modalService: NzModalService,
              public msg: NzMessageService,
  ) {
    this.loadObjectData();
    this.loadAccess();
    this.buildForm();
  }

  buildForm() {
    this.validateForm = this.fb.group({
      id: [0],
      status: [true, [Validators.required]],
      remark: [null, [Validators.required]],
    });
  }

  ngOnInit(): void {
  }

  loadObjectData() {
    this.http.get('http://oss-api.nositek.com/oss-api/pc/ai-msg/materials', {
      page: this.page,
      size: this.size,
      status: this.status,
      keyword: this.keyword,
    }, res => {
      if (res.data['rows'] != null && res.data['rows'].length > 0) {
        this.objects = res.data['rows'];
        this.total = Number(res.data['total']);
      } else {
        this.objects = [];
        this.total = 0;
        this.msg.info('暂无数据');
      }
    });
  }

  // 复选框
  onChange(data: any, checked: any) {
    this.objectInfo = data;
    if (checked === 'single') {
      if (data.checked) {
        this.operateOptions.push(data.id);
      } else {
        let index = this.operateOptions.indexOf(data.id);
        this.operateOptions.splice(index, 1);
      }
    }
  }

// 重置
  reset() {
    this.page = 1;
    this.size = 10;
    this.keyword = '';
    this.loadObjectData();
  }

  //页码切换
  indexChange() {
    console.log(this.page, this.size);
    this.loadObjectData();
  }


  preDetail(object: any) {
    this.viewIsVisible = true
  }

  //添加取消
  closeDetail() {
    this.viewIsVisible = false;
  }

  /*
    审批
     */
  preEdit(object: any) {
    this.editIsVisible = true;
    this.validateForm.reset();
    this.validateForm.patchValue({
      id: object.id,
      status: true,
    });
  }

  //审批取消
  editCancel() {
    this.editIsVisible = false;
    this.loadObjectData();
    this.validateForm.reset();
  }

  //审批提交
  editCommit() {
    console.log(this.validateForm.value)
    this.http.put('http://oss-api.nositek.com/oss-api/pc/ai-msg/audit', {
      id: this.validateForm.value.id,
      audit_type: 2,
      status: this.validateForm.value.status ? 2 : 3,
      remark: this.validateForm.value.remark,
    }, res => {
      if (res.code == 2000) {
        this.msg.success('审核成功');
      } else if (res.code == 100003) {
        this.msg.info(res.msg)
      } else {
        this.msg.error(res.msg)
      }
      this.loadObjectData();
    });

    this.editIsVisible = false;

  }


  add_dept = false;
  edit_dept = false;
  delete_dept = false;
  add_user = true;
  edit_user = false;
  delete_user = false;

  loadAccess() {
    console.log('加载页面操作权限');
    // let tempArr = JSON.parse(localStorage.getItem('access'));
    // console.log(tempArr);
    // this.add_dept = tempArr.findIndex(v => v.name == 'add_dept') != -1;
    // this.edit_dept = tempArr.findIndex(v => v.name == 'edit_dept') != -1;
    // this.delete_dept = tempArr.findIndex(v => v.name == 'delete_dept') != -1;
    // this.add_user = tempArr.findIndex(v => v.name == 'add_user') != -1;
    // this.edit_user = tempArr.findIndex(v => v.name == 'edit_user') != -1;
    // this.delete_user = tempArr.findIndex(v => v.name == 'delete_user') != -1;
  }
}

<nz-card>
  <div id="table-area">
    <div class="param-area">
      <nz-space class="table-query">
        <nz-space-item>
          <nz-input-group nzSearch [nzAddOnAfter]="suffixButton">
            <input type="text" nz-input placeholder="输入关键字搜索" [(ngModel)]="keyword"/>
          </nz-input-group>
          <ng-template #suffixButton>
            <button nz-button nzType="primary" (click)="loadObjectData()">搜索</button>
          </ng-template>
        </nz-space-item>
        <nz-space-item>
          <button nz-button [nzType]="'default'" (click)="reset()">重置</button>
        </nz-space-item>
      </nz-space>
      <div class="table-operation">
        <button nz-button nzType="primary" (click)="preOperate(1,null)">
          <i nz-icon nzType="plus" nzTheme="outline"></i>
          <span>添加接口</span>
        </button>
      </div>
    </div>
    <div id="table-data">
      <nz-table
        nzSize="small"
        [nzData]="objects"
        [(nzPageSize)]="size"
        [nzTotal]="total"
        [(nzPageIndex)]="page"
        [nzFrontPagination]="false"
        (nzPageIndexChange)="indexChange()"
        (nzPageSizeChange)="indexChange()"
        [nzShowSizeChanger]="true"
        [nzPageSizeOptions]="[5,10,15,20]"
        [nzShowTotal]="totalTemplate"
        [nzHideOnSinglePage]="true"
      >
        <ng-template #totalTemplate>共 <span style="color:cornflowerblue;margin:0 3px;">{{ total }}</span>
          条
        </ng-template>
        <thead>
        <tr>
          <th nzWidth="50px"></th>
          <th nzWidth="100px">所属菜单</th>
          <th nzWidth="100px">接口名称</th>
          <th nzWidth="100px">接口路径</th>
          <th nzWidth="100px">请求方式</th>
          <!--          <th nzWidth="100px">排序</th>-->
          <th nzWidth="100px">实现状态</th>
          <th nzWidth="100px">操作</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of objects">
          <td nzShowCheckbox [(nzChecked)]="item.checked"
              (nzCheckedChange)="onChange(item,'single')"></td>
          <td>{{ item.menu_name }}</td>
          <td>{{ item.interface_name }}</td>
          <td>{{ item.uri }}</td>
          <td>{{ item.request_method }}</td>
          <td>
            <i *ngIf="!item.realized" nz-icon nzType="sliders" nzTheme="outline" style="color: #0099FF"></i>
            <i *ngIf="item.realized" nz-icon nzType="rocket" nzTheme="outline" style="color: green"></i>
            {{  item.realized ? '已上线' : '研发中'}}
          </td>
          <td>
            <a (click)="preOperate(2,item)"><i nz-icon nzType="monitor" nzTheme="outline"></i>编辑</a>&nbsp;&nbsp;&nbsp;&nbsp;
            <a (click)="preDelete(item)"><i nz-icon nzType="delete" nzTheme="outline"></i>删除</a>&nbsp;&nbsp;&nbsp;&nbsp;
          </td>
        </tr>
        </tbody>
      </nz-table>
    </div>
  </div>
</nz-card>

<!--操作接口信息-->
<nz-drawer
  [nzBodyStyle]="{ height: 'calc(100% - 150px)', overflow: 'auto', 'padding-bottom': '53px' }"
  [nzMaskClosable]="true"
  [nzWidth]="720"
  [nzVisible]="operateIsVisible"
  nzTitle="{{operateModalName}}"
  (nzOnClose)="operateCancel()"
>
  <form nz-form [formGroup]="validateForm" class="ant-advanced-search-form">
    <div class="permission" style="width: 100%">
      <div nz-row [nzGutter]="24">
        <div nz-col [nzSpan]="24">
          <nz-form-item nzFlex>
            <nz-form-label style="width: 18%;text-align: left" nzRequired>所属菜单</nz-form-label>
            <nz-form-control style="width: 36%" nzErrorTip="请选择所属菜单!">
              <nz-tree-select
                [nzNodes]="selectOptions"
                nzShowSearch
                nzPlaceHolder="请选择所属菜单"
                formControlName="menu_id"
              >
              </nz-tree-select>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24">
          <nz-form-item nzFlex>
            <nz-form-label style="width: 18%;text-align: left;" nzRequired>接口名称</nz-form-label>
            <nz-form-control style="width: 36%" nzErrorTip="请输入接口名称!">
              <input nz-input placeholder="请输入接口名称" formControlName="interface_name"/>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24">
          <nz-form-item nzFlex>
            <nz-form-label style="width: 18%;text-align: left;" nzRequired>接口路径</nz-form-label>
            <nz-form-control style="width: 36%" nzErrorTip="请输入接口路径!">
              <input nz-input placeholder="请输入接口路径" formControlName="uri"/>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24">
          <nz-form-item nzFlex>
            <nz-form-label style="width: 18%;text-align: left" nzRequired>请求方式</nz-form-label>
            <nz-form-control style="width: 36%" nzErrorTip="请选择请求方式!">
              <nz-select formControlName="request_method" [nzPlaceHolder]="'请选择请求方式'">
                <nz-option *ngFor="let object of selectedMethodOptions" [nzValue]="object.id"
                           [nzLabel]="object.label"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24">
          <nz-form-item nzFlex>
            <nz-form-label style="width: 18%;text-align: left;">研发状态</nz-form-label>
            <nz-form-control style="width: 36%">
              <nz-switch formControlName="realized" nzCheckedChildren="已上线" nzUnCheckedChildren="研发中"></nz-switch>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24">
          <nz-form-item nzFlex>
            <nz-form-label style="width: 18%;text-align: left;">备注</nz-form-label>
            <nz-form-control style="width: 36%;">
              <input type="text" nz-input placeholder="请输入备注" formControlName="remark"/>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </div>
  </form>
  <div class="footer">
    <button type="button" (click)="operateCancel()" class="ant-btn" style="margin-right: 8px;"><span>取消</span>
    </button>
    <button type="button" (click)="operateCommit()" class="ant-btn ant-btn-primary"><span>提交</span></button>
  </div>
</nz-drawer>

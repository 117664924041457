<nz-card>
  <div id="department">
    <div id="tree-area">
      <div id="tree-struct">
        <div id="tree-title">通讯组织结构</div>
        <div id="tree-data">
          <nz-tree
            #nzTreeComponent
            [nzData]="treeObjects"
            [nzExpandedKeys]="expandKeys"
            [nzExpandAll]="true"
            (nzClick)="nzClick($event)"
          >
          </nz-tree>
        </div>
      </div>
    </div>
    <div id="table-area">
      <div id="param-area">
        <div id="param-input">
          <input type="text" nz-input placeholder="键入关键字搜索" [(ngModel)]="keyword"/>
        </div>
        <div id="table-operation">
          <div style=" margin-right: 5%;">
            <button nz-button [nzType]="'default'" (click)="reset()">重置</button>
          </div>
          <div style="margin-right: 5%">
            <button nz-button [nzType]="'primary'" (click)="loadObjectData()">搜索</button>
          </div>
          <div style="display: flex;margin-top: 2.4vh">
            <button nz-button nzType="primary" (click)="syncData()">
              <i *ngIf="syncIsVisible" nz-icon nzType="sync" nzTheme="outline"></i>
              <i *ngIf="loadIsVisible" nz-icon nzType="loading" nzTheme="outline"></i>
              <span>同步数据</span>
            </button>
            <button nz-button nzType="primary" (click)="auditUser()" style=" margin-left: 10%;">
              <i nz-icon nzType="audit" nzTheme="outline"></i>
              <span>审批设置</span>
            </button>
          </div>
        </div>
      </div>
      <div id="table-data">
        <nz-table
          nzSize="small"
          [nzData]="tableObjects"
          [(nzPageSize)]="size"
          [nzTotal]="total"
          [(nzPageIndex)]="page"
          [nzFrontPagination]="false"
          (nzPageIndexChange)="indexChange()"
          [nzShowTotal]="totalTemplate"
        >
          <ng-template #totalTemplate>共 <span style="color:cornflowerblue;margin:0 3px;">{{ total }}</span>
            条
          </ng-template>
          <thead>
          <tr>
            <th nzWidth="80px">姓名</th>
            <th nzWidth="80px">部门</th>
            <th nzWidth="80px">职位</th>
            <th nzWidth="80px">账号</th>
            <th nzWidth="80px">账号状态</th>
            <th nzWidth="80px">联系电话</th>
            <th nzWidth="80px">当前角色</th>
            <th nzWidth="150px">最近登录</th>
            <th nzWidth="100px">操作</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let data of tableObjects">
            <td>{{ data.user_name }}</td>
            <td>{{ data.department_name }}</td>
            <td>{{ data.position }}</td>
            <td>{{ data.qw_user_id }}</td>
            <td>
              <nz-badge *ngIf="data.status" nzStatus="processing"></nz-badge>
              <nz-badge *ngIf="!data.status" nzStatus="default"></nz-badge>
              {{  data.status ? '正常' : '关停'}}
            </td>
            <td>{{ data.mobile == 'N/A' ? '暂未绑定' : data.mobile }}</td>
            <td>{{data.role_name}}</td>
            <td>{{ data.last_login.slice(0, 10) == '2006-01-02' ? '暂未登录' : data.last_login.split(".")[0]| date: 'yyyy-MM-dd HH:mm:ss'}}</td>
            <td>
              <span><a (click)="preEditAccess(data)"><i nz-icon nzType="key" nzTheme="outline"></i>授权</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span *ngIf="!data.status"><a (click)="preDisableOrEnable(data)"><i nz-icon nzType="check-circle"
                                                                                  nzTheme="outline"></i>启用</a>&nbsp;&nbsp;&nbsp;</span>
              <span *ngIf="data.status"><a (click)="preDisableOrEnable(data)"><i nz-icon nzType="stop"
                                                                                 nzTheme="outline"></i>禁用</a></span>

            </td>
          </tr>
          </tbody>
        </nz-table>
      </div>
    </div>
  </div>
</nz-card>

<!--修改权限-->
<nz-modal [(nzVisible)]="editIsVisible" nzTitle="修改权限" [nzFooter]="modalFooter"
          [nzMaskClosable]="true" (nzOnCancel)="editCancel()">
  <form nz-form [formGroup]="validateForm">
    <nz-form-item nzFlex>
      <nz-form-label style="width: 18%;text-align: left" nzRequired>角色权限</nz-form-label>
      <nz-form-control style="width: 36%" nzErrorTip="请选择角色!">
        <nz-select formControlName="role_id" [nzPlaceHolder]="'请选择角色'">
          <nz-option *ngFor="let role of selectRoleOptions" [nzValue]="role.id"
                     [nzLabel]="role.role_name"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </form>
  <ng-template #modalFooter>
    <button nz-button nzType="default" (click)="editCancel()">取消</button>
    <button nz-button nzType="primary" (click)="editOk()">确定</button>
  </ng-template>
</nz-modal>


<!--审批设置-->
<nz-modal [(nzVisible)]="auditUserIsVisible" nzTitle="审批设置" [nzFooter]="modalFooter1"
          [nzMaskClosable]="true" (nzOnCancel)="auditUserCancel()">
  <form nz-form [formGroup]="auditUserValidateForm">
    <nz-form-item nzFlex>
      <nz-form-label style="width: 25%;text-align: left" nzRequired>开户审批人</nz-form-label>
      <nz-form-control style="width: 36%" nzErrorTip="请选择开户审批人!">
        <nz-select formControlName="audit_open_account_user_id" [nzPlaceHolder]="'请选择开户审批人'">
          <nz-option *ngFor="let item of selectUserOptions" [nzValue]="item.id"
                     [nzLabel]="item.user_name"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item nzFlex>
      <nz-form-label style="width: 25%;text-align: left" nzRequired>订单审批人</nz-form-label>
      <nz-form-control style="width: 36%" nzErrorTip="请选择订单审批人!">
        <nz-select formControlName="audit_customer_order_user_id" [nzPlaceHolder]="'请选择订单审批人'">
          <nz-option *ngFor="let item of selectUserOptions" [nzValue]="item.id"
                     [nzLabel]="item.user_name"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item nzFlex>
      <nz-form-label style="width: 25%;text-align: left" nzRequired>退款审批人</nz-form-label>
      <nz-form-control style="width: 36%" nzErrorTip="请选择退款审批人!">
        <nz-select formControlName="audit_customer_refund_user_id" [nzPlaceHolder]="'请选择退款审批人'">
          <nz-option *ngFor="let item of selectUserOptions" [nzValue]="item.id"
                     [nzLabel]="item.user_name"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item nzFlex>
      <nz-form-label style="width: 25%;text-align: left" nzRequired>开票审批人</nz-form-label>
      <nz-form-control style="width: 36%" nzErrorTip="请选择开票审批人!">
        <nz-select formControlName="audit_customer_invoice_user_id" [nzPlaceHolder]="'请选择开票审批人'">
          <nz-option *ngFor="let item of selectUserOptions" [nzValue]="item.id"
                     [nzLabel]="item.user_name"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item nzFlex>
      <nz-form-label style="width: 25%;text-align: left" nzRequired>采购审批人</nz-form-label>
      <nz-form-control style="width: 36%" nzErrorTip="请选择采购审批人!">
        <nz-select formControlName="audit_sk_purchase_user_id" [nzPlaceHolder]="'请选择采购审批人'">
          <nz-option *ngFor="let item of selectUserOptions" [nzValue]="item.id"
                     [nzLabel]="item.user_name"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </form>
  <ng-template #modalFooter1>
    <button nz-button nzType="default" (click)="auditUserCancel()">取消</button>
    <button nz-button nzType="primary" (click)="auditUserCommit()">确定</button>
  </ng-template>
</nz-modal>



import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {selfHttp} from '../httpservice/http.service';
import {NzMessageService} from 'ng-zorro-antd';
import {Md5} from "ts-md5";
import {WebsocketService} from "@src/app/common/websocket/websocket.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  validateForm: FormGroup;
  forgetValidateForm: FormGroup;
  resetValidateForm: FormGroup;
  prefixUser: any;
  prefixLock: any;
  public formData: any;
  forgetPwdIsVisible = false;
  resetPwdIsVisible = false;
  public countDownTime: number;
  public countDown = false;
  showButtonText = '发送短信验证码'; // 可以控制动态改变的按钮提示信息
  start: any;

  constructor(public fb: FormBuilder,
              public http: selfHttp,
              public router: Router,
              public msg: NzMessageService,
  ) {
  }

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      account: [null, [Validators.required]],
      password: [null, [Validators.required]],
      remember: [true],
    });
    this.forgetValidateForm = this.fb.group({
      mobile: [null, [Validators.required]],
      captcha: [null, [Validators.required]],
    });
    this.validateForm = this.fb.group({
      account: [null, [Validators.required]],
      password: [null, [Validators.required]],
      remember: [true],
    });
    this.resetValidateForm = this.fb.group({
      mobile: [null, [Validators.required]],
      password: [null, [Validators.required]],
      check_password: [null, [Validators.required, this.confirmationValidator]],
    });
  }

  updateConfirmValidator(): void {
    /** wait for refresh value */
    Promise.resolve().then(() => this.resetValidateForm.controls.check_password.updateValueAndValidity());
  }

  confirmationValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return {required: true};
    } else if (control.value !== this.resetValidateForm.controls.password.value) {
      return {confirm: true, error: true};
    }
    return {};
  }

  //登陆
  goLogin() {
    for (const i in this.validateForm.controls) {
      console.log(this.validateForm.controls[i].markAsDirty(), this.validateForm.controls[i].updateValueAndValidity())
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    if (this.validateForm.value.password && this.validateForm.value.account) {
      this.validateForm.value.password = Md5.hashStr("shunKe@20230216" + this.validateForm.value.password)
      this.formData = this.validateForm;
      // this.http.post('http://oss-api.nositek.com/oss-api/public/login/account', this.formData['value'], res => {
      this.http.post('http://oss-api.nositek.com/oss-api/public/login/account', this.formData['value'], res => {
          if (res.code === 2000) {
            localStorage.setItem('token', res.data['row'].token)
            this.router.navigateByUrl('/sidebar/home-page');
          } else if (res.code == 100080 || res.code == 100081 || res.code == 100002) {
            this.msg.info(res.msg)
          } else {
            this.msg.error(res.msg)
          }
        },
        err => {
          console.log(err);
          this.router.navigateByUrl('login');
        });
    }
  }


//获取验证码的
  sendCaptcha() {
    if (this.showButtonText == '重新发送') {
      this.forgetValidateForm.patchValue({
        captcha: ''
      });
    }
    this.countDown = true;
    this.countDownTime = 10;
    this.showButtonText = '验证码已发送（' + 60 + 's）'; // 设置按钮显示内容
    this.start = setInterval(() => {
      if (this.countDownTime >= 0) {
        this.showButtonText = '验证码已发送(' + this.countDownTime-- + 's)';     // 动态的进行倒计时
      } else {
        clearInterval(this.start);             // 如果超时则重新发送
        this.showButtonText = '重新发送';
        this.countDown = false;         // 按钮再次变成可点击状态
        this.countDownTime = 10;
      }
    }, 1000);

    this.http.get('http://oss-api.nositek.com/oss-api/public/captcha', {
        mobile: this.forgetValidateForm.value.mobile,
        template_code: '220093'
      },
      res => {
        if (res.code == 2000) {
          console.log('结果', res.data);
          localStorage.setItem('captcha', res.data['row']);
        } else if (res.code == 100080 || res.code == 100081 || res.code == 100002) {
          this.msg.info(res.msg)
        } else {
          this.msg.error(res.msg)
        }

      });

  }

//忘记密码
  forgetPwd() {
    this.forgetPwdIsVisible = true;
    this.forgetValidateForm.reset();
  }

//忘记密码取消
  forgetPwdCancel() {
    this.forgetPwdIsVisible = false;
  }

//忘记密码确定
  forgetPwdCommit() {
    for (const i in this.forgetValidateForm.controls) {
      this.forgetValidateForm.controls[i].markAsDirty();
      this.forgetValidateForm.controls[i].updateValueAndValidity();
    }
    if (this.forgetValidateForm.value.captcha && this.forgetValidateForm.value.mobile) {
      if (this.forgetValidateForm.value.captcha == localStorage.getItem('captcha')) {
        this.forgetPwdIsVisible = false;
        this.resetPwdIsVisible = true;
        localStorage.removeItem('captcha');
        this.resetValidateForm.reset();
        this.resetValidateForm.patchValue({
          mobile: this.forgetValidateForm.value.mobile
        })
      } else {
        this.msg.info('验证码错误')
      }
    }

  }

//重设密码取消
  resetPwdCancel() {
    this.resetPwdIsVisible = false;
  }

//重设密码确定
  resetPwdCommit() {
    for (const i in this.resetValidateForm.controls) {
      this.resetValidateForm.controls[i].markAsDirty();
      this.resetValidateForm.controls[i].updateValueAndValidity();
    }
    if (this.resetValidateForm.value.mobile && this.resetValidateForm.value.password && this.resetValidateForm.value.new_password) {
      this.http.put('http://oss-api.nositek.com/oss-api/public/users/password', {
        'mobile': this.resetValidateForm.value.mobile,
        'new_password': Md5.hashStr("shunKe@20230216" + this.resetValidateForm.value.password),
      }, res => {
        if (res.code == 2000) {
          this.msg.success('密码已重置');
        } else {
          this.msg.error(res.msg)
        }

      });
      this.resetPwdIsVisible = false;
    }
  }

}

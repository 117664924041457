<nz-card>
  <div class="table-area">
    <div class="param-area">
      <nz-space class="table-query">
        <nz-space-item>
          <nz-range-picker [nzFormat]="'yyyy-MM-dd'" [(ngModel)]="dateRangeValues"
                           (ngModelChange)="datePickChange($event)"></nz-range-picker>

        </nz-space-item>
        <nz-space-item>
          <nz-select nzAllowClear nzPlaceHolder="请选择订单状态" [(ngModel)]="orderStatus" (ngModelChange)="loadObjectData()">
            <nz-option nzLabel="全部" nzValue="0"></nz-option>
            <nz-option nzLabel="待支付" nzValue="1"></nz-option>
            <nz-option nzLabel="已取消" nzValue="2"></nz-option>
            <nz-option nzLabel="待确认" nzValue="3"></nz-option>
            <nz-option nzLabel="已确认" nzValue="4"></nz-option>
            <nz-option nzLabel="已作废" nzValue="5"></nz-option>
          </nz-select>
        </nz-space-item>
        <nz-space-item>
          <nz-select [nzPlaceHolder]="'请选择客户'" [(ngModel)]="companyId" (ngModelChange)="loadObjectData()">
            <nz-option *ngFor="let option of selectOptions" [nzValue]="option.value"
                       [nzLabel]="option.label"></nz-option>
          </nz-select>
        </nz-space-item>
        <nz-space-item>
          <nz-input-group nzSearch [nzAddOnAfter]="suffixButton">
            <input type="text" nz-input placeholder="输入关键字搜索" [(ngModel)]="keyword"/>
          </nz-input-group>
          <ng-template #suffixButton>
            <button nz-button nzType="primary" (click)="loadObjectData()">搜索</button>
          </ng-template>
        </nz-space-item>
        <nz-space-item>
          <button nz-button [nzType]="'default'" (click)="reset()">重置</button>
        </nz-space-item>
      </nz-space>
      <div class="table-operation">
        <button nz-button nzType="primary" (click)="preAdd()">
          <i nz-icon nzType="plus" nzTheme="outline"></i>
          <span>新增订单</span>
        </button>
      </div>
    </div>
    <div>
      <nz-table
        nzSize="small"
        [nzData]="tmpArr"
        [(nzPageSize)]="size"
        [nzTotal]="total"
        [(nzPageIndex)]="page"
        [nzFrontPagination]="false"
        (nzPageIndexChange)="indexChange()"
        (nzPageSizeChange)="indexChange()"
        [nzShowSizeChanger]="true"
        [nzPageSizeOptions]="[5,10,15,20]"
        [nzShowTotal]="totalTemplate"
        [nzHideOnSinglePage]="true"
        [nzBordered]="true"
      >
        <ng-template #totalTemplate>共 <span style="color:cornflowerblue;margin:0 3px;">{{ total }}</span>
          条
        </ng-template>
        <thead>
        <tr>
          <th nzWidth="80px">订单编号</th>
          <th nzWidth="120px">客户名称</th>
          <th nzWidth="150px">订单内容</th>
          <th nzWidth="60px">订单类型</th>
          <th nzWidth="60px">订单来源</th>
          <th nzWidth="100px">创建时间</th>
          <!--          <th nzWidth="60px">创建人</th>-->
          <th nzWidth="60px">订单状态</th>
          <th nzWidth="90px">订单金额（元）</th>
          <th nzWidth="70px">操作</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let object of objects">
          <ng-container *ngIf="object.merge">
            <td [attr.rowspan]="object.rowspan">
              {{ object.order_no }}&nbsp;&nbsp;&nbsp;
              <a nz-popover nzPopoverContent="点击复制文本">
                <i nz-icon nzType="copy" nzTheme="outline"></i>
              </a>
            </td>
            <td [attr.rowspan]="object.rowspan">{{ object.company_name }}</td>
            <td>
              <span
                style="font-weight: bolder">套餐名称：</span>&nbsp;&nbsp;<span>{{object.package_name == '' ? '-' : object.package_name}}</span><br>
              <span style="font-weight: bolder">服务名称：</span>&nbsp;&nbsp;<span>{{object.service_name}}</span><br>
              <span style="font-weight: bolder">产品名称：</span>&nbsp;&nbsp;<span>{{object.product_name}}</span><br>
              <span style="font-weight: bolder">购买数量：</span>&nbsp;&nbsp;<span
              style="color: #00CCFF">{{object.product_number}}{{object.product_unit}}</span><br>
              <span style="font-weight: bolder">有效时长：</span>&nbsp;&nbsp;<span
              style="color: #00CCFF">{{object.effective_month == 0 ? '不限制' : object.effective_month + '个月'}}</span><br>
              <span
                style="font-weight: bolder">单价：</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
              style="color: #00CCFF">{{object.discounted_single_price}}元/{{object.product_unit}}</span><br>
              <span
                style="font-weight: bolder">总价：</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
              style="color: #00CCFF">{{object.discounted_vital_price}}元</span><br>
            </td>
            <td [attr.rowspan]="object.rowspan">{{ object.order_type == 1 ? '客户自采' : '顺氪代采' }}</td>
            <td [attr.rowspan]="object.rowspan">
              {{ object.order_from == 1 ? '采购' : '合同' }}
            </td>
            <td [attr.rowspan]="object.rowspan">{{ object.create_time| date: 'yy/MM/dd HH:mm'}}</td>
            <!--            <td rowspan="2">{{ object.create_user_name }}</td>-->
            <td [attr.rowspan]="object.rowspan">
              <nz-badge *ngIf="object.order_status==1" nzStatus="processing"></nz-badge>
              <nz-badge *ngIf="object.order_status==2" nzStatus="default"></nz-badge>
              <nz-badge *ngIf="object.order_status==3" nzStatus="processing"></nz-badge>
              <nz-badge *ngIf="object.order_status==4" nzStatus="success"></nz-badge>
              <nz-badge *ngIf="object.order_status==5" nzStatus="default"></nz-badge>
              <span *ngIf="object.order_status==1">待支付</span>
              <span *ngIf="object.order_status==2">已取消</span>
              <span *ngIf="object.order_status==3">待确认</span>
              <span *ngIf="object.order_status==4">已确认</span>
              <span *ngIf="object.order_status==5">已作废</span>
            </td>
            <td [attr.rowspan]="object.rowspan">{{ object.price }}</td>
            <td [attr.rowspan]="object.rowspan">
              <a (click)="preDetail(object.id)"><i nz-icon nzType="monitor" nzTheme="outline"></i>订单详情</a>&nbsp;&nbsp;&nbsp;&nbsp;
              <!--              <a (click)="preEdit()"><i nz-icon nzType="shopping-cart" nzTheme="outline"></i>采购记录</a>&nbsp;&nbsp;&nbsp;&nbsp;-->
              <!--              <a (click)="preEdit()"><i nz-icon nzType="check-circle" nzTheme="outline"></i>开通</a>&nbsp;&nbsp;&nbsp;&nbsp;-->
            </td>
          </ng-container>
          <ng-container *ngIf="!object.merge">
            <td>
               <span
                 style="font-weight: bolder">套餐名称：</span>&nbsp;&nbsp;<span>{{object.package_name == '' ? '-' : object.package_name}}</span><br>
              <span style="font-weight: bolder">服务名称：</span>&nbsp;&nbsp;<span>{{object.service_name}}</span><br>
              <span style="font-weight: bolder">产品名称：</span>&nbsp;&nbsp;<span>{{object.product_name}}</span><br>
              <span style="font-weight: bolder">购买数量：</span>&nbsp;&nbsp;<span
              style="color: #00CCFF">{{object.product_number}}{{object.product_unit}}</span><br>
              <span style="font-weight: bolder">有效时长：</span>&nbsp;&nbsp;<span
              style="color: #00CCFF">{{object.effective_month == 0 ? '不限制' : object.effective_month + '个月'}}</span><br>
              <span
                style="font-weight: bolder">单价：</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
              style="color: #00CCFF">{{object.discounted_single_price}}元/{{object.product_unit}}</span><br>
              <span
                style="font-weight: bolder">总价：</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
              style="color: #00CCFF">{{object.discounted_vital_price}}元</span><br>
            </td>
          </ng-container>
        </tr>
        </tbody>
      </nz-table>
    </div>
  </div>
</nz-card>

<nz-drawer
  [nzBodyStyle]="{ height: 'calc(100% - 150px)', overflow: 'auto', 'padding-bottom': '53px' }"
  [nzMaskClosable]="true"
  [nzWidth]="1500"
  [nzVisible]="viewIsVisible"
  (nzOnClose)="closeDetail()"
>
  <nz-descriptions nzTitle="基本信息" nzBordered [nzSize]="'middle'">
    <nz-descriptions-item nzTitle="订单号码" [nzSpan]="2">{{showObject.order_no}}</nz-descriptions-item>
    <nz-descriptions-item nzTitle="客户名称" [nzSpan]="2">{{showObject.company_name}}</nz-descriptions-item>
    <nz-descriptions-item nzTitle="创建人" [nzSpan]="2">{{showObject.create_user_name}}</nz-descriptions-item>
    <nz-descriptions-item nzTitle="创建时间"
                          [nzSpan]="2">{{showObject.create_time.split('.')[0]|date:'yyyy-MM-dd HH:mm'}}</nz-descriptions-item>
    <nz-descriptions-item nzTitle="审批人" [nzSpan]="2">{{showObject.audit_user_name}}</nz-descriptions-item>
    <nz-descriptions-item nzTitle="审批时间" [nzSpan]="2">
      {{showObject.audit_time.slice(0, 10) == '2006-01-02' ? '暂未审批' : showObject.audit_time.split('.')[0]|date:'yyyy-MM-dd HH:mm'}}
    </nz-descriptions-item>
    <nz-descriptions-item nzTitle="订单类型" [nzSpan]="2">
      <span *ngIf="showObject.order_type==1">客户自采</span>
      <span *ngIf="showObject.order_type==2">顺氪代采</span>
    </nz-descriptions-item>
    <nz-descriptions-item nzTitle="订单来源" [nzSpan]="2">
      <span *ngIf="showObject.order_from==1">采购</span>
      <span *ngIf="showObject.order_from==2">合同</span>
    </nz-descriptions-item>
    <nz-descriptions-item nzTitle="订单价格" [nzSpan]="2">{{showObject.order_price}}元</nz-descriptions-item>
    <nz-descriptions-item nzTitle="折后价" [nzSpan]="2">{{showObject.discounted_vital_price}}元</nz-descriptions-item>
    <nz-descriptions-item nzTitle="订单状态" [nzSpan]="2">
      <nz-badge *ngIf="showObject.order_status==1" nzStatus="processing"></nz-badge>
      <nz-badge *ngIf="showObject.order_status==2" nzStatus="default"></nz-badge>
      <nz-badge *ngIf="showObject.order_status==3" nzStatus="processing"></nz-badge>
      <nz-badge *ngIf="showObject.order_status==4" nzStatus="success"></nz-badge>
      <nz-badge *ngIf="showObject.order_status==5" nzStatus="warning"></nz-badge>
      <span *ngIf="showObject.order_status==1">待支付</span>
      <span *ngIf="showObject.order_status==2">已取消</span>
      <span *ngIf="showObject.order_status==3">待确认</span>
      <span *ngIf="showObject.order_status==4">已确认</span>
      <span *ngIf="showObject.order_status==5">已作废</span>
    </nz-descriptions-item>
  </nz-descriptions>
  <div style="margin-top: 10vh;">
    <div style="margin-bottom: 20px;">
      <span style="font-size: 16px;color: rgba(0,0,0,.85);font-weight: 700;">订单详情</span>
      <span style="margin-left: 50px">外呼套餐剩余：</span>
      <span style="margin-left: 100px">短信套餐剩余：</span>
    </div>
    <div>
      <nz-table #basicTable [nzData]="details" nzSize="small" [nzHideOnSinglePage]="true">
        <thead>
        <tr>
          <th>产品编号</th>
          <th>服务</th>
          <th>产品</th>
          <th>购买数量</th>
          <th>产品折扣</th>
          <th>单价</th>
          <th>折后单价</th>
          <th>总价</th>
          <th>折后价</th>
          <th>状态</th>
          <th>有效时长</th>
          <th>生效时间</th>
          <th>截止时间</th>
          <!--          <th>操作</th>-->
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let data of basicTable.data">
          <td>{{ data.product_code }}</td>
          <td>{{ data.service_name }}</td>
          <td>{{ data.product_name }}</td>
          <td>{{ data.product_number }}{{ data.product_unit }}</td>
          <td>{{ data.discount }}%</td>
          <td>¥{{ data.single_price }}/{{data.product_unit}}</td>
          <td>¥{{ data.discounted_single_price }}/{{data.product_unit}}</td>
          <td>¥{{ data.vital_price }}</td>
          <td>¥{{ data.discounted_vital_price }}</td>
          <td>
            <nz-badge *ngIf="data.purchase_status==1" nzStatus="processing"></nz-badge>
            <nz-badge *ngIf="data.purchase_status==2" nzStatus="success"></nz-badge>
            <span *ngIf="data.purchase_status==1">采购中</span>
            <span *ngIf="data.purchase_status==2">已完成</span>
          </td>
          <td>{{ data.scene_bind == 11 || data.scene_bind == 22 ?'-':data.effective_month == 0 ? '不限制' : data.effective_month + '个月'}}</td>
          <td>
            {{ data.scene_bind == 11 || data.scene_bind == 22 ? '-': data.effective_start.slice(0, 10) == '2006-01-02' ? '暂未采购' : data.effective_start.split('.')[0]|date:'yyyy-MM-dd HH:mm' }}</td>
          <td>{{ data.scene_bind == 11 || data.scene_bind == 22 ? '-':data.effective_end.slice(0, 10) == '2006-01-02' ? '暂未采购' : data.effective_end.split('.')[0]|date:'yyyy-MM-dd HH:mm' }}</td>
        </tr>
        </tbody>
      </nz-table>
    </div>
  </div>
</nz-drawer>

<nz-drawer
  [nzBodyStyle]="{ height: 'calc(100% - 150px)', overflow: 'auto', 'padding-bottom': '53px' }"
  [nzMaskClosable]="true"
  [nzWidth]="1200"
  [nzVisible]="addIsVisible"
  nzTitle="新建订单"
  (nzOnClose)="addCancel()"
>
  <form nz-form [formGroup]="productConfigValidateForm">
    <nz-form-item>
      <nz-form-label [nzSm]="1" [nzXs]="20" nzRequired>客户</nz-form-label>
      <nz-form-control [nzSm]="7" [nzXs]="24" nzErrorTip="请选择客户!">
        <nz-select [nzPlaceHolder]="'请选择客户'" formControlName="company_id">
          <nz-option *ngFor="let option of selectOptions" [nzValue]="option.value"
                     [nzLabel]="option.label"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <nz-divider></nz-divider>
    <nz-descriptions nzTitle="合计" style="margin-top:2vh;">
      <nz-descriptions-item nzTitle="总价&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                            [nzSpan]="1.5" style="font-weight: bolder"><span
        style="color: red;font-size: 20px">{{vitalPrice}}</span>&nbsp;&nbsp;&nbsp;元
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="折后价&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" [nzSpan]="1.5"
                            style="font-weight: bolder"><span
        style="color: red;font-size: 20px">{{discountedVitalPrice}}</span>&nbsp;&nbsp;&nbsp;元
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="CRM座席总数" [nzSpan]="1.5" style="font-weight: bolder"><span
        style="color: red;font-size: 20px">{{vitalCRMNumber}}</span>&nbsp;&nbsp;&nbsp;个
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="CRM总金额" [nzSpan]="1.5" style="font-weight: bolder"><span
        style="color: red;font-size: 20px">{{vitalCrmPrice}}</span>&nbsp;&nbsp;&nbsp;元
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="外呼座席总数" [nzSpan]="1.5" style="font-weight: bolder"><span
        style="color: red;font-size: 20px">{{vitalSeatNumber}}</span>&nbsp;&nbsp;&nbsp;个
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="外呼座席总金额" [nzSpan]="1.5" style="font-weight: bolder"><span
        style="color: red;font-size: 20px">{{vitalSeatPrice}}</span>&nbsp;&nbsp;&nbsp;元
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="外呼号码总数" [nzSpan]="1.5" style="font-weight: bolder"><span
        style="color: red;font-size: 20px">{{vitalPhoneNumber}}</span>&nbsp;&nbsp;&nbsp;个
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="外呼号码总金额" [nzSpan]="1.5" style="font-weight: bolder"><span
        style="color: red;font-size: 20px">{{vitalPhonePrice}}</span>&nbsp;&nbsp;&nbsp;元
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="短信包总数" [nzSpan]="1.5" style="font-weight: bolder"><span
        style="color: red;font-size: 20px">{{vitalMsgNumber}}</span>&nbsp;&nbsp;&nbsp;条
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="短信包总金额" [nzSpan]="1.5" style="font-weight: bolder"><span
        style="color: red;font-size: 20px">{{vitalMsgPrice}}</span>&nbsp;&nbsp;&nbsp;元
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="语音包总数量" [nzSpan]="1.5" style="font-weight: bolder"><span
        style="color: red;font-size: 20px">{{vitalCallNumber}}</span>&nbsp;&nbsp;&nbsp;分钟
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="语音包总金额" [nzSpan]="1.5" style="font-weight: bolder"><span
        style="color: red;font-size: 20px">{{vitalCallPrice}}</span>&nbsp;&nbsp;&nbsp;元
      </nz-descriptions-item>
    </nz-descriptions>
  </form>
  <nz-divider></nz-divider>
  <form nz-form [formGroup]="itemValidateForm" style="margin-top: 2vh">
    <div style="float: left;margin-bottom: 2.5vh">
      <button nz-button [nzType]="'primary'" [nzSize]="'small'" (click)="addBtn()">添加内容</button>&nbsp;&nbsp;
    </div>
    <table style="width: 100%;text-align: center;margin-top: 2.5vh;">
      <tr style="background: rgb(73, 126, 245)">
        <td style="width: 10%;font-weight: bolder">服务</td>
        <td style="width: 10%;font-weight: bolder">产品</td>
        <td style="width: 10%;font-weight: bolder">数量</td>
        <td style="width: 5%;font-weight: bolder">有效时长</td>
        <td style="width: 10%;font-weight: bolder">单位</td>
        <td style="width: 10%;font-weight: bolder">折扣（%）</td>
        <td style="width: 10%;font-weight: bolder">单价（元）</td>
        <td style="width: 10%;font-weight: bolder">折后单价（元）</td>
        <td style="width: 10%;font-weight: bolder">总价（元）</td>
        <td style="width: 10%;font-weight: bolder">折后总价（元）</td>
        <td style="width: 5%;font-weight: bolder">操作</td>
      </tr>
      <ng-container formArrayName='arrayList'>
        <ng-container *ngFor="let row of arrayList.controls;let i =index">
          <tr>
            <ng-container [formGroup]="row">
              <td style="width: 10%;">
                <nz-select [nzPlaceHolder]="'请选择服务'" [nzSize]="'small'" style="width: 100%"
                           (ngModelChange)="loadOperateProductData($event,i)" formControlName="service_id"
                           nzDisabled="{{row['value']['disabled']}}">
                  <nz-option *ngFor="let option of operateServiceOptions" [nzValue]="option.id"
                             [nzLabel]="option.service_name"></nz-option>
                </nz-select>
              </td>
              <td style="width: 10%;">
                <nz-select [nzPlaceHolder]="'请选择产品'" [nzSize]="'small'" style="width: 100%"
                           formControlName="product_id" (ngModelChange)="fetchProductUnit($event,i)"
                           nzDisabled="{{row['value']['disabled']}}">
                  <nz-option *ngFor="let option of arrayList.controls[i].value.productOptions" [nzValue]="option.id"
                             [nzLabel]="option.product_name"></nz-option>
                </nz-select>
              </td>
              <td style="width: 10%;">
                <input nz-input type="number" placeholder="请输入数量" [nzSize]="'small'" style="width: 100%"
                       formControlName="product_number" (ngModelChange)="inputChange(i)"/>
              </td>
              <td style="width: 5%;">
                <nz-select [nzPlaceHolder]="'请选择时长'" [nzSize]="'small'" style="width: 100%"
                           formControlName="effective_month">
                  <nz-option *ngFor="let option of timeOptions" [nzValue]="option.value"
                             [nzLabel]="option.label"></nz-option>
                </nz-select>
              </td>
              <td style="width: 10%;">
                <input nz-input type="text" [nzSize]="'small'" style="width: 100%" formControlName="product_unit"
                       readonly placeholder="自动获取"/>
              </td>
              <td style="width: 10%;">
                <input nz-input type="number" placeholder="请输入折扣" min='0' max='100' step="1" [nzSize]="'small'"
                       style="width: 100%"
                       formControlName="discount" (ngModelChange)="inputChange(i)"/>
              </td>
              <td style="width: 10%;">
                <input nz-input type="text" [nzSize]="'small'" style="width: 100%" formControlName="single_price"
                       placeholder="自动获取"
                       readonly/>
              </td>
              <td style="width: 10%;">
                <input nz-input type="text" [nzSize]="'small'" style="width: 100%"
                       formControlName="discounted_single_price"
                       placeholder="自动计算"
                       readonly/>
              </td>
              <td style="width: 10%;">
                <input nz-input type="text" [nzSize]="'small'" style="width: 100%" formControlName="vital_price"
                       placeholder="自动计算"
                       readonly/>
              </td>
              <td style="width: 10%;">
                <input nz-input type="text" [nzSize]="'small'" style="width: 100%"
                       formControlName="discounted_vital_price" placeholder="自动计算" readonly/>
              </td>
              <td style="width: 5%">
                <button nz-button [nzType]="'danger'" [nzSize]="'small'" (click)="delBtn(i)">删除</button>
              </td>
            </ng-container>
          </tr>
        </ng-container>
      </ng-container>
    </table>
  </form>
  <div style="margin-top: 20vh">
    <button type="button" (click)="addCancel()" class="ant-btn" style="margin-right: 8px;"><span>取消</span>
    </button>
    <button type="button" (click)="addCommit()" class="ant-btn ant-btn-primary"><span>提交</span></button>
  </div>
</nz-drawer>

<nz-card>
  <div class="table-area">
    <div class="param-area">
      <nz-space class="table-query">
        <nz-space-item>
          <nz-range-picker></nz-range-picker>
        </nz-space-item>
        <nz-space-item>
          <nz-select nzShowSearch nzAllowClear nzPlaceHolder="请选择账号状态" [(ngModel)]="selectedValue">
            <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
            <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
            <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
          </nz-select>
        </nz-space-item>
        <nz-space-item>
          <nz-input-group nzSearch [nzAddOnAfter]="suffixButton">
            <input type="text" nz-input placeholder="输入关键字搜索" [(ngModel)]="keyword"/>
          </nz-input-group>
          <ng-template #suffixButton>
            <button nz-button nzType="primary" (click)="loadObjectData()">搜索</button>
          </ng-template>
        </nz-space-item>
        <nz-space-item>
          <button nz-button [nzType]="'default'" (click)="reset()">重置</button>
        </nz-space-item>
      </nz-space>
      <div class="table-operation">
        <button nz-button nzType="primary" (click)="preAdd()">
          <i nz-icon nzType="plus" nzTheme="outline"></i>
          <span>开户</span>
        </button>
      </div>
    </div>
    <div>
      <nz-table
        nzSize="small"
        [nzData]="objects"
        [(nzPageSize)]="size"
        [nzTotal]="total"
        [(nzPageIndex)]="page"
        [nzFrontPagination]="false"
        (nzPageIndexChange)="indexChange()"
        (nzPageSizeChange)="indexChange()"
        [nzShowSizeChanger]="true"
        [nzPageSizeOptions]="[5,10,15,20]"
        [nzShowTotal]="totalTemplate"
        [nzHideOnSinglePage]="true"
      >
        <ng-template #totalTemplate>共 <span style="color:cornflowerblue;margin:0 3px;">{{ total }}</span>
          条
        </ng-template>
        <thead style="background: yellow">
        <tr>
          <th nzWidth="50px"></th>
          <th nzWidth="100px">客户编号</th>
          <th nzWidth="100px">客户名称</th>
          <th nzWidth="100px">归属人</th>
          <th nzWidth="100px">开户时间</th>
          <th nzWidth="100px">开户人</th>
          <th nzWidth="100px">审批时间</th>
          <th nzWidth="100px">审批人</th>
          <th nzWidth="200px">有效时间</th>
          <th nzWidth="100px">审核状态</th>
          <!--          <th nzWidth="100px">账号状态</th>-->
          <th nzWidth="100px">操作</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let object of objects">
          <td nzShowCheckbox [(nzChecked)]="object.checked"
              (nzCheckedChange)="onChange(object,'single')"></td>
          <td>{{ object.id }}</td>
          <td>{{ object.company_name }}</td>
          <td>{{ object.sales_person_name }}</td>
          <td>{{ object.create_time.slice(0, 10) }}</td>
          <td>{{ object.create_user_name }}</td>
          <td>{{ object.audit_time.slice(0, 10) == '2006-01-02' ? '暂未审批' : object.audit_time.slice(0, 10)}}</td>
          <td>{{ object.audit_time.slice(0, 10) == '2006-01-02' ? '暂未审批' : object.audit_user_name }}</td>
          <td>{{ object.effective_start|date:'yyyy/MM/dd'}}至{{object.effective_end|date:'yyyy/MM/dd'}}</td>
          <td>
            <nz-badge *ngIf="object.audit_status==1" nzStatus="processing"></nz-badge>
            <nz-badge *ngIf="object.audit_status==2" nzStatus="success"></nz-badge>
            <nz-badge *ngIf="object.audit_status==3" nzStatus="error"></nz-badge>
            <span *ngIf="object.audit_status==1">审批中</span>
            <span *ngIf="object.audit_status==2">已通过</span>
            <span *ngIf="object.audit_status==3">未通过</span>
          </td>
          <td>
            <a (click)="preDetail(object)"><i nz-icon nzType="monitor" nzTheme="outline"></i>详情</a>&nbsp;&nbsp;&nbsp;&nbsp;
            <span *ngIf="object.audit_status==1&&object.status==0"><a (click)="preEdit(object)"><i nz-icon nzType="edit"
                                                                                                   nzTheme="outline"></i>编辑</a>&nbsp;&nbsp;&nbsp;</span>
          </td>
        </tr>
        </tbody>
      </nz-table>
    </div>
  </div>
</nz-card>

<!--开户-->
<nz-drawer
  [nzBodyStyle]="{ height: 'calc(100% - 150px)', overflow: 'auto', 'padding-bottom': '53px' }"
  [nzMaskClosable]="true"
  [nzWidth]="1250"
  [nzVisible]="addIsVisible"
  nzTitle="开户"
  (nzOnClose)="addCancel()"
>
  <nz-steps [nzCurrent]="current" class="card">
    <nz-step nzTitle="客户信息"></nz-step>
    <nz-step nzTitle="产品服务"></nz-step>
    <nz-step nzTitle="合同信息"></nz-step>
  </nz-steps>
  <div class="steps-content">
    <div *ngIf="current==0">
      <nz-card>
        <form nz-form [formGroup]="baseValidateForm" class="ant-advanced-search-form">
          <div class="permission" style="width: 100%">
            <div nz-row [nzGutter]="12">
              <div nz-col [nzSpan]="24">
                <nz-form-item nzFlex>
                  <nz-form-label style="width: 17.5%;text-align: left;" nzRequired>营业执照</nz-form-label>
                  <nz-form-control style="width: 12.5%" nzErrorTip="请上传营业执照!">
                    <nz-upload
                      class="avatar-uploader"
                      nzListType="picture-card"
                      [nzAction]="oss_save_url"
                      [nzLimit]="1"
                      [nzShowUploadList]="false"
                      [nzBeforeUpload]="beforeUploadImg"
                      (nzChange)="handleImgChange($event)"
                    >
                      <ng-container *ngIf="!company_license_url">
                        <i class="upload-icon" nz-icon [nzType]="loadingImg ? 'loading' : 'plus'"></i>
                        <div class="ant-upload-text">上传营业执照</div>
                      </ng-container>
                      <img *ngIf="company_license_url" [src]="company_license_url" style="width: 100%"/>
                    </nz-upload>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="12">
                <nz-form-item nzFlex>
                  <nz-form-label style="width: 35%;text-align: left;" nzRequired>企业名称</nz-form-label>
                  <nz-form-control style="width: 25%;" nzErrorTip="请输入企业名称!">
                    <input type="text" nz-input placeholder="请输入企业名称" formControlName="company_name"/>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="12">
                <nz-form-item nzFlex>
                  <nz-form-label style="width: 35%;text-align: left;" nzRequired>统一社会信用代码</nz-form-label>
                  <nz-form-control style="width: 25%;" nzErrorTip="请输入统一社会信用代码!">
                    <input type="text" nz-input placeholder="请输入统一社会信用代码" formControlName="uniform_social_credit_code"/>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="12">
                <nz-form-item nzFlex>
                  <nz-form-label style="width: 35%;text-align: left;" nzRequired>管理员姓名</nz-form-label>
                  <nz-form-control style="width: 25%;" nzErrorTip="请输入管理员姓名!">
                    <input type="text" nz-input placeholder="请输入管理员姓名" formControlName="user_name"/>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="12">
                <nz-form-item nzFlex>
                  <nz-form-label style="width: 35%;text-align: left;" nzRequired>手机号码</nz-form-label>
                  <nz-form-control style="width: 25%;" nzErrorTip="请输入手机号码!">
                    <input type="text" nz-input placeholder="请输入手机号码" formControlName="mobile"/>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="12">
                <nz-form-item nzFlex>
                  <nz-form-label style="width: 35%;text-align: left;" nzRequired>登录密码</nz-form-label>
                  <nz-form-control style="width: 25%;" nzErrorTip="请输入登录密码!">
                    <input nz-input type="password" placeholder="请输入登录密码" formControlName="password"
                           (ngModelChange)="updateConfirmValidator()"/>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="12">
                <nz-form-item nzFlex>
                  <nz-form-label style="width: 35%;text-align: left;" nzRequired>确认密码</nz-form-label>
                  <nz-form-control style="width: 25%;" [nzErrorTip]="errorTpl">
                    <input nz-input type="password" placeholder="请确认密码" formControlName="check_password"/>
                    <ng-template #errorTpl let-control>
                      <ng-container *ngIf="control.hasError('required')">
                        请输入确认密码!
                      </ng-container>
                      <ng-container *ngIf="control.hasError('confirm')">
                        两次输入密码不一致!
                      </ng-container>
                    </ng-template>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="12">
                <nz-form-item nzFlex>
                  <nz-form-label style="width: 35%;text-align: left;">所属行业</nz-form-label>
                  <nz-form-control style="width: 25%;">
                    <nz-cascader nzChangeOnSelect [nzPlaceHolder]="'请选择所属行业'" [nzOptions]="tradeOptions"
                                 formControlName="trades" (ngModelChange)="onCascaderChanges($event)"></nz-cascader>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="12">
                <nz-form-item nzFlex>
                  <nz-form-label style="width: 35%;text-align: left;">企业规模</nz-form-label>
                  <nz-form-control style="width: 25%;">
                    <nz-select nzShowSearch nzAllowClear formControlName="enterprise_size">
                      <nz-option nzLabel="0-20人" nzValue="1530"></nz-option>
                      <nz-option nzLabel="20-99人" nzValue="1531"></nz-option>
                      <nz-option nzLabel="100-499人" nzValue="1532"></nz-option>
                      <nz-option nzLabel="500-999人" nzValue="1533"></nz-option>
                      <nz-option nzLabel="1000-4999人" nzValue="1534"></nz-option>
                      <nz-option nzLabel="5000-9999人" nzValue="1535"></nz-option>
                      <nz-option nzLabel="10000人以上" nzValue="1536"></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="24">
                <nz-form-item nzFlex>
                  <nz-form-label style="width: 17.5%;text-align: left;">经营地址</nz-form-label>
                  <nz-form-control style="width: 25%;">
                    <input type="text" nz-input placeholder="请输入经营地址" formControlName="address"/>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </div>
        </form>
      </nz-card>
    </div>
    <div *ngIf="current==1">
      <form nz-form [formGroup]="itemValidateForm">
        <div style="float: right;margin-bottom: 2.5vh">
          <button nz-button [nzType]="'primary'" [nzSize]="'small'" (click)="addBtn()">添加内容</button>&nbsp;&nbsp;
        </div>
        <table style="width: 100%;text-align: center;margin-top: 2.5vh;">
          <tr style="background: rgb(73, 126, 245)">
            <td style="width: 10%;font-weight: bolder">服务</td>
            <td style="width: 10%;font-weight: bolder">产品</td>
            <td style="width: 10%;font-weight: bolder">数量</td>
            <td style="width: 5%;font-weight: bolder">有效时长</td>
            <td style="width: 10%;font-weight: bolder">单位</td>
            <td style="width: 10%;font-weight: bolder">折扣（%）</td>
            <td style="width: 10%;font-weight: bolder">单价（元）</td>
            <td style="width: 10%;font-weight: bolder">折后单价（元）</td>
            <td style="width: 10%;font-weight: bolder">总价（元）</td>
            <td style="width: 10%;font-weight: bolder">折后总价（元）</td>
            <td style="width: 5%;font-weight: bolder">操作</td>
          </tr>
          <ng-container formArrayName='arrayList'>
            <ng-container *ngFor="let row of arrayList.controls;let i =index">
              <tr>
                <ng-container [formGroup]="row">
                  <td style="width: 10%;">
                    <nz-select [nzPlaceHolder]="'请选择服务'" [nzSize]="'small'" style="width: 100%"
                               (ngModelChange)="loadOperateProductData($event,i)" formControlName="service_id"
                               nzDisabled="{{row['value']['disabled']}}">
                      <nz-option *ngFor="let option of operateServiceOptions" [nzValue]="option.id"
                                 [nzLabel]="option.service_name"></nz-option>
                    </nz-select>
                  </td>
                  <td style="width: 10%;">
                    <nz-select [nzPlaceHolder]="'请选择产品'" [nzSize]="'small'" style="width: 100%"
                               formControlName="product_id" (ngModelChange)="fetchProductUnit($event,i)"
                               nzDisabled="{{row['value']['disabled']}}">
                      <nz-option *ngFor="let option of operateProductOptions" [nzValue]="option.id"
                                 [nzLabel]="option.product_name"></nz-option>
                    </nz-select>
                  </td>
                  <td style="width: 10%;">
                    <input nz-input type="number" placeholder="请输入数量" [nzSize]="'small'" style="width: 100%"
                           formControlName="product_number" (ngModelChange)="inputChange(i)"/>
                  </td>
                  <td style="width: 5%;">
                    <nz-select [nzPlaceHolder]="'请选择时长'" [nzSize]="'small'" style="width: 100%"
                               formControlName="effective_time">
                      <nz-option *ngFor="let option of timeOptions" [nzValue]="option.value"
                                 [nzLabel]="option.label"></nz-option>
                    </nz-select>
                  </td>
                  <td style="width: 10%;">
                    <input nz-input type="text" [nzSize]="'small'" style="width: 100%" formControlName="product_unit"
                           readonly placeholder="自动获取"/>
                  </td>
                  <td style="width: 10%;">
                    <input nz-input type="number" placeholder="请输入折扣" [nzSize]="'small'" style="width: 100%"
                           formControlName="discount" (ngModelChange)="inputChange(i)"/>
                  </td>
                  <td style="width: 10%;">
                    <input nz-input type="text" [nzSize]="'small'" style="width: 100%" formControlName="single_price"
                           placeholder="自动获取"
                           readonly/>
                  </td>
                  <td style="width: 10%;">
                    <input nz-input type="text" [nzSize]="'small'" style="width: 100%"
                           formControlName="discounted_price"
                           placeholder="自动计算"
                           readonly/>
                  </td>
                  <td style="width: 10%;">
                    <input nz-input type="text" [nzSize]="'small'" style="width: 100%" formControlName="vital_price"
                           placeholder="自动计算"
                           readonly/>
                  </td>
                  <td style="width: 10%;">
                    <input nz-input type="text" [nzSize]="'small'" style="width: 100%"
                           formControlName="vital_discounted_price" placeholder="自动计算" readonly/>
                  </td>
                  <td style="width: 5%">
                    <button nz-button [nzType]="'danger'" [nzSize]="'small'" (click)="delBtn(i)">删除</button>
                  </td>
                </ng-container>
              </tr>
            </ng-container>
          </ng-container>
        </table>
      </form>
      <!--      <nz-card>-->
      <!--        <form nz-form [formGroup]="productValidateForm" class="ant-advanced-search-form">-->
      <!--          <div class="permission" style="width: 100%">-->
      <!--            <div nz-row [nzGutter]="24">-->
      <!--              <div nz-col [nzSpan]="12">-->
      <!--                <nz-form-item nzFlex>-->
      <!--                  <nz-form-label style="width: 35%;text-align: left;" nzRequired>企业名称</nz-form-label>-->
      <!--                  <nz-form-control style="width: 25%;" nzErrorTip="请输入企业名称!">-->
      <!--                    <input type="text" nz-input placeholder="请输入企业名称" formControlName="mobile"/>-->
      <!--                  </nz-form-control>-->
      <!--                </nz-form-item>-->
      <!--              </div>-->
      <!--              <div nz-col [nzSpan]="12">-->
      <!--                <nz-form-item nzFlex>-->
      <!--                  <nz-form-label style="width: 35%;text-align: left;" nzRequired>统一社会信用代码</nz-form-label>-->
      <!--                  <nz-form-control style="width: 25%;" nzErrorTip="请输入统一社会信用代码!">-->
      <!--                    <input type="text" nz-input placeholder="请输入统一社会信用代码" formControlName="mobile"/>-->
      <!--                  </nz-form-control>-->
      <!--                </nz-form-item>-->
      <!--              </div>-->
      <!--              <div nz-col [nzSpan]="12">-->
      <!--                <nz-form-item nzFlex>-->
      <!--                  <nz-form-label style="width: 35%;text-align: left;" nzRequired>管理员姓名</nz-form-label>-->
      <!--                  <nz-form-control style="width: 25%;" nzErrorTip="请输入管理员姓名!">-->
      <!--                    <input type="text" nz-input placeholder="请输入管理员姓名" formControlName="mobile"/>-->
      <!--                  </nz-form-control>-->
      <!--                </nz-form-item>-->
      <!--              </div>-->
      <!--              <div nz-col [nzSpan]="12">-->
      <!--                <nz-form-item nzFlex>-->
      <!--                  <nz-form-label style="width: 35%;text-align: left;" nzRequired>手机号码</nz-form-label>-->
      <!--                  <nz-form-control style="width: 25%;" nzErrorTip="请输入手机号码!">-->
      <!--                    <input type="text" nz-input placeholder="请输入手机号码" formControlName="mobile"/>-->
      <!--                  </nz-form-control>-->
      <!--                </nz-form-item>-->
      <!--              </div>-->
      <!--              <div nz-col [nzSpan]="12">-->
      <!--                <nz-form-item nzFlex>-->
      <!--                  <nz-form-label style="width: 35%;text-align: left;">所属行业</nz-form-label>-->
      <!--                  <nz-form-control style="width: 25%;">-->
      <!--                    <nz-cascader nzChangeOnSelect [nzPlaceHolder]="'请选择所属行业'" [nzOptions]="nzOptions"-->
      <!--                                 [(ngModel)]="values" (ngModelChange)="onCascaderChanges($event)"></nz-cascader>-->
      <!--                  </nz-form-control>-->
      <!--                </nz-form-item>-->
      <!--              </div>-->
      <!--              <div nz-col [nzSpan]="12">-->
      <!--                <nz-form-item nzFlex>-->
      <!--                  <nz-form-label style="width: 35%;text-align: left;">企业规模</nz-form-label>-->
      <!--                  <nz-form-control style="width: 25%;">-->
      <!--                    <nz-select nzShowSearch nzAllowClear [(ngModel)]="selectedValue">-->
      <!--                      <nz-option nzLabel="Jack" nzValue="jack"></nz-option>-->
      <!--                      <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>-->
      <!--                      <nz-option nzLabel="Tom" nzValue="tom"></nz-option>-->
      <!--                    </nz-select>-->
      <!--                  </nz-form-control>-->
      <!--                </nz-form-item>-->
      <!--              </div>-->
      <!--              <div nz-col [nzSpan]="24">-->
      <!--                <nz-form-item nzFlex>-->
      <!--                  <nz-form-label style="width: 17.5%;text-align: left;">经营地址</nz-form-label>-->
      <!--                  <nz-form-control style="width: 25%;">-->
      <!--                    <input type="text" nz-input placeholder="请输入经营地址" formControlName="mobile"/>-->
      <!--                  </nz-form-control>-->
      <!--                </nz-form-item>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </form>-->
      <!--      </nz-card>-->
    </div>
    <div *ngIf="current==2">
      <nz-card>
        <form nz-form [formGroup]="agreementValidateForm" class="ant-advanced-search-form">
          <div class="permission" style="width: 100%">
            <div nz-row [nzGutter]="24">
              <div nz-col [nzSpan]="12">
                <nz-form-item nzFlex>
                  <nz-form-label style="width: 25%;text-align: left;" nzRequired>合同名称</nz-form-label>
                  <nz-form-control style="width: 20%" nzErrorTip="请输入合同标题!">
                    <input nz-input placeholder="请输入合同标题" formControlName="title"/>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="12">
                <nz-form-item nzFlex>
                  <nz-form-label style="width: 25%;text-align: left" nzRequired>合同类型</nz-form-label>
                  <nz-form-control style="width: 25%" nzErrorTip="请选择合同类型!">
                    <nz-select formControlName="customer_charger" [nzPlaceHolder]="'请选择合同类型'">
                      <nz-option *ngFor="let role of selectOptions" [nzValue]="role.id"
                                 [nzLabel]="role.role_name">
                      </nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="12">
                <nz-form-item nzFlex>
                  <nz-form-label style="width: 25%;text-align: left" nzRequired>签约客户</nz-form-label>
                  <nz-form-control style="width: 25%">
                    <input nz-input placeholder="自动获取" formControlName="amount" [readOnly]="true" [disabled]="true"/>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="12">
                <nz-form-item nzFlex>
                  <nz-form-label style="width: 25%;text-align: left;" nzRequired>合同金额</nz-form-label>
                  <nz-form-control style="width: 20%;">
                    <input nz-input placeholder="自动计算" formControlName="amount" [readOnly]="true" [disabled]="true"/>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="12">
                <nz-form-item nzFlex>
                  <nz-form-label style="width: 25%;text-align: left" nzRequired>乙方负责人</nz-form-label>
                  <nz-form-control style="width: 25%" nzErrorTip="请选择乙方负责人!">
                    <nz-select formControlName="customer_charger" [nzPlaceHolder]="'请选择乙方负责人'">
                      <nz-option *ngFor="let role of selectOptions" [nzValue]="role.id"
                                 [nzLabel]="role.role_name">
                      </nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="12">
                <nz-form-item nzFlex>
                  <nz-form-label style="width: 25%;text-align: left" nzRequired>甲方负责人</nz-form-label>
                  <nz-form-control style="width: 25%" nzErrorTip="请选择甲方负责人!">
                    <nz-select formControlName="sk_charger" [nzPlaceHolder]="'请选择甲方负责人'">
                      <nz-option *ngFor="let role of selectOptions" [nzValue]="role.id"
                                 [nzLabel]="role.role_name">
                      </nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="12">
                <nz-form-item nzFlex>
                  <nz-form-label style="width: 25%;text-align: left" nzRequired>销售员</nz-form-label>
                  <nz-form-control style="width: 25%" nzErrorTip="请选择销售员!">
                    <nz-select formControlName="salesperson" [nzPlaceHolder]="'请选择销售员'">
                      <nz-option *ngFor="let role of selectOptions" [nzValue]="role.id"
                                 [nzLabel]="role.role_name"></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="12">
                <nz-form-item nzFlex>
                  <nz-form-label style="width: 25%;text-align: left" nzRequired>签订日期</nz-form-label>
                  <nz-form-control nzErrorTip="请选择签订日期!">
                    <nz-date-picker style="width: 100%" [nzFormat]="'yyyy/MM/dd'"
                                    formControlName="signing_date"></nz-date-picker>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="12">
                <nz-form-item nzFlex>
                  <nz-form-label style="width: 25%;text-align: left" nzRequired>有效期</nz-form-label>
                  <nz-form-control style="width: 25%" nzErrorTip="请选择有效期!">
                    <nz-range-picker style="width: 100%" [nzFormat]="'yyyy/MM/dd'"
                                     formControlName="effective_dates"></nz-range-picker>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="12">
                <nz-form-item nzFlex>
                  <nz-form-label style="width: 25%;text-align: left;" nzRequired>联系电话</nz-form-label>
                  <nz-form-control style="width: 25%;" nzErrorTip="请输入联系电话!">
                    <input type="text" nz-input placeholder="请输入联系电话" formControlName="mobile"/>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="12">
                <nz-form-item nzFlex>
                  <nz-form-label style="width: 25%;text-align: left;">联系邮箱</nz-form-label>
                  <nz-form-control style="width: 25%;">
                    <input type="text" nz-input placeholder="请输入联系邮箱" formControlName="email"/>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="12">
                <nz-form-item nzFlex>
                  <nz-form-label style="width: 25%;text-align: left;">联系地址</nz-form-label>
                  <nz-form-control style="width: 25%;">
                    <input type="text" nz-input placeholder="请输入联系地址" formControlName="address"/>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="12">
                <nz-form-item nzFlex>
                  <nz-form-label style="width: 25%;text-align: left;">合同附件</nz-form-label>
                  <nz-form-control style="width: 50%;">
                    <div style="display: flex">
                      <nz-upload [(nzFileList)]="appendixList" [nzBeforeUpload]="beforeUploadAppendix"
                                 style="float: left">
                        <button nz-button><i nz-icon nzType="upload"></i>选择文件</button>
                      </nz-upload>
                      <button
                        nz-button
                        [nzType]="'primary'"
                        [nzLoading]="uploading"
                        (click)="handleUploadAppendix()"
                        [disabled]="appendixList.length == 0"
                        style="margin-left:5%;float:right;">
                        {{ uploading ? '加载中' : '开始上传' }}
                      </button>
                    </div>

                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="24">
                <nz-form-item nzFlex>
                  <nz-form-label style="width: 12.5%;text-align: left;">备注</nz-form-label>
                  <nz-form-control style="width: 50%;">
                    <textarea rows="3" nz-input placeholder="请输入备注" formControlName="remark"></textarea>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </div>
        </form>
      </nz-card>
    </div>
  </div>
  <div class="steps-action">
    <button nz-button nzType="default" class="steps-button" (click)="pre()" *ngIf="current > 0">
      <span>上一步</span>
    </button>
    <button nz-button nzType="default" class="steps-button" (click)="next()" *ngIf="current < 2">
      <span>下一步</span>
    </button>
    <button nz-button nzType="primary" class="steps-button" (click)="done()" *ngIf="current === 2">
      <span>提交</span>
    </button>
    <button nz-button nzType="primary" class="steps-button" (click)="next()">
      <span>预览</span>
    </button>
  </div>
  <!--  </form>-->
  <!--  <div class="footer">-->
  <!--    <button type="button" (click)="editCancel()" class="ant-btn" style="margin-right: 8px;"><span>取消</span>-->
  <!--    </button>-->
  <!--    <button type="button" (click)="editCommit()" class="ant-btn ant-btn-primary"><span>提交</span></button>-->
  <!--  </div>-->
</nz-drawer>
<nz-modal [(nzVisible)]="preViewIsVisible" nzTitle="开户详情" (nzOnCancel)="preViewCancel()" (nzOnOk)="preViewOk()">
  <p>Content one</p>
  <p>Content two</p>
  <p>Content three</p>
  <p>Content three</p>
</nz-modal>

<!--审核-->
<nz-modal [(nzVisible)]="editIsVisible" nzTitle="审批" [nzFooter]="modalFooter"
          [nzMaskClosable]="true" (nzOnCancel)="editCancel()">
  <form nz-form [formGroup]="editValidateForm">
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>审核状态</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24">
        <nz-switch formControlName="status" nzCheckedChildren="通过" nzUnCheckedChildren="驳回"></nz-switch>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>审核说明</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24">
        <textarea rows="4" nz-input placeholder="请输入审核说明" formControlName="remark"></textarea>
      </nz-form-control>
    </nz-form-item>
  </form>
  <ng-template #modalFooter>
    <button nz-button nzType="default" (click)="editCancel()">取消</button>
    <button nz-button nzType="primary" (click)="editCommit()">确定</button>
  </ng-template>
</nz-modal>

<nz-card>
  <div class="table-area">
    <div class="param-area">
      <nz-space class="table-query">
        <nz-space-item style="width: 35%">
          <nz-select [nzPlaceHolder]="'请选择服务类型'" [(ngModel)]="serviceType" (ngModelChange)="loadObjectData()"
                     style="width: 100%">
            <nz-option *ngFor="let option of queryServiceTypeOptions" [nzValue]="option.value"
                       [nzLabel]="option.label"></nz-option>
          </nz-select>
        </nz-space-item>
        <nz-space-item style="width: 35%">
          <nz-select [nzPlaceHolder]="'请选择服务'" [(ngModel)]="serviceId" (ngModelChange)="loadObjectData()"
                     style="width: 100%">
            <nz-option *ngFor="let option of queryServiceOptions" [nzValue]="option.id"
                       [nzLabel]="option.service_name"></nz-option>
          </nz-select>
        </nz-space-item>
        <nz-space-item>
          <nz-input-group nzSearch [nzAddOnAfter]="suffixButton">
            <input type="text" nz-input placeholder="输入关键字搜索" [(ngModel)]="keyword"/>
          </nz-input-group>
          <ng-template #suffixButton>
            <button nz-button nzType="primary" (click)="loadObjectData()">搜索</button>
          </ng-template>
        </nz-space-item>
        <nz-space-item>
          <button nz-button [nzType]="'default'" (click)="reset()">重置</button>
        </nz-space-item>
      </nz-space>
      <div class="table-operation">
        <button nz-button nzType="primary" (click)="preOperate(1,null)">
          <i nz-icon nzType="plus" nzTheme="outline"></i>
          <span>新增产品</span>
        </button>
        <!--        <button nz-button nzType="default" (click)="preOperate(1,null)" style="margin-left: 8%">-->
        <!--          <i nz-icon nzType="undo" nzTheme="outline"></i>-->
        <!--          <span>误删恢复</span>-->
        <!--        </button>-->
      </div>
    </div>
    <div>
      <nz-table
        nzSize="small"
        [nzData]="objects"
        [(nzPageSize)]="size"
        [nzTotal]="total"
        [(nzPageIndex)]="page"
        [nzFrontPagination]="false"
        (nzPageIndexChange)="indexChange()"
        (nzPageSizeChange)="indexChange()"
        [nzShowSizeChanger]="true"
        [nzPageSizeOptions]="[5,10,15,20]"
        [nzShowTotal]="totalTemplate"
        [nzHideOnSinglePage]="true"
      >
        <ng-template #totalTemplate>共 <span style="color:cornflowerblue;margin:0 3px;">{{ total }}</span>
          条
        </ng-template>
        <thead style="background: yellow">
        <tr>
          <th nzWidth="100px">产品编号</th>
          <th nzWidth="100px">所属服务</th>
          <th nzWidth="100px">产品名称</th>
          <th nzWidth="100px">产品单价（元）</th>
          <th nzWidth="100px">产品单位</th>
          <th nzWidth="100px">上架状态</th>
          <th nzWidth="100px">应用场景</th>
          <th nzWidth="100px">操作</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let object of objects">
          <td>{{ object.product_code }}</td>
          <td>{{ object.service_name }}</td>
          <td>{{ object.product_name }}</td>
          <td>{{ object.single_price }}</td>
          <td>{{ object.product_unit }}</td>
          <td>
            <nz-badge *ngIf="object.status" nzStatus="processing"></nz-badge>
            <nz-badge *ngIf="!object.status" nzStatus="default"></nz-badge>
            {{  object.status ? '上架' : '下架'}}</td>
          <td>
            <span *ngIf="object.scene_bind==1">CRM座席</span>
            <span *ngIf="object.scene_bind==10">文本短信包</span>
            <span *ngIf="object.scene_bind==11">视频短信包</span>
            <span *ngIf="object.scene_bind==20">外呼座席</span>
            <span *ngIf="object.scene_bind==21">外呼号码</span>
            <span *ngIf="object.scene_bind==22">外呼语音包</span>
          </td>
          <td>
            <span><a (click)="preOperate(2,object)"><i nz-icon nzType="edit" nzTheme="outline"></i>编辑</a>&nbsp;&nbsp;&nbsp;</span>
            <span *ngIf="!object.status"><a (click)="preDownOrUp(object)"><i nz-icon nzType="vertical-align-top"
                                                                             nzTheme="outline"></i>上架</a>&nbsp;&nbsp;&nbsp;</span>
            <span *ngIf="object.status"><a (click)="preDownOrUp(object)"><i nz-icon nzType="vertical-align-bottom"
                                                                            nzTheme="outline"></i>下架</a></span>
          </td>
        </tr>
        </tbody>
      </nz-table>
    </div>
  </div>
</nz-card>
<!--添加/编辑产品-->
<nz-drawer
  [nzBodyStyle]="{ height: 'calc(100% - 150px)', overflow: 'auto', 'padding-bottom': '53px' }"
  [nzMaskClosable]="true"
  [nzWidth]="820"
  [nzVisible]="operateIsVisible"
  nzTitle="{{operateModalName}}"
  (nzOnClose)="operateCancel()"
>
  <nz-card>
    <form nz-form [formGroup]="validateForm">
      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>所属服务</nz-form-label>
        <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="请选择所属服务!">
          <nz-select [nzPlaceHolder]="'请选择所属服务'" formControlName="service_id">
            <nz-option *ngFor="let option of operateOptions" [nzValue]="option.id"
                       [nzLabel]="option.service_name"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>产品名称</nz-form-label>
        <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="请输入产品名称!">
          <input nz-input placeholder="请输入产品名称" formControlName="product_name"/>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>产品单价</nz-form-label>
        <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="请输入产品单价!">
          <input nz-input type="number" placeholder="请输入产品单价" [nzSize]="'small'" style="width: 100%"
                 formControlName="single_price"/>
          <!--                 formControlName="single_price" (ngModelChange)="inputChange(null,2)"/>-->
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>产品单位</nz-form-label>
        <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="请输入产品单位!">
          <input nz-input placeholder="请输入产品单位" formControlName="product_unit"/>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item *ngIf="switchIsVisible">
        <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>上架状态</nz-form-label>
        <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="请选择上架状态!">
          <nz-switch formControlName="status" nzCheckedChildren="上架" nzUnCheckedChildren="下架"></nz-switch>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>应用场景</nz-form-label>
        <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="请绑定应用场景!">
          <nz-radio-group formControlName="scene_bind">
            <div style="margin-top: 0.5vh">
              <label nz-radio nzValue=1>CRM座席</label>
<!--              <label nz-radio nzValue=10>文本短信包</label>-->
              <label nz-radio nzValue=11>视频短信包</label>
              <label nz-radio nzValue=20>外呼座席</label>
            </div>

            <div style="margin-top: 1vh">
              <label nz-radio nzValue=21>外呼号码</label>
              <label nz-radio nzValue=22>外呼语音包</label>
            </div>

          </nz-radio-group>
        </nz-form-control>
      </nz-form-item>
      <!--      <nz-form-item>-->
      <!--        <nz-form-label [nzSm]="3" [nzXs]="24" nzRequired>SKU管理</nz-form-label>-->
      <!--      </nz-form-item>-->
    </form>
    <!--    <form nz-form [formGroup]="itemValidateForm">-->
    <!--      <div style="margin-left: 82.5%">-->
    <!--        <button nz-button [nzType]="'primary'" [nzSize]="'small'" (click)="addBtn()">添加SKU</button>&nbsp;&nbsp;-->
    <!--      </div>-->
    <!--      <table style="margin-top: 2.5vh">-->
    <!--        <tr style="background: rgb(73, 126, 245)">-->
    <!--          <td style="width: 15%;font-weight: bolder">数量</td>-->
    <!--          <td style="width: 15%;font-weight: bolder">时长</td>-->
    <!--          <td style="width: 15%;font-weight: bolder">折扣（%）</td>-->
    <!--          <td style="width: 15%;font-weight: bolder">折后单价（元）</td>-->
    <!--          <td style="width: 15%;font-weight: bolder">总价（元）</td>-->
    <!--          <td style="width: 15%;font-weight: bolder">折后总价（元）</td>-->
    <!--          <td style="width: 5%;font-weight: bolder">操作</td>-->
    <!--        </tr>-->
    <!--        <ng-container formArrayName='arrayList'>-->
    <!--          <ng-container *ngFor="let row of arrayList.controls;let i =index">-->
    <!--            <tr>-->
    <!--              <ng-container [formGroup]="row">-->
    <!--                <td style="width: 15%;">-->
    <!--                  <input nz-input type="number" placeholder="请输入数量" [nzSize]="'small'" style="width: 100%"-->
    <!--                         formControlName="product_number" (ngModelChange)="inputChange(i  ,1)"/>-->
    <!--                </td>-->
    <!--                <td style="width: 15%;">-->
    <!--                  <nz-select [nzPlaceHolder]="'请选择时长'" [nzSize]="'small'" style="width: 100%"-->
    <!--                             formControlName="effective_time">-->
    <!--                    <nz-option *ngFor="let option of timeOptions" [nzValue]="option.value"-->
    <!--                               [nzLabel]="option.label"></nz-option>-->
    <!--                  </nz-select>-->
    <!--                </td>-->
    <!--                <td style="width: 15%;">-->
    <!--                  <input nz-input type="number" placeholder="请输入折扣" [nzSize]="'small'" style="width: 100%"-->
    <!--                         formControlName="discount" (ngModelChange)="inputChange(i,1)"/>-->
    <!--                </td>-->
    <!--                <td style="width: 15%;">-->
    <!--                  <input nz-input type="text" [nzSize]="'small'" style="width: 100%"-->
    <!--                         formControlName="discounted_price" placeholder="自动计算" readonly/>-->
    <!--                </td>-->
    <!--                <td style="width: 15%;">-->
    <!--                  <input nz-input type="text" [nzSize]="'small'" style="width: 100%"-->
    <!--                         formControlName="vital_price" placeholder="自动计算" readonly/>-->
    <!--                </td>-->
    <!--                <td style="width: 15%;">-->
    <!--                  <input nz-input type="text" [nzSize]="'small'" style="width: 100%"-->
    <!--                         formControlName="vital_discounted_price" placeholder="自动计算" readonly/>-->
    <!--                </td>-->
    <!--                <td style="width: 5%">-->
    <!--                  <button nz-button [nzType]="'danger'" [nzSize]="'small'" (click)="delBtn(i)">删除</button>-->
    <!--                </td>-->
    <!--              </ng-container>-->
    <!--            </tr>-->
    <!--          </ng-container>-->
    <!--        </ng-container>-->
    <!--      </table>-->
    <!--    </form>-->
  </nz-card>

  <div style="margin-top: 10vh">
    <button type="button" (click)="operateCancel()" class="ant-btn" style="margin-right: 8px;"><span>取消</span>
    </button>
    <button type="button" (click)="operateCommit()" class="ant-btn ant-btn-primary"><span>提交</span></button>
  </div>
</nz-drawer>

/*
ng-zorro-antd
 */
import {NgModule} from '@angular/core';
import {NgZorroAntdModule} from 'ng-zorro-antd/';
import {NzFormModule} from 'ng-zorro-antd/form';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzTableModule} from 'ng-zorro-antd/table';
import {NzTabsModule} from 'ng-zorro-antd/tabs';
import {NzSpaceModule} from "ng-zorro-antd/space";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxEchartsModule} from 'ngx-echarts';
/*
 self封装功能
 */
import {SidebarRouterModule} from './sidebar-router.module';
import {HttpClientModule} from '@angular/common/http';
import {CommonModule} from '@angular/common';
import {SimpleReuseStrategy} from '../../service/SimpleReuseStrategy';
import {RouteReuseStrategy} from '@angular/router';
import {DragDropModule} from "@angular/cdk/drag-drop";
/*
业务模块
 */
import {SidebaruserinfoComponent} from '../../pages/navigation/sidebaruserinfo/sidebaruserinfo.component';
import {SidebarNewMessageComponent} from '../../pages/navigation/sidebarnewmessage/sidebarnewmessage.component';
import {SidebarhistoryComponent} from '../../pages/navigation/sidebarhistory/sidebarhistory.component';
import {HistoryComponent} from '../../pages/navigation/history/history.component';
import {HomePageComponent} from '../../pages/home-page/home-page.component';
import {ProbationComponent} from "../../pages/customer/probation/probation.component";
import {SubscribeComponent} from "../../pages/customer/subscribe/subscribe.component";
import {AddSubscribeComponent} from "@src/app/pages/customer/subscribe/add-subscribe/add-subscribel.component";
import {SubscribeDetailComponent} from "../../pages/customer/subscribe/subscribe-detail/subscribe-detail.component";
import {OrderComponent} from "../../pages/finance-center/order/order.component";
import {BillComponent} from "@src/app/pages/finance-center/bill/bill.component";
import {FeeComponent} from "../../pages/finance-center/fee/fee.component";
import {PurchaseComponent} from "@src/app/pages/finance-center/purchase/purchase.component";
import {InvoiceComponent} from "../../pages/finance-center/invoice/invoice.component";
import {ServicesComponent} from "../../pages/product-service/services/services.component";
import {ProductsComponent} from "../../pages/product-service/products/products.component";
import {PackagesComponent} from "../../pages/product-service/packages/packages.component";
import {SignComponent} from "../../pages/ai-msg/sign/sign.component";
import {TemplateManageComponent} from "../../pages/ai-msg/template/template-manage.component";
import {MaterialComponent} from "../../pages/ai-msg/material/material.component";
import {ServerComponent} from "../../pages/ai-msg/server/server.component";
import {MenuComponent} from "../../pages/sk-accesses/menu/menu.component";
import {InterfaceComponent} from "../../pages/sk-accesses/interface/interface.component";
import {AccessTemplateComponent} from "../../pages/sk-accesses/access-template/access-template.component";
import {OssRoleComponent} from "../../pages/system-manage/oss-role/oss-role.component";
import {DepartmentComponent} from "@src/app/pages/department/department.component";
import {UsersComponent} from '../../pages/system-manage/users/users.component';
import {NotificationComponent} from '../../pages/notification/notification.component';
import {ApplyComponent} from "@src/app/pages/apply/apply.component";


@NgModule({
  imports: [
    FormsModule,
    NgZorroAntdModule,
    HttpClientModule,
    SidebarRouterModule,
    ReactiveFormsModule,
    CommonModule,
    NzFormModule,
    NzTabsModule,
    NzButtonModule,
    NgxEchartsModule,
    NzTableModule,
    NgZorroAntdModule,
    NzSpaceModule,
    DragDropModule,
  ],
  declarations: [
    HomePageComponent,
    SidebaruserinfoComponent,
    SidebarNewMessageComponent,
    SidebarhistoryComponent,
    HistoryComponent,
    ProbationComponent,
    SubscribeComponent,
    AddSubscribeComponent,
    SubscribeDetailComponent,
    OrderComponent,
    BillComponent,
    FeeComponent,
    PurchaseComponent,
    InvoiceComponent,
    ServicesComponent,
    ProductsComponent,
    PackagesComponent,
    SignComponent,
    TemplateManageComponent,
    MaterialComponent,
    ServerComponent,
    MenuComponent,
    InterfaceComponent,
    AccessTemplateComponent,
    DepartmentComponent,
    UsersComponent,
    OssRoleComponent,
    NotificationComponent,
    ApplyComponent,
  ],
  providers: [
    {provide: RouteReuseStrategy, useClass: SimpleReuseStrategy}
  ],
  exports: [],
})
export class SidebarModule {
}

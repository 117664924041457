<nz-layout>
  <nz-header id="navigation">
    <span class="logo">诺翔运营支撑系统</span>
    <i class="trigger" nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'" (click)="isCollapsed = !isCollapsed"
       id="icona"></i>
    <ul id="layout-nzMenu" nz-menu nzMode="horizontal">
      <li nz-menu-item>
        <a (click)="toUnProcess()" style="color: white"><i nz-icon nzType="question-circle"
                                                           nzTheme="outline"></i>使用说明</a>
      </li>
      <li nz-menu-item>
        <a (click)="toUnProcess()" style="color: white"><i nz-icon nzType="alert"></i>待办任务</a>
      </li>
      <li nz-submenu nzTitle="皮肤设置" nzIcon="skin">
        <ul>
          <li nz-menu-item>
            <div class="color1" (click)="changeNavigationColor('#f50')"></div>
          </li>
          <li nz-menu-item>
            <div class="color2" (click)="changeNavigationColor('rgb(73,126,245)')"></div>
          </li>
          <li nz-menu-item>
            <div class="color3" (click)="changeNavigationColor('#00CCFF')"></div>
          </li>
          <li nz-menu-item>
            <div class="color4" (click)="changeNavigationColor('#2db7f5')"></div>
          </li>

          <li nz-menu-item>
            <div class="color5" (click)="changeNavigationColor('#555555')"></div>
          </li>

        </ul>
      </li>
      <li nz-submenu nzTitle="个人中心" nzIcon="setting">
        <ul>
          <li nz-menu-item (click)="toBindMobile()"> 绑定手机</li>
          <li nz-menu-item (click)="toUpdatePassword()"> 修改密码</li>
          <li nz-menu-item (click)="toSetting()"> 通知设置</li>
          <li nz-menu-item (click)="dropOut()"> 安全退出</li>
        </ul>
      </li>
    </ul>
  </nz-header>
  <nz-layout style="overflow: hidden">
    <nz-sider [nzTrigger]="triggerTemplate" [(nzCollapsed)]="isCollapsed" style="background: white">
      <ul nz-menu [nzTheme]="'light'" [nzMode]="'inline'" [nzInlineCollapsed]="isCollapsed" style="margin-top: 15px">
        <li nz-menu-item (click)="home()" *ngIf="front_home">
          <span title><i class="icon-yunying" style="font-size: 20px; font-weight: bolder;"></i><span
            class="nav-text">数据概览</span>
          </span>
        </li>
        <li nz-submenu *ngIf="utils">
          <span title>
            <i class="icon-a-zu13" style="font-size: 20px;font-weight: bolder"></i><span
            class="nav-text">客户管理</span></span>
          <ul>
            <!--            <li nz-menu-item (click)="toProbation()">-->
            <!--              <span title>-->
            <!--                <span class="nav-text">试用用户</span>-->
            <!--              </span>-->
            <!--            </li>-->
            <li nz-menu-item (click)="toSubscribe()">
              <span title>
                <span class="nav-text">订阅客户</span>
              </span>
            </li>
          </ul>
        </li>
        <li nz-submenu *ngIf="utils">
          <span title>
            <i class="icon-caiwukaipiao" style="font-size: 20px;font-weight: bolder"></i><span
            class="nav-text">财务中心</span></span>
          <ul>
            <li nz-menu-item (click)="toOrder()">
              <span title>
                <span class="nav-text">订单管理</span>
              </span>
            </li>
            <li nz-menu-item (click)="toOffset()">
                          <span title>
                            <span class="nav-text">采购管理</span>
                          </span>
            </li>
            <li nz-menu-item (click)="toBill()">
                          <span title>
                            <span class="nav-text">账单管理</span>
                          </span>
            </li>
<!--            <li nz-menu-item (click)="toRefund()">-->
<!--                          <span title>-->
<!--                            <span class="nav-text">费用管理</span>-->
<!--                          </span>-->
<!--            </li>-->
            <li nz-menu-item (click)="toInvoice()">
                          <span title>
                            <span class="nav-text">发票管理</span>
                          </span>
            </li>
          </ul>
        </li>
        <li nz-submenu *ngIf="utils">
          <span title>
            <i class="icon-chanpin" style="font-size: 20px;font-weight: bolder"></i><span
            class="nav-text">产品服务</span></span>
          <ul>
            <!--            <li nz-menu-item (click)="toProduct()">-->
            <!--              <span title>-->
            <!--                <span class="nav-text">三方产品</span>-->
            <!--              </span>-->
            <!--            </li>-->
            <li nz-menu-item (click)="toService()">
              <span title>
                <span class="nav-text">服务管理</span>
              </span>
            </li>
            <li nz-menu-item (click)="toProduct()">
              <span title>
                <span class="nav-text">产品管理</span>
              </span>
            </li>
            <li nz-menu-item (click)="toPackage()">
              <span title>
                <span class="nav-text">套餐管理</span>
              </span>
            </li>
          </ul>
        </li>
        <li nz-submenu *ngIf="utils">
          <span title>
            <i class="icon-duanxinyingxiao-qunfaduanxin" style="font-size: 20px;font-weight: bolder"></i><span
            class="nav-text">智能短信</span></span>
          <ul>
            <li nz-menu-item (click)="toServer()">
              <span title><i class="icon-memcached" style="font-size: 20px; font-weight: bolder;"></i><span
                class="nav-text">短信配置</span></span>
            </li>
            <li nz-menu-item (click)="toSign()">
              <span title><i class="icon-xiangmu" style="font-size: 20px;"></i>
                <span class="nav-text">签名管理</span>
              </span>
            </li>
            <li nz-menu-item (click)="toTemplateManage()">
              <span title><i class="icon-task" style="font-size: 20px; font-weight: bolder;"></i><span
                class="nav-text">模板管理</span></span>
            </li>
            <li nz-menu-item (click)="toMaterial()">
              <span title><i class="icon-rongqigoujian" style="font-size: 20px; font-weight: bolder;"></i><span
                class="nav-text">素材管理</span></span>
            </li>

          </ul>
        </li>
        <li nz-submenu *ngIf="utils">
          <span title>
            <i class="icon-quanxian" style="font-size: 20px;font-weight: bolder"></i><span
            class="nav-text">业务权限</span></span>
          <ul>
            <li nz-menu-item (click)="toMenu()">
              <span title><span class="nav-text">菜单管理</span>
              </span>
            </li>
            <li nz-menu-item (click)="toInterface()">
              <span title><span class="nav-text">接口管理</span>
              </span>
            </li>
            <li nz-menu-item (click)="toTemplate()">
              <span title><span class="nav-text">权限模板</span>
              </span>
            </li>
          </ul>
        </li>
        <li nz-submenu *ngIf="utils">
          <span title>
            <i class="icon-xitong" style="font-size: 20px;font-weight: bolder"></i><span
            class="nav-text">系统管理</span></span>
          <ul>
            <li nz-menu-item (click)="department()">
              <span title><span class="nav-text">组织结构</span></span>
            </li>
            <!--            <li nz-menu-item (click)="users()">-->
            <!--              <span title><span class="nav-text">OSS用户</span></span>-->
            <!--            </li>-->
            <li nz-menu-item (click)="role()">
              <span title><span class="nav-text">OSS角色</span></span>
            </li>
            <!--            <li nz-menu-item (click)="continuousDelivery()">-->
            <!--              <span title><i class="icon-rongqigoujian" style="font-size: 20px; font-weight: bolder;"></i><span-->
            <!--                class="nav-text">字典管理</span></span>-->
            <!--            </li>-->
            <!--            <li nz-menu-item (click)="dbOperation()">-->
            <!--              <span title><i class="icon-shujuku2" style="font-size: 20px;"></i>-->
            <!--                <span class="nav-text">菜单管理</span>-->
            <!--              </span>-->
            <!--            </li>-->
            <!--            <li nz-menu-item (click)="role()">-->
            <!--              <span title><i class="icon-rongqigoujian" style="font-size: 20px; font-weight: bolder;"></i><span-->
            <!--                class="nav-text">操作记录</span></span>-->
            <!--            </li>-->
          </ul>
        </li>
        <li nz-menu-item (click)="home()" *ngIf="front_home">
         <span title>
            <i class="icon-gonggao1" style="font-size: 20px;font-weight: bolder"></i><span
           class="nav-text">公告管理</span></span>
        </li>
      </ul>
    </nz-sider>
    <nz-content>
      <nz-content id="view-body">
        <div>
          <app-tab>
            <router-outlet></router-outlet>
          </app-tab>
        </div>
      </nz-content>
      <nz-footer style="height: 10vh;line-height: 100%;padding-top: 5vh;padding-bottom: 10vh">
        <div class="footer">sk-oss @implement by loccay at 2023-03-03</div>
        <div class="footer">current version: 1.0.1</div>
      </nz-footer>
    </nz-content>
  </nz-layout>
</nz-layout>

<!--绑定收集-->
<nz-modal [(nzVisible)]="bindIsVisible" nzTitle="绑定手机" [nzFooter]="modalFooter" (nzOnCancel)="cancelBind()">
  <form nz-form [formGroup]="bindValidateForm">
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="mobile" nzRequired>手机号码</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="请输入手机号码!">
        <div nz-row [nzGutter]="8">
          <input formControlName="mobile" placeholder="请输入您的手机号码" nz-input/>
        </div>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="captcha" nzRequired>验证码</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="请输入验证码!">
        <div nz-row [nzGutter]="8">
          <div nz-col [nzSpan]="12">
            <input nz-input formControlName="captcha" id="Captcha">
          </div>
          <div nz-col [nzSpan]="12">
            <button nz-button (click)="sendCaptcha()" [disabled]="countDown">{{showButtonText}}</button>
          </div>
        </div>
      </nz-form-control>
    </nz-form-item>
  </form>
  <ng-template #modalFooter>
    <button nz-button nzType="default" (click)="cancelBind()">取消</button>
    <button nz-button nzType="primary" (click)="commitBind()">确定</button>
  </ng-template>
</nz-modal>
<!--重设密码-->
<nz-modal [(nzVisible)]="resetPwdIsVisible" nzTitle="重设密码" [nzFooter]="modalFooter1" [nzMaskClosable]="true"
          (nzOnCancel)="resetPwdCancel()">
  <form nz-form [formGroup]="resetValidateForm">
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>新的密码</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="请输入新的密码!">
        <input nz-input type="password" formControlName="password" (ngModelChange)="updateConfirmValidator()"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>确认密码</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" [nzErrorTip]="errorTpl">
        <input nz-input type="password" formControlName="check_password"/>
        <ng-template #errorTpl let-control>
          <ng-container *ngIf="control.hasError('required')">
            请输入确认密码!
          </ng-container>
          <ng-container *ngIf="control.hasError('confirm')">
            两次输入密码不一致!
          </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
  </form>
  <ng-template #modalFooter1>
    <button nz-button nzType="default" (click)="resetPwdCancel()">取消</button>
    <button nz-button nzType="primary" (click)="resetPwdCommit()">确定</button>
  </ng-template>
</nz-modal>
<!--通知设置-->
<nz-drawer
  [nzBodyStyle]="{ height: 'calc(50% - 150px)', overflow: 'auto', 'padding-bottom': '53px' }"
  [nzMaskClosable]="true"
  [nzWidth]="300"
  [nzVisible]="settingIsVisible"
  nzTitle="个人设置"
  (nzOnClose)="cancelSetting()">
  <form [formGroup]="settingValidateForm">
    <nz-form-item nzFlex>
      <nz-form-label style="width: 40%; ">消息通知</nz-form-label>
      <nz-form-control style="width: 50%;margin-left: 10%;color: #00ccff">
        <nz-switch formControlName="msg_notify" nzCheckedChildren='开' nzUnCheckedChildren='关'
                   [nzDisabled]="true"></nz-switch>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item nzFlex>
      <nz-form-label style="width: 40%; ">弹窗提醒</nz-form-label>
      <nz-form-control style="width: 50%;margin-left: 10%;color: #00ccff">
        <nz-switch formControlName="blank" nzCheckedChildren='开' nzUnCheckedChildren='关'></nz-switch>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item nzFlex>
      <nz-form-label style="width: 40%; ">声音提醒</nz-form-label>
      <nz-form-control style="width: 50%;margin-left: 10%;color: #00ccff">
        <nz-switch formControlName="voice" nzCheckedChildren='开' nzUnCheckedChildren='关'></nz-switch>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item nzFlex>
      <nz-form-label style="width: 40%; ">企业微信通知</nz-form-label>
      <nz-form-control style="width: 50%;margin-left: 10%;color: #00ccff">
        <nz-switch formControlName="tecent_wechat" nzCheckedChildren='开' nzUnCheckedChildren='关'></nz-switch>
      </nz-form-control>
    </nz-form-item>
  </form>
  <div nz-row>
    <div nz-col [nzSpan]="22" style="text-align: center;margin-top: 5vh">
      <button nz-button [nzType]="'primary'" (click)="commitSetting()">保存</button>
    </div>
  </div>
</nz-drawer>

import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzModalService} from 'ng-zorro-antd/modal';
import {selfHttp} from "@src/app/httpservice/http.service";
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'subscribe-detail',
  templateUrl: 'subscribe-detail.component.html',
  styleUrls: ['./subscribe-detail.component.css']

})

export class SubscribeDetailComponent implements OnInit {
  objects:any=[]
total=0
  size=5
  page=1




  validateForm: FormGroup;
  // 连接数
  //查询相关
  connectionSize = 10;
  connectionPage: any = 1;
  connectionsData: any = [];
  connectionTotal: any = 0;
  isLoading: false;
  // 搜索相关
  keyword: any = null;
  viewIsVisible = false;
  addIsVisible = false;
  editIsVisible = false;
  addCallAppVisible = true;
  addSeatVisible = false;
  addPhoneVisible = false;
  addPackageVisible = false;
  addServicerVisible = false;
  // 数据绑定
  connectionHeaderNames = ['主机', '用户名', '状态', 'SSL / TLS', '协议', '通道', '源客户', '目标客户'];
  // Channels
  channelsData: any = [];
  channelsHeaderNames = ['通道', '用户名', '状态', '未确定', '未标记', '发布', '确认', '未匹配', '传输', '标记'];
  channelsTotal: any = 1;
  channelsPage: any = 1;
  channelsSize = 10;
  // exchanges
  exchangesData: any = [];
  exchangesHeaderNames = ['名称', '类型', '特征 ', '传输', '标记'];
  exchangesTotal: any = 1;
  exchangesPage: any = 1;
  exchangesSize = 10;
  // 队列
  queuesData: any = [];
  queuesHeaderNames = ['名称', '类型', '特征 ', '准备就绪', '未标记', '总数', '入速度', '传递速度', '标记速度'];
  queuesTotal: any = 1;
  queuesPage: any = 1;
  queuesSize = 10;

  inputValue1='江苏诺翔科技集团成立于2016年，是国内领先的企业数智化转型赋能者和企业数据智能应'
  inputValue2='用软件的践行者，我们始终致力于推动人工智能、大数据、机器学习等领域的深度创新与变'
  inputValue3= '革，秉承“你的美好生活·我来守护”的伟大宗旨。'

  constructor(public router: Router,
              public fb: FormBuilder,
              public http: selfHttp,
              public modalService: NzModalService,
              public msg: NzMessageService,
  ) {
  }
  buildForm() {
    this.validateForm = this.fb.group({
      id: [0],
      user_name: [null, [Validators.required]],
      role_id: [null, [Validators.required]],
      mobile: [null, [Validators.required]],
      status: [true],
    });
  }
  ngOnInit() {
    this.loadConnectionsData();
  }

  reset() {
  }

  loadObjectData() {

  }

  //查询连接数表格数据、
  loadConnectionsData() {
    // this.msg.info('暂无数据');
    this.http.get('http://oss-api.nositek.com/oss-api/pcrmq/connections', {}, res => {
      console.log(res.data);
      if (res.data != null) {
        this.connectionsData = res.data;
        this.connectionTotal = res.data.length;
      } else {
        this.connectionsData = [];
        this.connectionTotal = 0;
        this.msg.info('暂无数据');
      }

    });
  }

  // 查询Channels表格数据
  loadChannelsData() {

  }

// 查询交易所
  loadExchangesData() {

  }

// 查询队列
  loadQueuesData() {

  }


  preDetail(object: any) {
    this.viewIsVisible = true
  }

  //添加取消
  closeDetail() {
    this.viewIsVisible = false;
  }


  /*
  添加系统用户
   */
  preAdd() {
    this.addIsVisible = true;
    this.loadOptionData();
  }

  loadOptionData() {
    // this.http.get('http://oss-api.nositek.com/oss-api/pc/roles', {}, res => {
    //   if (res.data['rows'] != null) {
    //     this.selectOptions = res.data['rows'];
    //   } else {
    //     this.selectOptions = [];
    //     this.msg.info('暂无数据');
    //   }
    //
    // });
  }

  //添加取消
  addCancel() {
    this.addIsVisible = false;
    this.validateForm.reset();
    this.validateForm.patchValue({
      status: true,
    });
  }

  //添加提交
  addCommit() {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    let enable: number;
    if (this.validateForm.value.status) {
      enable = 1;
    } else {
      enable = 0;
    }
    if (this.validateForm.value.user_name && this.validateForm.value.mobile && this.validateForm.value.role_id) {
      this.http.post('http://oss-api.nositek.com/oss-api/pc/users', {
        'user_name': this.validateForm.value.user_name,
        'role_id': this.validateForm.value.role_id,
        'mobile': this.validateForm.value.mobile,
        'status': enable,
      }, res => {
        console.log(res)
        if (res.code == 2000) {
          this.msg.success('添加成功');
        } else if (res.code == 100003) {
          this.msg.info(res.msg)
        } else {
          this.msg.error(res.msg)
        }
        this.validateForm.reset();
        this.validateForm.patchValue({
          status: true,
        });
        this.addIsVisible = false;
        this.loadObjectData();
      });
    }
  }

  /*
  修改系统用户
   */
  preEdit() {
    // if (this.operateOptions.length == 0) {
    //   this.msg.info('请选择数据！');
    //   return;
    // } else if (this.operateOptions.length > 1) {
    //   this.msg.info('请逐条记录操作！');
    //   return;
    // }
    // this.loadOptionData();
    // let index = this.objects.map(item => item.id).indexOf(this.operateOptions[0]);
    // this.operateItem = this.objects[index];
    // this.validateForm.patchValue({
    //   id: this.operateItem.id,
    //   user_name: this.operateItem.name,
    // });
    // if (this.operateItem.status == 1) {
    //   this.validateForm.patchValue({
    //     'status': true
    //   });
    // } else {
    //   this.validateForm.patchValue({
    //     'status': false
    //   });
    // }
    // this.validateForm.patchValue({
    //   user_name: this.operateItem.user_name,
    //   role_id: this.operateItem.role_id,
    //   mobile: this.operateItem.mobile,
    // })
    //
    // this.editIsVisible = true;
  }

  //修改取消
  editCancel() {
    this.editIsVisible = false;
    // this.operateOptions = [];
    // this.loadObjectData();
    // this.validateForm.reset();
    // this.validateForm.patchValue({
    //   status: true,
    // });
  }

  //修改提交
  editCommit() {
    // for (const i in this.validateForm.controls) {
    //   this.validateForm.controls[i].markAsDirty();
    //   this.validateForm.controls[i].updateValueAndValidity();
    // }
    // let enable: number;
    // if (this.validateForm.value.status) {
    //   enable = 1;
    // } else {
    //   enable = 0;
    // }
    // if (this.validateForm.value.user_name && this.validateForm.value.mobile && this.validateForm.value.role_id) {
    //   this.http.post('http://oss-api.nositek.com/oss-api/pc/users', {
    //     'id': Number(this.objectInfo.id),
    //     'user_name': this.validateForm.value.user_name,
    //     'role_id': this.validateForm.value.role_id,
    //     'mobile': this.validateForm.value.mobile,
    //     'status': enable,
    //   }, res => {
    //     if (res.code == 2000) {
    //       this.msg.success('编辑成功');
    //     } else if (res.code == 100003) {
    //       this.msg.info(res.msg)
    //     } else {
    //       this.msg.error(res.msg)
    //     }
    //     this.validateForm.reset();
    //     this.validateForm.patchValue({
    //       status: true,
    //     });
    //     this.editIsVisible = false;
    //     this.loadObjectData();
    //   });
    }
  // 复选框
  onChange(data: any, checked: any) {
    // this.objectInfo = data;
    // if (checked === 'single') {
    //   if (data.checked) {
    //     this.operateOptions.push(data.id);
    //   } else {
    //     let index = this.operateOptions.indexOf(data.id);
    //     this.operateOptions.splice(index, 1);
    //   }
    // }
  }


  //页码切换
  indexChange() {
    // console.log(this.page, this.size);
    this.loadObjectData();
  }




}

<nz-card>
  <div class="table-area">
    <div class="param-area">
      <nz-space class="table-query">
        <nz-space-item>
          <!--          <nz-range-picker></nz-range-picker>-->
          <nz-range-picker [nzFormat]="'yyyy-MM-dd'" [(ngModel)]="dateRangeValues"
                           (ngModelChange)="datePickChange($event)"></nz-range-picker>
        </nz-space-item>
        <nz-space-item>
          <nz-select class="param-select" [(ngModel)]="selectedValue" [nzPlaceHolder]="'请选择申请类型'"
                     (ngModelChange)="loadObjectData()">
            <nz-option *ngFor="let s of selectOptions" [nzValue]="s.value" [nzLabel]="s.label"></nz-option>
          </nz-select>
        </nz-space-item>
        <nz-space-item>
          <nz-input-group nzSearch [nzAddOnAfter]="suffixButton">
            <input type="text" nz-input placeholder="输入关键字搜索" [(ngModel)]="keyword"/>
          </nz-input-group>
          <ng-template #suffixButton>
            <button nz-button nzType="primary" (click)="loadObjectData()">搜索</button>
          </ng-template>
        </nz-space-item>
        <nz-space-item>
          <button nz-button [nzType]="'default'" (click)="reset()">重置</button>
        </nz-space-item>
      </nz-space>
    </div>
    <div>
      <nz-table
        nzSize="small"
        [nzData]="objects"
        [(nzPageSize)]="size"
        [nzTotal]="total"
        [(nzPageIndex)]="page"
        [nzFrontPagination]="false"
        (nzPageIndexChange)="indexChange()"
        (nzPageSizeChange)="indexChange()"
        [nzShowSizeChanger]="true"
        [nzPageSizeOptions]="[5,10,15,20]"
        [nzShowTotal]="totalTemplate"
        [nzHideOnSinglePage]="true"
      >
        <ng-template #totalTemplate>共 <span style="color:cornflowerblue;margin:0 3px;">{{ total }}</span>
          条
        </ng-template>
        <thead style="background: yellow">
        <tr>
          <th nzWidth="100px">申请类型</th>
          <th nzWidth="200px">申请摘要</th>
          <th nzWidth="120px">申请时间</th>
          <th nzWidth="120px">申请公司</th>
          <th nzWidth="80px">申请人</th>
          <th nzWidth="120px">审批时间</th>
          <th nzWidth="80px">审批人</th>
          <th nzWidth="80px">审核状态</th>
          <th nzWidth="150px">说明</th>
          <th nzWidth="80px">操作</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let object of objects">
          <td>
            <span *ngIf="object.apply_type==1">客户开户</span>
            <span *ngIf="object.apply_type==2">客户充值</span>
            <span *ngIf="object.apply_type==3">客户退款</span>
            <span *ngIf="object.apply_type==4">客户开票</span>
            <span *ngIf="object.apply_type==5">内部采购</span>
            <span *ngIf="object.apply_type==6">发票抬头</span>
            <span *ngIf="object.apply_type==7">AI模块</span>
          </td>
          <td>{{ object.apply_content }}&nbsp;&nbsp;&nbsp;
            <a nz-popover nzPopoverContent="点击复制文本">
              <i nz-icon nzType="copy" nzTheme="outline"></i>
            </a></td>
          <td>{{ object.create_time.split(".")[0]| date: 'yy/MM/dd HH:mm' }}</td>
          <td>{{object.apply_company_name}}</td>
          <td>{{object.apply_user_name}}</td>
          <td>{{ object.audit_time.slice(0, 10) == '2006-01-02' ? '审批中' : object.audit_time.split(".")[0]| date: 'yy/MM/dd hh:mm'}}</td>
          <td>{{ object.audit_user_name }}</td>
          <td>
            <nz-badge *ngIf="object.audit_status==1" nzStatus="processing"></nz-badge>
            <nz-badge *ngIf="object.audit_status==2" nzStatus="success"></nz-badge>
            <nz-badge *ngIf="object.audit_status==3" nzStatus="error"></nz-badge>
            <span *ngIf="object.audit_status==1">审批中</span>
            <span *ngIf="object.audit_status==2">已通过</span>
            <span *ngIf="object.audit_status==3">未通过</span>
          </td>
          <td>{{ object.remark }}</td>
          <td>
            <span *ngIf="object.audit_status===1"><a (click)="preEdit(object)"><i nz-icon nzType="audit" nzTheme="outline"></i>审核</a></span>
          </td>
        </tr>
        </tbody>
      </nz-table>
    </div>
  </div>
</nz-card>

<!--审核-->
<nz-modal [(nzVisible)]="editIsVisible" nzTitle="审批" [nzFooter]="modalFooter"
          [nzMaskClosable]="true" (nzOnCancel)="editCancel()">
  <form nz-form [formGroup]="validateForm">
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>审核状态</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24">
        <nz-switch formControlName="audit_status" nzCheckedChildren="通过" nzUnCheckedChildren="驳回"></nz-switch>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>审核说明</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24">
        <textarea rows="4" nz-input placeholder="请输入审核说明" formControlName="remark"></textarea>
      </nz-form-control>
    </nz-form-item>
  </form>
  <ng-template #modalFooter>
    <button nz-button nzType="default" (click)="editCancel()">取消</button>
    <button nz-button nzType="primary" (click)="editCommit()">确定</button>
  </ng-template>
</nz-modal>

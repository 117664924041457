import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzModalService} from 'ng-zorro-antd/modal';
import {TransferChange} from 'ng-zorro-antd/transfer';
import {NzTreeNode, NzTreeNodeOptions,} from 'ng-zorro-antd/tree';
import {selfHttp} from '../../../httpservice/http.service';
import {HttpHeaders} from '@angular/common/http';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'oss-role',
  templateUrl: './oss-role.component.html',
  styleUrls: ['./oss-role.component.css']
})


export class OssRoleComponent implements OnInit {
  validateForm: FormGroup;

  //权限相关
  addAccess = false;
  editAccess = false;
  deleteAccess = false;

  //查询相关
  allChecked: any = false;
  checkChild: any = false;
  keyword: any = '';
  size = 5;
  page: any = 1;
  objects: any = [];
  total: any = 1;


  //操作相关
  operateOptions = [];
  operateItem: any;
  indeterminate = false;
  addIsVisible = false;
  editIsVisible = false;
  accessCodes: any = [];
  currentUser: any;
  public objectInfo: any;
  public options: any;

  constructor(public router: Router,
              public fb: FormBuilder,
              public http: selfHttp,
              public modalService: NzModalService,
              public msg: NzMessageService,
  ) {
    this.loadObjectData();
    // this.loadAccess();
    this.buildForm();
  }

  buildForm() {
    this.validateForm = this.fb.group({
      id: [0],
      role_name: ['', [Validators.required]],
      status: [true, [Validators.required]],
      remark: [''],
    });
  }

  ngOnInit(): void {
  }

  //查询表格数据、
  loadObjectData() {
    this.http.get('http://oss-api.nositek.com/oss-api/pc/roles', {
      'page': this.page,
      'size': this.size,
      'keyword': this.keyword,
    }, res => {
      if (res.data['rows'] != null) {
        this.objects = res.data['rows'];
        this.total = res.data['total'];
        this.objects.forEach(object => {
          object.accessData = this.generateTree(object.accesses)
        })
      } else {
        this.objects = [];
        this.msg.info('暂无数据');
      }

    });
  }

  //复选框
  // 选中父多选框时，选中所有子复选框
  onChange(data: any, che: any) {
    this.objectInfo = data;
    if (che === 'single') {
      if (data.checked) {
        this.operateOptions.push(data.id);
      } else {
        let index = this.operateOptions.indexOf(data.id);
        this.operateOptions.splice(index, 1);
      }
    }
  }

  loadData() {
    //获取角色权限信息
    this.http.get('http://oss-api.nositek.com/oss-api/pc/accesses/tree', {}, res => {
        if (res.data != null) {
          this.list = res.data['rows'];
          this.treeData = this.generateTree(this.list);
        }
      }
    );
  }

  loadData2Reset(operateItem) {
    //获取角色权限信息
    this.http.get('http://oss-api.nositek.com/oss-api/pc/accesses/tree', {}, res => {
        if (res.data != null) {
          this.list = res.data['rows'];
          this.treeData = this.resetTree(this.list, operateItem.accesses);
          operateItem.accesses.forEach(access => {
            this.checkedNodeList.push(new NzTreeNode({
              title: access.title,
              key: access.key,
              checked: true
            }));
          })
        }
      }
    );
  }

  /*
  添加角色
   */
  preAdd() {
    this.addIsVisible = true;
    this.checkedNodeList = [];
    this.loadData();
  }

  //添加取消
  addCancel() {
    this.addIsVisible = false;
    this.checkedNodeList = [];
    this.validateForm.reset();
    this.validateForm.patchValue({
      status: true,
    });
  }

  //添加提交
  addCommit() {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    let enable: number;
    if (this.validateForm.value.status) {
      enable = 1;
    } else {
      enable = 0;
    }

    this.checkedNodeList.forEach(node => {
      this.accessCodes.push(node.origin.key);
    });
    if (this.validateForm.value.role_name) {
      this.http.post('http://oss-api.nositek.com/oss-api/pc/roles', {
        'role_name': this.validateForm.value.role_name,
        'remark': this.validateForm.value.remark,
        'access_codes': this.accessCodes,
        'status': enable,
      }, res => {
        if (res.code == 2000) {
          this.msg.success('添加成功');
        } else {
          this.msg.error(res.msg)
        }
        this.validateForm.reset();
        this.validateForm.patchValue({
          status: true,
        });
        this.checkedNodeList = [];
        this.list = [];
        this.treeData = [];
        this.addIsVisible = false;
        this.loadObjectData();
      });
    }
  }

  /*
  修改角色
   */
  preEdit() {
    if (this.operateOptions.length == 0) {
      this.msg.info('请选择数据！');
      return;
    } else if (this.operateOptions.length > 1) {
      this.msg.info('请逐条记录操作！');
      return;
    }
    let index = this.objects.map(item => item.id).indexOf(this.operateOptions[0]);
    this.operateItem = this.objects[index];
    this.validateForm.patchValue({
      id: this.operateItem.id,
      role_name: this.operateItem.name,
    });
    if (this.operateItem.status == 1) {
      this.validateForm.patchValue({
        'status': true
      });
    } else {
      this.validateForm.patchValue({
        'status': false
      });
    }
    this.validateForm.patchValue({
      role_name: this.operateItem.role_name,
      remark: this.operateItem.role_name,
    })

    this.loadData2Reset(this.operateItem);
    this.editIsVisible = true;

  }

  //修改取消
  editCancel() {
    this.editIsVisible = false;
    this.operateOptions = [];
    this.loadObjectData();
    this.validateForm.reset();
    this.validateForm.patchValue({
      status: true,
    });
  }

  //修改提交
  editCommit() {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    let enable: number;
    if (this.validateForm.value.status) {
      enable = 1;
    } else {
      enable = 0;
    }

    this.checkedNodeList.forEach(node => {
      this.accessCodes.push(node.origin.key);
    });
    if (this.validateForm.value.role_name) {
      this.http.post('http://oss-api.nositek.com/oss-api/pc/roles', {
        'id': Number(this.objectInfo.id),
        'role_name': this.validateForm.value.role_name,
        'access_codes': this.accessCodes,
        'remark': this.validateForm.value.remark,
        'status': enable,
      }, res => {
        this.validateForm.reset();
        this.validateForm.patchValue({
          status: true,
        });
        this.editIsVisible = false;
        this.msg.success('修改权限成功');
        this.accessCodes = [];
        this.operateOptions = [];
        this.loadObjectData();
      });
    }

  }

  /*
  删除角色
   */
  preDelete() {
    if (this.operateOptions.length == 0) {
      this.msg.info('请选择数据！');
      return;
    }
    this.modalService.confirm({
      nzTitle: '<i>确定删除数据 ？</i>',
      nzContent: '<b></b>',
      nzOkText: '确定',
      nzCancelText: '取消',
      nzOnOk: () => this.deleteCommit(),
      nzOnCancel: () => this.deleteCancel()
    });
  }

  // 删除提交
  deleteCommit() {
    this.options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        ids: this.operateOptions
      }
    };
    this.http.delete('http://oss-api.nositek.com/oss-api/pc/roles', this.options, res => {
      if (res.code == 2000) {
        this.msg.success('删除成功');
      } else {
        this.msg.info(res.msg);
      }
      this.loadObjectData();
    });
    this.operateOptions = [];
  }

  // 删除取消
  deleteCancel() {
    this.operateOptions = [];
    this.loadObjectData();
  }

  reset() {
    this.page = 1;
    this.size = 10;
    this.keyword = '';
    this.loadObjectData();
  }

  indexChange() {
    this.loadObjectData();
  }

  list: NzTreeNodeOptions[] = [];
  treeData: NzTreeNodeOptions[] = [];
  checkedNodeList: NzTreeNode[] = [];

  //树结构函数
  generateTree(arr: NzTreeNodeOptions[]): NzTreeNodeOptions[] {
    const tree: NzTreeNodeOptions[] = [];
    const mappedArr: any = {};
    let arrElem: NzTreeNodeOptions;
    let mappedElem: NzTreeNodeOptions;

    for (let i = 0, len = arr.length; i < len; i++) {
      arrElem = arr[i];
      mappedArr[arrElem.key] = {...arrElem};
      mappedArr[arrElem.key].children = [];
    }

    for (const key in mappedArr) {
      if (mappedArr.hasOwnProperty(key)) {
        mappedElem = mappedArr[key];
        if (mappedElem.parent_code != "0") {
          mappedArr[mappedElem.parent_code].children.push(mappedElem);
        } else {
          tree.push(mappedElem);
        }
      }
    }
    return tree;
  }

  //回传树结构函数
  resetTree(arr: NzTreeNodeOptions[], checkedNodes: any): NzTreeNodeOptions[] {
    const tree: NzTreeNodeOptions[] = [];
    const mappedArr: any = {};
    let arrElem: NzTreeNodeOptions;
    let mappedElem: NzTreeNodeOptions;

    for (let i = 0, len = arr.length; i < len; i++) {
      arrElem = arr[i];
      let idx = checkedNodes.findIndex(v => v.key == arrElem.key)
      if (idx != -1) {
        arrElem.isChecked = true
        arrElem.isSelected = true
        arrElem.checked = true
        arrElem.selected = true
      }
      mappedArr[arrElem.key] = {...arrElem};
      mappedArr[arrElem.key].children = [];
    }

    for (const key in mappedArr) {
      if (mappedArr.hasOwnProperty(key)) {
        mappedElem = mappedArr[key];
        if (mappedElem.parent_code != "0") {
          mappedArr[mappedElem.parent_code].children.push(mappedElem);
        } else {
          tree.push(mappedElem);
        }
      }
    }
    return tree;
  }

  //树结构选中事件
  checkBoxChange(node: NzTreeNode, onItemSelect: (item: NzTreeNodeOptions) => void): void {
    console.log(node)
    if (node.isDisabled) {
      return;
    }
    node.isChecked = !node.isChecked;
    if (node.isChecked) {
      this.checkedNodeList.push(node);
    } else {
      const idx = this.checkedNodeList.indexOf(node);
      if (idx !== -1) {
        this.checkedNodeList.splice(idx, 1);
      }
    }
    const item = this.list.find(w => w.key === node.origin.key);
    onItemSelect(item!);//手动触发勾选条目事件，若不是指定参数类型，如空值，则不影响函数执行，如不加则会报错
  }

  //穿梭框左右穿梭事件
  change(ret: TransferChange): void {
    const isDisabled = ret.to === 'right';
    console.log(ret, this.checkedNodeList)
    if (ret.to === 'left') {
      for (let i = 0; i < ret.list.length; i++) {
        let id = ret.list[i].id;
        let index = this.checkedNodeList.findIndex(v => v.origin.id == id);
        this.checkedNodeList[index].isDisabled = false;
        this.checkedNodeList[index].isChecked = false;
        this.checkedNodeList.splice(index, 1);
      }
    } else {
      this.checkedNodeList.forEach(node => {
        node.isDisabled = isDisabled;
      });
    }
  }

  loadAccess() {
    console.log('加载页面操作权限');
    let tempArr = JSON.parse(localStorage.getItem('access'));
    this.addAccess = tempArr.findIndex(v => v.name == 'add_role') != -1;
    this.editAccess = tempArr.findIndex(v => v.name == 'edit_role') != -1;
    this.deleteAccess = tempArr.findIndex(v => v.name == 'delete_role') != -1;
  }
}

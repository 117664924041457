import {Component} from '@angular/core';
import {HttpClientModule} from "@angular/common/http";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {WebsocketService} from "@src/app/common/websocket/websocket.service";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor() {

  }
}

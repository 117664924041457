import {Component, ViewChild, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzModalService} from 'ng-zorro-antd/modal';
import {TransferChange, TransferItem} from 'ng-zorro-antd/transfer';
import {NzTreeNode,} from 'ng-zorro-antd/tree';
import {NzTableModule} from 'ng-zorro-antd/table';
import {selfHttp} from '../../httpservice/http.service';
import {HttpHeaders} from '@angular/common/http';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NzTreeComponent, NzFormatEmitEvent, NzTreeNodeOptions} from 'ng-zorro-antd/tree';

@Component({
  selector: 'department',
  templateUrl: './department.component.html',
  styleUrls: ['./department.component.css']
})


export class DepartmentComponent implements OnInit {
  @ViewChild('nzTreeComponent') nzTreeComponent: NzTreeComponent;
  validateForm: FormGroup;
  auditUserValidateForm: FormGroup;

  //权限相关

  //查询相关
  checkChild: any = false;
  keyword: string = '';
  size = 10;
  page: number = 1;
  department_id: number = 0;
  total: number = 1;

  //操作相关
  operateOptions = [];
  operateItem = null;
  addIsVisible = false;
  editIsVisible = false;
  auditUserIsVisible = false;
  accessCodes: any = [];
  currentUser: any;
  defaultExpandedKeys = ['100'];
  treeObjects: NzTreeNodeOptions[];
  expandKeys = ['1']
  nodes: NzTreeNodeOptions[];

  tableObjects = [];

  constructor(public router: Router,
              public fb: FormBuilder,
              public http: selfHttp,
              public modalService: NzModalService,
              public msg: NzMessageService,
  ) {
    this.loadObjectData();
    // this.loadAccess();
    this.buildForm();
  }

  buildForm() {
    this.validateForm = this.fb.group({
      id: [null, [Validators.required]],
      role_id: ['1', [Validators.required]],
    });
    this.auditUserValidateForm = this.fb.group({
      audit_open_account_user_id: [null, [Validators.required]],
      audit_customer_order_user_id: [null, [Validators.required]],
      audit_customer_refund_user_id: [null, [Validators.required]],
      audit_customer_invoice_user_id: [null, [Validators.required]],
      audit_sk_purchase_user_id: [null, [Validators.required]],
    });
  }

  ngOnInit(): void {
  }

  // 重置
  reset() {
    this.page = 1;
    this.size = 10;
    this.keyword = '';
    this.department_id = 0;
    this.loadTableData();
  }

  loadObjectData() {
    //step1:加载树形图数据
    this.loadTreeData();
    //step2:加载表格数据
    this.loadTableData();
  }

  loadTreeData() {
    this.http.get('http://oss-api.nositek.com/oss-api/tecent/departments/tree', {}, res => {
      if (res.data != null) {
        this.treeObjects = res.data['rows'];
      } else {
        this.treeObjects = [];
        this.msg.info('暂无数据');
      }

      if (this.treeObjects.length > 0) {
        this.generateTree(this.treeObjects);
      }
    });

  }

  loadTableData() {
    this.http.get('http://oss-api.nositek.com/oss-api/pc/users', {
      page: this.page,
      size: this.size,
      keyword: this.keyword,
      department_id: this.department_id,
    }, res => {
      if (res.data['rows'] != null && res.data['rows'].length > 0) {
        this.tableObjects = res.data['rows'];
        this.total = Number(res.data['total']);
      } else {
        this.tableObjects = [];
        this.total = 0;
        this.msg.info('暂无数据');
      }
    });
  }


  //树结构函数
  generateTree(arr: NzTreeNodeOptions[]): NzTreeNodeOptions[] {
    const tree: NzTreeNodeOptions[] = [];
    const mappedArr: any = {};
    let arrElem: NzTreeNodeOptions;
    let mappedElem: NzTreeNodeOptions;

    for (let i = 0, len = arr.length; i < len; i++) {
      arrElem = arr[i];
      mappedArr[arrElem.key] = {...arrElem};
      mappedArr[arrElem.key].children = [];
    }

    for (const key in mappedArr) {
      if (mappedArr.hasOwnProperty(key)) {
        mappedElem = mappedArr[key];
        if (mappedElem.parent_id != "0") {
          mappedArr[mappedElem.parent_id].children.push(mappedElem);
        } else {
          tree.push(mappedElem);
        }
      }
    }
    return tree;
  }


  //同步企微数据
  syncIsVisible = true;
  loadIsVisible = false;

  syncData() {
    this.syncIsVisible = false;
    this.loadIsVisible = true;
    this.http.get('http://oss-api.nositek.com/oss-api/tecent/sync/users', {}, res => {
      if (res != null) {
        this.syncIsVisible = true;
        this.loadIsVisible = false;
        if (res.code == 2000) {
          this.msg.success('同步完成！')
        } else {
          this.msg.error('同步出错')
        }
        this.loadTableData();
        this.loadTreeData();
      }
    });
  }

  selectUserOptions: any = []

  auditUser() {
    this.auditUserIsVisible = true;
    this.selectUserOptions = [];
    this.http.get('http://oss-api.nositek.com/oss-api/pc/users', {}, res => {
      if (res.data != null) {
        this.selectUserOptions = res.data['rows']
      } else {
        this.msg.info('暂无数据');
      }
    });
    this.http.get('http://oss-api.nositek.com/oss-api/pc/audit/user', {}, res => {
      if (res.data != null) {
        this.auditUserValidateForm.patchValue({
          audit_open_account_user_id: res.data['row']['audit_open_account_user_id'],
          audit_customer_order_user_id: res.data['row']['audit_customer_order_user_id'],
          audit_customer_refund_user_id: res.data['row']['audit_customer_refund_user_id'],
          audit_customer_invoice_user_id: res.data['row']['audit_customer_invoice_user_id'],
          audit_sk_purchase_user_id: res.data['row']['audit_sk_purchase_user_id'],
        })
      } else {
        this.msg.info('暂无数据');
      }
    });

  }

  auditUserCommit() {
    this.http.put('http://oss-api.nositek.com/oss-api/pc/audit/user', this.auditUserValidateForm.value, res => {
      if (res.code != 2000) {
        this.msg.error(res.message);
      } else {
        this.msg.success('设置成功');
      }
      this.auditUserIsVisible = false;
      this.auditUserValidateForm.reset();
      this.selectUserOptions = [];
      this.loadTableData();
    });

  }

  auditUserCancel() {
    this.auditUserIsVisible = false;
    this.auditUserValidateForm.reset();
    this.selectUserOptions = [];
    this.loadTableData();
  }

  selectRoleOptions = [];

  loadOptionData() {
    this.selectRoleOptions = [];
    this.http.get('http://oss-api.nositek.com/oss-api/pc/roles', {}, res => {
      if (res.data['rows'] != null) {
        this.selectRoleOptions = res.data['rows'];
      } else {
        this.selectRoleOptions = [];
        this.msg.info('暂无数据');
      }

    });
  }

  nzClick(event: NzFormatEmitEvent): void {
    console.log(event.node);
    if (event.node.key != '100') {
      if (event.node.level != 2) {
        this.department_id = Number(event.node.key);
      }
    }

    this.loadTableData();
  }

  //修改权限
  preEditAccess(object: any) {
    this.editIsVisible = true;
    this.loadOptionData();
    this.validateForm.patchValue({
      id: object.id,
      role_id: object.role_id
    })
  }

  editOk() {
    this.editUser();
    this.validateForm.reset();
    this.editIsVisible = false;
    this.loadTableData();
  }

  editCancel() {
    this.editIsVisible = false;
    this.validateForm.reset();
    this.loadTableData();
  }

  editUser() {
    this.http.put('http://oss-api.nositek.com/oss-api/tecent/user/role', {
      'id': this.validateForm.value.id,
      'role_id': this.validateForm.value.role_id,
    }, res => {
      if (res.code != 2000) {
        this.msg.error(res.message);
      } else {
        this.msg.success('编辑成功');
      }
    });
  }

  indexChange() {
    console.log(this.page, this.size);
    this.loadTableData();
  }

  /*
 启停用户
  */
  preDisableOrEnable(object) {
    if (object.status) {
      this.modalService.confirm({
        nzTitle: '<i>确定禁用用户 ？</i>',
        nzContent: '<b></b>',
        nzOkText: '确定',
        nzCancelText: '取消',
        nzOnOk: () => this.disableOrEnableCommit(object),
        nzOnCancel: () => this.disableOrEnableCancel()
      });
    } else {
      this.disableOrEnableCommit(object)
    }
  }

  // 启停用户操作提交
  disableOrEnableCommit(object) {
    this.http.put('http://oss-api.nositek.com/oss-api/tecent/user/status', {
      id: object.id,
      status: !object.status
    }, res => {
      if (res.code == 2000) {
        if (object.status) {
          this.msg.success('用户已禁用');
        } else {
          this.msg.success('用户已启用');
        }
      } else {
        this.msg.info(res.msg);
      }
      this.loadObjectData();
    });
  }

  // 启停用户取消
  disableOrEnableCancel() {
    this.loadObjectData();
  }

  add_dept = false;
  edit_dept = false;
  delete_dept = false;
  add_user = true;
  edit_user = false;
  delete_user = false;

  loadAccess() {
    console.log('加载页面操作权限');
    let tempArr = JSON.parse(localStorage.getItem('access'));
    console.log(tempArr);
    this.add_dept = tempArr.findIndex(v => v.name == 'add_dept') != -1;
    this.edit_dept = tempArr.findIndex(v => v.name == 'edit_dept') != -1;
    this.delete_dept = tempArr.findIndex(v => v.name == 'delete_dept') != -1;
    this.add_user = tempArr.findIndex(v => v.name == 'add_user') != -1;
    this.edit_user = tempArr.findIndex(v => v.name == 'edit_user') != -1;
    this.delete_user = tempArr.findIndex(v => v.name == 'delete_user') != -1;
  }
}

<!--<nz-card>-->
<!--  <form nz-form [formGroup]="validateForm" class="ant-advanced-search-form">-->
<!--    <nz-tabset style="min-height: 86vh">-->
<!--      <nz-tab nzTitle="个人资料">-->
<!--        <div style="margin-left: 5%;display: flex">-->
<!--          <img [src]="userAvatar"-->
<!--               style="text-align: center;margin-left: 2%;margin-bottom: 2%;-->
<!--                  width: 110px;height: 110px;border-radius: 50%;border: 1px solid cornflowerblue;">-->
<!--          <div style="margin-left: 5%;margin-top: 2%;">-->
<!--            <nz-upload [nzBeforeUpload]="beforeUpload">-->
<!--              <button nz-button [nzType]="'primary'">-->
<!--                点击修改-->
<!--              </button>-->
<!--            </nz-upload>-->
<!--            <button nz-button [nzType]="'primary'" [nzLoading]="uploading"-->
<!--                    (click)="handleUpload()"-->
<!--                    [disabled]="fileList2.length == 0" style="margin-top:10%">-->
<!--              {{ uploading ? 'Uploading' : '开始上传' }}-->
<!--            </button>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div nz-row [nzGutter]="24" style="margin-top: 20px">-->
<!--          <div nz-col [nzSpan]="24">-->
<!--            <nz-form-item nzFlex>-->
<!--              <nz-form-label style="width: 10%;text-align: left">账号</nz-form-label>-->
<!--              <nz-form-control style="width: 18%">-->
<!--                <input nz-input placeholder="请输入账号" formControlName="account"/>-->
<!--              </nz-form-control>-->
<!--            </nz-form-item>-->
<!--          </div>-->
<!--          <div nz-col [nzSpan]="24">-->
<!--            <nz-form-item nzFlex>-->
<!--              <nz-form-label style="width: 10%;text-align: left">省份</nz-form-label>-->
<!--              <nz-form-control style="width: 18%">-->
<!--                <nz-select formControlName="province" style="width: 100%">-->
<!--                  <nz-option *ngFor="let p of provinces" [nzValue]="p.item_code+'|'+p.item_name"-->
<!--                             [nzLabel]="p.item_name"></nz-option>-->
<!--                </nz-select>-->
<!--              </nz-form-control>-->
<!--            </nz-form-item>-->
<!--          </div>-->
<!--          <div nz-col [nzSpan]="24">-->
<!--            <nz-form-item nzFlex>-->
<!--              <nz-form-label style="width: 10%;text-align: left">城市</nz-form-label>-->
<!--              <nz-form-control style="width: 18%">-->
<!--                <nz-select formControlName="city" style="width: 100%">-->
<!--                  <nz-option *ngFor="let c of citys" [nzValue]="c.item_code+'|'+c.item_name"-->
<!--                             [nzLabel]="c.item_name"></nz-option>-->
<!--                </nz-select>-->
<!--              </nz-form-control>-->
<!--            </nz-form-item>-->
<!--          </div>-->
<!--          <div nz-col [nzSpan]="24">-->
<!--            <nz-form-item nzFlex>-->
<!--              <nz-form-label style="width: 10%;text-align: left">县区</nz-form-label>-->
<!--              <nz-form-control style="width: 18%">-->
<!--                <nz-select formControlName="district" style="width: 100%">-->
<!--                  <nz-option *ngFor="let c of districts" [nzValue]="c.item_code+'|'+c.item_name"-->
<!--                             [nzLabel]="c.item_name"></nz-option>-->
<!--                </nz-select>-->
<!--              </nz-form-control>-->
<!--            </nz-form-item>-->
<!--          </div>-->
<!--          <div nz-col [nzSpan]="24">-->
<!--            <nz-form-item nzFlex>-->
<!--              <nz-form-label style="width: 10%;text-align: left">详细地址</nz-form-label>-->
<!--              <nz-form-control style="width: 65%;">-->
<!--                <input nz-input formControlName="address" style="width: 60%"/>-->
<!--              </nz-form-control>-->
<!--            </nz-form-item>-->
<!--          </div>-->
<!--          <div nz-col [nzSpan]="24">-->
<!--            <nz-form-item nzFlex>-->
<!--              <nz-form-label style="width: 10%;text-align: left">个性签名</nz-form-label>-->
<!--              <nz-form-control style="width: 65%;">-->
<!--                <textarea rows="4" nz-input formControlName="staff_sign" style="width: 60%"></textarea>-->
<!--              </nz-form-control>-->
<!--            </nz-form-item>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div nz-row>-->
<!--          <div nz-col [nzSpan]="22" style="text-align: center;margin-top: 5vh">-->
<!--            <button nz-button [nzType]="'primary'" (click)="submitform()">保存</button>-->
<!--            <button nz-button [nzType]="'default'" (click)="return()">返回首页</button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </nz-tab>-->
<!--      <nz-tab nzTitle="安全与隐私">-->
<!--        &lt;!&ndash;        <nz-card>&ndash;&gt;-->
<!--        <div nz-col [nzSpan]="24">-->
<!--          <nz-form-item nzFlex>-->
<!--            <nz-form-label style="width: 10%;text-align: left">当前密码</nz-form-label>-->
<!--            <nz-form-control style="width: 20%">-->
<!--              <input nz-input formControlName="password" readonly/>-->
<!--            </nz-form-control>-->
<!--            <nz-form-control style="width: 20%">-->
<!--              <button nz-button [nzType]="'primary'" (click)="updatePassword()">修改密码</button>-->
<!--            </nz-form-control>-->
<!--            <nz-modal [(nzVisible)]="isVisible" nzTitle="修改密码" (nzOnCancel)="handleCancel()"-->
<!--                      (nzOnOk)="handleOk()">-->
<!--              <nz-form-item>-->
<!--                <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="PassWord" nzRequired>密码</nz-form-label>-->
<!--                <nz-form-control [nzSm]="14" [nzXs]="24">-->
<!--                  <input nz-input type="PassWord" formControlName="password"-->
<!--                         (ngModelChange)="updateConfirmValidator()">-->
<!--                  <nz-form-explain *ngIf="validateForm.get('password').dirty && validateForm.get('password').errors">-->
<!--                    请输入您的密码！-->
<!--                  </nz-form-explain>-->
<!--                </nz-form-control>-->
<!--              </nz-form-item>-->
<!--              <nz-form-item>-->
<!--                <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="CheckPassWord" nzRequired>确认密码</nz-form-label>-->
<!--                <nz-form-control [nzSm]="14" [nzXs]="24">-->
<!--                  <input nz-input type="password" formControlName="CheckPassWord">-->
<!--                  <nz-form-explain-->
<!--                    *ngIf="validateForm.get('CheckPassWord').dirty && validateForm.get('CheckPassWord').errors">-->
<!--                    <ng-container *ngIf="validateForm.get('CheckPassWord').hasError('required')">-->
<!--                      请确认您的密码！-->
<!--                    </ng-container>-->
<!--                    <ng-container *ngIf="validateForm.get('CheckPassWord').hasError('confirm')">-->
<!--                      两次密码不一致！-->
<!--                    </ng-container>-->
<!--                  </nz-form-explain>-->
<!--                </nz-form-control>-->
<!--              </nz-form-item>-->
<!--            </nz-modal>-->
<!--          </nz-form-item>-->
<!--        </div>-->
<!--        <div nz-col [nzSpan]="24">-->
<!--          <nz-form-item nzFlex>-->
<!--            <nz-form-label style="width: 10%;text-align: left">当前绑定手机</nz-form-label>-->
<!--            <nz-form-control style="width: 20%">-->
<!--              <input nz-input placeholder={{mobilePlaceHolderText}} formControlName="mobile" type="number"/>-->
<!--            </nz-form-control>-->
<!--            <button nz-button [nzType]="'primary'" (click)="toUpdateMobile()" style="margin-top: 4px">换绑手机</button>-->
<!--            <nz-modal [(nzVisible)]="updateIsVisible" nzTitle="换绑手机" (nzOnCancel)="handleMobileCancel()"-->
<!--                      (nzOnOk)="handleMobileOk()">-->
<!--              <div style="margin-left: 10% ;">-->
<!--                <div>验证码将发送到手机{{prefixMobile}}****{{suffixMobile}}</div>-->
<!--                <i style="color: #969696;font-size:12px;">-->
<!--                  如果长时间未收到验证码，请检查是否将运营商拉黑-->
<!--                </i>-->
<!--                <nz-form-item>-->
<!--                  <div nz-col [nzSpan]="24" style="margin-top: 30px">-->
<!--                    <nz-form-item nzFlex>-->
<!--                      <nz-form-label style="width: 25%;text-align: left">请输入验证码</nz-form-label>-->
<!--                      <nz-form-control style="width: 25%">-->
<!--                        <input nz-input formControlName="captcha" style="margin-top: 4px"/>-->
<!--                      </nz-form-control>-->
<!--                      <button nz-button [nzType]="'primary'" [disabled]="countDown" (click)="sendCaptcha()"-->
<!--                              style="margin-top: 4px;margin-left:20px;width: 35%">{{showButtonText}}</button>-->
<!--                    </nz-form-item>-->
<!--                  </div>-->
<!--                </nz-form-item>-->
<!--              </div>-->
<!--              <div style="margin-top:60px;padding-left: 70%;font-size:10px;">如有疑问，请<a>联系客服</a></div>-->
<!--            </nz-modal>-->
<!--            <nz-modal [(nzVisible)]="reBindMobileIsVisible" nzTitle="修改密码" (nzOnCancel)="handleReBindMobileCancel()"-->
<!--                      (nzOnOk)="handleReBindMobileOk()">-->
<!--              <nz-form-item nzFlex>-->
<!--                <nz-form-label [nzSm]="6" [nzXs]="24">输入新的手机号</nz-form-label>-->
<!--                <nz-form-control [nzSm]="14" [nzXs]="24">-->
<!--                  <input nz-input type="text" formControlName="mobile">-->
<!--                </nz-form-control>-->
<!--              </nz-form-item>-->
<!--              <nz-form-item nzFlex>-->
<!--                <nz-form-label [nzSm]="6" [nzXs]="24">输入验证码</nz-form-label>-->
<!--                <nz-form-control style="width: 25%">-->
<!--                  <input nz-input formControlName="captcha" style="margin-top: 4px"/>-->
<!--                </nz-form-control>-->
<!--                <button nz-button [nzType]="'primary'" [disabled]="countDown" (click)="sendCaptcha()"-->
<!--                        style="margin-top: 4px;margin-left:20px;width: 35%">{{showButtonText}}</button>-->
<!--              </nz-form-item>-->
<!--            </nz-modal>-->
<!--          </nz-form-item>-->
<!--        </div>-->
<!--        <div nz-row>-->
<!--          <div nz-col [nzSpan]="22" style="text-align: center;margin-top:  40vh;">-->
<!--            <button nz-button [nzType]="'default'" (click)="return()">返回首页</button>-->
<!--          </div>-->
<!--        </div>-->
<!--        &lt;!&ndash;        </nz-card>&ndash;&gt;-->
<!--      </nz-tab>-->
<!--    </nz-tabset>-->
<!--  </form>-->
<!--</nz-card>-->

<nz-card nzTitle="个人设置" style="width: 96%;margin-left: 2%">
  <form [formGroup]="validateForm">
<!--    <div style="margin-left: 10%;display: flex">-->
<!--      <img [src]="userAvatar"-->
<!--           style="text-align: center;margin-left: 2%;margin-bottom: 2%;-->
<!--                  width: 110px;height: 110px;border-radius: 50%;border: 1px solid cornflowerblue;">-->
<!--      <div style="margin-left: 5%;margin-top: 2%;">-->
<!--        <nz-upload [nzBeforeUpload]="beforeUpload">-->
<!--          <button nz-button [nzType]="'primary'">-->
<!--            点击修改-->
<!--          </button>-->
<!--        </nz-upload>-->
<!--        <button nz-button [nzType]="'primary'" [nzLoading]="uploading"-->
<!--                (click)="handleUpload()"-->
<!--                [disabled]="fileList2.length == 0" style="margin-top:10%">-->
<!--          {{ uploading ? 'Uploading' : '开始上传' }}-->
<!--        </button>-->
<!--      </div>-->
<!--    </div>-->
    <div nz-row [nzGutter]="24" style="margin-left: 10%;margin-top: 5vh">
<!--      <div nz-col [nzSpan]="24">-->
<!--        <nz-form-item nzFlex>-->
<!--          <nz-form-label style="width: 10%;text-align: left">账号</nz-form-label>-->
<!--          <nz-form-control>-->
<!--            <input style="width:26%" nz-input placeholder="请输入账号" formControlName="account"/>-->
<!--          </nz-form-control>-->
<!--        </nz-form-item>-->
<!--      </div>-->
      <div nz-col [nzSpan]="12">
        <nz-form-item nzFlex>
          <nz-form-label style="width: 20%;text-align: left">当前密码</nz-form-label>
          <nz-form-control>
            <input style="width:60%" nz-input formControlName="password" readonly/>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="12">
        <nz-form-item nzFlex>
          <nz-form-control>
            <button nz-button [nzType]="'primary'" (click)="updatePassword()" >修改密码</button>
          </nz-form-control>
          <nz-modal [(nzVisible)]="isVisible" nzTitle="修改密码" (nzOnCancel)="handleCancel()"
                    (nzOnOk)="handleOk()">
            <nz-form-item>
              <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="PassWord" nzRequired>密码</nz-form-label>
              <nz-form-control [nzSm]="14" [nzXs]="24">
                <input nz-input type="PassWord" formControlName="password"
                       (ngModelChange)="updateConfirmValidator()">
                <!--                <nz-form-explain *ngIf="validateForm.get('password').dirty && validateForm.get('password').errors">-->
                <!--                  请输入您的密码！-->
                <!--                </nz-form-explain>-->
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="CheckPassWord" nzRequired>确认密码</nz-form-label>
              <nz-form-control [nzSm]="14" [nzXs]="24">
                <input nz-input type="password" formControlName="CheckPassWord">
                <!--                <nz-form-explain-->
                <!--                  *ngIf="validateForm.get('CheckPassWord').dirty && validateForm.get('CheckPassWord').errors">-->
                <!--                  <ng-container *ngIf="validateForm.get('CheckPassWord').hasError('required')">-->
                <!--                    请确认您的密码！-->
                <!--                  </ng-container>-->
                <!--                  <ng-container *ngIf="validateForm.get('CheckPassWord').hasError('confirm')">-->
                <!--                    两次密码不一致！-->
                <!--                  </ng-container>-->
                <!--                </nz-form-explain>-->
              </nz-form-control>
            </nz-form-item>
          </nz-modal>
        </nz-form-item>
      </div>
<!--      <div nz-col [nzSpan]="12">-->
<!--        <nz-form-item nzFlex>-->
<!--          <nz-form-label style="width: 20%;text-align: left">当前绑定手机</nz-form-label>-->
<!--          <nz-form-control>-->
<!--            <input nz-input placeholder={{mobilePlaceHolderText}} formControlName="mobile" style="width:60%"/>-->
<!--          </nz-form-control>-->
<!--        </nz-form-item>-->
<!--      </div>-->
<!--      <div nz-col [nzSpan]="12">-->
<!--        <nz-form-item nzFlex>-->
<!--          <button nz-button [nzType]="'primary'" (click)="toUpdateMobile()" style="margin-top: 4px">换绑手机</button>-->
<!--          <nz-modal [(nzVisible)]="updateIsVisible" nzTitle="换绑手机" (nzOnCancel)="handleMobileCancel()"-->
<!--                    (nzOnOk)="handleMobileOk()">-->
<!--            <div style="margin-left: 10% ;">-->
<!--              <div>验证码将发送到手机{{prefixMobile}}****{{suffixMobile}}</div>-->
<!--              <i style="color: #969696;font-size:12px;">-->
<!--                如果长时间未收到验证码，请检查是否将运营商拉黑-->
<!--              </i>-->
<!--              <nz-form-item>-->
<!--                <div nz-col [nzSpan]="24" style="margin-top: 30px">-->
<!--                  <nz-form-item nzFlex>-->
<!--                    <nz-form-label style="width: 25%;text-align: left">请输入验证码</nz-form-label>-->
<!--                    <nz-form-control style="width: 25%">-->
<!--                      <input nz-input formControlName="captcha" style="margin-top: 4px"/>-->
<!--                    </nz-form-control>-->
<!--                    <button nz-button [nzType]="'primary'" [disabled]="countDown" (click)="sendCaptcha()"-->
<!--                            style="margin-top: 4px;margin-left:20px;width: 35%">{{showButtonText}}</button>-->
<!--                  </nz-form-item>-->
<!--                </div>-->
<!--              </nz-form-item>-->
<!--            </div>-->
<!--            <div style="margin-top:60px;padding-left: 70%;font-size:10px;">如有疑问，请<a>联系客服</a></div>-->
<!--          </nz-modal>-->
<!--          <nz-modal [(nzVisible)]="reBindMobileIsVisible" nzTitle="修改密码" (nzOnCancel)="handleReBindMobileCancel()"-->
<!--                    (nzOnOk)="handleReBindMobileOk()">-->
<!--            <nz-form-item nzFlex>-->
<!--              <nz-form-label [nzSm]="6" [nzXs]="24">输入新的手机号</nz-form-label>-->
<!--              <nz-form-control [nzSm]="14" [nzXs]="24">-->
<!--                <input nz-input type="text" formControlName="mobile">-->
<!--              </nz-form-control>-->
<!--            </nz-form-item>-->
<!--            <nz-form-item nzFlex>-->
<!--              <nz-form-label [nzSm]="6" [nzXs]="24">输入验证码</nz-form-label>-->
<!--              <nz-form-control style="width: 25%">-->
<!--                <input nz-input formControlName="captcha" style="margin-top: 4px"/>-->
<!--              </nz-form-control>-->
<!--              <button nz-button [nzType]="'primary'" [disabled]="countDown" (click)="sendCaptcha()"-->
<!--                      style="margin-top: 4px;margin-left:20px;width: 35%">{{showButtonText}}</button>-->
<!--            </nz-form-item>-->
<!--          </nz-modal>-->
<!--        </nz-form-item>-->
<!--      </div>-->
<!--      <div nz-col [nzSpan]="24">-->
<!--        <nz-form-item nzFlex>-->
<!--          <nz-form-label style="width: 10%;text-align: left">省份</nz-form-label>-->
<!--          <nz-form-control>-->
<!--            <nz-select formControlName="province" style="width:26%">-->
<!--              <nz-option *ngFor="let p of provinces" [nzValue]="p.item_code+'|'+p.item_name"-->
<!--                         [nzLabel]="p.item_name"></nz-option>-->
<!--            </nz-select>-->
<!--          </nz-form-control>-->
<!--        </nz-form-item>-->
<!--      </div>-->
<!--      <div nz-col [nzSpan]="24">-->
<!--        <nz-form-item nzFlex>-->
<!--          <nz-form-label style="width: 10%;text-align: left">城市</nz-form-label>-->
<!--          <nz-form-control style="width: 18%">-->
<!--            <nz-select formControlName="city" style="width:26%">-->
<!--              <nz-option *ngFor="let c of citys" [nzValue]="c.item_code+'|'+c.item_name"-->
<!--                         [nzLabel]="c.item_name"></nz-option>-->
<!--            </nz-select>-->
<!--          </nz-form-control>-->
<!--        </nz-form-item>-->
<!--      </div>-->
<!--      <div nz-col [nzSpan]="24">-->
<!--        <nz-form-item nzFlex>-->
<!--          <nz-form-label style="width: 10%;text-align: left">县区</nz-form-label>-->
<!--          <nz-form-control style="width: 18%">-->
<!--            <nz-select formControlName="district" style="width:26%">-->
<!--              <nz-option *ngFor="let c of districts" [nzValue]="c.item_code+'|'+c.item_name"-->
<!--                         [nzLabel]="c.item_name"></nz-option>-->
<!--            </nz-select>-->
<!--          </nz-form-control>-->
<!--        </nz-form-item>-->
<!--      </div>-->
<!--      <div nz-col [nzSpan]="24">-->
<!--        <nz-form-item nzFlex>-->
<!--          <nz-form-label style="width: 10%;text-align: left">详细地址</nz-form-label>-->
<!--          <nz-form-control style="width: 65%;">-->
<!--            <input nz-input formControlName="address" style="width: 60%"/>-->
<!--          </nz-form-control>-->
<!--        </nz-form-item>-->
<!--      </div>-->
      <div nz-col [nzSpan]="24">
        <nz-form-item nzFlex>
          <nz-form-label style="width: 10%;text-align: left">个性签名</nz-form-label>
          <nz-form-control style="width: 65%;">
            <textarea rows="4" nz-input formControlName="staff_sign" style="width: 60%"></textarea>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div nz-row>
      <div nz-col [nzSpan]="22" style="text-align: center;margin-top: 5vh">
        <button nz-button [nzType]="'primary'" (click)="submitform()">保存</button>
        <button nz-button [nzType]="'default'" (click)="return()">返回首页</button>
      </div>
    </div>
  </form>
</nz-card>





import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
/*
业务模块
 */
import {SidebarComponent} from './sidebar.component';
import {SidebaruserinfoComponent} from '../../pages/navigation/sidebaruserinfo/sidebaruserinfo.component';
import {SidebarNewMessageComponent} from '../../pages/navigation/sidebarnewmessage/sidebarnewmessage.component';
import {SidebarhistoryComponent} from '../../pages/navigation/sidebarhistory/sidebarhistory.component';
import {HomePageComponent} from '../../pages/home-page/home-page.component';
import {ProbationComponent} from "../../pages/customer/probation/probation.component";
import {SubscribeComponent} from "../../pages/customer/subscribe/subscribe.component";
import {AddSubscribeComponent} from "@src/app/pages/customer/subscribe/add-subscribe/add-subscribel.component";
import {SubscribeDetailComponent} from "../../pages/customer/subscribe/subscribe-detail/subscribe-detail.component";
import {OrderComponent} from "../../pages/finance-center/order/order.component";
import {BillComponent} from "@src/app/pages/finance-center/bill/bill.component";
import {FeeComponent} from "../../pages/finance-center/fee/fee.component";
import {PurchaseComponent} from "@src/app/pages/finance-center/purchase/purchase.component";
import {InvoiceComponent} from "../../pages/finance-center/invoice/invoice.component";
import {ServicesComponent} from "../../pages/product-service/services/services.component";
import {ProductsComponent} from "../../pages/product-service/products/products.component";
import {PackagesComponent} from "../../pages/product-service/packages/packages.component";
import {SignComponent} from "../../pages/ai-msg/sign/sign.component";
import {TemplateManageComponent} from "../../pages/ai-msg/template/template-manage.component";
import {MaterialComponent} from "../../pages/ai-msg/material/material.component";
import {ServerComponent} from "../../pages/ai-msg/server/server.component";
import {MenuComponent} from "../../pages/sk-accesses/menu/menu.component";
import {InterfaceComponent} from "../../pages/sk-accesses/interface/interface.component";
import {AccessTemplateComponent} from "../../pages/sk-accesses/access-template/access-template.component";
import {OssRoleComponent} from "../../pages/system-manage/oss-role/oss-role.component";
import {UsersComponent} from '../../pages/system-manage/users/users.component';
import {NotificationComponent} from '../../pages/notification/notification.component';
import {DepartmentComponent} from "@src/app/pages/department/department.component";
import {ApplyComponent} from "@src/app/pages/apply/apply.component";


export const routes: Routes = [
  {
    path: 'sidebar',
    component: SidebarComponent,
    children: [
      {
        path: 'sidrbaruserInfo', component: SidebaruserinfoComponent, data: {
          title: '个人资料',
          isRemove: true
        }
      },
      {
        path: 'apply', component: ApplyComponent, data: {
          title: '待办清单',
          isRemove: true
        }
      },
      {
        path: 'sidebarnewmessage', component: SidebarNewMessageComponent, data: {
          title: '最新消息',
          isRemove: true
        }
      },
      {
        path: 'sidebarhistory', component: SidebarhistoryComponent, data: {
          title: '历史消息',
          isRemove: true
        }
      },
      {
        path: 'home-page', component: HomePageComponent, data: {
          title: '数据概览',
          isRemove: true
        }
      },
      {
        path: 'probation', component: ProbationComponent, data: {
          title: '试用用户',
          isRemove: true
        }
      },
      {
        path: 'subscribe', component: SubscribeComponent, data: {
          title: '订阅客户',
          isRemove: true
        }
      },
      {
        path: 'add-subscribe', component: AddSubscribeComponent, data: {
          title: '客户开户',
          isRemove: true
        }
      },
      {
        path: 'subscribe-detail', component: SubscribeDetailComponent, data: {
          title: '客户详情',
          isRemove: true
        }
      },
      {
        path: 'order', component: OrderComponent, data: {
          title: '订单管理',
          isRemove: true
        }
      },
      {
        path: 'bill', component: BillComponent, data: {
          title: '账单管理',
          isRemove: true
        }
      },
      {
        path: 'fee', component: FeeComponent, data: {
          title: '费用管理',
          isRemove: true
        }
      },
      {
        path: 'purchase', component: PurchaseComponent, data: {
          title: '采购管理',
          isRemove: true
        }
      },
      {
        path: 'invoice', component: InvoiceComponent, data: {
          title: '发票管理',
          isRemove: true
        }
      },
      {
        path: 'services', component: ServicesComponent, data: {
          title: '服务管理',
          isRemove: true
        }
      },
      {
        path: 'products', component: ProductsComponent, data: {
          title: '产品管理',
          isRemove: true
        }
      },
      {
        path: 'packages', component: PackagesComponent, data: {
          title: '套餐管理',
          isRemove: true
        }
      },
      {
        path: 'sign', component: SignComponent, data: {
          title: '签名管理',
          isRemove: true
        }
      },
      {
        path: 'template-manage', component: TemplateManageComponent, data: {
          title: '模板管理',
          isRemove: true
        }
      },
      {
        path: 'material', component: MaterialComponent, data: {
          title: '素材管理',
          isRemove: true
        }
      },
      {
        path: 'server', component: ServerComponent, data: {
          title: '短信配置',
          isRemove: true
        }
      },
      {
        path: 'menu', component: MenuComponent, data: {
          title: '菜单管理',
          isRemove: true
        }
      },
      {
        path: 'interface', component: InterfaceComponent, data: {
          title: '接口管理',
          isRemove: true
        }
      },
      {
        path: 'access-template', component: AccessTemplateComponent, data: {
          title: '模板管理',
          isRemove: true
        }
      },
      {
        path: 'department', component: DepartmentComponent, data: {
          title: '组织结构',
          isRemove: true
        }
      },
      {
        path: 'users', component: UsersComponent, data: {
          title: 'OSS用户',
          isRemove: true
        }
      },
      {
        path: 'oss-role', component: OssRoleComponent, data: {
          title: 'OSS角色',
          isRemove: true
        }
      },
      {
        path: 'notification', component: NotificationComponent, data: {
          title: '系统公告',
          isRemove: true
        }
      },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})

export class SidebarRouterModule {

}

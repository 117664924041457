<nz-card>
  <div id="table-area">
    <div id="param-area">
      <div id="param-input">
        <input type="text" nz-input placeholder="键入关键字搜索" [(ngModel)]="keyword"/>
      </div>
      <div id="table-operation">
        <div style=" margin-right: 5%;">
          <button nz-button [nzType]="'default'" (click)="reset()">重置</button>
        </div>
        <div style="margin-right: 5%">
          <button nz-button [nzType]="'primary'" (click)="loadObjectData()">搜索</button>
        </div>
        <div>
          <!--        <div *ngIf="add_role||delete_role||edit_role">-->
          <button nz-button nz-dropdown [nzDropdownMenu]="menu" nzSize="default" class="column">操作列表</button>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu nzSelectable>
              <li nz-menu-item>
                <button nz-button (click)="preAdd()" nzSize="small">
                  <i nz-icon nzType="plus" nzTheme="outline"></i>
                  <span>添加</span>
                </button>
              </li>
              <li nz-menu-item>
                <button nz-button nzType="info" nzSize="small" (click)="preDelete()">
                  <i nz-icon nzType="delete" nzTheme="outline"></i><span>删除</span>
                </button>
              </li>
              <li nz-menu-item>
                <button nz-button nzType="info" nzSize="small" (click)="preEdit()">
                  <i nz-icon nzType="edit" nzTheme="outline"></i><span>编辑</span>
                </button>
              </li>
            </ul>
          </nz-dropdown-menu>
        </div>
      </div>
    </div>
    <div id="table-data">
      <nz-table
        nzSize="small"
        nzTemplateMode
        [nzData]="objects"
        [(nzPageSize)]="size"
        [nzTotal]="total"
        [(nzPageIndex)]="page"
        [nzFrontPagination]="false"
        (nzPageIndexChange)="indexChange()"
        (nzPageSizeChange)="indexChange()"
        [nzShowSizeChanger]="true"
        [nzPageSizeOptions]="[5,10,15,20]"
        [nzShowTotal]="totalTemplate"
      >
        <ng-template #totalTemplate>共 <span style="color:cornflowerblue;margin:0 3px;">{{ total }}</span>
          条
        </ng-template>
        <thead>
        <tr style="height: 5.5vh">
          <th nzWidth="50px" nzLeft="0px"></th>
          <th>角色名称</th>
          <th>当前权限</th>
          <th>更新时间</th>
          <th>启用状态</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let role of objects" style="height: 5vh">
          <td nzShowCheckbox nzWidth="50px" nzLeft="0px" [(nzChecked)]="role.checked"
              (nzCheckedChange)="onChange(role,'single')"></td>
          <td>{{role.role_name}}</td>
          <td>
            <nz-tree [nzData]="role.accessData" nzVirtualHeight="25vh" nzDraggable nzBlockNode></nz-tree>
          </td>
          <td>{{role.update_time.slice(0, 10)}}</td>
          <td>{{role.status == '1' ? '启用' : '未启用'}}</td>
        </tr>
        </tbody>
      </nz-table>
    </div>
  </div>
</nz-card>
<!--添加角色信息-->
<nz-drawer
  [nzBodyStyle]="{ height: 'calc(100% - 150px)', overflow: 'auto', 'padding-bottom': '53px' }"
  [nzMaskClosable]="false"
  [nzWidth]="720"
  [nzVisible]="addIsVisible"
  nzTitle="添加角色"
  (nzOnClose)="addCancel()"
>
  <form nz-form [formGroup]="validateForm" class="ant-advanced-search-form">
    <div class="permission" style="width: 100%">
      <div nz-row [nzGutter]="24">
        <div nz-col [nzSpan]="24">
          <nz-form-item nzFlex>
            <nz-form-label style="width: 18%;text-align: left;" nzRequired>角色名称</nz-form-label>
            <nz-form-control style="width: 36%" nzErrorTip="请输入角色名称!">
              <input id="test" nz-input placeholder="请输入角色名称" formControlName="role_name"/>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24">
          <nz-form-item nzFlex>
            <nz-form-label style="width: 18%;text-align: left;">权限设置</nz-form-label>
            <nz-form-control style="width: 80%">
              <nz-transfer
                [nzDataSource]="list"
                [nzTitles]="['所有权限', '已选权限']"
                [nzItemUnit]="'项'"
                [nzItemsUnit]="'项'"
                [nzListStyle]="{'width.px': 178, 'height.vh': 50}"
                [nzRenderList]="[renderListTemplate, null]"
                (nzChange)="change($event)"
                [nzShowSelectAll]="false"
                [nzNotFoundContent]="'暂无数据'"
              >
                <ng-template #renderListTemplate let-items let-onItemSelectAll="onItemSelectAll"
                             let-onItemSelect="onItemSelect">
                  <div class="tree-content">
                    <nz-tree #tree [nzData]="treeData" nzBlockNode>
                      <ng-template #nzTreeTemplate let-node>
            <span
              class="ant-tree-checkbox"
              [class.ant-tree-checkbox-disabled]="node.isDisabled"
              [class.ant-tree-checkbox-checked]="node.isChecked"
              (click)="checkBoxChange(node, onItemSelect)"
            >
              <span class="ant-tree-checkbox-inner"></span>
            </span>
                        <span
                          (click)="checkBoxChange(node, onItemSelect)"
                          class="ant-tree-node-content-wrapper ant-tree-node-content-wrapper-open"
                        >{{ node.title }}</span>
                      </ng-template>
                    </nz-tree>
                  </div>
                </ng-template>
              </nz-transfer>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24">
          <nz-form-item nzFlex>
            <nz-form-label style="width: 18%;text-align: left;" >启用状态</nz-form-label>
            <nz-form-control style="width: 36%" >
              <nz-switch formControlName="status" nzCheckedChildren="启用" nzUnCheckedChildren="禁用"></nz-switch>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24">
          <nz-form-item nzFlex>
            <nz-form-label style="width: 18%;text-align: left;">说明</nz-form-label>
            <nz-form-control style="width: 36%">
              <textarea rows="3" nz-input formControlName="remark"></textarea>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </div>
  </form>
  <div class="footer">
    <button type="button" (click)="addCancel()" class="ant-btn" style="margin-right: 8px;"><span>取消</span>
    </button>
    <button type="button" (click)="addCommit()" class="ant-btn ant-btn-primary"><span>提交</span></button>
  </div>
</nz-drawer>
<!--修改角色信息-->
<nz-drawer
  [nzBodyStyle]="{ height: 'calc(100% - 150px)', overflow: 'auto', 'padding-bottom': '53px' }"
  [nzMaskClosable]="false"
  [nzWidth]="720"
  [nzVisible]="editIsVisible"
  nzTitle="编辑角色"
  (nzOnClose)="editCancel()"
>
  <form nz-form [formGroup]="validateForm" class="ant-advanced-search-form">
    <div class="permission" style="width: 100%">
      <div nz-row [nzGutter]="24">
        <div nz-col [nzSpan]="24">
          <nz-form-item nzFlex>
            <nz-form-label style="width: 18%;text-align: left;" nzRequired>角色名称</nz-form-label>
            <nz-form-control style="width: 36%" nzErrorTip="请输入角色名称!">
              <input nz-input placeholder="请输入角色名称" formControlName="role_name"/>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24">
          <nz-form-item nzFlex>
            <nz-form-label style="width: 18%;text-align: left;" >权限设置</nz-form-label>
            <nz-form-control style="width: 80%">
              <nz-transfer
                [nzDataSource]="list"
                [nzTitles]="['所有权限', '已选权限']"
                [nzItemUnit]="'项'"
                [nzItemsUnit]="'项'"
                [nzSelectedKeys]="['1000']"
                [nzListStyle]="{'width.px': 178, 'height.vh': 50}"
                [nzRenderList]="[leftRenderList2, null]"
                (nzChange)="change($event)"
                [nzShowSelectAll]="false"
                [nzNotFoundContent]="'暂无数据'"
              >
                <ng-template #leftRenderList2 let-items let-onItemSelectAll="onItemSelectAll"
                             let-onItemSelect="onItemSelect">
                  <div class="tree-content">
                    <nz-tree #tree [nzData]="treeData" nznzBlockNode>
                      <ng-template #nzTreeTemplate let-node>
            <span
              class="ant-tree-checkbox"
              [class.ant-tree-checkbox-disabled]="node.isDisabled"
              [class.ant-tree-checkbox-checked]="node.isChecked"
              (click)="checkBoxChange(node, onItemSelect)"
            >
              <span class="ant-tree-checkbox-inner"></span>
            </span>
                        <span
                          (click)="checkBoxChange(node, onItemSelect)"
                          class="ant-tree-node-content-wrapper ant-tree-node-content-wrapper-open"
                        >{{ node.title }}</span
                        >
                      </ng-template>
                    </nz-tree>
                  </div>
                </ng-template>
              </nz-transfer>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24">
          <nz-form-item nzFlex>
            <nz-form-label style="width: 18%;text-align: left;" >启用状态</nz-form-label>
            <nz-form-control style="width: 36%">
              <nz-switch nzCheckedChildren="启用" nzUnCheckedChildren="禁用"
                         formControlName="status">
              </nz-switch>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24">
          <nz-form-item nzFlex>
            <nz-form-label style="width: 18%;text-align: left;">说明</nz-form-label>
            <nz-form-control style="width: 36%">
              <textarea rows="3" nz-input formControlName="remark"></textarea>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </div>
  </form>
  <div class="footer">
    <button type="button" (click)="editCancel()" class="ant-btn" style="margin-right: 8px;"><span>取消</span>
    </button>
    <button type="button" (click)="editCommit()" class="ant-btn ant-btn-primary"><span>提交</span></button>
  </div>
</nz-drawer>

<nz-card>
  <div class="table-area">
    <div class="param-area">
      <nz-space class="table-query">
<!--        <nz-space-item style="width: 30%">-->
<!--          <nz-select [nzPlaceHolder]="'请选择服务类型'" [(ngModel)]="serviceType" (ngModelChange)="loadObjectData()"-->
<!--                     style="width: 100%">-->
<!--            <nz-option *ngFor="let option of queryServiceTypeOptions" [nzValue]="option.value"-->
<!--                       [nzLabel]="option.label"></nz-option>-->
<!--          </nz-select>-->
<!--        </nz-space-item>-->
        <nz-space-item style="width: 30%">
          <nz-select [nzPlaceHolder]="'请选择服务'" [(ngModel)]="serviceId" (ngModelChange)="loadObjectData()"
                     style="width: 100%">
            <nz-option *ngFor="let option of queryServiceOptions" [nzValue]="option.id"
                       [nzLabel]="option.service_name"></nz-option>
          </nz-select>
        </nz-space-item>
        <nz-space-item style="width: 30%">
          <nz-select [nzPlaceHolder]="'请选择产品'" [(ngModel)]="productId" (ngModelChange)="loadObjectData()"
                     style="width: 100%">
            <nz-option *ngFor="let option of queryProductOptions" [nzValue]="option.id"
                       [nzLabel]="option.product_name"></nz-option>
          </nz-select>
        </nz-space-item>
        <nz-space-item>
          <nz-input-group nzSearch [nzAddOnAfter]="suffixButton">
            <input type="text" nz-input placeholder="输入关键字搜索" [(ngModel)]="keyword"/>
          </nz-input-group>
          <ng-template #suffixButton>
            <button nz-button nzType="primary" (click)="loadObjectData()">搜索</button>
          </ng-template>
        </nz-space-item>
        <nz-space-item>
          <button nz-button [nzType]="'default'" (click)="reset()">重置</button>
        </nz-space-item>
      </nz-space>
      <div class="table-operation">
        <button nz-button nzType="primary" (click)="preOperate(1,null)">
          <i nz-icon nzType="plus" nzTheme="outline"></i>
          <span>新增套餐</span>
        </button>
        <!--        <button nz-button nzType="default" (click)="preOperate(1,null)" style="margin-left: 8%">-->
        <!--          <i nz-icon nzType="undo" nzTheme="outline"></i>-->
        <!--          <span>误删恢复</span>-->
        <!--        </button>-->
      </div>
    </div>
    <div>
      <nz-table
        nzSize="small"
        [nzData]="objects"
        [(nzPageSize)]="size"
        [nzTotal]="total"
        [(nzPageIndex)]="page"
        [nzFrontPagination]="false"
        (nzPageIndexChange)="indexChange()"
        (nzPageSizeChange)="indexChange()"
        [nzShowSizeChanger]="true"
        [nzPageSizeOptions]="[5,10,15,20]"
        [nzShowTotal]="totalTemplate"
        [nzHideOnSinglePage]="true"
      >
        <ng-template #totalTemplate>共 <span style="color:cornflowerblue;margin:0 3px;">{{ total }}</span>
          条
        </ng-template>
        <thead style="background: yellow">
        <tr>
          <th nzWidth="100px">套餐编号</th>
          <th nzWidth="100px">套餐名称</th>
          <th nzWidth="250px">套餐详情</th>
          <th nzWidth="100px">套餐折后价（元）</th>
          <!--          <th nzWidth="100px">套餐折扣（%）</th>-->
          <th nzWidth="100px">套餐价格（元）</th>
          <th nzWidth="100px">历史销量</th>
          <th nzWidth="100px">上架状态</th>
          <th nzWidth="120px">操作</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let object of objects">
          <td>{{ object.package_code }}</td>
          <td>{{ object.package_name }}</td>
          <td>
            <div *ngFor="let detail of object.items;let i=index">
              <span style="font-weight: bolder">项目{{i + 1}}:</span>
              <span style="margin-left: 20px"><a (click)="toService()">{{detail.service_name}}</a>---</span>
              <span><a
                (click)="toProduct()">{{detail.product_name}}</a>---{{detail.product_number}}{{detail.product_unit}}</span>
              <br>
            </div>
          </td>
          <td>{{ object.discounted_package_price }}</td>
          <!--          <td>{{ object.discount }}</td>-->
          <td>{{ object.package_price }}</td>
          <td>{{ object.sales_volume }}</td>
          <td>
            <nz-badge *ngIf="object.status" nzStatus="processing"></nz-badge>
            <nz-badge *ngIf="!object.status" nzStatus="default"></nz-badge>
            {{  object.status ? '上架' : '下架'}}</td>
          <td>
            <span><a (click)="preOperate(2,object)"><i nz-icon nzType="edit" nzTheme="outline"></i>编辑</a>&nbsp;&nbsp;&nbsp;</span>
            <!--            <span *ngIf="object.status"><a (click)="preDownOrUp(object)"><i nz-icon nzType="delete" nzTheme="outline"></i>删除</a>&nbsp;&nbsp;&nbsp;</span>-->
            <span *ngIf="!object.status"><a (click)="preDownOrUp(object)"><i nz-icon nzType="vertical-align-top"
                                                                             nzTheme="outline"></i>上架</a>&nbsp;&nbsp;&nbsp;</span>
            <span *ngIf="object.status"><a (click)="preDownOrUp(object)"><i nz-icon nzType="vertical-align-bottom"
                                                                            nzTheme="outline"></i>下架</a></span>
          </td>
        </tr>
        </tbody>
      </nz-table>
    </div>
  </div>
</nz-card>


<nz-drawer
  [nzBodyStyle]="{ height: 'calc(100% - 150px)', overflow: 'auto', 'padding-bottom': '53px' }"
  [nzMaskClosable]="true"
  [nzWidth]="1200"
  [nzVisible]="operateIsVisible"
  nzTitle="{{operateModalName}}"
  (nzOnClose)="operateCancel()"
>
  <nz-card>
    <form nz-form [formGroup]="validateForm">
      <nz-form-item>
        <nz-form-label [nzSm]="3" [nzXs]="24" nzRequired>套餐名称</nz-form-label>
        <nz-form-control [nzSm]="8" [nzXs]="24" nzErrorTip="请输入套餐名称!">
          <input nz-input placeholder="请输入套餐名称" formControlName="package_name"/>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item *ngIf="switchIsVisible">
        <nz-form-label [nzSm]="3" [nzXs]="24" nzRequired>上架状态</nz-form-label>
        <nz-form-control [nzSm]="8" [nzXs]="24" nzErrorTip="请选择上架状态!">
          <nz-switch formControlName="status" nzCheckedChildren="上架" nzUnCheckedChildren="下架"></nz-switch>
        </nz-form-control>
      </nz-form-item>
    </form>
    <nz-divider></nz-divider>
    <nz-descriptions nzTitle="费用合计" style="margin-top:2vh;width:50%;margin-left: 5%">
      <nz-descriptions-item nzTitle="套餐价格&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                            [nzSpan]="3" style="font-weight: bolder"><span
        style="color: red;font-size: 20px">{{package_price}}</span>&nbsp;&nbsp;&nbsp;元
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="套餐折后价&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" [nzSpan]="3"
                            style="font-weight: bolder"><span
        style="color: red;font-size: 20px">{{discounted_package_price}}</span>&nbsp;&nbsp;&nbsp;元
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="套餐详情&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" [nzSpan]="3"
                            style="font-weight: bolder">
      </nz-descriptions-item>
    </nz-descriptions>
    <form nz-form [formGroup]="itemValidateForm">
      <div style="float: right;margin-bottom: 2.5vh">
        <button nz-button [nzType]="'primary'" [nzSize]="'small'" (click)="addBtn()">添加内容</button>&nbsp;&nbsp;
      </div>
      <table style="width: 100%;text-align: center;margin-top: 2.5vh;">
        <tr style="background: rgb(73, 126, 245)">
          <td style="width: 10%;font-weight: bolder">服务</td>
          <td style="width: 10%;font-weight: bolder">产品</td>
          <td style="width: 10%;font-weight: bolder">数量</td>
          <td style="width: 5%;font-weight: bolder">有效时长</td>
          <td style="width: 10%;font-weight: bolder">单位</td>
          <td style="width: 10%;font-weight: bolder">折扣（%）</td>
          <td style="width: 10%;font-weight: bolder">单价（元）</td>
          <td style="width: 10%;font-weight: bolder">折后单价（元）</td>
          <td style="width: 10%;font-weight: bolder">总价（元）</td>
          <td style="width: 10%;font-weight: bolder">折后总价（元）</td>
          <td style="width: 5%;font-weight: bolder">操作</td>
        </tr>
        <ng-container formArrayName='arrayList'>
          <ng-container *ngFor="let row of arrayList.controls;let i =index">
            <tr>
              <ng-container [formGroup]="row">
                <td style="width: 10%;">
                  <nz-select [nzPlaceHolder]="'请选择服务'" [nzSize]="'small'" style="width: 100%"
                             (ngModelChange)="loadOperateProductData($event,i)" formControlName="service_id"
                             nzDisabled="{{row['value']['disabled']}}">
                    <nz-option *ngFor="let option of operateServiceOptions" [nzValue]="option.id"
                               [nzLabel]="option.service_name"></nz-option>
                  </nz-select>
                </td>
                <td style="width: 10%;">
                  <nz-select [nzPlaceHolder]="'请选择产品'" [nzSize]="'small'" style="width: 100%"
                             formControlName="product_id" (ngModelChange)="fetchProductUnit($event,i)"
                             nzDisabled="{{row['value']['disabled']}}">
                    <nz-option *ngFor="let option of arrayList.controls[i].value.productOptions" [nzValue]="option.id"
                               [nzLabel]="option.product_name"></nz-option>
                  </nz-select>
                </td>
                <td style="width: 10%;">
                  <input nz-input type="number" placeholder="请输入数量" [nzSize]="'small'" style="width: 100%"
                         formControlName="product_number" (ngModelChange)="inputChange(i)"/>
                </td>
                <td style="width: 5%;">
                  <nz-select [nzPlaceHolder]="'请选择时长'" [nzSize]="'small'" style="width: 100%"
                             formControlName="effective_month">
                    <nz-option *ngFor="let option of timeOptions" [nzValue]="option.value"
                               [nzLabel]="option.label"></nz-option>
                  </nz-select>
                </td>
                <td style="width: 10%;">
                  <input nz-input type="text" [nzSize]="'small'" style="width: 100%" formControlName="product_unit"
                         readonly placeholder="自动获取"/>
                </td>
                <td style="width: 10%;">
                  <input nz-input type="number" placeholder="请输入折扣" [nzSize]="'small'" style="width: 100%"
                         formControlName="discount" (ngModelChange)="inputChange(i)"/>
                </td>
                <td style="width: 10%;">
                  <input nz-input type="text" [nzSize]="'small'" style="width: 100%" formControlName="single_price"
                         placeholder="自动获取"
                         readonly/>
                </td>
                <td style="width: 10%;">
                  <input nz-input type="text" [nzSize]="'small'" style="width: 100%" formControlName="discounted_single_price"
                         placeholder="自动计算"
                         readonly/>
                </td>
                <td style="width: 10%;">
                  <input nz-input type="text" [nzSize]="'small'" style="width: 100%" formControlName="vital_price"
                         placeholder="自动计算"
                         readonly/>
                </td>
                <td style="width: 10%;">
                  <input nz-input type="text" [nzSize]="'small'" style="width: 100%"
                         formControlName="discounted_vital_price" placeholder="自动计算" readonly/>
                </td>
                <td style="width: 5%">
                  <button nz-button [nzType]="'danger'" [nzSize]="'small'" (click)="delBtn(i)">删除</button>
                </td>
              </ng-container>
            </tr>
          </ng-container>
        </ng-container>
      </table>
    </form>
  </nz-card>

  <div style="margin-top: 10vh">
    <button type="button" (click)="operateCancel()" class="ant-btn" style="margin-right: 8px;"><span>取消</span>
    </button>
    <button type="button" (click)="operateCommit()" class="ant-btn ant-btn-primary"><span>提交</span></button>
  </div>
</nz-drawer>

import {Component, ViewChild, OnInit, LOCALE_ID, Inject} from '@angular/core';
import {Router} from '@angular/router';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzModalService} from 'ng-zorro-antd/modal';
import {selfHttp} from "@src/app/httpservice/http.service";
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NzTreeComponent} from 'ng-zorro-antd/tree';
import {formatDate,} from "@angular/common";

@Component({
  selector: 'apply',
  templateUrl: './apply.component.html',
  styleUrls: ['./apply.component.css']
})


export class ApplyComponent implements OnInit {
  @ViewChild('nzTreeComponent') nzTreeComponent: NzTreeComponent;
  selectedValue: any;
  startTime: string = '';
  endTime: string = '';
  validateForm: FormGroup;
  //权限相关
  addAccess = false;
  editAccess = false;
  deleteAccess = false;
  //查询相关
  checkChild: any = false;
  keyword: string = '';
  auditEvent = -1;
  size = 5;
  page: number = 1;
  total: number = 0;

  //操作相关
  selectOptions = [
    {'label': '客户开户', value: 1},
    {'label': '客户充值', value: 2},
    {'label': '客户退款', value: 3},
    {'label': '客户开票', value: 4},
    {'label': '内部采购', value: 5},
  ];
  dateRangeValues = []
  operateOptions = [];
  operateItem = null;
  viewIsVisible = false;
  addIsVisible = false;
  editIsVisible = false;
  currentUser: any;

  objects = [];

  constructor(public router: Router,
              public fb: FormBuilder,
              public http: selfHttp,
              public modalService: NzModalService,
              public msg: NzMessageService,
              @Inject(LOCALE_ID) public locale: string,
  ) {
    this.loadObjectData();
    this.loadAccess();
    this.buildForm();
  }

  buildForm() {
    this.validateForm = this.fb.group({
      id: [0],
      apply_type: [0],
      apply_event_id: [0],
      audit_status: [true, [Validators.required]],
      remark: [null, [Validators.required]],
    });
  }

  ngOnInit(): void {
  }

  loadObjectData() {
    this.http.get('http://oss-api.nositek.com/oss-api/pc/apply', {
      page: this.page,
      size: this.size,
      audit_event: this.selectedValue,
      keyword: this.keyword,
      start_time: this.startTime,
      end_time: this.endTime,
    }, res => {
      if (res.data['rows'] != null && res.data['rows'].length > 0) {
        this.objects = res.data['rows'];
        this.total = Number(res.data['total']);
      } else {
        this.objects = [];
        this.total = 0;
        this.msg.info('暂无数据');
      }
    });
  }

  datePickChange(object: any) {
    if (object.length > 0) {
      this.startTime = formatDate(object[0], 'yyyy-MM-dd', this.locale)
      this.endTime = formatDate(object[1], 'yyyy-MM-dd', this.locale)
    } else {
      this.startTime = '';
      this.endTime = '';
    }
    this.loadObjectData();

  }


// 重置
  reset() {
    this.page = 1;
    this.size = 10;
    this.selectedValue = 0;
    this.keyword = '';
    this.startTime = '';
    this.endTime = '';
    this.dateRangeValues = []
    this.loadObjectData();
  }

  //页码切换
  indexChange() {
    this.loadObjectData();
  }


  preDetail(object: any) {
    this.viewIsVisible = true
  }

  /*
   审批
    */
  preEdit(object: any) {
    this.editIsVisible = true;
    this.validateForm.reset();
    this.validateForm.patchValue({
      id: object.id,
      apply_type: object.apply_type,
      apply_event_id: object.apply_event_id,
      audit_status: true,
    });
  }

  //审批取消
  editCancel() {
    this.editIsVisible = false;
    this.loadObjectData();
    this.validateForm.reset();
  }

  //审批提交
  editCommit() {
    console.log(this.validateForm.value)
    this.http.put('http://oss-api.nositek.com/oss-api/pc/apply/audit', {
      id: this.validateForm.value.id,
      apply_type: this.validateForm.value.apply_type,
      apply_event_id: this.validateForm.value.apply_event_id,
      audit_status: this.validateForm.value.audit_status ? 2 : 3,
      remark: this.validateForm.value.remark,
    }, res => {
      if (res.code == 2000) {
        this.msg.success('审核成功');
      } else if (res.code == 100147 || res.code == 100148 || res.code == 100149) {
        this.msg.warning(res.msg)
      } else {
        this.msg.error(res.msg)
      }
      this.loadObjectData();
    });

    this.editIsVisible = false;

  }


  add_dept = false;
  edit_dept = false;
  delete_dept = false;
  add_user = true;
  edit_user = false;
  delete_user = false;

  loadAccess() {
    console.log('加载页面操作权限');
    // let tempArr = JSON.parse(localStorage.getItem('access'));
    // console.log(tempArr);
    // this.add_dept = tempArr.findIndex(v => v.name == 'add_dept') != -1;
    // this.edit_dept = tempArr.findIndex(v => v.name == 'edit_dept') != -1;
    // this.delete_dept = tempArr.findIndex(v => v.name == 'delete_dept') != -1;
    // this.add_user = tempArr.findIndex(v => v.name == 'add_user') != -1;
    // this.edit_user = tempArr.findIndex(v => v.name == 'edit_user') != -1;
    // this.delete_user = tempArr.findIndex(v => v.name == 'delete_user') != -1;
  }
}

import {Injectable} from "@angular/core";
import {selfHttp} from "../../httpservice/http.service";


@Injectable()
export class OpensComponentService {

  constructor(
    public http: selfHttp
  ) {

  }


  public companies:any=[];
}

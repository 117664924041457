import {Component, OnInit,} from '@angular/core';
import {Router} from "@angular/router";
import {NzMessageService} from "ng-zorro-antd/message";
import {NzModalService} from "ng-zorro-antd/modal";
import {selfHttp} from "../../../httpservice/http.service";
import { NzNotificationService } from 'ng-zorro-antd/notification';


@Component({
  selector: 'app-sidebarnewmessage',
  templateUrl: './sidebarnewmessage.component.html',
  styleUrls: ['./sidebarnewmessage.component.css']
})
export class SidebarNewMessageComponent implements OnInit {
  ws: WebSocket;
  constructor(public router: Router,
              public http: selfHttp,
              public modalService: NzModalService,
              public msg: NzMessageService,
              private notification: NzNotificationService
  ) {

  }

  ngOnInit() {
    // this.connectWs();
  }

  public isVisible=false;

  //socket连接
  connectWs() {
    console.log('ws开始');
    // if (this.ws != null) {
    //   this.ws.close()
    // }

    if (this.ws == null) {

      this.ws = new WebSocket("ws://http://localhost:88999/ws/systemNotification");
      console.log(this.ws)
    }


    //this.ws = new WebSocket("ws://http://localhost:88999/ws/systemNotification");
    let that = this;

    this.ws.onopen = function (event) {
      //socket 开启后执行，可以向后端传递信息
      that.ws.send(localStorage.getItem("token"));
    };

    this.ws.onmessage = function (event) {
      //socket 获取后端传递到前端的信息
console.log(typeof event.data)
      if(event.data!=null){
        let notifyObject=JSON.parse(event.data)

        //that.isVisible=true;
        //that.createBasicNotification();

        that.notification.blank(
          '最新消息',
          '姓名：'+notifyObject.Name+'地址：'+notifyObject.Address+'电话：'+notifyObject.Tel+'年龄：'+notifyObject.Age,
        );
      }


    };
    this.ws.onerror = function (event) {
      //socket error信息
      console.log(event,"websocket链接失败")

    };
    this.ws.onclose = function (event) {
      //socket 关闭后执行

    }
  }

  handleCancel(){

  }

  handleOk(){

  }


}

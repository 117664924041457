import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {selfHttp} from '../../httpservice/http.service';
import {NzMessageService} from 'ng-zorro-antd/message';
import {HttpHeaders} from '@angular/common/http';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Md5} from "ts-md5";
import jwtDecode from "jwt-decode";
import {WebsocketService} from "@src/app/common/websocket/websocket.service";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})

export class SidebarComponent implements OnInit {
  bindValidateForm: FormGroup;
  resetValidateForm: FormGroup;
  settingValidateForm: FormGroup;

  //logo的拉伸和收缩
  done() {
    if (this.isCollapsed) {
      this.isCollapsed = !this.isCollapsed;
    } else {
      this.isCollapsed = !this.isCollapsed;
    }
  }

  // logo = false;
  isCollapsed = false;
  bindIsVisible = false;
  drawDownIsVisible = false;
  settingIsVisible = false;
  triggerTemplate;
  userAvatar: string;
  options: any;
  access = [];
  //权限相关
  front_home: boolean;
  file_system: boolean;
  utils: boolean;
  role_access: boolean;
  // department: boolean;
  notification: boolean;
  helper: boolean;

  constructor(
    public router: Router,
    public http: selfHttp,
    public msg: NzMessageService,
    public fb: FormBuilder,
    private ws: WebsocketService,
  ) {
    this.buildForm();
    this.ws.createWebSocket();
  }


  ngOnInit() {
    this.front_home = true
    this.file_system = true
    this.utils = true
    this.role_access = true
    // this.department = true
    this.notification = true
    this.helper = true
    // let access = JSON.parse(localStorage.getItem('access'));
    // this.front_home = access.findIndex(v => v.name == 'front_home') != -1;
    // this.file_system = access.findIndex(v => v.name == 'file_system') != -1;
    // this.utils = access.findIndex(v => v.name == 'utils') != -1;
    // this.role_access = access.findIndex(v => v.name == 'role_access') != -1;
    // this.users = access.findIndex(v => v.name == 'users') != -1;
    // this.notification = access.findIndex(v => v.name == 'notification') != -1;
    // this.helper = access.findIndex(v => v.name == 'helper') != -1;
    if (localStorage.getItem('avatar') == '') {
      this.msg.info('暂未设置logo');
    } else {
      // this.userAvatar = 'http://oss-api.nositek.com/oss-api/loccay/images/' + localStorage.getItem('avatar');
      this.userAvatar = localStorage.getItem('avatar');
    }

    this.loadSetting();

  }

  loadSetting() {
    this.http.get('http://oss-api.nositek.com/oss-api/pc/user/setting', {
      user_id: jwtDecode(localStorage.getItem('token'))['user_id'],
    }, res => {
      if (res.data['row'] != null) {
        localStorage.setItem('config', JSON.stringify(res.data['row']))
      }
    })
  }

  buildForm() {
    this.bindValidateForm = this.fb.group({
      id: [null, [Validators.required]],
      mobile: [null, [Validators.required]],
      captcha: [null, [Validators.required]],
    });
    this.resetValidateForm = this.fb.group({
      password: [null, [Validators.required]],
      check_password: [null, [Validators.required, this.confirmationValidator]],
    });
    this.settingValidateForm = this.fb.group({
      id: [],
      msg_notify: [],
      voice: [],
      blank: [],
      tecent_wechat: [],
    });
  }

  //改变导航颜色
  changeNavigationColor(color) {
    const nzMenu = document.getElementById('layout-nzMenu');
    nzMenu.style.setProperty('background', color);
    const themeWrapper = document.getElementById('navigation');
    themeWrapper.style.setProperty('background', color);

    // const tableAreaList = document.getElementsByClassName("param-area")
    // for (let i=0;i<tableAreaList.length;i++){
    //   tableAreaList[i].
    // }
    // const navicatOperate = document.getElementById('navicat-operate');
    // navicatOperate.style.setProperty('background', color);
  }

  public countDownTime: number;
  public countDown = false;
  showButtonText = '发送短信验证码'; // 可以控制动态改变的按钮提示信息
  start: any;

  sendCaptcha() {
    if (this.showButtonText == '重新发送') {
      this.bindValidateForm.patchValue({
        captcha: ''
      });
    }
    this.countDown = true;
    this.countDownTime = 10;
    this.showButtonText = '验证码已发送（' + 60 + 's）'; // 设置按钮显示内容
    this.start = setInterval(() => {
      if (this.countDownTime >= 0) {
        this.showButtonText = '验证码已发送(' + this.countDownTime-- + 's)';     // 动态的进行倒计时
      } else {
        clearInterval(this.start);             // 如果超时则重新发送
        this.showButtonText = '重新发送';
        this.countDown = false;         // 按钮再次变成可点击状态
        this.countDownTime = 10;
      }
    }, 1000);

    this.http.get('http://oss-api.nositek.com/oss-api/public/captcha1', {
        mobile: this.bindValidateForm.value.mobile,
        template_code: '220093'
      },
      res => {
        if (res.code == 2000) {
          console.log('结果', res.data);
          localStorage.setItem('captcha', res.data['row']);
        } else if (res.code == 100080 || res.code == 100081 || res.code == 100002) {
          this.msg.info(res.msg)
        } else {
          this.msg.error(res.msg)
        }

      });

  }

  toBindMobile() {
    this.bindIsVisible = true;
    this.bindValidateForm.patchValue({
      id: jwtDecode(localStorage.getItem('token'))['user_id'],
    })
  }

  commitBind() {
    let localCaptcha = localStorage.getItem('captcha')
    if (this.bindValidateForm.value.captcha != localCaptcha) {
      this.msg.error('验证码错误，请重试')
      this.bindValidateForm.patchValue({
        captcha: null
      })
      return
    } else {
      localStorage.removeItem('captcha')
      for (const i in this.bindValidateForm.controls) {
        this.bindValidateForm.controls[i].markAsDirty();
        this.bindValidateForm.controls[i].updateValueAndValidity();
      }
      if (this.bindValidateForm.value.id && this.bindValidateForm.value.mobile) {
        this.http.put('http://oss-api.nositek.com/oss-api/tecent/user/bind', {
          'id': this.bindValidateForm.value.id,
          'mobile': this.bindValidateForm.value.mobile,
        }, res => {
          if (res.code != 2000) {
            this.msg.error(res.message);
          } else {
            this.msg.success('绑定成功');
          }
          this.bindIsVisible = false;
        });
      }
    }
    this.bindIsVisible = false;
  }

  cancelBind() {
    this.bindIsVisible = false;
    this.bindValidateForm.reset();
  }

  toUpdatePassword() {
    this.resetPwdIsVisible = true
    this.resetValidateForm.reset()
  }

  toSetting() {
    this.settingIsVisible = true;
    this.http.get('http://oss-api.nositek.com/oss-api/pc/user/setting', {
      user_id: jwtDecode(localStorage.getItem('token'))['user_id'],
    }, res => {
      if (res.data['row'] != null) {
        this.settingValidateForm.patchValue({
          id: res.data['row']['id'],
          msg_notify: res.data['row']['msg_notify'],
          blank: res.data['row']['blank'],
          voice: res.data['row']['voice'],
          tecent_wechat: res.data['row']['tecent_wechat'],
        })
      } else {
        this.settingValidateForm.patchValue({
          msg_notify: true,
          blank: true,
          voice: true,
          tecent_wechat: true,
        })
      }

    });
  }

  commitSetting() {
    this.http.put('http://oss-api.nositek.com/oss-api/pc/user/setting', this.settingValidateForm.value, res => {
      if (res.code != 2000) {
        this.msg.error(res.message);
      } else {
        this.msg.success('已保存');
      }
      this.settingIsVisible = false;
    });

  }

  cancelSetting() {
    this.settingIsVisible = false;
  }

  updateConfirmValidator(): void {
    /** wait for refresh value */
    Promise.resolve().then(() => this.resetValidateForm.controls.check_password.updateValueAndValidity());
  }

  confirmationValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return {required: true};
    } else if (control.value !== this.resetValidateForm.controls.password.value) {
      return {confirm: true, error: true};
    }
    return {};
  }
  public formdata: any;
  public resetPwdIsVisible = false;

  //重设密码取消
  resetPwdCancel() {
    this.resetPwdIsVisible = false;
  }

  //重设密码确定
  resetPwdCommit() {
    for (const i in this.resetValidateForm.controls) {
      this.resetValidateForm.controls[i].markAsDirty();
      this.resetValidateForm.controls[i].updateValueAndValidity();
    }
    if (this.resetValidateForm.value.password && this.resetValidateForm.value.check_password) {
      this.http.put('http://oss-api.nositek.com/oss-api/public/users/password', {
        'mobile': jwtDecode(localStorage.getItem('token'))['mobile'],
        'new_password': Md5.hashStr("shunKe@20230216" + this.resetValidateForm.value.password),
      }, res => {
        if (res.code == 2000) {
          this.msg.success('密码已重置');
          this.router.navigateByUrl('login');
        } else {
          this.msg.error(res.msg)
        }

      });
      this.resetPwdIsVisible = false;
    }

  }


  public toUserInfo(): void {
    this.router.navigateByUrl('/sidebar/sidrbaruserInfo');
  }

  public newmsg(): void {
    this.router.navigateByUrl('/sidebar/sidebarnewmsg');
  }

  public history(): void {
    this.router.navigateByUrl('/sidebar/sidebarhistory');
  }


  option: any;

  //注销账户
  drawDown() {
    this.drawDownIsVisible = true;
  }

  dropOut() {
    localStorage.clear();
    this.ws.webSocket.close();
    this.ws.heartCheckStop();
    this.router.navigateByUrl('/login');
  }

  //注销账户确定
  drawDownOk() {
    let id = JSON.parse(localStorage.getItem('user')).ID;
    this.option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        ID: id
      }
    };

    this.http.delete('http://oss-api.nositek.com/oss-api/pcuser', this.option, res => {
      localStorage.setItem('token', '');
      this.msg.success('账户已注销');
      this.drawDownIsVisible = false;
      this.router.navigateByUrl('/login');
    });
  }

  //注销账户取消
  drawDownCancel() {
    this.drawDownIsVisible = false;
  }

  //业务函数
  public toUnProcess() {
    this.router.navigateByUrl('/sidebar/apply');
  }

  public home() {
    this.router.navigateByUrl('/sidebar/home-page');
  }

  public toProbation() {
    this.router.navigateByUrl('/sidebar/probation');
  }

  public toSubscribe() {
    this.router.navigateByUrl('/sidebar/subscribe');
  }

  public toOrder() {
    this.router.navigateByUrl('/sidebar/order');
  }

  public toBill() {
    this.router.navigateByUrl('/sidebar/bill');
  }

  public toRefund() {
    this.router.navigateByUrl('/sidebar/fee');
  }

  public toOffset() {
    this.router.navigateByUrl('/sidebar/purchase');
  }

  public toInvoice() {
    this.router.navigateByUrl('/sidebar/invoice');
  }

  public toService() {
    this.router.navigateByUrl('/sidebar/services');
  }

  public toProduct() {
    this.router.navigateByUrl('/sidebar/products');
  }

  public toPackage() {
    this.router.navigateByUrl('/sidebar/packages');
  }

  public toSign() {
    this.router.navigateByUrl('/sidebar/sign');
  }

  public toTemplateManage() {
    this.router.navigateByUrl('/sidebar/template-manage');
  }

  public toMaterial() {
    this.router.navigateByUrl('/sidebar/material');
  }

  public toServer() {
    this.router.navigateByUrl('/sidebar/server');
  }

  public toMenu() {
    this.router.navigateByUrl('/sidebar/menu');
  }

  public toInterface() {
    this.router.navigateByUrl('/sidebar/interface');
  }

  public toTemplate() {
    this.router.navigateByUrl('/sidebar/access-template');
  }

  public role() {
    this.router.navigateByUrl('/sidebar/oss-role');
  }

  public department() {
    this.router.navigateByUrl('/sidebar/department');
  }

  // public users() {
  //   this.router.navigateByUrl('/sidebar/users');
  // }

  public help() {
    let url = 'http://view.xdocin.com/xdoc?_xdoc=http://sk.jsnuox.com/loccay/texts/sk帮助手册.docx&_watermark=品诚誉&_copyable=false&_printable=false' +
      '&_saveable=false&_title=sk帮助手册';
    window.open(url);
  }

  public systemConfig() {
    this.router.navigateByUrl('/sidebar/setting');
  }

  public notificationF() {
    this.router.navigateByUrl('/sidebar/notification');
  }

}

import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse} from '@angular/common/http';
//事件 拦截器 处理器 请求 响应
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
// import {ErrorObservable} from 'rxjs/observable/ErrorObservable';
import {NzMessageService} from 'ng-zorro-antd/message';
import {catchError} from 'rxjs/operators';
import {mergeMap} from 'rxjs/operators';
import {OpensComponentService} from '../pages/opens/opens.component.service';


@Injectable()
export class InterceptorService implements HttpInterceptor {
  constructor(public router: Router,
              public msg: NzMessageService,
              public service: OpensComponentService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let a = localStorage.getItem('token');
    const authReq = req.clone({
      url: (req.url), //对任意请求的url添加token参数
      headers: req.headers.set('Authorization', 'Bearer ' + a),
    });
    return next.handle(authReq)
      .pipe(catchError((error, caught) => {
        console.log('Error Occurred');
        console.log(error);
        return Observable.throw(error);
      })) as any;
  }

  // return next.handle(authReq).pipe(mergeMap((event: any) => {
  //     if (event instanceof HttpResponse && event.status != 200) {
  //       return ErrorObservable.create(event);
  //     }
  //     return Observable.create(observer => observer.next(event)); //请求成功返回响应
  //   }),
  //   catchError((res: HttpResponse<any>) => {   //请求失败处理
  //     switch (res.status) {
  //       case 401:
  //         break;
  //       // case 200:
  //       //   console.log('业务错误');
  //       //   break;
  //       case 404:
  //         break;
  //       case 403:
  //         console.log('业务错误');
  //         break;
  //     }
  //     return ErrorObservable.create(event);
  //   }));
  // }
}

<nz-card>
  <div id="table-area">
    <div id="param-area">
      <div id="param-input">
        <input type="text" nz-input placeholder="键入关键字搜索" [(ngModel)]="keyword"/>
      </div>
      <div id="table-operation">
        <div style=" margin-right: 5%;">
          <button nz-button [nzType]="'default'" (click)="reset()">重置</button>
        </div>
        <div style="margin-right: 5%">
          <button nz-button [nzType]="'primary'" (click)="loadObjectData()">搜索</button>
        </div>
        <div>
          <!--        <div *ngIf="add_role||delete_role||edit_role">-->
          <button nz-button nz-dropdown [nzDropdownMenu]="menu" nzSize="default" class="column">操作列表</button>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu nzSelectable>
              <li nz-menu-item>
                <button nz-button (click)="preAdd()" nzSize="small">
                  <i nz-icon nzType="plus" nzTheme="outline"></i>
                  <span>添加</span>
                </button>
              </li>
              <li nz-menu-item>
                <button nz-button nzType="info" nzSize="small" (click)="preEdit()">
                  <i nz-icon nzType="edit" nzTheme="outline"></i><span>编辑</span>
                </button>
              </li>
              <li nz-menu-item>
                <button nz-button nzType="info" nzSize="small" (click)="preDelete()">
                  <i nz-icon nzType="delete" nzTheme="outline"></i><span>删除</span>
                </button>
              </li>
            </ul>
          </nz-dropdown-menu>
        </div>
      </div>
    </div>
    <div id="table-data">
      <nz-table
        nzSize="small"
        [nzData]="objects"
        [(nzPageSize)]="size"
        [nzTotal]="total"
        [(nzPageIndex)]="page"
        [nzFrontPagination]="false"
        (nzPageIndexChange)="indexChange()"
        (nzPageSizeChange)="indexChange()"
        [nzShowSizeChanger]="true"
        [nzPageSizeOptions]="[5,10,15,20]"
        [nzShowTotal]="totalTemplate"
      >
        <ng-template #totalTemplate>共 <span style="color:cornflowerblue;margin:0 3px;">{{ total }}</span>
          条
        </ng-template>
        <thead>
        <tr>
          <th nzWidth="50px"></th>
          <th nzWidth="100px">姓名</th>
          <th nzWidth="100px">联系电话</th>
          <th nzWidth="100px">系统用户</th>
          <th nzWidth="100px">启用状态</th>
          <th nzWidth="100px">更新时间</th>
          <th nzWidth="100px">最近登录</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let data of objects">
          <td nzShowCheckbox [(nzChecked)]="data.checked"
              (nzCheckedChange)="onChange(data,'single')"></td>
          <td>{{ data.user_name }}</td>
          <td>{{ data.mobile }}</td>
          <td>{{ data.role_name }}</td>
          <td>{{ data.status == 0 ? '禁用' : '启用' }}</td>
          <td>{{ data.update_time.slice(0, 10) }}</td>
          <td>{{ data.last_login.slice(0, 10) == '2006-01-02' ? '暂未登录' : data.last_login.split(".")[0]| date: 'MM月dd日 hh:mm'}}</td>
        </tr>
        </tbody>
      </nz-table>
    </div>
  </div>
</nz-card>

<!--添加系统用户信息-->
<nz-drawer
  [nzBodyStyle]="{ height: 'calc(100% - 150px)', overflow: 'auto', 'padding-bottom': '53px' }"
  [nzMaskClosable]="false"
  [nzWidth]="720"
  [nzVisible]="addIsVisible"
  nzTitle="添加系统用户"
  (nzOnClose)="addCancel()"
>
  <form nz-form [formGroup]="validateForm" class="ant-advanced-search-form">
    <div class="permission" style="width: 100%">
      <div nz-row [nzGutter]="24">
        <div nz-col [nzSpan]="24">
          <nz-form-item nzFlex>
            <nz-form-label style="width: 18%;text-align: left;" nzRequired>用户名称</nz-form-label>
            <nz-form-control style="width: 36%" nzErrorTip="请输入用户名称!">
              <input id="test" nz-input placeholder="请输入用户名称" formControlName="user_name"/>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24">
          <nz-form-item nzFlex>
            <nz-form-label style="width: 18%;text-align: left" nzRequired>角色权限</nz-form-label>
            <nz-form-control style="width: 36%" nzErrorTip="请选择角色!">
              <nz-select formControlName="role_id" [nzPlaceHolder]="'请选择角色'">
                <nz-option *ngFor="let role of selectOptions" [nzValue]="role.id"
                           [nzLabel]="role.role_name"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24">
          <nz-form-item nzFlex>
            <nz-form-label style="width: 18%;text-align: left;" nzRequired>联系电话</nz-form-label>
            <nz-form-control style="width: 36%;" nzErrorTip="请输入联系电话!">
              <input type="text" nz-input placeholder="请输入联系电话" formControlName="mobile"/>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24">
          <nz-form-item nzFlex>
            <nz-form-label style="width: 18%;text-align: left;">启用状态</nz-form-label>
            <nz-form-control style="width: 36%">
              <nz-switch formControlName="status" nzCheckedChildren="启用" nzUnCheckedChildren="禁用"></nz-switch>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </div>
  </form>
  <div class="footer">
    <button type="button" (click)="addCancel()" class="ant-btn" style="margin-right: 8px;"><span>取消</span>
    </button>
    <button type="button" (click)="addCommit()" class="ant-btn ant-btn-primary"><span>提交</span></button>
  </div>
</nz-drawer>

<!--编辑系统用户信息-->
<nz-drawer
  [nzBodyStyle]="{ height: 'calc(100% - 150px)', overflow: 'auto', 'padding-bottom': '53px' }"
  [nzMaskClosable]="false"
  [nzWidth]="720"
  [nzVisible]="editIsVisible"
  nzTitle="编辑系统用户"
  (nzOnClose)="editCancel()"
>
  <form nz-form [formGroup]="validateForm" class="ant-advanced-search-form">
    <div class="permission" style="width: 100%">
      <div nz-row [nzGutter]="24">
        <div nz-col [nzSpan]="24">
          <nz-form-item nzFlex>
            <nz-form-label style="width: 18%;text-align: left;" nzRequired>用户名称</nz-form-label>
            <nz-form-control style="width: 36%" nzErrorTip="请输入用户名称!">
              <input nz-input placeholder="请输入用户名称" formControlName="user_name"/>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24">
          <nz-form-item nzFlex>
            <nz-form-label style="width: 18%;text-align: left" nzRequired>角色权限</nz-form-label>
            <nz-form-control style="width: 36%" nzErrorTip="请选择角色!">
              <nz-select formControlName="role_id" [nzPlaceHolder]="'请选择角色'">
                <nz-option *ngFor="let role of selectOptions" [nzValue]="role.id"
                           [nzLabel]="role.role_name"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24">
          <nz-form-item nzFlex>
            <nz-form-label style="width: 18%;text-align: left;" nzRequired>联系电话</nz-form-label>
            <nz-form-control style="width: 36%;" nzErrorTip="请输入联系电话!">
              <input type="text" nz-input placeholder="请输入联系电话" formControlName="mobile"/>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24">
          <nz-form-item nzFlex>
            <nz-form-label style="width: 18%;text-align: left;">启用状态</nz-form-label>
            <nz-form-control style="width: 36%">
              <nz-switch formControlName="status" nzCheckedChildren="启用" nzUnCheckedChildren="禁用"></nz-switch>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </div>
  </form>
  <div class="footer">
    <button type="button" (click)="editCancel()" class="ant-btn" style="margin-right: 8px;"><span>取消</span>
    </button>
    <button type="button" (click)="editCommit()" class="ant-btn ant-btn-primary"><span>提交</span></button>
  </div>
</nz-drawer>

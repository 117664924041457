<nz-card>
  <div class="table-area">
    <div class="param-area">
      <nz-space class="table-query">
        <nz-space-item>
          <nz-range-picker></nz-range-picker>
        </nz-space-item>
        <nz-space-item>
          <nz-select nzShowSearch nzAllowClear nzPlaceHolder="请选择账号状态" [(ngModel)]="selectedValue">
            <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
            <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
            <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
          </nz-select>
        </nz-space-item>
        <nz-space-item>
          <nz-input-group nzSearch [nzAddOnAfter]="suffixButton">
            <input type="text" nz-input placeholder="输入关键字搜索" [(ngModel)]="keyword"/>
          </nz-input-group>
          <ng-template #suffixButton>
            <button nz-button nzType="primary" (click)="loadObjectData()">搜索</button>
          </ng-template>
        </nz-space-item>
        <nz-space-item>
          <button nz-button [nzType]="'default'" (click)="reset()">重置</button>
        </nz-space-item>
      </nz-space>
      <!--          <div class="table-operation">-->
      <!--            <button nz-button nz-dropdown [nzDropdownMenu]="menu" nzSize="default" class="column">操作列表</button>-->
      <!--            <nz-dropdown-menu #menu="nzDropdownMenu">-->
      <!--              <ul nz-menu nzSelectable>-->
      <!--                <li nz-menu-item>-->
      <!--                  <button nz-button (click)="preAdd()" nzSize="small">-->
      <!--                    <i nz-icon nzType="plus" nzTheme="outline"></i>-->
      <!--                    <span>添加</span>-->
      <!--                  </button>-->
      <!--                </li>-->
      <!--                <li nz-menu-item>-->
      <!--                  <button nz-button nzType="info" nzSize="small" (click)="preEdit()">-->
      <!--                    <i nz-icon nzType="edit" nzTheme="outline"></i><span>编辑</span>-->
      <!--                  </button>-->
      <!--                </li>-->
      <!--                <li nz-menu-item>-->
      <!--                  <button nz-button nzType="info" nzSize="small" (click)="preDelete()">-->
      <!--                    <i nz-icon nzType="delete" nzTheme="outline"></i><span>删除</span>-->
      <!--                  </button>-->
      <!--                </li>-->
      <!--              </ul>-->
      <!--            </nz-dropdown-menu>-->
      <!--          </div>-->
    </div>
    <div>
      <nz-table
        nzSize="small"
        [nzData]="objects"
        [(nzPageSize)]="size"
        [nzTotal]="total"
        [(nzPageIndex)]="page"
        [nzFrontPagination]="false"
        (nzPageIndexChange)="indexChange()"
        (nzPageSizeChange)="indexChange()"
        [nzShowSizeChanger]="true"
        [nzPageSizeOptions]="[5,10,15,20]"
        [nzShowTotal]="totalTemplate"
        [nzHideOnSinglePage]="true"
      >
        <ng-template #totalTemplate>共 <span style="color:cornflowerblue;margin:0 3px;">{{ total }}</span>
          条
        </ng-template>
        <thead style="background: yellow">
        <tr>
          <th nzWidth="50px"></th>
          <th nzWidth="100px">用户编号</th>
          <th nzWidth="100px">手机号码</th>
          <th nzWidth="100px">用户姓名</th>
          <th nzWidth="100px">客户公司</th>
          <th nzWidth="100px">来源</th>
          <th nzWidth="100px">行业</th>
          <th nzWidth="100px">申请时间</th>
          <th nzWidth="100px">开通时间</th>
          <th nzWidth="100px">到期时间</th>
          <th nzWidth="100px">认证状态</th>
          <th nzWidth="100px">账号状态</th>
          <th nzWidth="200px">操作</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let object of objects">
          <td nzShowCheckbox [(nzChecked)]="object.checked"
              (nzCheckedChange)="onChange(object,'single')"></td>
          <td>{{ object.user_name }}</td>
          <td>{{ object.mobile }}</td>
          <td>{{ object.role_name }}</td>
          <td>{{ object.status == 0 ? '禁用' : '启用' }}</td>
          <td>{{ object.update_time.slice(0, 10) }}</td>
          <td>{{ object.last_login.slice(0, 10) == '2006-01-02' ? '暂未登录' : object.last_login.split(".")[0]| date: 'MM月dd日 hh:mm'}}</td>
          <td>{{ object.role_name }}</td>
          <td>{{ object.role_name }}</td>
          <td>{{ object.role_name }}</td>
          <td>{{ object.role_name }}</td>
          <td>{{ object.role_name }}</td>
          <td>
            <a (click)="preDetail(object)"><i nz-icon nzType="monitor" nzTheme="outline"></i>详情</a>&nbsp;&nbsp;&nbsp;&nbsp;
            <a (click)="preEdit()"><i nz-icon nzType="stop" nzTheme="outline"></i>冻结</a>&nbsp;&nbsp;&nbsp;&nbsp;
            <a (click)="preEdit()"><i nz-icon nzType="check-circle" nzTheme="outline"></i>开通</a>&nbsp;&nbsp;&nbsp;&nbsp;
          </td>
        </tr>
        </tbody>
      </nz-table>
    </div>
  </div>
</nz-card>

<!--添加系统用户信息-->
<nz-drawer
  [nzBodyStyle]="{ height: 'calc(100% - 150px)', overflow: 'auto', 'padding-bottom': '53px' }"
  [nzMaskClosable]="true"
  [nzWidth]="1000"
  [nzVisible]="viewIsVisible"
  (nzOnClose)="closeDetail()"
>
  <form nz-form [formGroup]="validateForm" class="ant-advanced-search-form">
    <nz-descriptions nzTitle="客户信息" nzBordered>
      <nz-descriptions-item nzTitle="企业名称" [nzSpan]="2">江苏诺翔科技有限公司</nz-descriptions-item>
      <nz-descriptions-item nzTitle="统一社会信用代码" [nzSpan]="2">923485375828XTLA</nz-descriptions-item>
      <nz-descriptions-item nzTitle="所属行业" [nzSpan]="2">科技-软件</nz-descriptions-item>
      <nz-descriptions-item nzTitle="企业规模" [nzSpan]="2">50-99人</nz-descriptions-item>
      <nz-descriptions-item nzTitle="法人姓名" [nzSpan]="2">刘翔</nz-descriptions-item>
      <nz-descriptions-item nzTitle="法人电话" [nzSpan]="2">17768015066</nz-descriptions-item>
      <nz-descriptions-item nzTitle="审核状态" [nzSpan]="2">
        <nz-badge nzStatus="default" nzText="待审核"></nz-badge>
        <nz-switch formControlName="status" nzCheckedChildren="开通" nzUnCheckedChildren="冻结" style="float: right"></nz-switch>
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="审核人" [nzSpan]="2">张佳</nz-descriptions-item>
      <nz-descriptions-item nzTitle="审核时间" [nzSpan]="2">2023-03-01 09：54：55</nz-descriptions-item>
      <nz-descriptions-item nzTitle="经营地址" [nzSpan]="3">苏州市渔郎桥浜1幢201室二层</nz-descriptions-item>
      <nz-descriptions-item nzTitle="营业执照" [nzSpan]="2">
        <img [src]="'../../assets/login/sk.jpg'" style="margin-left: 5%;margin-top: 2%;width: 140px;height: 58.3px;" alt="">
      </nz-descriptions-item>
    </nz-descriptions>
    <nz-descriptions nzTitle="管理员信息" nzBordered style="margin-top: 10vh">
      <nz-descriptions-item nzTitle="管理员" [nzSpan]="2">刘奇</nz-descriptions-item>
      <nz-descriptions-item nzTitle="账号" [nzSpan]="2">loccay</nz-descriptions-item>
      <nz-descriptions-item nzTitle="来源" [nzSpan]="2">智能名片</nz-descriptions-item>
      <nz-descriptions-item nzTitle="账号状态" [nzSpan]="2">
        <nz-badge nzStatus="processing" nzText="正常"></nz-badge>
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="开通时间" [nzSpan]="2">2018-04-24 18:00:00</nz-descriptions-item>
      <nz-descriptions-item nzTitle="截止时间" [nzSpan]="2">
        2018-04-24 18:00:00
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="最近登录时间" [nzSpan]="3">2018-04-24 18:00:00</nz-descriptions-item>
    </nz-descriptions>
  </form>
</nz-drawer>

<!--编辑系统用户信息-->
<nz-drawer
  [nzBodyStyle]="{ height: 'calc(100% - 150px)', overflow: 'auto', 'padding-bottom': '53px' }"
  [nzMaskClosable]="false"
  [nzWidth]="720"
  [nzVisible]="editIsVisible"
  nzTitle="编辑系统用户"
  (nzOnClose)="editCancel()"
>
  <form nz-form [formGroup]="validateForm" class="ant-advanced-search-form">
    <div class="permission" style="width: 100%">
      <div nz-row [nzGutter]="24">
        <div nz-col [nzSpan]="24">
          <nz-form-item nzFlex>
            <nz-form-label style="width: 18%;text-align: left;" nzRequired>用户名称</nz-form-label>
            <nz-form-control style="width: 36%" nzErrorTip="请输入用户名称!">
              <input nz-input placeholder="请输入用户名称" formControlName="user_name"/>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24">
          <nz-form-item nzFlex>
            <nz-form-label style="width: 18%;text-align: left" nzRequired>角色权限</nz-form-label>
            <nz-form-control style="width: 36%" nzErrorTip="请选择角色!">
              <nz-select formControlName="role_id" [nzPlaceHolder]="'请选择角色'">
                <nz-option *ngFor="let role of selectOptions" [nzValue]="role.id"
                           [nzLabel]="role.role_name"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24">
          <nz-form-item nzFlex>
            <nz-form-label style="width: 18%;text-align: left;" nzRequired>联系电话</nz-form-label>
            <nz-form-control style="width: 36%;" nzErrorTip="请输入联系电话!">
              <input type="text" nz-input placeholder="请输入联系电话" formControlName="mobile"/>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24">
          <nz-form-item nzFlex>
            <nz-form-label style="width: 18%;text-align: left;">启用状态</nz-form-label>
            <nz-form-control style="width: 36%">
              <nz-switch formControlName="status" nzCheckedChildren="启用" nzUnCheckedChildren="禁用"></nz-switch>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </div>
  </form>
  <div class="footer">
    <button type="button" (click)="editCancel()" class="ant-btn" style="margin-right: 8px;"><span>取消</span>
    </button>
    <button type="button" (click)="editCommit()" class="ant-btn ant-btn-primary"><span>提交</span></button>
  </div>
</nz-drawer>

import {Component, ViewChild, OnInit, Inject, LOCALE_ID} from '@angular/core';
import {Router} from '@angular/router';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzModalService} from 'ng-zorro-antd/modal';
import {selfHttp} from "@src/app/httpservice/http.service";
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NzTreeComponent} from 'ng-zorro-antd/tree';
import {formatDate} from "@angular/common";
import {HttpHeaders} from "@angular/common/http";

@Component({
  selector: 'purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.css']
})


export class PurchaseComponent implements OnInit {
  @ViewChild('nzTreeComponent') nzTreeComponent: NzTreeComponent;
  status: any;
  startTime = '';
  endTime = '';
  dateRangeValues = []
  validateForm: FormGroup;
  itemValidateForm: FormGroup;
  purchaseInfoValidateForm: FormGroup;
  settleInfoValidateForm: FormGroup;
  operateOptions = [
    {value: 1, label: '腾讯科技'},
    {value: 2, label: '鸿联九五'},
  ]
  //权限相关
  addAccess = false;
  editAccess = false;
  deleteAccess = false;
  //查询相关
  checkChild: any = false;
  keyword: string = '';
  size = 5;
  page: number = 1;
  total: number = 0;

  //操作相关
  companyOptions = [];
  // operateOptions = [];
  operateItem = null;
  viewIsVisible = false;
  addIsVisible = false;
  addPurchaseInfoIsVisible = false;
  addSettleInfoIsVisible = false;
  editIsVisible = false;
  accessCodes: any = [];
  currentUser: any;

  objects = [];
  tmpArr = [];
  details = []
  public objectInfo: any;
  public options: any;
  purchaseItems = [];
  orderOptions = []

  constructor(public router: Router,
              public fb: FormBuilder,
              public http: selfHttp,
              public modalService: NzModalService,
              public msg: NzMessageService,
              @Inject(LOCALE_ID) public locale: string,
  ) {
    this.loadObjectData();
    this.loadAccess();
    this.buildForm();
    this.loadOptionData();
  }

  buildForm() {
    this.validateForm = this.fb.group({
      servicer_type: [0],
      apply_balance: [null],
    });
    this.itemValidateForm = this.fb.group({
      arrayList: this.fb.array([]),
      company_id: [null],
      downstream_order_no: [null],
      service_id: [null, [Validators.required]],
      product_id: [null, [Validators.required]],
      product_unit: [null],
      scene_bind: [null],
      product_number: [0, [Validators.required]],
      effective_month: [0, [Validators.required]],
      single_price: [0],
      vital_price: [0, [Validators.required]],
      productOptions: [[]]
    });
    this.purchaseInfoValidateForm = this.fb.group({
      purchase_id: [null],
      purchase_detail_id: [null],
      upstream_order_no: [null],
      purchase_price: [null],
      pickerTimes: [[]],
      effective_start: [null],
      effective_end: [null],
    });
    this.settleInfoValidateForm = this.fb.group({
      purchase_id: [null],
      purchase_detail_id: [null],
      settle_number: [null],
      settle_balance: [null],
    });
  }

  ngOnInit(): void {
  }

  datePickChange(object: any) {
    if (object.length > 0) {
      this.startTime = formatDate(object[0], 'yyyy-MM-dd', this.locale)
      this.endTime = formatDate(object[1], 'yyyy-MM-dd', this.locale)
    } else {
      this.startTime = '';
      this.endTime = '';
    }
    this.loadObjectData();

  }

  onPickerChange(result: Array<Date>): void {
    if (result.length != 0) {
      let object = this.purchaseInfoValidateForm.value.pickerTimes
      this.purchaseInfoValidateForm.patchValue({
        effective_start: formatDate(object[0], 'yyyy-MM-dd HH:mm:ss', this.locale),
        effective_end: formatDate(object[1], 'yyyy-MM-dd HH:mm:ss', this.locale)
      })
    } else {
      this.purchaseInfoValidateForm.patchValue({
        effective_start: '',
        effective_end: ''
      })
    }

  }


  loadObjectData() {
    this.objects = [];
    this.http.get('http://oss-api.nositek.com/oss-api/pc/purchases', {
      page: this.page,
      size: this.size,
      status: this.status,
      keyword: this.keyword,
      start_time: this.startTime,
      end_time: this.endTime,
    }, res => {
      if (res.data['rows'] != null && res.data['rows'].length > 0) {
        this.objects = res.data['rows'];
        this.total = Number(res.data['total']);
      } else {
        this.total = 0;
        this.msg.info('暂无数据');
      }
    });
  }


  loadObjectDetailData(id: number) {
    this.details = [];
    this.http.get('http://oss-api.nositek.com/oss-api/pc/purchase/details', {
      id: id,
    }, res => {
      if (res.data['rows'] != null) {
        this.details = res.data['rows'];
      } else {
        this.msg.info('暂无数据');
      }
    });
  }


  // 复选框
  onChange(data: any, checked: any) {
    this.objectInfo = data;
    if (checked === 'single') {
      if (data.checked) {
        this.operateOptions.push(data.id);
      } else {
        let index = this.operateOptions.indexOf(data.id);
        this.operateOptions.splice(index, 1);
      }
    }
  }

// 重置
  reset() {
    this.page = 1;
    this.size = 5;
    this.keyword = '';
    this.status = '';
    this.startTime = '';
    this.endTime = '';
    this.dateRangeValues = [];
    this.loadObjectData();
  }

  //页码切换
  indexChange() {
    console.log(this.page, this.size);
    this.loadObjectData();
  }


  preDetail(object: any) {
    this.loadObjectDetailData(object.id)
    this.viewIsVisible = true
  }

  //添加取消
  closeDetail() {
    this.viewIsVisible = false;
    this.details = []
  }


  preAddPurchaseInfo(object: any) {
    this.purchaseInfoValidateForm.patchValue({
      purchase_id: object.purchase_id,
      purchase_detail_id: object.id
    })
    this.addPurchaseInfoIsVisible = true
  }

  addPurchaseInfoCancel() {
    this.addPurchaseInfoIsVisible = false;
    this.purchaseInfoValidateForm.reset();
  }

  addPurchaseInfoCommit() {
    this.http.put('http://oss-api.nositek.com/oss-api/pc/purchase/detail/info', {
      purchase_detail_id: this.purchaseInfoValidateForm.value.purchase_detail_id,
      upstream_order_no: this.purchaseInfoValidateForm.value.upstream_order_no,
      purchase_price: Number(this.purchaseInfoValidateForm.value.purchase_price),
      effective_start: this.purchaseInfoValidateForm.value.effective_start,
      effective_end: this.purchaseInfoValidateForm.value.effective_end,

    }, res => {
      if (res.code == 2000) {
        this.msg.success('编辑成功');
      } else {
        this.msg.error('编辑异常');
      }
      this.loadObjectDetailData(this.purchaseInfoValidateForm.value.purchase_id)
      this.purchaseInfoValidateForm.reset();
      this.addPurchaseInfoIsVisible = false;
    });
  }

  preAddSettleInfo(object: any) {
    this.settleInfoValidateForm.patchValue({
      purchase_id: object.purchase_id,
      purchase_detail_id: object.id
    })
    this.addSettleInfoIsVisible = true
  }

  addSettleCancel() {
    this.addSettleInfoIsVisible = false;
    this.settleInfoValidateForm.reset();
  }

  addSettleCommit() {
    this.http.put('http://oss-api.nositek.com/oss-api/pc/purchase/detail/settle', {
      purchase_detail_id: this.settleInfoValidateForm.value.purchase_detail_id,
      settle_number: Number(this.settleInfoValidateForm.value.settle_number),
      settle_balance: Number(this.settleInfoValidateForm.value.settle_balance),

    }, res => {
      if (res.code == 2000) {
        this.msg.success('编辑成功');
      } else {
        this.msg.error('编辑异常');
      }
      this.loadObjectDetailData(this.settleInfoValidateForm.value.purchase_id);
      this.addSettleInfoIsVisible = false;
      this.settleInfoValidateForm.reset();
    });

  }

  /*
  添加系统用户
   */
  preAdd() {
    this.validateForm.reset();
    this.itemValidateForm.reset();
    this.arrayList.clear();
    this.loadOperateServiceData();
    this.addIsVisible = true;
  }

  loadOptionData() {
    this.http.get('http://oss-api.nositek.com/oss-api/pc/companies', {}, res => {
      if (res.data['rows'] != null) {
        this.companyOptions = res.data['rows'];
      } else {
        this.companyOptions = [];
        this.msg.info('暂无数据');
      }

    });
  }

  //添加取消
  addCancel() {
    this.addIsVisible = false;
    this.purchaseItems = [];
    this.itemValidateForm.reset();
  }

  //添加提交
  addCommit() {
    this.purchaseItems = []
    this.arrayList.controls.forEach((item, index) => {
      if (item['value'].company_id == "") {
        item['value'].company_id = 0
      }
      this.purchaseItems.push(item['value'])
    })
    this.http.post('http://oss-api.nositek.com/oss-api/pc/purchase', {
      servicer_type: this.validateForm.value.servicer_type,
      apply_balance: this.validateForm.value.apply_balance,
      details: this.purchaseItems,
    }, res => {
      if (res.code == 2000) {
        this.msg.success('添加成功')
      } else {
        this.msg.error(res.data)
      }
      this.addIsVisible = false;
      this.validateForm.reset();
      this.itemValidateForm.reset();
      this.arrayList.clear()
      this.purchaseItems = [];
      this.loadObjectData();
    })
  }

  /*
  修改系统用户
   */
  preEdit() {
    if (this.operateOptions.length == 0) {
      this.msg.info('请选择数据！');
      return;
    } else if (this.operateOptions.length > 1) {
      this.msg.info('请逐条记录操作！');
      return;
    }
    this.loadOptionData();
    let index = this.objects.map(item => item.id).indexOf(this.operateOptions[0]);
    this.operateItem = this.objects[index];
    this.validateForm.patchValue({
      id: this.operateItem.id,
      user_name: this.operateItem.name,
    });
    if (this.operateItem.status == 1) {
      this.validateForm.patchValue({
        'status': true
      });
    } else {
      this.validateForm.patchValue({
        'status': false
      });
    }
    this.validateForm.patchValue({
      user_name: this.operateItem.user_name,
      role_id: this.operateItem.role_id,
      mobile: this.operateItem.mobile,
    })

    this.editIsVisible = true;
  }

  //修改取消
  editCancel() {
    this.editIsVisible = false;
    this.operateOptions = [];
    this.loadObjectData();
    this.validateForm.reset();
    this.validateForm.patchValue({
      status: true,
    });
  }

  //修改提交
  editCommit() {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    let enable: number;
    if (this.validateForm.value.status) {
      enable = 1;
    } else {
      enable = 0;
    }
    if (this.validateForm.value.user_name && this.validateForm.value.mobile && this.validateForm.value.role_id) {
      this.http.post('http://oss-api.nositek.com/oss-api/pc/users', {
        'id': Number(this.objectInfo.id),
        'user_name': this.validateForm.value.user_name,
        'role_id': this.validateForm.value.role_id,
        'mobile': this.validateForm.value.mobile,
        'status': enable,
      }, res => {
        if (res.code == 2000) {
          this.msg.success('编辑成功');
        } else if (res.code == 100003) {
          this.msg.info(res.msg)
        } else {
          this.msg.error(res.msg)
        }
        this.validateForm.reset();
        this.validateForm.patchValue({
          status: true,
        });
        this.editIsVisible = false;
        this.loadObjectData();
      });
    }

  }


  /*
  删除用户
   */
  preDelete() {
    if (this.operateOptions.length == 0) {
      this.msg.info('请选择数据！');
      return;
    }
    this.modalService.confirm({
      nzTitle: '<i>确定删除数据 ？</i>',
      nzContent: '<b></b>',
      nzOkText: '确定',
      nzCancelText: '取消',
      nzOnOk: () => this.deleteCommit(),
      nzOnCancel: () => this.deleteCancel()
    });
  }

  // 删除提交
  deleteCommit() {
    this.options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        ids: this.operateOptions
      }
    };
    this.http.delete('http://oss-api.nositek.com/oss-api/pc/users', this.options, res => {
      if (res.code == 2000) {
        this.msg.success('删除成功');
      } else {
        this.msg.info(res.msg);
      }
      this.loadObjectData();
    });
    this.operateOptions = [];
  }

  // 删除取消
  deleteCancel() {
    this.operateOptions = [];
    this.loadObjectData();
  }

  //动态表格
  timeOptions = [
    {label: '不限制', value: 0},
    {label: '1个月', value: 1},
    {label: '2个月', value: 2},
    {label: '3个月', value: 3},
    {label: '4个月', value: 4},
    {label: '5个月', value: 5},
    {label: '半年', value: 6},
    {label: '7个月', value: 7},
    {label: '8个月', value: 8},
    {label: '9个月', value: 9},
    {label: '10个月', value: 10},
    {label: '11个月', value: 11},
    {label: '一年', value: 12},
  ]
  operateServiceOptions = [];

  inputChange(index: number) {
    let currentItem = this.arrayList.controls[index]
    if (currentItem['value']['single_price']) {
      if (currentItem['value']['product_number']) {
        //处理单行数据
        currentItem.patchValue({
          vital_price: Number((currentItem['single_price'] * currentItem['product_number']).toFixed(3)),
        })
      }
    }

  }

  fetchProductUnit(optionId: any, index: number) {
    if (optionId) {
      let i = this.operateProductOptions.map(item => item.id).indexOf(optionId);
      this.arrayList.controls[index].patchValue({
        product_unit: this.operateProductOptions[i].product_unit,
        scene_bind: this.operateProductOptions[i].scene_bind,
        single_price: this.operateProductOptions[i].single_price,
        product_number: 1,
        effective_month: 0,
        vital_price: this.operateProductOptions[i].single_price,
      })
      if (this.operateProductOptions[i].scene_bind != 20 && this.operateProductOptions[i].scene_bind != 21) {
        this.arrayList.controls[index].patchValue({
          disabled: true
        })
      } else {
        this.arrayList.controls[index].patchValue({
          disabled: false
        })
      }
    }
  }

  loadOperateServiceData() {
    this.operateServiceOptions = [];
    this.http.get('http://oss-api.nositek.com/oss-api/pc/services', {}, res => {
      if (res.data['rows'] != null && res.data['rows'].length > 0) {
        this.operateServiceOptions = res.data['rows'];
      } else {
        this.msg.info('暂无数据');
      }
    });
  }

  operateProductOptions = [];

  loadOperateProductData(object: any, index: number) {
    if (object) {
      this.http.get('http://oss-api.nositek.com/oss-api/pc/products', {service_id: object}, res => {
        if (res.data['rows'] != null && res.data['rows'].length > 0) {
          this.operateProductOptions = res.data['rows'];
          this.arrayList.controls[index].patchValue({
            product_id: null,
            product_unit: null,
            product_number: 1,
            effective_month: 0,
            single_price: null,
            vital_price: null,
            productOptions: this.operateProductOptions,
          })
        } else {
          this.msg.info('暂无数据');
        }
      });
    }
  }

  loadCompanyOrderData(object: any) {
    this.http.get('http://oss-api.nositek.com/oss-api/pc/orders', {
      company_id: object,
      order_status: 3,
    }, res => {
      if (res.data['rows'] != null && res.data['rows'].length > 0) {
        this.orderOptions = res.data['rows'];
      } else {
        this.msg.info('暂无数据');
      }
    });
  }

  //获取数组对象
  get arrayList() {
    return this.itemValidateForm.get('arrayList') as FormArray;
  }

  //创建一行组件
  createRow() {
    return this.fb.group({
      company_id: [''],
      downstream_order_no: [''],
      service_id: [''],
      product_id: [''],
      product_unit: [''],
      scene_bind: [null],
      product_number: [1],
      effective_month: [0],
      single_price: [null],
      vital_price: [null],
      productOptions: [[]],
      disabled: [false],
    });
  }

  //增加一行事件
  addBtn() {
    this.arrayList.push(this.createRow());
  }

  // 删除一行事件
  delBtn(index: number) {
    if (this.arrayList.controls[index]['value']['product_number']) {
      this.arrayList.removeAt(index);
    }
  }

  //保存数据
  saveData() {
    this.purchaseItems = []
    this.arrayList.controls.forEach((item, index) => {
      console.log(item)
      this.purchaseItems.push(item['value'])
    })
    console.log(this.purchaseItems)
  }

  //清空数据
  clearData() {
    this.arrayList.controls.forEach(item => {
      item.patchValue({
        service_id: '',
        product_id: '',
        product_number: '',
      });
    });
  }


  add_dept = false;
  edit_dept = false;
  delete_dept = false;
  add_user = true;
  edit_user = false;
  delete_user = false;

  loadAccess() {
    console.log('加载页面操作权限');
    // let tempArr = JSON.parse(localStorage.getItem('access'));
    // console.log(tempArr);
    // this.add_dept = tempArr.findIndex(v => v.name == 'add_dept') != -1;
    // this.edit_dept = tempArr.findIndex(v => v.name == 'edit_dept') != -1;
    // this.delete_dept = tempArr.findIndex(v => v.name == 'delete_dept') != -1;
    // this.add_user = tempArr.findIndex(v => v.name == 'add_user') != -1;
    // this.edit_user = tempArr.findIndex(v => v.name == 'edit_user') != -1;
    // this.delete_user = tempArr.findIndex(v => v.name == 'delete_user') != -1;
  }
}

<nz-card>
  <div id="param-area">
    <div id="param-option">
      <input class="param-select" type="text" nz-input placeholder="键入关键字搜索" [(ngModel)]="keyword"/>
      <nz-select class="param-select" [(ngModel)]="msgType" [nzPlaceHolder]="'请选择消息类型'">
        <nz-option *ngFor="let s of msgTypes" [nzValue]="s.value" [nzLabel]="s.label"></nz-option>
      </nz-select>
    </div>
    <div id="operation">
      <div style=" margin-right: 5%;">
        <button nz-button [nzType]="'default'" (click)="reset()">重置</button>
      </div>
      <div style="margin-right: 5%">
        <button nz-button [nzType]="'primary'" (click)="loadObjectData()">搜索</button>
      </div>
      <div>
        <button nz-button [nzType]="'default'" (click)="preAdd()">发布公告</button>
      </div>
    </div>
  </div>
  <div id="table-data">
    <nz-table
      nzSize="middle"
      nzTemplateMode
      [nzData]="objects"
      [(nzPageSize)]="size"
      [nzTotal]="total"
      [(nzPageIndex)]="page"
      [nzFrontPagination]="false"
      (nzPageIndexChange)="indexChange()"
      (nzPageSizeChange)="indexChange()"
      [nzShowSizeChanger]="true"
      [nzPageSizeOptions]="[10,20,30,40,50]"
      [nzShowTotal]="totalTemplate"
    >
      <thead>
      <tr style="height: 5.5vh">
        <th>标题</th>
        <th>详情</th>
        <th>消息类型</th>
        <th>发布时间</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let role of objects" style="height: 5vh">
        <td>{{role.title}}</td>
        <td>{{role.context}}</td>
        <td>{{role.msg_type == '1' ? '人工' : '系统'}}</td>
        <td>{{role.create_time.slice(0, 10)}}</td>
      </tr>
      </tbody>
    </nz-table>
    <ng-template #totalTemplate>共 <span>{{ total }}</span>
      条
    </ng-template>
  </div>
</nz-card>
<!--发布公告-->
<nz-modal
  [(nzVisible)]="addIsVisible"
  nzTitle="发布公告"
  (nzOnCancel)="addCancel()"
  (nzOnOk)="addCommit()"
  nzWidth="50%">
  <form [formGroup]="validateForm">
    <nz-form-item nzFlex>
      <nz-form-label style="width: 15%; " nzRequired>标题</nz-form-label>
      <nz-form-control style="width: 70%">
        <input nz-input placeholder="请输入公告标题" formControlName="title"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item nzFlex>
      <nz-form-label style="width: 15%; " nzRequired>内容</nz-form-label>
      <nz-form-control style="width: 70%">
        <textarea rows="4" nz-input placeholder="请输入公告内容" formControlName="context"></textarea>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item nzFlex>
      <nz-form-label style="width: 15%" nzRequired>可见人员</nz-form-label>
      <nz-form-control style="width: 70%">
        <nz-tree-select
          [nzNodes]="nodes"
          [nzMultiple]="true"
          [nzMaxTagCount]=3
          nzShowSearch
          nzPlaceHolder="请选择可见人员"
          formControlName="visibleUserIds"
          nzCheckable
          (nzTreeCheckBoxChange)="onTreeCheckBoxChange($event)"
        >
        </nz-tree-select>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-modal>

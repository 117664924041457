import {Component, ViewChild, OnInit, Inject, LOCALE_ID} from '@angular/core';
import {Router} from '@angular/router';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzModalService} from 'ng-zorro-antd/modal';
import {selfHttp} from "@src/app/httpservice/http.service";
import {HttpHeaders} from '@angular/common/http';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NzTreeComponent} from 'ng-zorro-antd/tree';
import {formatDate} from "@angular/common";

interface DailyConsumption {
  company_name: string,
  start_date: string,
  end_date: string,
  begin_balance: number,
  collection_balance: number,
  deduction_balance: number,
  refund_balance: number,
  end_balance: number,
  row_type: number,//0-单条数据，1-汇总数据
}

interface ProcessedData {
  row_type: number,//行类型：0-数据，1-小计（服务统计），2-合计（公司统计），3-汇总（当前页面统计）
  company_id: number,
  start_date: string,
  end_date: string,
  company_name: string,
  service_id: number,
  service_name: string,
  product_id: number,
  product_name: string,
  begin_balance: number,
  begin_number: number,
  collection_balance: number,
  collection_number: number,
  deduction_balance: number,
  deduction_number: number,
  refund_balance: number,
  refund_number: number,
  end_balance: number,
  end_number: number,
  // company_column_merge: boolean,
  // service_column_merge: boolean,
  company_column_rowspan: number,
  service_column_rowspan: number,
  sub_begin_balance: number,
  sub_collection_balance: number,
  sub_deduction_balance: number,
  sub_refund_balance: number,
  sub_end_balance: number,
  total_begin_balance: number,
  total_collection_balance: number,
  total_deduction_balance: number,
  total_refund_balance: number,
  total_end_balance: number,
  collect_begin_balance: number,
  collect_collection_balance: number,
  collect_deduction_balance: number,
  collect_refund_balance: number,
  collect_end_balance: number,
  object_index: number,//每一行综合数据的index，判别新对象开始
  service_index: number,//每行索引，只有0和1，0-代表有服务名称列，其余没有
}

@Component({
  selector: 'bill',
  templateUrl: './bill.component.html',
  styleUrls: ['./bill.component.css']
})


export class BillComponent implements OnInit {
  tmpBillValidateForm: FormGroup;
  //查询相关
  currentTabIndex = 0;
  isLoading: false;
  // 搜索相关
  /*
    tab0:客户实时数据
   */
  dailyConsumptionRadio = "current_month";
  dailyConsumptionRanges = [];
  dailyConsumptionStartDate = '';
  dailyConsumptionEndDate = '';
  dailyConsumptionCascader = []
  dailyConsumptionServiceId = 0;
  dailyConsumptionProductId = 0;
  dailyConsumptionKeyword = '';
  dailyConsumptionPage = 1;
  dailyConsumptionSize = 10;
  tmpDailyConsumptionObjects = [];
  tmpDailyConsumptionVital = null;
  dailyConsumptionObjects = [];
  dailyConsumptionTotal = 0;
  /*
  tab1:量价统计
   */
  dailyVitalRadio = "current_month";
  dailyVitalRanges = [];
  dailyVitalStartDate = '';
  dailyVitalEndDate = '';
  dailyVitalCascader = []
  dailyVitalServiceId = 0;
  dailyVitalProductId = 0;
  dailyVitalKeyword = '';
  dailyVitalPage = 1;
  dailyVitalSize = 10;
  dailyVitalObjects = [];
  dailyVitalTotal = 0;
  /*
  tab2:账单流水
   */
  billFlowRadio = 'current'
  billTimeRangeValues = [];
  billFlowStartTime = '';
  billFlowEndTime = '';
  billFlowCompanyId = 0;
  billFlowCascader = []
  billFlowServiceId = 0;
  billFlowProductId = 0;
  billFlowKeyword = '';
  billFlowPage = 1;
  billFlowSize = 10;
  billFlowObjects = [];
  billFlowTotal = 0;
  /*
  tab3:账单
   */
  billRadio = 'current'
  billDateValue = ''
  billDate = '';
  billCheckStatus = 0;
  billKeyword = '';
  billPage = 1;
  billSize = 5;
  billObjects = [];
  billTotal = 0;
  /*
  tab4:临时账单
   */
  tmpBillRadio = 'current'
  tmpBillDateValue = ''
  tmpBillDate = '';
  tmpBillCheckStatus = 0;
  tmpBillKeyword = '';
  tmpBillPage = 1;
  tmpBillSize = 5;
  tmpBillObjects = [];
  tmpBillTotal = 0;
  /*
  tab5:账单稽核
   */
  billCheckRadio = 'current'
  billCheckTimeRangeValues = [];
  billCheckStartTime = '';
  billCheckEndTime = '';
  billCheckType = 0;
  billCheckKeyword = '';
  billCheckPage = 1;
  billCheckSize = 5;
  billCheckObjects = [];
  billCheckTotal = 0;
  selectOptions = [
    {'label': '客户开户', value: 1},
    {'label': '客户充值', value: 2},
    {'label': '客户退款', value: 3},
    {'label': '客户开票', value: 4},
    {'label': '内部采购', value: 5},
  ];
  companyOptions = []

  constructor(public router: Router,
              public fb: FormBuilder,
              public http: selfHttp,
              public modalService: NzModalService,
              public msg: NzMessageService,
              @Inject(LOCALE_ID) public locale: string,
  ) {
  }

  ngOnInit() {
    this.loadServiceTree()
    this.loadDailyConsumptionObject()
    this.buildForm()
  }

  buildForm() {
    this.tmpBillValidateForm = this.fb.group({
      company_id: [0],
      serviceProducts: [[]],
      service_id: [0],
      product_id: [0],
      tmpBillDates: [[]],
      begin_date: [],
      end_date: [],
    });
  }

  tabChange(object: any) {
    this.currentTabIndex = object.index;
    switch (object.index) {
      case 0:
        this.loadDailyConsumptionObject();
        break
      case 1:
        this.loadVitalObject();
        break
      case 2:
        this.loadBillFlowObject();
        break
      case 3:
        this.loadBillObject();
        break
      case 4:
        this.loadTmpBillObject();
        break
      case 5:
        this.loadBillCheckObject();
        break
    }
    console.log(object)
  }

  tree = []

  loadServiceTree() {
    this.tree = [];
    this.http.get('http://oss-api.nositek.com/oss-api/pc/services/tree', {}, res => {
      if (res) {
        this.tree = res.data['rows'];
        console.log(this.tree)
      } else {
        this.tree = [];
      }
    });
  }

  /*
    tab0:客户实时统计
   */
  loadDailyConsumptionObject() {
    this.tmpDailyConsumptionObjects = [];
    this.http.get('http://oss-api.nositek.com/oss-api/pc/daily/consumptions', {
      page: this.dailyConsumptionPage,
      size: this.dailyConsumptionSize,
      start_date: this.dailyConsumptionStartDate,
      end_date: this.dailyConsumptionEndDate,
      date_range: this.dailyConsumptionRadio,
      service_id: this.dailyConsumptionServiceId,
      product_id: this.dailyConsumptionProductId,
      keyword: this.billFlowKeyword,
    }, res => {
      if (res.code == 2000) {
        this.tmpDailyConsumptionObjects = res.data['rows'];
        this.tmpDailyConsumptionVital = res.data['row'];
        this.processDailyConsumption(this.tmpDailyConsumptionObjects, this.tmpDailyConsumptionVital)
        this.dailyConsumptionTotal = Number(res.data['total']);
        if (this.dailyConsumptionTotal == 0) {
          this.msg.info('暂无数据')
        }
      } else {
        this.dailyConsumptionTotal = 0;
        this.msg.error(res.msg)
      }
    });
  }

  processDailyConsumption(arr: any[], object: any) {
    this.dailyConsumptionObjects = [];
    if (arr.length != 0) {
      arr.forEach((item) => {
        let singleObject: DailyConsumption = {
          company_name: item.company_name,
          start_date: item.start_date,
          end_date: item.end_date,
          begin_balance: item.begin_balance,
          collection_balance: item.collection_balance,
          deduction_balance: item.deduction_balance,
          refund_balance: item.refund_balance,
          end_balance: item.end_balance,
          row_type: 0,
        }
        this.dailyConsumptionObjects.push(singleObject)
      })
      let processedData: DailyConsumption = {
        company_name: '',
        start_date: '',
        end_date: '',
        begin_balance: object.begin_balance,
        collection_balance: object.collection_balance,
        deduction_balance: object.deduction_balance,
        refund_balance: object.refund_balance,
        end_balance: object.end_balance,
        row_type: 1,
      }
      this.dailyConsumptionObjects.push(processedData)
    }
    console.log(this.dailyConsumptionObjects)
  }

  dailyConsumptionDatePickChange(object: any) {
    if (object.length > 0) {
      this.dailyConsumptionStartDate = formatDate(object[0], 'yyyy-MM-dd', this.locale)
      this.dailyConsumptionEndDate = formatDate(object[1], 'yyyy-MM-dd', this.locale)
    } else {
      this.dailyConsumptionStartDate = '';
      this.dailyConsumptionEndDate = '';
    }
    this.loadDailyConsumptionObject();
  }

  onDailyConsumptionCascaderChanges(values: string[]): void {
    if (this.dailyConsumptionCascader) {
      this.dailyConsumptionServiceId = Number(values[0])
      this.dailyConsumptionProductId = Number(values[1])
      this.loadDailyConsumptionObject()
    }
  }

  dailyConsumptionReset() {
    this.dailyConsumptionRadio = "current_month";
    this.dailyConsumptionRanges = [];
    this.dailyConsumptionCascader = [];
    this.dailyConsumptionStartDate = '';
    this.dailyConsumptionEndDate = '';
    this.dailyConsumptionServiceId = 0;
    this.dailyConsumptionProductId = 0;
    this.dailyConsumptionKeyword = '';
    this.dailyConsumptionPage = 1;
    this.dailyConsumptionSize = 10;
    this.loadDailyConsumptionObject();
  }

  /*
      tab1:量价统计
     */
  tmpArr = []
  tmpRow: any;

  loadVitalObject() {
    this.tmpArr = [];
    this.tmpRow = null;
    this.dailyVitalObjects = []
    this.http.get('http://oss-api.nositek.com/oss-api/pc/daily/vital', {
      page: this.dailyVitalPage,
      size: this.dailyVitalSize,
      start_date: this.dailyVitalStartDate,
      end_date: this.dailyVitalEndDate,
      date_range: this.dailyVitalRadio,
      service_id: this.dailyVitalServiceId,
      product_id: this.dailyVitalProductId,
      keyword: this.dailyVitalKeyword,
    }, res => {
      if (res.code == 2000) {
        this.tmpArr = res.data['rows'];
        this.tmpRow = res.data['row']
        if (this.tmpArr != null) {
          console.log('走到这里')
          this.rowMerge(this.tmpArr, this.tmpRow)
        }
        this.dailyVitalTotal = Number(res.data['total']);
        if (this.dailyVitalTotal == 0) {
          this.msg.info('暂无数据')
        }
      } else {
        this.dailyVitalTotal = 0;
        this.msg.error(res.msg)
      }
    });
  }

  dailyVitalDatePickChange(object: any) {
    if (object.length > 0) {
      this.dailyVitalStartDate = formatDate(object[0], 'yyyy-MM-dd', this.locale)
      this.dailyVitalEndDate = formatDate(object[1], 'yyyy-MM-dd', this.locale)
    } else {
      this.dailyVitalStartDate = '';
      this.dailyVitalEndDate = '';
    }
    this.loadVitalObject();
  }

  onDailyVitalCascaderChanges(values: string[]): void {
    if (this.dailyVitalCascader) {
      this.dailyVitalServiceId = Number(values[0])
      this.dailyVitalProductId = Number(values[1])
      this.loadVitalObject()
    }
  }

  dailyVitalReset() {
    this.dailyVitalRadio = "current_month";
    this.dailyVitalRanges = [];
    this.dailyVitalCascader = [];
    this.dailyVitalStartDate = '';
    this.dailyVitalEndDate = '';
    this.dailyVitalServiceId = 0;
    this.dailyVitalProductId = 0;
    this.dailyVitalKeyword = '';
    this.dailyVitalPage = 1;
    this.dailyVitalSize = 10;
    this.loadVitalObject();
  }

  rowMerge(arr: any, row: any) {
    this.dailyVitalObjects = [];
    arr.forEach((companyItem) => {
      const services = companyItem.services
      services.forEach((serviceItem, serviceIndex) => {
        const products = serviceItem.products
        products.forEach((productItem, productIndex) => {
          const processedItem: ProcessedData = {
            row_type: 0,
            company_id: companyItem.company_id,
            start_date: companyItem.start_date,
            end_date: companyItem.end_date,
            company_name: companyItem.company_name,
            service_id: serviceItem.service_id,
            service_name: serviceItem.service_name,
            product_id: productItem.product_id,
            product_name: productItem.product_name,
            begin_balance: productItem.begin_balance,
            begin_number: productItem.begin_number,
            collection_balance: productItem.collection_balance,
            collection_number: productItem.collection_number,
            deduction_balance: productItem.deduction_balance,
            deduction_number: productItem.deduction_number,
            refund_balance: productItem.refund_balance,
            refund_number: productItem.refund_number,
            end_balance: productItem.end_balance,
            end_number: productItem.end_number,
            // company_column_merge: false,
            // service_column_merge: false,
            company_column_rowspan: 1,
            service_column_rowspan: 1,
            sub_begin_balance: 0,
            sub_collection_balance: 0,
            sub_deduction_balance: 0,
            sub_refund_balance: 0,
            sub_end_balance: 0,
            total_begin_balance: 0,
            total_collection_balance: 0,
            total_deduction_balance: 0,
            total_refund_balance: 0,
            total_end_balance: 0,
            collect_begin_balance: 0,
            collect_collection_balance: 0,
            collect_deduction_balance: 0,
            collect_refund_balance: 0,
            collect_end_balance: 0,
            object_index: 0,
            service_index: 0,
          }
          if (productIndex == 0) {
            processedItem.service_index = 0
            processedItem.service_column_rowspan = products.length + 1
            if (serviceIndex == 0) {
              processedItem.object_index = 0
              processedItem.company_column_rowspan += products.length + services.length
            } else {
              processedItem.object_index = 1
            }
          } else {
            processedItem.service_index = 1
          }
          this.dailyVitalObjects.push(processedItem)
          if (productIndex == products.length - 1) {
            let processedItem1: ProcessedData = {
              row_type: 1,
              company_id: 0,
              start_date: '',
              end_date: '',
              company_name: '',
              service_id: 0,
              service_name: serviceItem.service_name,
              product_id: 0,
              product_name: '',
              begin_balance: 0,
              begin_number: 0,
              collection_balance: 0,
              collection_number: 0,
              deduction_balance: 0,
              deduction_number: 0,
              refund_balance: 0,
              refund_number: 0,
              end_balance: 0,
              end_number: 0,
              // company_column_merge: false,
              // service_column_merge: false,
              company_column_rowspan: 1,
              service_column_rowspan: 1,
              sub_begin_balance: serviceItem.sub_begin_balance,
              sub_collection_balance: serviceItem.sub_collection_balance,
              sub_deduction_balance: serviceItem.sub_deduction_balance,
              sub_refund_balance: serviceItem.sub_refund_balance,
              sub_end_balance: serviceItem.sub_end_balance,
              total_begin_balance: 0,
              total_collection_balance: 0,
              total_deduction_balance: 0,
              total_refund_balance: 0,
              total_end_balance: 0,
              collect_begin_balance: 0,
              collect_collection_balance: 0,
              collect_deduction_balance: 0,
              collect_refund_balance: 0,
              collect_end_balance: 0,
              object_index: 1,
              service_index: 1,
            }
            this.dailyVitalObjects.push(processedItem1)
          }
          if (serviceIndex == services.length - 1 && productIndex == products.length - 1) {
            let processedItem2: ProcessedData = {
              row_type: 2,
              company_id: 0,
              start_date: '',
              end_date: '',
              company_name: '',
              service_id: 0,
              service_name: serviceItem.service_name,
              product_id: 0,
              product_name: '',
              begin_balance: 0,
              begin_number: 0,
              collection_balance: 0,
              collection_number: 0,
              deduction_balance: 0,
              deduction_number: 0,
              refund_balance: 0,
              refund_number: 0,
              end_balance: 0,
              end_number: 0,
              // company_column_merge: false,
              // service_column_merge: false,
              company_column_rowspan: 1,
              service_column_rowspan: 1,
              sub_begin_balance: 0,
              sub_collection_balance: 0,
              sub_deduction_balance: 0,
              sub_refund_balance: 0,
              sub_end_balance: 0,
              total_begin_balance: companyItem.total_begin_balance,
              total_collection_balance: companyItem.total_collection_balance,
              total_deduction_balance: companyItem.total_deduction_balance,
              total_refund_balance: companyItem.total_refund_balance,
              total_end_balance: companyItem.total_end_balance,
              collect_begin_balance: 0,
              collect_collection_balance: 0,
              collect_deduction_balance: 0,
              collect_refund_balance: 0,
              collect_end_balance: 0,
              object_index: 1,
              service_index: 1,
            }
            this.dailyVitalObjects.push(processedItem2)
          }

        })
      })
    })
    let processedItem3: ProcessedData = {
      row_type: 3,
      company_id: 0,
      start_date: '',
      end_date: '',
      company_name: '',
      service_id: 0,
      service_name: '',
      product_id: 0,
      product_name: '',
      begin_balance: 0,
      begin_number: 0,
      collection_balance: 0,
      collection_number: 0,
      deduction_balance: 0,
      deduction_number: 0,
      refund_balance: 0,
      refund_number: 0,
      end_balance: 0,
      end_number: 0,
      // company_column_merge: false,
      // service_column_merge: false,
      company_column_rowspan: 1,
      service_column_rowspan: 1,
      // index: 2,
      sub_begin_balance: 0,
      sub_collection_balance: 0,
      sub_deduction_balance: 0,
      sub_refund_balance: 0,
      sub_end_balance: 0,
      total_begin_balance: 0,
      total_collection_balance: 0,
      total_deduction_balance: 0,
      total_refund_balance: 0,
      total_end_balance: 0,
      collect_begin_balance: row.collect_begin_balance,
      collect_collection_balance: row.collect_collection_balance,
      collect_deduction_balance: row.collect_deduction_balance,
      collect_refund_balance: row.collect_refund_balance,
      collect_end_balance: row.collect_end_balance,
      object_index: 1,
      service_index: 1,
    }
    this.dailyVitalObjects.push(processedItem3)
    console.log(this.dailyVitalObjects)
  }

  /*
    tab2:账单流水
   */
  loadBillFlowObject() {
    this.http.get('http://oss-api.nositek.com/oss-api/pc/bill/flows', {
      page: this.billFlowPage,
      size: this.billFlowSize,
      start_time: this.billFlowStartTime,
      end_time: this.billFlowEndTime,
      month: this.billFlowRadio,
      company_id: this.billFlowCompanyId,
      service_id: this.billFlowServiceId,
      product_id: this.billFlowProductId,
      keyword: this.billFlowKeyword,
    }, res => {
      if (res.code == 2000) {
        this.billFlowObjects = res.data['rows'];
        this.billFlowTotal = Number(res.data['total']);
        if (this.billFlowTotal == 0) {
          this.msg.info('暂无数据')
        }
      } else {
        this.billFlowTotal = 0;
        this.msg.error(res.msg)
      }
    });
  }

  billFlowTimePickChange(object: any) {
    if (object.length > 0) {
      this.billFlowStartTime = formatDate(object[0], 'yyyy-MM-dd HH:mm:ss', this.locale)
      this.billFlowEndTime = formatDate(object[1], 'yyyy-MM-dd HH:mm:ss', this.locale)
    } else {
      this.billFlowStartTime = '';
      this.billFlowEndTime = '';
    }
    this.loadBillFlowObject();
  }

  onBillFlowCascaderChanges(values: string[]): void {
    if (this.billFlowCascader) {
      this.billFlowServiceId = Number(values[0])
      this.billFlowProductId = Number(values[1])
      this.loadBillFlowObject()
    }
  }


  billFlowReset() {
    this.billFlowPage = 1;
    this.billFlowSize = 10;
    this.billFlowRadio = 'current'
    this.billFlowStartTime = '';
    this.billFlowEndTime = '';
    this.billFlowCompanyId = 0;
    this.billFlowServiceId = 0;
    this.billFlowProductId = 0;
    this.billFlowKeyword = '';
    this.billTimeRangeValues = [];
    this.billFlowCascader = [];
    this.loadBillFlowObject();
  }

  /*
    tab3:账单
 */
  loadBillObject() {
    this.http.get('http://oss-api.nositek.com/oss-api/pc/bills', {
      page: this.billPage,
      size: this.billSize,
      date_range: this.billRadio,
      bill_period: this.billDate,
      check_type: this.billCheckStatus,
      keyword: this.billKeyword,
    }, res => {
      if (res.code == 2000) {
        this.billObjects = res.data['rows'];
        this.billTotal = Number(res.data['total']);
        if (this.billTotal == 0) {
          this.msg.info('暂无数据')
        }
      } else {
        this.billTotal = 0;
        this.msg.error(res.msg)
      }
    });
  }

  billPointDateChange(object: any) {
    if (object) {
      this.billDate = formatDate(object, 'yyyy-MM', this.locale)
    } else {
      this.billDate = '';
    }
    this.loadBillObject();
  }

  billReset() {
    this.billRadio = 'current'
    this.billDate = '';
    this.billDateValue = '';
    this.billCheckStatus = 0;
    this.billKeyword = '';
    this.billPage = 1;
    this.billSize = 5;
    this.loadBillObject()
  }

  /*
    tab4:临时账单
 */
  loadTmpBillObject() {
    this.http.get('http://oss-api.nositek.com/oss-api/pc/bills/tmp', {
      page: this.billPage,
      size: this.billSize,
      date_range: this.billRadio,
      bill_period: this.billDate,
      check_type: this.billCheckStatus,
      keyword: this.billKeyword,
    }, res => {
      if (res.code == 2000) {
        this.tmpBillObjects = res.data['rows'];
        this.tmpBillTotal = Number(res.data['total']);
        if (this.tmpBillTotal == 0) {
          this.msg.info('暂无数据')
        }
      } else {
        this.tmpBillTotal = 0;
        this.msg.error(res.msg)
      }
    });
  }

  tmpBillPointDateChange(object: any) {
    if (object) {
      this.tmpBillDate = formatDate(object, 'yyyy-MM', this.locale)
    } else {
      this.tmpBillDate = '';
    }
    this.loadTmpBillObject();
  }

  tmpBillReset() {
    this.tmpBillRadio = 'current'
    this.tmpBillDate = '';
    this.tmpBillDateValue = '';
    this.tmpBillCheckStatus = 0;
    this.tmpBillKeyword = '';
    this.tmpBillPage = 1;
    this.tmpBillSize = 5;
    this.loadTmpBillObject()
  }

  /*
    tab5:账单稽核
     */
  loadBillCheckObject() {
    this.http.get('http://oss-api.nositek.com/oss-api/pc/bill/reconciliation_audits', {
      page: this.billCheckPage,
      size: this.billCheckSize,
      date_range: this.billCheckRadio,
      start_time: this.billCheckStartTime,
      end_time: this.billCheckEndTime,
      check_type: this.billCheckType,
      keyword: this.billCheckKeyword,
    }, res => {
      if (res.code == 2000) {
        this.billCheckObjects = res.data['rows'];
        this.billCheckTotal = Number(res.data['total']);
        if (this.billCheckTotal == 0) {
          this.msg.info('暂无数据')
        }
      } else {
        this.billCheckTotal = 0;
        this.msg.error(res.msg)
      }
    });
  }

  billCheckTimePickChange(object: any) {
    if (object.length > 0) {
      this.billCheckStartTime = formatDate(object[0], 'yyyy-MM-dd HH:mm:ss', this.locale)
      this.billCheckEndTime = formatDate(object[1], 'yyyy-MM-dd HH:mm:ss', this.locale)
    } else {
      this.billCheckStartTime = '';
      this.billCheckEndTime = '';
    }
    this.loadBillCheckObject();
  }

  billCheckReset() {
    this.billCheckRadio = 'current'
    this.billCheckTimeRangeValues = [];
    this.billCheckStartTime = '';
    this.billCheckEndTime = '';
    this.billCheckType = 0;
    this.billCheckKeyword = '';
    this.billCheckPage = 1;
    this.billCheckSize = 5;
    this.loadBillCheckObject()
  }

  addTmpBillIsVisible = false;

  preAddTmpBill() {
    this.addTmpBillIsVisible = true;
    this.loadCompanyOptionData();
    this.tmpBillValidateForm.reset();
  }

  onAddTmpBillCascaderChanges(values: string[]): void {
    if (values) {
      this.tmpBillValidateForm.patchValue({
        service_id: Number(values[0]),
        product_id: Number(values[1])
      })
      console.log(this.tmpBillValidateForm.value)
    }
  }

  addTmpBillDatePickChange(object: any) {
    if (object != null) {
      this.tmpBillValidateForm.patchValue({
        begin_date: formatDate(object[0], 'yyyy-MM-dd', this.locale),
        end_date: formatDate(object[1], 'yyyy-MM-dd', this.locale)
      })
    } else {
      this.tmpBillValidateForm.patchValue({
        begin_date: '',
        end_date: '',
      })
    }
    console.log(this.tmpBillValidateForm.value)
  }

  loadCompanyOptionData() {
    this.companyOptions = [];
    this.http.get('http://oss-api.nositek.com/oss-api/pc/companies', {}, res => {
      if (res.data['rows'] != null) {
        this.companyOptions = res.data['rows'];
      } else {
        this.companyOptions = [];
        this.msg.info('暂无数据');
      }
    });
  }

  addTmpBillCommit() {
    this.addTmpBillIsVisible = false;
  }

  addTmpBillCancel() {
    this.addTmpBillIsVisible = false;
  }


  preDetail(object: any) {
    // this.viewIsVisible = true
  }

  preEdit(object: any) {
    // this.editIsVisible = true;
    // this.validateForm.reset();
    // this.validateForm.patchValue({
    //   id: object.id,
    //   apply_type: object.apply_type,
    //   apply_event_id: object.apply_event_id,
    //   audit_status: true,
    // });
  }

  // 查询Channels表格数据
  loadChannelsData() {

  }

// 查询交易所
  loadExchangesData() {

  }

// 查询队列
  loadQueuesData() {

  }
}

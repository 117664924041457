import {Component, OnInit, AfterViewInit} from '@angular/core';
import {NzMessageService} from 'ng-zorro-antd/message';
import {selfHttp} from '../../httpservice/http.service';
import {WebsocketService} from '../../common/websocket/websocket.service';
import {ActivatedRoute, Params} from '@angular/router';
import jwtDecode from "jwt-decode";

@Component({
  selector: 'home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css'],
})
export class HomePageComponent implements OnInit, AfterViewInit {
  msgs = [];                      // 消息列表
  msg;                            // 发送的消息内容
  error: any;                         // 异常信息
  completed = false;                  // 发送完成
  users = [];                         // 登陆的用户
  currentUser;                        // 当前用户

  spirit = 'image://../../../assets/cpu.jpeg';
  machineData = {
    boot_time: '',
    kernel_version: '',
    family: '',
    platform: '',
    version: '',
  };
  osData = {
    goos: '',
    numCpu: '',
    compiler: '',
    goVersion: '',
    numGoroutine: '',
  };
  cpuOption: any;
  cpuEcharts: any;
  memOption: any;
  memEcharts: any;
  memExchangeOption: any;
  memExchangeEcharts: any;
  processOption: any;
  processEcharts: any;
  ioOption: any;
  ioEcharts: any;
  diskOption: any;
  diskEcharts: any;

  cpuYAxisData = [];
  cpuData = [];

  constructor(
    private webSocketService: WebsocketService,
    private activatedRoute: ActivatedRoute,
    public http: selfHttp,
    public message: NzMessageService,
  ) {
    // 从路由中获取参数
    // this.activatedRoute.params.subscribe((params: Params) => {
    //   this.currentUser = params['id'];
    // });
    // this.initOption();
    this.loadSetting();
  }

  ngOnInit(): void {
    // this.loadObjectData();
    this.loadSetting();
  }

  loadSetting() {
    this.http.get('http://oss-api.nositek.com/oss-api/pc/user/setting', {
      user_id: jwtDecode(localStorage.getItem('token'))['user_id'],
    }, res => {
      if (res.data['row'] != null) {
        localStorage.setItem('config', JSON.stringify(res.data['row']))
      }
    })
  }

  ngAfterViewInit(): void {
    // 连接websocket
    // this.webSocketService.connect(`ws://http://localhost:88999/loccay/ws/machine`);
    // 接收消息
    // this.webSocketService.msgSubject.subscribe(
    //   data => {
    //     // 根据目标，动态实时更新相关数据
    //     switch (data.target) {
    //       case 'cpu':
    //         this.cpuData = [];
    //         this.cpuYAxisData = [];
    //         for (let i = -1; i < data.data.single_percent.length; i++) {
    //           if (i == -1) {
    //             this.cpuData.push(
    //               {
    //                 value: data.data.total_percent.toFixed(3),
    //               },);
    //             this.cpuYAxisData.push('总占用');
    //           } else {
    //             this.cpuData.push({
    //               value: data.data.single_percent[i].toFixed(3),
    //             });
    //             this.cpuYAxisData.push('CPU' + i);
    //           }
    //         }
    //         this.cpuOption.series[0].data = this.cpuData;
    //         this.cpuOption.yAxis.data = this.cpuYAxisData;
    //         this.cpuEcharts.setOption(this.cpuOption);
    //         break;
    //       case 'mem':
    //         let tmpArr1 = [];
    //         let tmpArr2 = [];
    //         tmpArr1.push(
    //           {value: data.data.usedMb, name: '已用'},
    //           {value: data.data.totalMb - data.data.usedMb, name: '剩余'},
    //         );
    //         tmpArr2.push(
    //           {value: data.data.usedMb},
    //           {value: data.data.totalMb - data.data.usedMb},
    //         );
    //         this.memExchangeOption.series[0].data = tmpArr1;
    //         this.memExchangeOption.series[1].data = tmpArr2;
    //         this.memExchangeEcharts.setOption(this.memExchangeOption);
    //
    //
    //         let tmpArr3 = [];
    //         tmpArr3.push(
    //           {value: data.data.swap_memory_stat.pgin / 1024 / 1024, name: '写入'},
    //           {value: data.data.swap_memory_stat.pgout / 1024 / 1024, name: '读取'},
    //         );
    //
    //         if (this.ioOption.series[0].type === 'pie') {
    //           this.ioOption.series[0] = {
    //             type: 'pie',
    //             id: 'distribution',
    //             radius: ['30%', '80%'],
    //             label: {
    //               show: false
    //             },
    //             emphasis: {
    //               label: {
    //                 show: true,
    //                 fontSize: '40',
    //                 fontWeight: 'bold'
    //               }
    //             },
    //             labelLine: {
    //               show: false
    //             },
    //             // universalTransition: true,
    //             data: tmpArr3
    //           };
    //         } else {
    //           this.ioOption.series[0] = {
    //             type: 'custom',
    //             id: 'distribution',
    //             radius: ['30%', '80%'],
    //             label: {
    //               show: false
    //             },
    //             emphasis: {
    //               label: {
    //                 show: true,
    //                 fontSize: '40',
    //                 fontWeight: 'bold'
    //               }
    //             },
    //             labelLine: {
    //               show: false
    //             },
    //             data: tmpArr3
    //           };
    //         }
    //
    //         this.ioEcharts.setOption(this.ioOption);
    //         break;
    //       case 'disk':
    //         this.diskOption.series[0].data[0].value = data.data.usedMb;
    //         this.diskEcharts.setOption(this.diskOption);
    //     }
    //
    //   },
    //   err => this.error = err,
    //   () => this.completed = true
    // );
  }


  initOption() {
    this.cpuOption = {
      title: {
        text: 'CPU实况'
      },
      color: [
        '#2db7f5',
        '#f50',
        '#87d068',
        '#108ee9',
        'geekblue',
        'magenta',
        'magenta',
        'red',
        'green',
        'rgb(232,41,139)',// 'pink',
        'rgb(249,214,20)',// 'yellow',
        'rgb(249,129,22)',// 'orange',
        'rgb(20,186,186)',//'cyan',
        'rgb(23,133,255)',//'blue',
        'rgb(103,41,203)',// 'purple',
        'rgb(249,74,26)',// 'volcano',
        'rgb(249,163,20)',// 'gold',
        'rgb(150,212,18)',// 'lime',
      ],

      tooltip: {
        trigger: 'item',
        formatter: '{b}: {c}%'
      },
      grid: {
        containLabel: true,
        left: 20
      },
      yAxis: {
        data: this.cpuYAxisData,
        inverse: true,
        axisLine: {show: false},
        axisTick: {show: false},
        axisLabel: {
          margin: 30,
          fontSize: 14
        },
        axisPointer: {
          label: {
            show: true,
            margin: 30
          }
        }
      },
      xAxis: {
        splitLine: {show: false},
        axisLabel: {show: false},
        axisTick: {show: false},
        axisLine: {show: false}
      },
      animationDurationUpdate: 500,
      series: [{
        name: '2015',
        id: 'bar1',
        type: 'bar',
        label: {
          show: true,
          position: 'right',
          formatter: '{c}%',
          offset: [5, 0],
          textStyle: {
            fontSize: 16
          }
        },
        symbolRepeat: true,
        symbolSize: ['80%', '60%'],
        barCategoryGap: '40%',
        // universalTransition: {
        //   enabled: true,
        //   delay: function(idx, total) {
        //     return (idx / total) * 1000;
        //   }
        // },
        data: this.cpuData,
      }]
    };
    this.diskOption = {
      title: {
        text: '硬盘实况',
      },
      label: {
        show: false,
        position: 'center'
      },
      series: [{
        type: 'gauge',
        startAngle: 180,
        endAngle: 0,
        min: 0,
        max: 61000,
        radius: '100%',
        splitNumber: 10,
        itemStyle: {
          color: '#58D9F9',
          shadowColor: 'rgba(0,138,255,0.45)',
          shadowBlur: 10,
          shadowOffsetX: 2,
          shadowOffsetY: 2
        },
        progress: {
          show: true,
          roundCap: true,
          width: 8
        },
        pointer: {
          icon: 'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z',
          length: '60%',
          width: 10,
          offsetCenter: [0, '5%']
        },
        axisLine: {
          roundCap: true,
          lineStyle: {
            width: 10
          }
        },
        axisTick: {
          splitNumber: 2,
          lineStyle: {
            width: 2,
            color: '#999'
          }
        },
        splitLine: {
          length: 5,
          lineStyle: {
            width: 3,
            color: '#999'
          }
        },
        axisLabel: {
          distance: 20,
          color: '#999',
          fontSize: 10
        },
        title: {
          show: false
        },
        detail: {
          backgroundColor: '#fff',
          borderColor: '#999',
          borderWidth: 2,
          width: '40%',
          lineHeight: 25,
          height: 15,
          borderRadius: 8,
          offsetCenter: [0, '35%'],
          valueAnimation: true,
          formatter: function (value) {
            return '{value|' + value.toFixed(0) + '}{unit|Mb}';
          },
          rich: {
            value: {
              fontSize: 12,
              fontWeight: 'bolder',
              color: '#777'
            },
            unit: {
              fontSize: 12,
              color: '#999',
              padding: [0, 0, -10, -15]
            }
          }
        },
        data: [{
          value: 1000
        }]
      }]
    };
    this.ioOption = {
      title: {
        text: 'I/O实况',
      },
      color: [
        '#5470c6',
        '#91cc75',
        '#fac858',
        '#ee6666',
        '#73c0de',
        '#3ba272',
        '#fc8452',
        '#9a60b4',
        '#ea7ccc'
      ],
      tooltip: {
        trigger: 'item'
      },
      label: {
        show: false,
        position: 'center'
      },
      emphasis: {
        label: {
          show: true,
          fontSize: '40',
          fontWeight: 'bold'
        }
      },
      series: [
        {
          type: 'pie',
          radius: ['40%', '70%'],
          label: {
            show: false,
            position: 'center'
          },
          data: []
        }
      ]
    };
    this.memExchangeOption = {
      title: {
        text: '内存实况',
      },
      color: [
        '#2db7f5',
        'rgb(249,163,20)',// 'gold',
      ],
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b}: {c} MB({d}%)'
      },
      // legend: {
      //   data: ['已用', '剩余']
      // },
      series: [
        {
          name: ' 内存',
          type: 'pie',
          selectedMode: 'single',
          radius: [0, '30%'],
          labelLine: {
            show: false
          },
          data: [
            {value: 1548, name: '已用'},
            {value: 775, name: '剩余'},
          ]
        },
        {
          name: '内存',
          type: 'pie',
          radius: ['45%', '60%'],
          label: {
            position: 'inner',
            fontSize: 14,
          },
          labelLine: {
            show: false
          },
          data: [
            {value: 1548, name: '已用'},
            {value: 775, name: '剩余'}
          ]
        },
      ]
    };
  }

  onCpuChartInit(ec) {
    console.log('初始化echarts');
    this.cpuEcharts = ec;
  }

  onMemChartInit(ec) {
    console.log('初始化echarts');
    this.memEcharts = ec;
  }

  onMemExchangeChartInit(ec) {
    console.log('初始化echarts');
    this.memExchangeEcharts = ec;
  }

  onIoChartInit(ec) {
    console.log('初始化echarts');
    this.ioEcharts = ec;
  }

  onDiskChartInit(ec) {
    this.diskEcharts = ec;
  }

  loadObjectData() {
    this.http.get('http://oss-api.nositek.com/oss-api/pcmachine', {}, res => {
      console.log(res.data);
      this.machineData = res.data['machine_data'];
      this.osData = res.data['os_data'];
    });
  }
}


// this.cpuOption = {
//   title: {
//     text: 'CPU实况',
//   },
//   color: [
//     '#2db7f5',
//     '#f50',
//     '#87d068',
//     '#108ee9',
//     'geekblue',
//     'magenta',
//     'magenta',
//     'red',
//     'green',
//     'rgb(232,41,139)',// 'pink',
//     'rgb(249,214,20)',// 'yellow',
//     'rgb(249,129,22)',// 'orange',
//     'rgb(20,186,186)',//'cyan',
//     'rgb(23,133,255)',//'blue',
//     'rgb(103,41,203)',// 'purple',
//     'rgb(249,74,26)',// 'volcano',
//     'rgb(249,163,20)',// 'gold',
//     'rgb(150,212,18)',// 'lime',
//   ],
//   tooltip: {
//     trigger: 'item',
//     formatter: '{b}: {c}%'
//   },
//   // legend: {
//   //   show: true,
//   //   data: ['总占用', 'cpu1', 'cpu2']
//   // },
//   series: [{
//     type: 'gauge',
//     startAngle: 90,
//     endAngle: -270,
//     pointer: {
//       show: false
//     },
//     progress: {
//       show: true,
//       overlap: false,
//       roundCap: true,
//       clip: false,
//       itemStyle: {
//         borderWidth: 1,
//         borderColor: '#464646'
//       }
//     },
//     axisLine: {
//       lineStyle: {
//         width: 30
//       }
//     },
//     splitLine: {
//       show: false,
//     },
//     axisTick: {
//       show: false
//     },
//     axisLabel: {
//       show: false,
//       distance: 100
//     },
//     data: [{
//       value: 20,
//       name: '总占用',
//       title: {
//         offsetCenter: ['0%', '0%']
//       },
//       detail: {
//         valueAnimation: true,
//         offsetCenter: ['0%', '20%']
//       }
//     },
//       {
//         value: 40,
//         name: 'cpu1',
//         title: {
//           show: false,
//         },
//         detail: {
//           show: false,
//         }
//       },
//       {
//         value: 60,
//         name: 'cpu2',
//         title: {
//           show: false,
//         },
//         detail: {
//           show: false,
//         }
//       }
//     ],
//     title: {
//       fontSize: 12
//     },
//     detail: {
//       width: 60,
//       // height: 20,
//       fontSize: 12,
//       color: 'auto',
//       // borderColor: 'auto',
//       // borderRadius: 20,
//       // borderWidth: 1,
//       formatter: '{value}%'
//     }
//   }]
// };

// this.ioOption = {
//   title: {
//     text: 'I/O实况',
//   },
//   color: [
//     '#2db7f5',
//     '#f50',
//     '#87d068',
//     '#108ee9',
//     'geekblue',
//     'magenta',
//     'magenta',
//     'red',
//     'green',
//     'rgb(232,41,139)',// 'pink',
//     'rgb(249,214,20)',// 'yellow',
//     'rgb(249,129,22)',// 'orange',
//     'rgb(20,186,186)',//'cyan',
//     'rgb(23,133,255)',//'blue',
//     'rgb(103,41,203)',// 'purple',
//     'rgb(249,74,26)',// 'volcano',
//     'rgb(249,163,20)',// 'gold',
//     'rgb(150,212,18)',// 'lime',
//   ],
//   tooltip: {
//     trigger: 'item',
//     formatter: '{b}: {c}%'
//   },
//   // legend: {
//   //   show: true,
//   //   data: ['总占用', 'cpu1', 'cpu2']
//   // },
//   series: [{
//     type: 'gauge',
//     startAngle: 90,
//     endAngle: -270,
//     pointer: {
//       show: false
//     },
//     progress: {
//       show: true,
//       overlap: false,
//       roundCap: true,
//       clip: false,
//       itemStyle: {
//         borderWidth: 1,
//         borderColor: '#464646'
//       }
//     },
//     axisLine: {
//       lineStyle: {
//         width: 30
//       }
//     },
//     splitLine: {
//       show: false,
//     },
//     axisTick: {
//       show: false
//     },
//     axisLabel: {
//       show: false,
//       distance: 100
//     },
//     data: [{
//       value: 20,
//       name: '写入',
//       title: {
//         offsetCenter: ['0%', '0%']
//       },
//       detail: {
//         valueAnimation: true,
//         offsetCenter: ['0%', '20%']
//       }
//     },
//       {
//         value: 40,
//         name: '读取',
//         title: {
//           show: false,
//         },
//         detail: {
//           show: false,
//         }
//       }
//     ],
//     title: {
//       fontSize: 12
//     },
//     detail: {
//       width: 60,
//       // height: 20,
//       fontSize: 12,
//       color: 'auto',
//       // borderColor: 'auto',
//       // borderRadius: 20,
//       // borderWidth: 1,
//       formatter: '{value}%'
//     }
//   }]
// };

// this.memOption = {
//   color: [],
//   title: {
//     text: '内存实况',
//   },
//   series: [
//     {
//       name: '访问来源',
//       type: 'pie',
//       radius: ['40%', '70%'],
//       avoidLabelOverlap: false,
//       itemStyle: {
//         borderRadius: 10,
//         borderColor: '#fff',
//         borderWidth: 2
//       },
//       label: {
//         show: false,
//         position: 'center'
//       },
//       emphasis: {
//         label: {
//           show: true,
//           fontSize: '40',
//           fontWeight: 'bold'
//         }
//       },
//       labelLine: {
//         show: false
//       },
//       data: [
//         {value: 1048, name: '搜索引擎', itemStyle: {color: 'cyan'}},
//         {value: 735, name: '直接访问', itemStyle: {color: '#87d068'}},
//         {value: 580, name: '邮件营销', itemStyle: {color: '#2db7f5'}},
//         {value: 484, name: '联盟广告', itemStyle: {color: 'lime'}},
//         {value: 300, name: '视频广告', itemStyle: {color: 'gold'}}
//       ]
//     }
//   ]
// };

// this.ioOption = {
//   title: {
//     text: 'I/O实况',
//   },
//   color: [
//     '#5470c6',
//     '#91cc75',
//     '#fac858',
//     '#ee6666',
//     '#73c0de',
//     '#3ba272',
//     '#fc8452',
//     '#9a60b4',
//     '#ea7ccc'
//   ],
//   radius: ['30%', '80%'],
//   series:[]
// };

// this.processOption = {
//   color: [],
//   title: {
//     text: '进程实况',
//   },
//   series: [
//     {
//       name: '访问来源',
//       type: 'pie',
//       radius: ['40%', '70%'],
//       avoidLabelOverlap: false,
//       itemStyle: {
//         borderRadius: 10,
//         borderColor: '#fff',
//         borderWidth: 2
//       },
//       label: {
//         show: false,
//         position: 'center'
//       },
//       emphasis: {
//         label: {
//           show: true,
//           fontSize: '40',
//           fontWeight: 'bold'
//         }
//       },
//       labelLine: {
//         show: false
//       },
//       data: [
//         {value: 1048, name: '搜索引擎', itemStyle: {color: 'cyan'}},
//         {value: 735, name: '直接访问', itemStyle: {color: '#87d068'}},
//         {value: 580, name: '邮件营销', itemStyle: {color: '#2db7f5'}},
//         {value: 484, name: '联盟广告', itemStyle: {color: 'lime'}},
//         {value: 300, name: '视频广告', itemStyle: {color: 'gold'}}
//       ]
//     }
//   ]
// };
// this.diskOption = {
//   color: [],
//   title: {
//     text: '硬盘实况',
//   },
//   series: [
//     {
//       name: '访问来源',
//       type: 'pie',
//       radius: ['40%', '70%'],
//       avoidLabelOverlap: false,
//       itemStyle: {
//         borderRadius: 10,
//         borderColor: '#fff',
//         borderWidth: 2
//       },
//       label: {
//         show: false,
//         position: 'center'
//       },
//       emphasis: {
//         label: {
//           show: true,
//           fontSize: '40',
//           fontWeight: 'bold'
//         }
//       },
//       labelLine: {
//         show: false
//       },
//       data: [
//         {value: 1048, name: '搜索引擎', itemStyle: {color: 'cyan'}},
//         {value: 735, name: '直接访问', itemStyle: {color: '#87d068'}},
//         {value: 580, name: '邮件营销', itemStyle: {color: '#2db7f5'}},
//         {value: 484, name: '联盟广告', itemStyle: {color: 'lime'}},
//         {value: 300, name: '视频广告', itemStyle: {color: 'gold'}}
//       ]
//     }
//   ]
// };

// onProcessChartInit(ec) {
//   console.log('初始化echarts');
//   this.processEcharts = ec;
// }

<nz-card>
  <div class="table-area">
    <div class="param-area">
      <nz-space class="table-query">
        <nz-space-item style="width: 100%">
          <nz-select [nzPlaceHolder]="'请选择客户'" [(ngModel)]="companyId" (ngModelChange)="loadObjectData()"
                     style="width: 100%">
            <nz-option *ngFor="let option of queryOptions" [nzValue]="option.id"
                       [nzLabel]="option.company_name"></nz-option>
          </nz-select>
        </nz-space-item>
        <nz-space-item>
          <button nz-button nzType="primary" (click)="loadObjectData()">搜索</button>
        </nz-space-item>
        <nz-space-item>
          <button nz-button [nzType]="'default'" (click)="reset()">重置</button>
        </nz-space-item>
      </nz-space>
      <div class="table-operation">
        <button nz-button nzType="primary" (click)="preOperate(1,null)">
          <i nz-icon nzType="plus" nzTheme="outline"></i>
          <span>新增配置</span>
        </button>
        <!--        <button nz-button nzType="default" (click)="preOperate(1,null)" style="margin-left: 8%">-->
        <!--          <i nz-icon nzType="undo" nzTheme="outline"></i>-->
        <!--          <span>误删恢复</span>-->
        <!--        </button>-->
      </div>
    </div>
    <div>
      <nz-table
        nzSize="small"
        [nzData]="objects"
        [(nzPageSize)]="size"
        [nzTotal]="total"
        [(nzPageIndex)]="page"
        [nzFrontPagination]="false"
        (nzPageIndexChange)="indexChange()"
        (nzPageSizeChange)="indexChange()"
        [nzShowSizeChanger]="true"
        [nzPageSizeOptions]="[5,10,15,20]"
        [nzShowTotal]="totalTemplate"
        [nzHideOnSinglePage]="true"
      >
        <ng-template #totalTemplate>共 <span style="color:cornflowerblue;margin:0 3px;">{{ total }}</span>
          条
        </ng-template>
        <thead style="background: yellow">
        <tr>
          <th nzWidth="150px">客户名称</th>
          <th nzWidth="100px">短信类型</th>
          <th nzWidth="150px">配置内容</th>
          <th nzWidth="100px">开通状态</th>
          <th nzWidth="100px">创建时间</th>
          <th nzWidth="100px">操作</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let object of objects">
          <td>{{ object.company_name }}</td>
          <td>{{ object.sms_type == 1 ? '文本短信' : '视频短信' }}</td>
          <td>{{ object.sms_config}}</td>
          <td>
            <nz-badge *ngIf="object.status==1" nzStatus="processing"></nz-badge>
            <nz-badge *ngIf="object.status==0" nzStatus="default"></nz-badge>
            {{  object.status == 1 ? '已开通' : '未开通'}}</td>
          <td>{{ object.create_time.slice(0, 10) }}</td>
          <td>
            <span><a (click)="preOperate(2,object)"><i nz-icon nzType="edit" nzTheme="outline"></i>编辑</a>&nbsp;&nbsp;&nbsp;</span>
          </td>
        </tr>
        </tbody>
      </nz-table>
    </div>
  </div>
</nz-card>

<nz-modal [(nzVisible)]="operateIsVisible" nzTitle="{{operateModalName}}" [nzFooter]="modalFooter"
          [nzMaskClosable]="true" (nzOnCancel)="operateCancel()">
  <form nz-form [formGroup]="validateForm">
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>客户名称</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="请选择客户!">
        <nz-select [nzPlaceHolder]="'请选择客户'" formControlName="company_id" nzDisabled="{{selectDisabled}}">
          <nz-option *ngFor="let option of queryOptions" [nzValue]="option.id"
                     [nzLabel]="option.company_name"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>短信类型</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="请选择短信类型!">
        <nz-select [nzPlaceHolder]="'短信类型'" formControlName="sms_type" nzDisabled="{{selectDisabled}}">
          <nz-option *ngFor="let option of smsTypeOptions" [nzValue]="option.value"
                     [nzLabel]="option.label"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>短信配置</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="请输入短信配置!">
        <textarea rows="4" nz-input placeholder="请输入短信配置" formControlName="sms_config"></textarea>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24">开通状态</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24">
        <nz-switch formControlName="status" nzCheckedChildren="已开通" nzUnCheckedChildren="未开通"></nz-switch>
      </nz-form-control>
    </nz-form-item>
  </form>
  <ng-template #modalFooter>
    <button nz-button nzType="default" (click)="operateCancel()">取消</button>
    <button nz-button nzType="primary" (click)="operateCommit()">确定</button>
  </ng-template>
</nz-modal>

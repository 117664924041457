<nz-card>
  <div class="table-area">
    <div class="param-area">
      <nz-space class="table-query">
        <nz-space-item>
          <nz-range-picker [nzFormat]="'yyyy-MM-dd'" [(ngModel)]="dateRangeValues"
                           (ngModelChange)="datePickChange($event)"></nz-range-picker>

        </nz-space-item>
        <nz-space-item>
          <nz-select nzAllowClear nzPlaceHolder="请选择审批状态" [(ngModel)]="status" (ngModelChange)="loadObjectData()">
            <nz-option nzLabel="全部" nzValue="0"></nz-option>
            <nz-option nzLabel="待审批" nzValue="1"></nz-option>
            <nz-option nzLabel="已通过" nzValue="2"></nz-option>
            <nz-option nzLabel="未通过" nzValue="3"></nz-option>
          </nz-select>
        </nz-space-item>
        <nz-space-item>
          <nz-input-group nzSearch [nzAddOnAfter]="suffixButton">
            <input type="text" nz-input placeholder="输入关键字搜索" [(ngModel)]="keyword"/>
          </nz-input-group>
          <ng-template #suffixButton>
            <button nz-button nzType="primary" (click)="loadObjectData()">搜索</button>
          </ng-template>
        </nz-space-item>
        <nz-space-item>
          <button nz-button [nzType]="'default'" (click)="reset()">重置</button>
        </nz-space-item>
      </nz-space>
      <div class="table-operation">
        <button nz-button nzType="primary" (click)="preAdd()">
          <i nz-icon nzType="plus" nzTheme="outline"></i>
          <span>新增采购</span>
        </button>
      </div>
    </div>
    <div>
      <nz-table
        nzSize="small"
        [nzData]="tmpArr"
        [(nzPageSize)]="size"
        [nzTotal]="total"
        [(nzPageIndex)]="page"
        [nzFrontPagination]="false"
        (nzPageIndexChange)="indexChange()"
        (nzPageSizeChange)="indexChange()"
        [nzShowSizeChanger]="true"
        [nzPageSizeOptions]="[5,10,15,20]"
        [nzShowTotal]="totalTemplate"
        [nzHideOnSinglePage]="true"
      >
        <ng-template #totalTemplate>共 <span style="color:cornflowerblue;margin:0 3px;">{{ total }}</span>
          条
        </ng-template>
        <thead>
        <tr>
          <th nzWidth="120px">采购单号</th>
          <th nzWidth="100px">上游服务商</th>
          <th nzWidth="100px">采购金额（元）</th>
          <th nzWidth="100px">创建时间</th>
          <th nzWidth="100px">采购人</th>
          <th nzWidth="100px">审批时间</th>
          <th nzWidth="100px">审批状态</th>
          <th nzWidth="100px">审批人</th>
          <th nzWidth="100px">操作</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let object of objects">
          <td>{{ object.purchase_no }}</td>
          <td>{{ object.servicer_type == 1 ? '腾讯科技' : '鸿联九五' }}</td>
          <td>{{ object.apply_balance }}</td>
          <td>{{ object.create_time.split(".")[0]| date: 'yy/MM/dd HH:mm' }}</td>
          <td>{{ object.create_user_name }}</td>
          <td>{{ object.audit_time.slice(0, 10) == '2006-01-02' ? '暂未审批' : object.audit_time.split(".")[0]| date: 'yy/MM/dd HH:mm' }}</td>
          <td>
            <nz-badge *ngIf="object.audit_status==1" nzStatus="processing"></nz-badge>
            <nz-badge *ngIf="object.audit_status==2" nzStatus="success"></nz-badge>
            <nz-badge *ngIf="object.audit_status==3" nzStatus="error"></nz-badge>
            <span *ngIf="object.audit_status==1">待审批</span>
            <span *ngIf="object.audit_status==2">已通过</span>
            <span *ngIf="object.audit_status==3">未通过</span>
          </td>
          <td>{{ object.audit_user_name }}</td>
          <td>
            <a (click)="preDetail(object)"><i nz-icon nzType="monitor" nzTheme="outline"></i>采购详情</a>&nbsp;&nbsp;&nbsp;&nbsp;
          </td>
        </tr>
        </tbody>
      </nz-table>
    </div>
  </div>
</nz-card>

<nz-drawer
  [nzBodyStyle]="{ height: 'calc(100% - 150px)', overflow: 'auto', 'padding-bottom': '53px' }"
  [nzMaskClosable]="true"
  [nzWidth]="1200"
  [nzVisible]="addIsVisible"
  nzTitle="新建采购"
  (nzOnClose)="addCancel()"
>
  <form nz-form [formGroup]="validateForm">
    <nz-form-item>
      <nz-form-label [nzSm]="3" [nzXs]="20" nzRequired>上游服务商</nz-form-label>
      <nz-form-control [nzSm]="7" [nzXs]="24" nzErrorTip="请选择服务商!">
        <nz-select [nzPlaceHolder]="'请选择服务商'" formControlName="servicer_type">
          <nz-option *ngFor="let option of operateOptions" [nzValue]="option.value"
                     [nzLabel]="option.label"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="3" [nzXs]="20" nzRequired>预计采购金额</nz-form-label>
      <nz-form-control [nzSm]="7" [nzXs]="24" nzErrorTip="请输入预计采购金额!">
        <input nz-input type="number" placeholder="请输入预计采购金额" formControlName="apply_balance"/>
      </nz-form-control>
    </nz-form-item>
  </form>
  <nz-divider></nz-divider>
  <form nz-form [formGroup]="itemValidateForm" style="margin-top: 2vh">
    <div style="float: left;margin-bottom: 2.5vh">
      <button nz-button [nzType]="'primary'" [nzSize]="'small'" (click)="addBtn()">添加内容</button>&nbsp;&nbsp;
    </div>
    <table style="width: 100%;text-align: center;margin-top: 2.5vh;">
      <tr style="background: rgb(73, 126, 245)">
        <td style="width: 10%;font-weight: bolder">服务</td>
        <td style="width: 10%;font-weight: bolder">产品</td>
        <td style="width: 10%;font-weight: bolder">数量</td>
        <td style="width: 10%;font-weight: bolder">单位</td>
        <td style="width: 5%;font-weight: bolder">有效时长</td>
        <td style="width: 10%;font-weight: bolder">关联客户</td>
        <td style="width: 10%;font-weight: bolder">关联订单</td>
        <td style="width: 5%;font-weight: bolder">操作</td>
      </tr>
      <ng-container formArrayName='arrayList'>
        <ng-container *ngFor="let row of arrayList.controls;let i =index">
          <tr>
            <ng-container [formGroup]="row">
              <td style="width: 10%;">
                <nz-select [nzPlaceHolder]="'(必选)请选择服务'" [nzSize]="'small'" style="width: 100%"
                           (ngModelChange)="loadOperateProductData($event,i)" formControlName="service_id">
                  <nz-option *ngFor="let option of operateServiceOptions" [nzValue]="option.id"
                             [nzLabel]="option.service_name"></nz-option>
                </nz-select>
              </td>
              <td style="width: 10%;">
                <nz-select [nzPlaceHolder]="'(必选)请选择产品'" [nzSize]="'small'" style="width: 100%"
                           formControlName="product_id" (ngModelChange)="fetchProductUnit($event,i)">
                  <nz-option *ngFor="let option of arrayList.controls[i].value.productOptions" [nzValue]="option.id"
                             [nzLabel]="option.product_name"></nz-option>
                </nz-select>
              </td>
              <td style="width: 10%;">
                <input nz-input type="number" placeholder="请输入数量" [nzSize]="'small'" style="width: 100%"
                       formControlName="product_number" (ngModelChange)="inputChange(i)"/>
              </td>
              <td style="width: 10%;">
                <input nz-input type="text" [nzSize]="'small'" style="width: 100%" formControlName="product_unit"
                       readonly placeholder="自动获取"/>
              </td>
              <td style="width: 5%;">
                <nz-select [nzPlaceHolder]="'请选择时长'" [nzSize]="'small'" style="width: 100%"
                           formControlName="effective_month">
                  <nz-option *ngFor="let option of timeOptions" [nzValue]="option.value"
                             [nzLabel]="option.label"></nz-option>
                </nz-select>
              </td>
              <td style="width: 10%;">
                <nz-select [nzPlaceHolder]="'(非必选)请选择客户'" [nzSize]="'small'" style="width: 100%"
                           (ngModelChange)="loadCompanyOrderData($event)" formControlName="company_id" nzDisabled="{{row['value']['disabled']}}">
                  <nz-option *ngFor="let option of companyOptions" [nzValue]="option.value"
                             [nzLabel]="option.label"></nz-option>
                </nz-select>
              </td>
              <td style="width: 10%;">
                <nz-select [nzPlaceHolder]="'(非必选)请选择单号'" [nzSize]="'small'" style="width: 100%" formControlName="downstream_order_no" nzDisabled="{{row['value']['disabled']}}">
                  <nz-option *ngFor="let option of orderOptions" [nzValue]="option.order_no"
                             [nzLabel]="option.order_no"></nz-option>
                </nz-select>
              </td>
              <td style="width: 5%">
                <button nz-button [nzType]="'danger'" [nzSize]="'small'" (click)="delBtn(i)">删除</button>
              </td>
            </ng-container>
          </tr>
        </ng-container>
      </ng-container>
    </table>
  </form>
  <div style="margin-top: 20vh">
    <button type="button" (click)="addCancel()" class="ant-btn" style="margin-right: 8px;"><span>取消</span>
    </button>
    <button type="button" (click)="addCommit()" class="ant-btn ant-btn-primary"><span>提交</span></button>
  </div>
</nz-drawer>

<nz-drawer
  [nzBodyStyle]="{ height: 'calc(100% - 150px)', overflow: 'auto', 'padding-bottom': '53px' }"
  [nzMaskClosable]="true"
  [nzWidth]="1400"
  [nzVisible]="viewIsVisible"
  nzTitle="采购详情"
  (nzOnClose)="closeDetail()"
>
  <nz-table nzSize="small" [nzData]="details" [nzFrontPagination]="false" [nzBordered]="true">
    <thead>
    <tr>
      <th colspan="2">采购清单</th>
      <th colspan="5">采购信息</th>
      <th colspan="2">结算信息</th>
      <th rowspan="2">操作</th>
    </tr>
    <tr>
      <th nzWidth="100px">采购项目</th>
      <th nzWidth="100px">采购数量</th>
      <th nzWidth="100px">上游单号</th>
      <th nzWidth="100px">采购金额（元）</th>
      <th nzWidth="100px">生效时间</th>
      <th nzWidth="100px">生效截止</th>
      <th nzWidth="100px">状态</th>
      <th nzWidth="100px">清算数量</th>
      <th nzWidth="100px">清算金额（元）</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let object of details">
      <td>{{ object.service_name }}-{{ object.product_name }}</td>
      <td>{{ object.product_number }}{{ object.product_unit }}</td>
      <td>{{ object.status == 1 ? '采购中' : object.upstream_order_no}}</td>
      <td>{{ object.status == 1 ? '采购中' : object.purchase_price }}</td>
      <td>{{ object.status == 1 ? '采购中' : object.effective_start| date: 'yy/MM/dd HH:mm' }}</td>
      <td>{{ object.status == 1 ? '采购中' : object.effective_end| date: 'yy/MM/dd HH:mm' }}</td>
      <td>
        <nz-badge *ngIf="object.status==1" nzStatus="processing"></nz-badge>
        <nz-badge *ngIf="object.status==2" nzStatus="success"></nz-badge>
        <nz-badge *ngIf="object.status==3" nzStatus="default"></nz-badge>
        <span *ngIf="object.status==1">采购中</span>
        <span *ngIf="object.status==2">使用中</span>
        <span *ngIf="object.status==3">已结算</span>
      </td>
      <td>{{ object.status == 3 ? object.settle_number : '-' }}</td>
      <td>{{ object.status == 3 ? object.settle_balance : '-' }}</td>
      <td>
        <span *ngIf="object.status==1"><a (click)="preAddPurchaseInfo(object)"><i nz-icon nzType="shopping-cart"
                                                                         nzTheme="outline"></i>采购</a>&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <span *ngIf="object.status==2"><a (click)="preAddSettleInfo(object)"><i nz-icon nzType="calculator"
                                                                         nzTheme="outline"></i>结算</a></span>
      </td>
    </tr>
    </tbody>
  </nz-table>
</nz-drawer>

<nz-modal [(nzVisible)]="addPurchaseInfoIsVisible" nzTitle="回填采购信息" [nzFooter]="modalFooter"
          [nzMaskClosable]="true" (nzOnCancel)="addPurchaseInfoCancel()">
  <form nz-form [formGroup]="purchaseInfoValidateForm">
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>上游订单号</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="请输入上游订单号!">
        <input nz-input placeholder="请输入上游订单号" formControlName="upstream_order_no"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>采购金额</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="请输入采购金额!">
        <input nz-input placeholder="请输入采购金额" formControlName="purchase_price"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>有效时间</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="请设置有效时间!">
        <nz-range-picker
          [nzShowTime]="{ nzFormat: 'HH:mm' }"
          nzFormat="yyyy-MM-dd HH:mm"
          [nzPlaceHolder]="['生效时间', '截止时间']"
          formControlName="pickerTimes"
          (ngModelChange)="onPickerChange($event)"
        ></nz-range-picker>
      </nz-form-control>
    </nz-form-item>
  </form>
  <ng-template #modalFooter>
    <button nz-button nzType="default" (click)="addPurchaseInfoCancel()">取消</button>
    <button nz-button nzType="primary" (click)="addPurchaseInfoCommit()">确定</button>
  </ng-template>
</nz-modal>

<nz-modal [(nzVisible)]="addSettleInfoIsVisible" nzTitle="清帐结算" [nzFooter]="modalFooter1"
          [nzMaskClosable]="true" (nzOnCancel)="addSettleCancel()">
  <form nz-form [formGroup]="settleInfoValidateForm">
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>结算数量</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="请输入结算数量!">
        <input nz-input placeholder="请输入结算数量" formControlName="settle_number"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>结算金额</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="请输入结算金额!">
        <input nz-input placeholder="请输入结算金额" formControlName="settle_balance"/>
      </nz-form-control>
    </nz-form-item>
  </form>
  <ng-template #modalFooter1>
    <button nz-button nzType="default" (click)="addSettleCancel()">取消</button>
    <button nz-button nzType="primary" (click)="addSettleCommit()">确定</button>
  </ng-template>
</nz-modal>

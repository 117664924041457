import {Component, ViewChild, OnInit, Inject, LOCALE_ID} from '@angular/core';
import {Router} from '@angular/router';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzModalService} from 'ng-zorro-antd/modal';
import {selfHttp} from "@src/app/httpservice/http.service";
import {HttpHeaders} from '@angular/common/http';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NzTreeComponent} from 'ng-zorro-antd/tree';
import {formatDate} from "@angular/common";

interface ProcessedData {
  id: number,
  create_time: string,
  company_name: string,
  create_user_name: string,
  order_no: string,
  order_type: number,
  price: number,
  order_status: number,
  purchase_status: number,
  package_name: string,
  service_name: string,
  product_name: string,
  product_number: number,
  product_unit: string,
  discounted_single_price: number,
  discounted_vital_price: number,
  effective_month: number,
  merge: boolean,
  rowspan: number,
}

interface showObject {
  order_no: string,
  company_name: string,
  create_user_name: string,
  create_time: string,
  audit_user_name: string,
  audit_time: string,
  order_type: number,
  order_from: number,
  order_price: number,
  discounted_vital_price: number,
  order_status: number,
}

@Component({
  selector: 'order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})


export class OrderComponent implements OnInit {
  @ViewChild('nzTreeComponent') nzTreeComponent: NzTreeComponent;
  orderStatus: any;
  companyId: any;
  startTime = '';
  endTime = '';
  dateRangeValues = []
  validateForm: FormGroup;
  productConfigValidateForm: FormGroup;
  itemValidateForm: FormGroup;
  //权限相关
  addAccess = false;
  editAccess = false;
  deleteAccess = false;
  //查询相关
  checkChild: any = false;
  keyword: string = '';
  size = 5;
  page: number = 1;
  total: number = 0;

  //操作相关
  selectOptions = [];
  operateOptions = [];
  operateItem = null;
  viewIsVisible = false;
  addIsVisible = false;
  editIsVisible = false;
  accessCodes: any = [];
  currentUser: any;

  objects = [];
  tmpArr = [];
  details = []
  public objectInfo: any;
  public options: any;
  packageItems = [];
  showObject: showObject = {
    order_no: '',
    company_name: '',
    create_user_name: '',
    create_time: '',
    audit_user_name: '',
    audit_time: '',
    order_type: 0,
    order_from: 0,
    order_price: 0,
    discounted_vital_price: 0,
    order_status: 0,
  }

  constructor(public router: Router,
              public fb: FormBuilder,
              public http: selfHttp,
              public modalService: NzModalService,
              public msg: NzMessageService,
              @Inject(LOCALE_ID) public locale: string,
  ) {
    this.loadObjectData();
    this.loadAccess();
    this.buildForm();
    this.loadOptionData();
  }

  buildForm() {
    this.validateForm = this.fb.group({
      order_no: [0],
      company_name: [null, [Validators.required]],
      create_user_name: [null, [Validators.required]],
      create_time: [null, [Validators.required]],
      audit_user_name: [null],
    });
    this.productConfigValidateForm = this.fb.group({
      company_id: [],
      vital_crm_number: [],
      vital_seat_number: [],
      vital_phone_number: [],
      vital_msg_number: [],
      vital_call_number: [],
      vital_crm_price: [],
      vital_seat_price: [],
      vital_phone_price: [],
      vital_msg_price: [],
      vital_call_price: [],
      vital_price: [],
      discounted_vital_price: [],
    });
    this.itemValidateForm = this.fb.group({
      arrayList: this.fb.array([]),
      service_id: [null, [Validators.required]],
      product_id: [null, [Validators.required]],
      product_unit: [null],
      product_number: [0, [Validators.required]],
      effective_month: [0, [Validators.required]],
      discount: [100, [Validators.required]],
      single_price: [0],
      discounted_single_price: [0, [Validators.required]],
      vital_price: [0, [Validators.required]],
      discounted_vital_price: [0, [Validators.required]],
      scene_bind: [],
      productOptions: [[]]
    });
  }

  ngOnInit(): void {
  }

  datePickChange(object: any) {
    if (object.length > 0) {
      this.startTime = formatDate(object[0], 'yyyy-MM-dd', this.locale)
      this.endTime = formatDate(object[1], 'yyyy-MM-dd', this.locale)
    } else {
      this.startTime = '';
      this.endTime = '';
    }
    this.loadObjectData();

  }

  loadObjectData() {
    this.objects = [];
    this.http.get('http://oss-api.nositek.com/oss-api/pc/orders', {
      page: this.page,
      size: this.size,
      company_id: this.companyId,
      order_status: this.orderStatus,
      keyword: this.keyword,
      start_time: this.startTime,
      end_time: this.endTime,
    }, res => {
      if (res.data['rows'] != null && res.data['rows'].length > 0) {
        this.tmpArr = res.data['rows'];
        this.rowMerge(this.tmpArr)
        this.total = Number(res.data['total']);
      } else {
        this.total = 0;
        this.msg.info('暂无数据');
      }
    });
  }

  rowMerge(arr: any) {
    this.objects = []
    arr.forEach((item) => {
      console.log(item.create_time.split(".")[0])
      if (item.details.length >= 1) {

        item.details.forEach((childItem, index) => {
          const processedItem: ProcessedData = {
            id: item.id,
            create_time: item.create_time.split(".")[0],
            company_name: item.company_name,
            create_user_name: item.create_user_name,
            order_no: item.order_no,
            order_type: item.order_type,
            price: item.discounted_vital_price,
            order_status: item.order_status,
            purchase_status: item.purchase_status,
            package_name: childItem.package_name,
            service_name: childItem.service_name,
            product_name: childItem.product_name,
            product_number: childItem.product_number,
            product_unit: childItem.product_unit,
            discounted_vital_price: childItem.discounted_vital_price,
            discounted_single_price: childItem.discounted_single_price,
            effective_month: childItem.effective_month,
            merge: false,
            rowspan: 1,
          }
          processedItem.merge = index == 0;
          processedItem.rowspan = item.details.length
          this.objects.push(processedItem)
        })
      } else {
        let processedItem: ProcessedData = {
          id: item.id,
          create_time: item.create_time,
          company_name: item.company_name,
          create_user_name: item.create_user_name,
          order_no: item.order_no,
          order_type: item.order_type,
          price: item.price,
          order_status: item.order_status,
          purchase_status: item.purchase_status,
          package_name: '',
          service_name: '',
          product_name: '',
          product_number: 0,
          product_unit: '',
          discounted_vital_price: 0,
          discounted_single_price: 0,
          effective_month: 0,
          merge: false,
          rowspan: 1,
        }
        this.objects.push(processedItem)
      }
    })
    console.log(this.objects)
  }

  // 复选框
  onChange(data: any, checked: any) {
    this.objectInfo = data;
    if (checked === 'single') {
      if (data.checked) {
        this.operateOptions.push(data.id);
      } else {
        let index = this.operateOptions.indexOf(data.id);
        this.operateOptions.splice(index, 1);
      }
    }
  }

// 重置
  reset() {
    this.page = 1;
    this.size = 5;
    this.keyword = '';
    this.orderStatus = '';
    this.companyId = 0;
    this.startTime = '';
    this.endTime = '';
    this.dateRangeValues = [];
    this.loadObjectData();
  }

  //页码切换
  indexChange() {
    console.log(this.page, this.size);
    this.loadObjectData();
  }


  preDetail(id: number) {
    let index = this.tmpArr.findIndex(p => p.id === id)
    this.showObject.order_no = this.tmpArr[index].order_no
    this.showObject.company_name = this.tmpArr[index].company_name
    this.showObject.create_user_name = this.tmpArr[index].create_user_name
    this.showObject.create_time = this.tmpArr[index].create_time
    this.showObject.audit_user_name = this.tmpArr[index].audit_user_name
    this.showObject.audit_time = this.tmpArr[index].audit_time
    this.showObject.order_type = this.tmpArr[index].order_type
    this.showObject.order_from = this.tmpArr[index].order_from
    this.showObject.order_price = this.tmpArr[index].order_price
    this.showObject.discounted_vital_price = this.tmpArr[index].discounted_vital_price
    this.showObject.order_status = this.tmpArr[index].order_status
    this.details = this.tmpArr[index].details
    this.viewIsVisible = true
  }

  //添加取消
  closeDetail() {
    this.viewIsVisible = false;
    this.showObject.order_no = ''
    this.showObject.company_name = ''
    this.showObject.create_user_name = ''
    this.showObject.create_time = ''
    this.showObject.audit_user_name = ''
    this.showObject.audit_time = ''
    this.showObject.order_type = 0
    this.showObject.order_from = 0
    this.showObject.order_price = 0
    this.showObject.discounted_vital_price = 0
    this.showObject.order_status = 0
    // this.showObject.purchase_status = 0
    this.details = []
  }


  /*
  添加系统用户
   */
  preAdd() {
    this.addIsVisible = true;
    this.loadOperateServiceData();
  }

  loadOptionData() {
    this.http.get('http://oss-api.nositek.com/oss-api/pc/companies', {}, res => {
      if (res.data['rows'] != null) {
        this.selectOptions = res.data['rows'];
      } else {
        this.selectOptions = [];
        this.msg.info('暂无数据');
      }

    });
  }

  //添加取消
  addCancel() {
    this.addIsVisible = false;
    this.productConfigValidateForm.reset();
    this.packageItems = [];
    this.itemValidateForm.reset();
  }

  //添加提交
  addCommit() {
    this.packageItems = []
    this.arrayList.controls.forEach((item, index) => {
      this.packageItems.push(item['value'])
    })
    this.http.post('http://oss-api.nositek.com/oss-api/pc/order', {
      product_vital: this.productConfigValidateForm.value,
      product_items: this.packageItems,
    }, res => {
      if (res.code == 2000) {
        this.msg.success('添加成功')
      } else {
        this.msg.error(res.data)
      }
      this.productConfigValidateForm.reset();
      this.itemValidateForm.reset();
      this.packageItems = [];
      this.loadObjectData();
      this.itemValidateForm.value.arrayList = this.fb.array([])
      this.addIsVisible = false;
      // }
    })
  }

  /*
  修改系统用户
   */
  preEdit() {
    if (this.operateOptions.length == 0) {
      this.msg.info('请选择数据！');
      return;
    } else if (this.operateOptions.length > 1) {
      this.msg.info('请逐条记录操作！');
      return;
    }
    this.loadOptionData();
    let index = this.objects.map(item => item.id).indexOf(this.operateOptions[0]);
    this.operateItem = this.objects[index];
    this.validateForm.patchValue({
      id: this.operateItem.id,
      user_name: this.operateItem.name,
    });
    if (this.operateItem.status == 1) {
      this.validateForm.patchValue({
        'status': true
      });
    } else {
      this.validateForm.patchValue({
        'status': false
      });
    }
    this.validateForm.patchValue({
      user_name: this.operateItem.user_name,
      role_id: this.operateItem.role_id,
      mobile: this.operateItem.mobile,
    })

    this.editIsVisible = true;
  }

  //修改取消
  editCancel() {
    this.editIsVisible = false;
    this.operateOptions = [];
    this.loadObjectData();
    this.validateForm.reset();
    this.validateForm.patchValue({
      status: true,
    });
  }

  //修改提交
  editCommit() {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    let enable: number;
    if (this.validateForm.value.status) {
      enable = 1;
    } else {
      enable = 0;
    }
    if (this.validateForm.value.user_name && this.validateForm.value.mobile && this.validateForm.value.role_id) {
      this.http.post('http://oss-api.nositek.com/oss-api/pc/users', {
        'id': Number(this.objectInfo.id),
        'user_name': this.validateForm.value.user_name,
        'role_id': this.validateForm.value.role_id,
        'mobile': this.validateForm.value.mobile,
        'status': enable,
      }, res => {
        if (res.code == 2000) {
          this.msg.success('编辑成功');
        } else if (res.code == 100003) {
          this.msg.info(res.msg)
        } else {
          this.msg.error(res.msg)
        }
        this.validateForm.reset();
        this.validateForm.patchValue({
          status: true,
        });
        this.editIsVisible = false;
        this.loadObjectData();
      });
    }

  }


  /*
  删除用户
   */
  preDelete() {
    if (this.operateOptions.length == 0) {
      this.msg.info('请选择数据！');
      return;
    }
    this.modalService.confirm({
      nzTitle: '<i>确定删除数据 ？</i>',
      nzContent: '<b></b>',
      nzOkText: '确定',
      nzCancelText: '取消',
      nzOnOk: () => this.deleteCommit(),
      nzOnCancel: () => this.deleteCancel()
    });
  }

  // 删除提交
  deleteCommit() {
    this.options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        ids: this.operateOptions
      }
    };
    this.http.delete('http://oss-api.nositek.com/oss-api/pc/users', this.options, res => {
      if (res.code == 2000) {
        this.msg.success('删除成功');
      } else {
        this.msg.info(res.msg);
      }
      this.loadObjectData();
    });
    this.operateOptions = [];
  }

  // 删除取消
  deleteCancel() {
    this.operateOptions = [];
    this.loadObjectData();
  }

  //动态表格
  vitalPrice: any = 0;
  discountedVitalPrice: any = 0;
  vitalCRMNumber: any = 0;
  vitalSeatNumber: any = 0;
  vitalPhoneNumber: any = 0;
  vitalMsgNumber: any = 0;
  vitalCallNumber: any = 0;
  vitalCrmPrice: any = 0;
  vitalSeatPrice: any = 0;
  vitalPhonePrice: any = 0;
  vitalMsgPrice: any = 0;
  vitalCallPrice: any = 0;
  timeOptions = [
    {label: '不限制', value: 0},
    {label: '1个月', value: 1},
    {label: '2个月', value: 2},
    {label: '3个月', value: 3},
    {label: '4个月', value: 4},
    {label: '5个月', value: 5},
    {label: '半年', value: 6},
    {label: '7个月', value: 7},
    {label: '8个月', value: 8},
    {label: '9个月', value: 9},
    {label: '10个月', value: 10},
    {label: '11个月', value: 11},
    {label: '一年', value: 12},
  ]
  smsDisabled: boolean = true;
  tcccDisabled: boolean = true;
  operateServiceOptions = [];

  inputChange(index: number) {
    // switch (formControlName) {
    //   case 'productNumber':
    //     let validProductNumber: string = ""+this.arrayList.controls[index]['value']['product_number'];
    //     console.log(validProductNumber)
    //     //限制数字输入
    //     //先把非数字的都替换掉，除了数字和.
    //     validProductNumber = validProductNumber.replace(/[^\d.]/g, "");
    //     //必须保证第一个为数字而不是.
    //     validProductNumber = validProductNumber.replace(/^\./g, "");
    //     //保证只有出现一个.而没有多个.
    //     validProductNumber = validProductNumber.replace(/\.{2,}/g, "");
    //     //保证.只出现一次，而不能出现两次以上
    //     validProductNumber = validProductNumber.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
    //     this.arrayList.controls[index].patchValue({
    //         product_number: validProductNumber
    //       })
    //     break;
    //   case 'discount':
    //     let validDiscount: any = this.arrayList.controls[index]['value']['product_number'];
    //     //限制数字输入
    //     //先把非数字的都替换掉，除了数字和.
    //     validDiscount = validDiscount.replace(/[^\d.]/g, "");
    //     //必须保证第一个为数字而不是.
    //     validDiscount = validDiscount.replace(/^\./g, "");
    //     //保证只有出现一个.而没有多个.
    //     validDiscount = validDiscount.replace(/\.{2,}/g, "");
    //     //保证.只出现一次，而不能出现两次以上
    //     validDiscount = validDiscount.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
    //     this.arrayList.controls[index].patchValue({
    //       discount: validDiscount
    //     })
    //     break;
    //
    // }
    this.arrayList.controls.forEach(item => {
      if (item['value']['scene_bind'] == 11) {
        this.smsDisabled = false;
      }
      if (item['value']['scene_bind'] == 20 || item['value']['scene_bind'] == 21 || item['value']['scene_bind'] == 22) {
        this.tcccDisabled = false;
      }
    });

    let currentItem = this.arrayList.controls[index]['value']
    if (currentItem['single_price']) {
      if (currentItem['product_number'] || currentItem['discount']) {
        //处理单行数据
        this.arrayList.controls[index].patchValue({
          vital_price: Number((currentItem['single_price'] * currentItem['product_number']).toFixed(3)),
          discounted_single_price: Number((currentItem['single_price'] * currentItem['discount'] / 100).toFixed(3)),
          discounted_vital_price: Number((currentItem['single_price'] * currentItem['product_number'] * currentItem['discount'] / 100).toFixed(3)),
        })
        /*
        处理汇总统计数据
         */
        //所有行都需要汇总计算总价和折后总价
        let vitalPrice: number = 0;
        let discountedVitalPrice: number = 0;
        //特殊行（视频短信包和外呼语音包）需要分别统计总量和总钱
        let vitalCRMNumber = 0;
        let vitalSeatNumber = 0;
        let vitalPhoneNumber = 0;
        let vitalMsgNumber: number = 0;
        let vitalCallNumber: number = 0;
        let vitalCRMPrice: number = 0;
        let vitalSeatPrice: number = 0;
        let vitalPhonePrice: number = 0;
        let vitalMsgPrice: number = 0;
        let vitalCallPrice: number = 0;

        this.arrayList.controls.forEach((item, index) => {
          vitalPrice = Number((vitalPrice + parseFloat(item['value']['vital_price'])).toFixed(3))
          discountedVitalPrice = Number((discountedVitalPrice + parseFloat(item['value']['discounted_vital_price'])).toFixed(3))
          switch (item['value']['scene_bind']) {
            case 1://统计CRM座席数量
              vitalCRMNumber = Number(vitalCRMNumber + parseFloat(item['value']['product_number']))
              vitalCRMPrice = Number((vitalCRMPrice + parseFloat(item['value']['discounted_vital_price'])).toFixed(3))
              break;
            case 11: //统计视频短信包
              vitalMsgNumber = Number(vitalMsgNumber + parseFloat(item['value']['product_number']))
              vitalMsgPrice = Number((vitalMsgPrice + parseFloat(item['value']['discounted_vital_price'])).toFixed(3))
              break;
            case 20://统计外呼座席数量
              vitalSeatNumber = Number(vitalSeatNumber + parseFloat(item['value']['product_number']))
              vitalSeatPrice = Number((vitalSeatPrice + parseFloat(item['value']['discounted_vital_price'])).toFixed(3))
              break;
            case 21://统计外呼号码数量
              vitalPhoneNumber = Number(vitalPhoneNumber + parseFloat(item['value']['product_number']))
              vitalPhonePrice = Number((vitalPhonePrice + parseFloat(item['value']['discounted_vital_price'])).toFixed(3))
              break;
            case 22://统计外呼语音包
              vitalCallNumber = Number(vitalCallNumber + parseFloat(item['value']['product_number']))
              vitalCallPrice = Number((vitalCallPrice + parseFloat(item['value']['discounted_vital_price'])).toFixed(3))
              break;
          }
        })
        this.productConfigValidateForm.patchValue({
          vital_crm_number: vitalCRMNumber,
          vital_seat_number: vitalSeatNumber,
          vital_phone_number: vitalPhoneNumber,
          vital_msg_number: vitalMsgNumber,
          vital_call_number: vitalCallNumber,
          vital_crm_price: vitalCRMPrice,
          vital_seat_price: vitalSeatPrice,
          vital_phone_price: vitalPhonePrice,
          vital_call_price: vitalCallPrice,
          vital_msg_price: vitalMsgPrice,
          vital_price: vitalPrice,
          discounted_vital_price: discountedVitalPrice,
        })
        this.vitalCRMNumber = vitalCRMNumber;
        this.vitalSeatNumber = vitalSeatNumber;
        this.vitalPhoneNumber = vitalPhoneNumber;
        this.vitalMsgNumber = vitalMsgNumber;
        this.vitalCallNumber = vitalCallNumber;
        ;
        this.vitalCrmPrice = vitalCRMPrice;
        this.vitalSeatPrice = vitalSeatPrice;
        this.vitalPhonePrice = vitalPhonePrice;
        this.vitalMsgPrice = vitalMsgPrice
        this.vitalCallPrice = vitalCallPrice;
        this.vitalPrice = vitalPrice;
        this.discountedVitalPrice = discountedVitalPrice;
      }
    }

  }

  fetchProductUnit(optionId: any, index: number) {
    if (optionId) {
      let i = this.operateProductOptions.map(item => item.id).indexOf(optionId);
      this.arrayList.controls[index].patchValue({
        product_unit: this.operateProductOptions[i].product_unit,
        single_price: this.operateProductOptions[i].single_price,
        product_number: 1,
        effective_month: 0,
        discount: 100,
        discounted_price: this.operateProductOptions[i].single_price,
        vital_price: this.operateProductOptions[i].single_price,
        vital_discounted_price: this.operateProductOptions[i].single_price,
        scene_bind: this.operateProductOptions[i].scene_bind,
      })
    }
  }

  loadOperateServiceData() {
    this.operateServiceOptions = [];
    this.http.get('http://oss-api.nositek.com/oss-api/pc/services', {}, res => {
      if (res.data['rows'] != null && res.data['rows'].length > 0) {
        this.operateServiceOptions = res.data['rows'];
      } else {
        this.msg.info('暂无数据');
      }
    });
  }

  operateProductOptions = [];

  loadOperateProductData(object: any, index: number) {
    this.http.get('http://oss-api.nositek.com/oss-api/pc/products', {service_id: object}, res => {
      if (res.data['rows'] != null && res.data['rows'].length > 0) {
        this.operateProductOptions = res.data['rows'];
        this.arrayList.controls[index].patchValue({
          product_id: null,
          product_unit: null,
          product_number: 1,
          effective_month: 0,
          discount: 100,
          single_price: null,
          vital_price: null,
          discounted_vital_price: null,
          discounted_single_price: null,
          productOptions: this.operateProductOptions,
        })
      } else {
        this.msg.info('暂无数据');
      }
    });
  }

  //获取数组对象
  get arrayList() {
    return this.itemValidateForm.get('arrayList') as FormArray;
  }

  //创建一行组件
  createRow() {
    return this.fb.group({
      service_id: [''],
      product_id: [''],
      product_unit: [''],
      product_number: [1],
      effective_month: [0],
      discount: [100],
      single_price: [null],
      vital_price: [null],
      discounted_single_price: [null],
      discounted_vital_price: [null],
      scene_bind: [null],
      productOptions: [[]],
      disabled: false,
    });
  }

  //增加一行事件
  addBtn() {
    this.arrayList.push(this.createRow());
  }


  //todo:删除事件计算总统计数量
  // 删除一行事件
  delBtn(index: number) {
    if (this.arrayList.controls[index]['value']['product_number'] || this.arrayList.controls[index]['value']['discount']) {
      let currentItem = this.arrayList.controls[index]['value']
      let currentVitalPrice = Number((this.productConfigValidateForm.value.vital_price - currentItem['vital_price']).toFixed(3));
      let currentDiscountedVitalPrice = Number((this.productConfigValidateForm.value.discounted_vital_price - currentItem['discounted_vital_price']).toFixed(3));

      let currentVitalCRMNumber: number = this.productConfigValidateForm.value.vital_crm_number;
      let currentVitalSeatNumber: number = this.productConfigValidateForm.value.vital_seat_number;
      let currentVitalPhoneNumber: number = this.productConfigValidateForm.value.vital_phone_number;
      let currentVitalMsgNumber: number = this.productConfigValidateForm.value.vital_msg_number;
      let currentVitalCallNumber: number = this.productConfigValidateForm.value.vital_call_number;
      let currentVitalCrmPrice: number = this.productConfigValidateForm.value.vital_crm_price;
      let currentVitalSeatPrice: number = this.productConfigValidateForm.value.vital_seat_price;
      let currentVitalPhonePrice: number = this.productConfigValidateForm.value.vital_phone_price;
      let currentVitalMsgPrice: number = this.productConfigValidateForm.value.vital_msg_price;
      let currentVitalCallPrice: number = this.productConfigValidateForm.value.vital_call_price;

      switch (currentItem['scene_bind']) {
        case 1:
          currentVitalCRMNumber = Number(this.productConfigValidateForm.value.vital_crm_number - currentItem['product_number']);
          currentVitalCrmPrice = Number((this.productConfigValidateForm.value.vital_crm_price - currentItem['discounted_vital_price']).toFixed(3));
          break;
        case 11:
          currentVitalMsgNumber = Number(this.productConfigValidateForm.value.vital_msg_number - currentItem['product_number']);
          currentVitalMsgPrice = Number((this.productConfigValidateForm.value.vital_msg_price - currentItem['discounted_vital_price']).toFixed(3));
          break;
        case 20:
          currentVitalSeatNumber = Number(this.productConfigValidateForm.value.vital_seat_number - currentItem['product_number']);
          currentVitalSeatPrice = Number((this.productConfigValidateForm.value.vital_seat_price - currentItem['discounted_vital_price']).toFixed(3));
          break;
        case 21:
          currentVitalPhoneNumber = Number(this.productConfigValidateForm.value.vital_phone_number - currentItem['product_number']);
          currentVitalPhonePrice = Number((this.productConfigValidateForm.value.vital_phone_price - currentItem['discounted_vital_price']).toFixed(3));
          break;
        case 22:
          currentVitalCallNumber = Number(this.productConfigValidateForm.value.vital_call_number - currentItem['product_number']);
          currentVitalCallPrice = Number((this.productConfigValidateForm.value.vital_call_price - currentItem['discounted_vital_price']).toFixed(3));
          break;
      }

      this.productConfigValidateForm.patchValue({
        vital_crm_number: currentVitalCRMNumber,
        vital_seat_number: currentVitalSeatNumber,
        vital_phone_number: currentVitalPhoneNumber,
        vital_msg_number: currentVitalMsgNumber,
        vital_call_number: currentVitalCallNumber,
        vital_crm_price: currentVitalCrmPrice,
        vital_seat_price: currentVitalSeatPrice,
        vital_phone_price: currentVitalPhonePrice,
        vital_msg_price: currentVitalMsgPrice,
        vital_call_price: currentVitalCallPrice,
        vital_price: currentVitalPrice,
        discounted_vital_price: currentDiscountedVitalPrice,

      })
      this.vitalCRMNumber = currentVitalCRMNumber;
      this.vitalSeatNumber = currentVitalSeatNumber;
      this.vitalPhoneNumber = currentVitalPhoneNumber;
      this.vitalMsgNumber = currentVitalMsgNumber;
      this.vitalCallNumber = currentVitalCallNumber;
      this.vitalCrmPrice = currentVitalCrmPrice;
      this.vitalSeatPrice = currentVitalSeatPrice;
      this.vitalPhonePrice = currentVitalPhonePrice;
      this.vitalCallPrice = currentVitalCallPrice;
      this.vitalMsgPrice = currentVitalMsgPrice;
      this.vitalPrice = currentVitalPrice;
      this.discountedVitalPrice = currentDiscountedVitalPrice;
      this.arrayList.removeAt(index);
      this.smsDisabled = true;
      this.tcccDisabled = true;
      this.arrayList.controls.forEach(item => {
        if (item['value']['scene_bind'] == 11) {
          this.smsDisabled = false;
        }
        if (item['value']['scene_bind'] == 20 || item['value']['scene_bind'] == 21 || item['value']['scene_bind'] == 22) {
          this.tcccDisabled = false;
        }
      });
      if (this.smsDisabled) {
        this.productConfigValidateForm.patchValue({
          sms_type: 2,
          sms_config: null,
        })
      }
      if (this.tcccDisabled) {
        this.productConfigValidateForm.patchValue({
          sdk_app_id: null,
          staff_num: null,
          phones_num: null
        })

      }
    }
  }

  //保存数据
  saveData() {
    this.packageItems = []
    this.arrayList.controls.forEach((item, index) => {
      console.log(item)
      this.packageItems.push(item['value'])
    })
    console.log(this.packageItems)
  }

  //清空数据
  clearData() {
    this.arrayList.controls.forEach(item => {
      item.patchValue({
        service_id: '',
        product_id: '',
        product_number: '',
      });
    });
  }


  add_dept = false;
  edit_dept = false;
  delete_dept = false;
  add_user = true;
  edit_user = false;
  delete_user = false;

  loadAccess() {
    console.log('加载页面操作权限');
    // let tempArr = JSON.parse(localStorage.getItem('access'));
    // console.log(tempArr);
    // this.add_dept = tempArr.findIndex(v => v.name == 'add_dept') != -1;
    // this.edit_dept = tempArr.findIndex(v => v.name == 'edit_dept') != -1;
    // this.delete_dept = tempArr.findIndex(v => v.name == 'delete_dept') != -1;
    // this.add_user = tempArr.findIndex(v => v.name == 'add_user') != -1;
    // this.edit_user = tempArr.findIndex(v => v.name == 'edit_user') != -1;
    // this.delete_user = tempArr.findIndex(v => v.name == 'delete_user') != -1;
  }
}

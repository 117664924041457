import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {NzMessageService} from 'ng-zorro-antd/message';

@Injectable()
export class selfHttp {
  public restServer;
  public http;

  constructor(Http: HttpClient,
              public msg: NzMessageService,) {
    this.http = Http;
    this.restServer = '';
  }

  public get(url, params?: Object, cb?: Function) {
    let httpParams = new HttpParams();
    const vm = this;
    if (params) {
      for (const key in params) {
        if (params[key] === false || params[key]) {
          httpParams = httpParams.set(key, params[key]);
        }
      }
    }
    console.log(vm.restServer + url);
    console.log(httpParams);
    vm.http.get(vm.restServer + url, {params: httpParams})
      .subscribe(data => {
        cb(data);
      }),
      err => {
        console.log('get请求异常');
        console.log(err);
      };
  }

  public post(url, data?: Object, cb?: Function, options?: Object) {
    const vm = this;
    vm.http.post(vm.restServer + url, data, options)
      .subscribe(res => {
          cb(res);
        },
        err => {
          console.log(err);
          this.msg.error('操作失败', err);
        }
      );
  }

  public put(url, data?: Object, cb?: Function, options?: Object) {
    const vm = this;
    vm.http.put(vm.restServer + url, data, options)
      .subscribe(res => {
          cb(res);
        },
        err => {
          console.log(err);
          this.msg.error('操作失败', err);
        });

  }

  public patch(url, data?: Object, cb?: Function, options?: Object) {
    const vm = this;
    vm.http.patch(vm.restServer + url, data, options)
      .subscribe(res => {
          cb(res);
        },
        err => {
          console.log(err);
          this.msg.error('操作失败', err);
        });
  }

  public delete(url, data?: Object, cb?: Function, options?: Object) {
    const vm = this;
    console.log(data);
    vm.http.delete(vm.restServer + url, data, options)
      .subscribe(res => {
        cb(res);
      }, err => {
        console.log('删除失败', err);
        this.msg.error('操作失败', err);
      });
  }

}

<nz-card>
  <div id="table-area">
    <div class="param-area">
      <nz-space class="table-query">
        <nz-space-item>
          <nz-input-group nzSearch [nzAddOnAfter]="suffixButton">
            <input type="text" nz-input placeholder="输入关键字搜索" [(ngModel)]="keyword"/>
          </nz-input-group>
          <ng-template #suffixButton>
            <button nz-button nzType="primary" (click)="loadObjectData()">搜索</button>
          </ng-template>
        </nz-space-item>
        <nz-space-item>
          <button nz-button [nzType]="'default'" (click)="reset()">重置</button>
        </nz-space-item>
      </nz-space>
      <div class="table-operation">
        <button nz-button nzType="primary" (click)="preAdd()">
          <i nz-icon nzType="plus" nzTheme="outline"></i>
          <span>添加模版</span>
        </button>
      </div>
    </div>
  </div>
  <div id="table-data">
    <nz-table
      nzSize="small"
      nzTemplateMode
      [nzData]="objects"
      [(nzPageSize)]="size"
      [nzTotal]="total"
      [(nzPageIndex)]="page"
      [nzFrontPagination]="false"
      (nzPageIndexChange)="indexChange()"
      (nzPageSizeChange)="indexChange()"
      [nzShowSizeChanger]="true"
      [nzPageSizeOptions]="[5,10,15,20]"
      [nzShowTotal]="totalTemplate"
      [nzHideOnSinglePage]="true"
    >
      <ng-template #totalTemplate>共 <span style="color:cornflowerblue;margin:0 3px;">{{ total }}</span>
        条
      </ng-template>
      <thead>
      <tr style="height: 5.5vh">
        <th nzWidth="50px" nzLeft="0px"></th>
        <th>模版名称</th>
        <th>模版权限</th>
        <th>启用状态</th>
        <th>说明</th>
        <th>操作</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let object of objects" style="height: 5vh">
        <td nzShowCheckbox nzWidth="50px" nzLeft="0px" [(nzChecked)]="object.checked"
            (nzCheckedChange)="onChange(object,'single')"></td>
        <td>{{object.template_name}}</td>
        <td>
          <div *ngFor="let detail of object.details">
            <span>{{detail.menu_name}}</span>--<span>{{detail.interface_name}}</span><br>
          </div>
        </td>
        <td>{{object.status == '1' ? '启用' : '未启用'}}</td>
        <td>{{object.remark}}</td>
        <td>
          <a (click)="preEdit(object)"><i nz-icon nzType="monitor" nzTheme="outline"></i>编辑</a>&nbsp;&nbsp;&nbsp;&nbsp;
          <a (click)="preDelete(object)"><i nz-icon nzType="delete" nzTheme="outline"></i>删除</a>&nbsp;&nbsp;&nbsp;&nbsp;
        </td>
      </tr>
      </tbody>
    </nz-table>
  </div>
</nz-card>
<!--添加模版信息-->
<nz-drawer
  [nzBodyStyle]="{ height: 'calc(100% - 150px)', overflow: 'auto', 'padding-bottom': '53px' }"
  [nzMaskClosable]="true"
  [nzWidth]="720"
  [nzVisible]="addIsVisible"
  nzTitle="添加模版"
  (nzOnClose)="addCancel()"
>
  <form nz-form [formGroup]="validateForm" class="ant-advanced-search-form">
    <div class="permission" style="width: 100%">
      <div nz-row [nzGutter]="24">
        <div nz-col [nzSpan]="24">
          <nz-form-item nzFlex>
            <nz-form-label style="width: 18%;text-align: left;" nzRequired>模版名称</nz-form-label>
            <nz-form-control style="width: 36%" nzErrorTip="请输入模版名称!">
              <input nz-input placeholder="请输入模版名称" formControlName="template_name"/>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24">
          <nz-form-item nzFlex>
            <nz-form-label style="width: 18%;text-align: left;">权限设置</nz-form-label>
            <nz-form-control style="width: 80%">
              <nz-tree-select
                formControlName="tmpTreeKeys"
                [nzNodes]="treeData"
                [nzDefaultExpandAll]="true"
                nzShowSearch
                nzCheckable
                nzPlaceHolder="请选择权限（多选）"
              >
              </nz-tree-select>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24">
          <nz-form-item nzFlex>
            <nz-form-label style="width: 18%;text-align: left;">启用状态</nz-form-label>
            <nz-form-control style="width: 36%">
              <nz-switch formControlName="status" nzCheckedChildren="启用" nzUnCheckedChildren="禁用"></nz-switch>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24">
          <nz-form-item nzFlex>
            <nz-form-label style="width: 18%;text-align: left;">说明</nz-form-label>
            <nz-form-control style="width: 36%">
              <textarea rows="3" nz-input formControlName="remark"></textarea>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </div>
  </form>
  <div class="footer">
    <button type="button" (click)="addCancel()" class="ant-btn" style="margin-right: 8px;"><span>取消</span>
    </button>
    <button type="button" (click)="addCommit()" class="ant-btn ant-btn-primary"><span>提交</span></button>
  </div>
</nz-drawer>

<!--修改模版信息-->
<nz-drawer
  [nzBodyStyle]="{ height: 'calc(100% - 150px)', overflow: 'auto', 'padding-bottom': '53px' }"
  [nzMaskClosable]="false"
  [nzWidth]="720"
  [nzVisible]="editIsVisible"
  nzTitle="编辑模版"
  (nzOnClose)="editCancel()"
>
  <form nz-form [formGroup]="validateForm" class="ant-advanced-search-form">
    <div class="permission" style="width: 100%">
      <div nz-row [nzGutter]="24">
        <div nz-col [nzSpan]="24">
          <nz-form-item nzFlex>
            <nz-form-label style="width: 18%;text-align: left;" nzRequired>模版名称</nz-form-label>
            <nz-form-control style="width: 36%" nzErrorTip="请输入模版名称!">
              <input nz-input placeholder="请输入模版名称" formControlName="template_name"/>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24">
          <nz-form-item nzFlex>
            <nz-form-label style="width: 18%;text-align: left;">权限设置</nz-form-label>
            <nz-form-control style="width: 80%">
              <nz-tree-select
                formControlName="tmpTreeKeys"
                [nzNodes]="treeData"
                [nzDefaultExpandAll]="true"
                nzShowSearch
                nzCheckable
                nzPlaceHolder="请选择权限（多选）"
              >
              </nz-tree-select>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24">
          <nz-form-item nzFlex>
            <nz-form-label style="width: 18%;text-align: left;">启用状态</nz-form-label>
            <nz-form-control style="width: 36%">
              <nz-switch formControlName="status" nzCheckedChildren="启用" nzUnCheckedChildren="禁用"></nz-switch>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24">
          <nz-form-item nzFlex>
            <nz-form-label style="width: 18%;text-align: left;">说明</nz-form-label>
            <nz-form-control style="width: 36%">
              <textarea rows="3" nz-input formControlName="remark"></textarea>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </div>
  </form>
  <div class="footer">
    <button type="button" (click)="editCancel()" class="ant-btn" style="margin-right: 8px;"><span>取消</span>
    </button>
    <button type="button" (click)="editCommit()" class="ant-btn ant-btn-primary"><span>提交</span></button>
  </div>
</nz-drawer>

<nz-card>
  <div id="table-area">
    <div class="param-area">
      <nz-space class="table-query">
        <nz-space-item>
          <nz-input-group nzSearch [nzAddOnAfter]="suffixButton">
            <input type="text" nz-input placeholder="输入关键字搜索" [(ngModel)]="keyword"/>
          </nz-input-group>
          <ng-template #suffixButton>
            <button nz-button nzType="primary" (click)="loadObjectData()">搜索</button>
          </ng-template>
        </nz-space-item>
        <nz-space-item>
          <button nz-button [nzType]="'default'" (click)="reset()">重置</button>
        </nz-space-item>
      </nz-space>
      <div class="table-operation">
        <button nz-button nzType="primary" (click)="preOperate(1,null)">
          <i nz-icon nzType="plus" nzTheme="outline"></i>
          <span>添加菜单</span>
        </button>
      </div>
    </div>
    <div id="table-data">
      <nz-table nzSize="small" #expandTable [nzData]="listOfMapData" nzTableLayout="fixed">
        <thead>
        <tr>
          <th nzWidth="200px">name</th>
          <th nzWidth="100px">title</th>
          <th nzWidth="100px">path</th>
          <th nzWidth="100px">隐藏状态</th>
          <th nzWidth="100px">图标</th>
          <th nzWidth="100px">研发状态</th>
          <th nzWidth="100px">添加时间</th>
          <th nzWidth="100px">操作</th>
        </tr>
        </thead>
        <tbody cdkDropList (cdkDropListDropped)="drop($event)">
        <ng-container *ngFor="let data of expandTable.data">
          <ng-container *ngFor="let item of mapOfExpandedData[data.id]">
            <tr *ngIf="(item.parent && item.parent.expand) || !item.parent" cdkDrag>
              <td
                [nzIndentSize]="item.level! * 50"
                [nzShowExpand]="!!item.children"
                [(nzExpand)]="item.expand"
                (nzExpandChange)="collapse(mapOfExpandedData[data.id], item, $event)"
              >
                {{ item.menu_name }}
              </td>
              <td>{{ item.title }}</td>
              <td>{{ item.path }}</td>
              <td>
                <i *ngIf="!item.hidden" nz-icon nzType="eye" nzTheme="outline" style="color: green"></i>
                <i *ngIf="item.hidden" nz-icon nzType="eye-invisible" nzTheme="outline" style="color: #0099FF"></i>
                {{  item.hidden ? '隐藏' : '展示'}}
              </td>
              <td>
                {{item.icon}}
<!--                <img [src]="item.icon" style="text-align: center;margin-bottom: 2%;-->
<!--                      width: 50px;height: 50px;border: 1px solid cornflowerblue;" alt="">-->
              </td>
              <td>
                <i *ngIf="!item.realized" nz-icon nzType="sliders" nzTheme="outline" style="color: #0099FF"></i>
                <i *ngIf="item.realized" nz-icon nzType="rocket" nzTheme="outline" style="color: green"></i>
                {{  item.realized ? '已上线' : '研发中'}}
              </td>
              <td>{{ item.create_time.slice(0, 10) }}</td>
              <td>
                <a (click)="preOperate(2,item)"><i nz-icon nzType="monitor" nzTheme="outline"></i>编辑</a>&nbsp;&nbsp;&nbsp;&nbsp;
                <a (click)="preDelete(item)"><i nz-icon nzType="delete" nzTheme="outline"></i>删除</a>&nbsp;&nbsp;&nbsp;&nbsp;
              </td>
            </tr>
          </ng-container>
        </ng-container>
        </tbody>
      </nz-table>
    </div>
  </div>
</nz-card>

<!--添加系统菜单信息-->
<nz-drawer
  [nzBodyStyle]="{ height: 'calc(100% - 150px)', overflow: 'auto', 'padding-bottom': '53px' }"
  [nzMaskClosable]="true"
  [nzWidth]="720"
  [nzVisible]="operateIsVisible"
  nzTitle="{{operateModalName}}"
  (nzOnClose)="operateCancel()"
>
  <form nz-form [formGroup]="validateForm" class="ant-advanced-search-form">
    <div class="permission" style="width: 100%">
      <div nz-row [nzGutter]="24">
        <div nz-col [nzSpan]="24">
          <nz-form-item nzFlex>
            <nz-form-label style="width: 18%;text-align: left" nzRequired>父级菜单</nz-form-label>
            <nz-form-control style="width: 36%" nzErrorTip="请选择父级菜单!">
              <nz-tree-select
                [nzNodes]="selectOptions"
                nzShowSearch
                nzPlaceHolder="请选择父级菜单"
                formControlName="parent_id"
              >
              </nz-tree-select>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24">
          <nz-form-item nzFlex>
            <nz-form-label style="width: 18%;text-align: left;" nzRequired>name</nz-form-label>
            <nz-form-control style="width: 36%" nzErrorTip="请输入name!">
              <input nz-input placeholder="请输入name" formControlName="menu_name"/>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24">
          <nz-form-item nzFlex>
            <nz-form-label style="width: 18%;text-align: left;" nzRequired>title</nz-form-label>
            <nz-form-control style="width: 36%" nzErrorTip="请输入title!">
              <input nz-input placeholder="请输入title" formControlName="title"/>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24">
          <nz-form-item nzFlex>
            <nz-form-label style="width: 18%;text-align: left;" nzRequired>path</nz-form-label>
            <nz-form-control style="width: 36%" nzErrorTip="请输入path!">
              <input nz-input placeholder="请输入path" formControlName="path"/>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24">
          <nz-form-item nzFlex>
            <nz-form-label style="width: 18%;text-align: left;">hidden</nz-form-label>
            <nz-form-control style="width: 36%">
              <nz-switch formControlName="hidden" nzCheckedChildren="是" nzUnCheckedChildren="否"></nz-switch>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24">
          <nz-form-item nzFlex>
            <nz-form-label style="width: 18%;text-align: left;" nzRequired>图标</nz-form-label>
            <nz-form-control style="width: 36%">
              <div style="display: flex">
                <nz-upload [(nzFileList)]="fileList" [nzBeforeUpload]="beforeUpload" style="float: left">
                  <button nz-button><i nz-icon nzType="upload"></i>选择图标</button>
                </nz-upload>
                <button
                  nz-button
                  [nzType]="'primary'"
                  [nzLoading]="uploading"
                  (click)="handleUpload()"
                  [disabled]="fileList.length == 0"
                  style="margin-left:8%;float:right;">
                  {{ uploading ? '加载中' : '开始上传' }}
                </button>
              </div>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24">
          <nz-form-item nzFlex>
            <nz-form-label style="width: 18%;text-align: left;">开发进度</nz-form-label>
            <nz-form-control style="width: 36%">
              <nz-switch formControlName="realized" nzCheckedChildren="已上线" nzUnCheckedChildren="研发中"></nz-switch>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </div>
  </form>
  <div class="footer">
    <button type="button" (click)="operateCancel()" class="ant-btn" style="margin-right: 8px;"><span>取消</span>
    </button>
    <button type="button" (click)="operateCommit()" class="ant-btn ant-btn-primary"><span>提交</span></button>
  </div>
</nz-drawer>

<nz-card>
  <div class="table-area">
    <div class="param-area">
      <nz-space class="table-query">
        <nz-space-item style="width: 50%">
          <nz-select [nzPlaceHolder]="'请选择服务类型'" [(ngModel)]="serviceType" (ngModelChange)="loadObjectData()" style="width: 100%">
            <nz-option *ngFor="let option of queryOptions" [nzValue]="option.value"
                       [nzLabel]="option.label"></nz-option>
          </nz-select>
        </nz-space-item>
        <nz-space-item>
          <nz-input-group nzSearch [nzAddOnAfter]="suffixButton" >
            <input type="text" nz-input placeholder="输入关键字搜索" [(ngModel)]="keyword"/>
          </nz-input-group>
          <ng-template #suffixButton>
            <button nz-button nzType="primary" (click)="loadObjectData()">搜索</button>
          </ng-template>
        </nz-space-item>
        <nz-space-item>
          <button nz-button [nzType]="'default'" (click)="reset()">重置</button>
        </nz-space-item>
      </nz-space>
      <div class="table-operation">
        <button nz-button nzType="primary" (click)="preOperate(1,null)">
          <i nz-icon nzType="plus" nzTheme="outline"></i>
          <span>新增退款</span>
        </button>
<!--        <button nz-button nzType="default" (click)="preOperate(1,null)" style="margin-left: 8%">-->
<!--          <i nz-icon nzType="undo" nzTheme="outline"></i>-->
<!--          <span>误删恢复</span>-->
<!--        </button>-->
      </div>
    </div>
    <div>
      <nz-table
        nzSize="small"
        [nzData]="objects"
        [(nzPageSize)]="size"
        [nzTotal]="total"
        [(nzPageIndex)]="page"
        [nzFrontPagination]="false"
        (nzPageIndexChange)="indexChange()"
        (nzPageSizeChange)="indexChange()"
        [nzShowSizeChanger]="true"
        [nzPageSizeOptions]="[5,10,15,20]"
        [nzShowTotal]="totalTemplate"
        [nzHideOnSinglePage]="true"
      >
        <ng-template #totalTemplate>共 <span style="color:cornflowerblue;margin:0 3px;">{{ total }}</span>
          条
        </ng-template>
        <thead style="background: yellow">
        <tr>
          <th nzWidth="100px">服务编号</th>
          <th nzWidth="100px">服务类型</th>
          <th nzWidth="100px">服务名称</th>
          <th nzWidth="100px">上架状态</th>
          <th nzWidth="100px">创建时间</th>
          <th nzWidth="100px">操作</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let object of objects">
          <td>{{ object.service_code }}</td>
          <td>{{ object.service_type == 1 ? '订阅服务' : '增值服务' }}</td>
          <td>{{ object.service_name }}</td>
          <td>
            <nz-badge *ngIf="object.status" nzStatus="processing"></nz-badge>
            <nz-badge *ngIf="!object.status" nzStatus="default"></nz-badge>
            {{  object.status ? '上架' : '下架'}}</td>
          <td>{{ object.create_time.slice(0, 10) }}</td>
          <td>
            <span><a (click)="preOperate(2,object)"><i nz-icon nzType="edit" nzTheme="outline"></i>编辑</a>&nbsp;&nbsp;&nbsp;</span>
<!--            <span *ngIf="object.status"><a (click)="preDownOrUp(object)"><i nz-icon nzType="delete" nzTheme="outline"></i>删除</a>&nbsp;&nbsp;&nbsp;</span>-->
            <span *ngIf="!object.status"><a  (click)="preDownOrUp(object)"><i nz-icon nzType="vertical-align-top"
                                                                                nzTheme="outline"></i>上架</a>&nbsp;&nbsp;&nbsp;</span>
            <span *ngIf="object.status" ><a (click)="preDownOrUp(object)"><i nz-icon nzType="vertical-align-bottom"
                                                                               nzTheme="outline"></i>下架</a></span>
          </td>
        </tr>
        </tbody>
      </nz-table>
    </div>
  </div>
</nz-card>

<nz-modal [(nzVisible)]="operateIsVisible" nzTitle="{{operateModalName}}" [nzFooter]="modalFooter"
          [nzMaskClosable]="true" (nzOnCancel)="operateCancel()">
  <form nz-form [formGroup]="validateForm">
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>服务类型</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="请选择服务类型!">
        <nz-select [nzPlaceHolder]="'请选择服务类型'" formControlName="service_type">
          <nz-option *ngFor="let option of operateOptions" [nzValue]="option.value"
                     [nzLabel]="option.label"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>服务名称</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="请输入服务名称!">
        <input nz-input placeholder="请输入服务名称" formControlName="service_name"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item *ngIf="switchIsVisible">
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>上架状态</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="请选择上架状态!">
        <nz-switch formControlName="status" nzCheckedChildren="上架" nzUnCheckedChildren="下架"></nz-switch>
      </nz-form-control>
    </nz-form-item>
  </form>
  <ng-template #modalFooter>
    <button nz-button nzType="default" (click)="operateCancel()">取消</button>
    <button nz-button nzType="primary" (click)="operateCommit()">确定</button>
  </ng-template>
</nz-modal>

<div class="login-box">
  <img [src]="'../../assets/login/logo.png'" style="margin-left: 5%;margin-top: 2%;width: 430.8px;height: 97.6px;" alt="">
  <div style="margin-left: 5%;display: flex">
    <div style="margin-left: 5%;margin-top: 2%;">
      <div class="login-content">
        <div class="login-header">诺翔运营支撑系统</div>
        <div class="login-form" style="margin-left: 19%">
          <form nz-form [formGroup]="validateForm" class="login-form">
            <nz-form-item>
              <nz-form-control nzErrorTip="请输入账号!">
                <nz-input-group nzPrefixIcon="user">
                  <input type="text" nz-input formControlName="account" placeholder="输入账号或手机号登录"/>
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-control nzErrorTip="请输入密码!">
                <nz-input-group nzPrefixIcon="lock">
                  <input type="password" nz-input formControlName="password" placeholder="输入登录密码"/>
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
            <div nz-row class="login-form-margin">
              <div nz-col [nzSpan]="12">
<!--                <label nz-checkbox formControlName="remember">-->
<!--                  <span>记住密码</span>-->
<!--                </label>-->
              </div>
              <div nz-col [nzSpan]="12">
                <a class="login-form-forgot" (click)="forgetPwd()">忘记密码</a>
              </div>
            </div>
            <button nz-button class="login-form-button login-form-margin" [nzType]="'primary'" (click)="goLogin()">登录
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<nz-modal [(nzVisible)]="forgetPwdIsVisible" nzTitle="忘记密码" [nzFooter]="modalFooter" (nzOnCancel)="forgetPwdCancel()">
  <form nz-form [formGroup]="forgetValidateForm">
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="mobile" nzRequired>手机号码</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="请输入手机号码!">
        <div nz-row [nzGutter]="8">
          <input formControlName="mobile" placeholder="请输入您的手机号码" nz-input/>
        </div>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="captcha" nzRequired>验证码</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="请输入验证码!">
        <div nz-row [nzGutter]="8">
          <div nz-col [nzSpan]="12">
            <input nz-input formControlName="captcha" id="Captcha">
          </div>
          <div nz-col [nzSpan]="12">
            <button nz-button (click)="sendCaptcha()" [disabled]="countDown">{{showButtonText}}</button>
          </div>
        </div>
      </nz-form-control>
    </nz-form-item>
  </form>
  <ng-template #modalFooter>
    <button nz-button nzType="default" (click)="forgetPwdCancel()">取消</button>
    <button nz-button nzType="primary" (click)="forgetPwdCommit()">确定</button>
  </ng-template>
</nz-modal>

<nz-modal [(nzVisible)]="resetPwdIsVisible" nzTitle="重设密码"  [nzFooter]="modalFooter1">
  <form nz-form [formGroup]="resetValidateForm">
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="password" nzRequired>新的密码</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="请输入新的密码!">
        <input nz-input type="password" formControlName="password" (ngModelChange)="updateConfirmValidator()" />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="checkPassword" nzRequired>确认密码</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" [nzErrorTip]="errorTpl">
        <input nz-input type="password" formControlName="check_password"/>
        <ng-template #errorTpl let-control>
          <ng-container *ngIf="control.hasError('required')">
            请输入确认密码!
          </ng-container>
          <ng-container *ngIf="control.hasError('confirm')">
            两次输入密码不一致!
          </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
  </form>
  <ng-template #modalFooter1>
    <button nz-button nzType="default" (click)="resetPwdCancel()">取消</button>
    <button nz-button nzType="primary" (click)="resetPwdCommit()">确定</button>
  </ng-template>
</nz-modal>

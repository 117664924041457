import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {selfHttp} from '../../../httpservice/http.service';
import {Router} from '@angular/router';
import { NzUploadFile} from 'ng-zorro-antd/upload';
import {NzMessageService} from 'ng-zorro-antd/message';
import {TabComponent} from '../../../common/tab/tab.component';
import {SimpleReuseStrategy} from '../../../service/SimpleReuseStrategy';


@Component({
  selector: 'app-sidebaruserInfo',
  templateUrl: './sidebaruserinfo.component.html',
  styleUrls: ['./sidebaruserinfo.component.css']
})
export class SidebaruserinfoComponent implements OnInit {
  validateForm: FormGroup;

  constructor(public fb: FormBuilder,
              public http: selfHttp,
              public router: Router,
              public msg: NzMessageService,
              public tab: TabComponent,
  ) {
    this.buildForm();
    this.getUserInfo();
    this.setAll();
  }

  public formdata: any;
  public userAvatar: string;
  sendMsgIsShow = false;
  reBindMobileIsShow = true;
  // 发送手机验证
  public countDownTime: number;
  public countDown = false;
  showButtonText = '发送短信验证码'; //可以控制动态改变的按钮提示信息
  showRebindText = '换绑手机';
  public mobileText = '当前手机号';
  public mobilePlaceHolderText = '当前手机号';
  loading = false;
  //isDisabled = true;
  public fileList2: any = [];
  uploading = false;
  binding = false;
  uploadIsShow = false;
  changeImageIsShow = true;
  matchCaptchaIsShow = false;
  start: any;
  public all: any = [];                         //存储所有的省市区数据
  public provinces: any = [];                    //存储省的信息
  public citys: any = [];
  public districts: any = [];

  ngOnInit(): void {

  }

  //上传校验
  buildForm() {
    this.validateForm = this.fb.group({
      id: [],
      account: [''],
      avatar: [''],
      province: ['', [this.selectProvince]],
      city: ['', [this.selectCity]],
      district: [''],
      address: [''],
      // Password: ['**********'],
      // Mobile: [],
      password: ['**********'],
      mobile: [''],
      captcha: [''],
      staff_sign: [''],
      CheckPassWord: [null, [Validators.required, this.confirmationValidator]],
    });

    // this.bindValidateForm = this.fb.group({
    //   ID: [],
    //   Password: ['**********'],
    //   Mobile: [],
    //   Captcha: [''],
    //   CheckPassWord: [null, [Validators.required, this.confirmationValidator]],
    // });
  }

  submitform() {
    this.formdata = this.validateForm.value;
    this.http.put('http://oss-api.nositek.com/oss-api/pcusers/self', this.formdata, res => {
      this.msg.success('编辑成功');
    });

  }

  return() {
    // 当前关闭的是第几个路由
    const index = this.tab.menuList.findIndex(p => p.url === '/sidebar/sidebaruserInfo');
    this.tab.menuList.splice(index, 1);
    // 删除复用
    SimpleReuseStrategy.deleteRouteSnapshot('/sidebar/sidebaruserInfo');
    this.router.navigateByUrl('/sidebar/home-page');
  }

  //点击更换头像的回调
  beforeUpload = (file: NzUploadFile): boolean => {
    this.fileList2.push(file);
    this.uploadIsShow = true;
    this.changeImageIsShow = false;
    return false;
  };


  //点击上传头像的回调
  handleUpload(): void {
    const formData = new FormData();
    formData.append('image', this.fileList2[0]);
    this.uploadIsShow = false;
    this.uploading = false;
    let tmpUrl:string
    this.http.post('http://oss-api.nositek.com/oss-api/loccay/image', formData, res => {
      tmpUrl = res.data;
      this.userAvatar = 'http://oss-api.nositek.com/oss-api/loccay/images/' + tmpUrl;
      this.fileList2 = [];
      this.http.put('http://oss-api.nositek.com/oss-api/pcusers/avatar', {
        id: this.validateForm.value.id,
        avatar: tmpUrl,
      }, res => {
        localStorage.setItem('avatar', tmpUrl);
        this.msg.success('上传成功');
      });
    });
    this.changeImageIsShow = true;
  }

  isVisible = false;

  updatePassword() {
    console.log('走到这里');
    this.validateForm.patchValue({
      'password': '',
      'CheckPassWord': '',
    });
    this.isVisible = true;
  }

  updateConfirmValidator(): void {
    Promise.resolve().then(() => this.validateForm.controls.CheckPassWord.updateValueAndValidity());
  }

  //验证账号密码是否一致
  confirmationValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return {required: true};
    } else if (control.value !== this.validateForm.controls.Password.value) {
      return {confirm: true, error: true};
    }
  };


  handleOk(): void {
    this.http.patch('http://oss-api.nositek.com/oss-api/loccay/server/password', {
        'ID': JSON.parse(localStorage.getItem('user'))['ID'],
        'Password': this.validateForm.value.Password,
      },
      res => {
        this.msg.success('修改成功,5s后重新登录');
        this.isVisible = false;
        setTimeout(() => {
          this.router.navigateByUrl('login');
        }, 5000);


      });

  }

  handleCancel(): void {
    this.isVisible = false;
    this.validateForm.patchValue({'password': '********'});
  }

  //本地获取用户信息
  getUserInfo() {
    //设置全部省市信息和省份单独信息
    this.http.get('assets/tree-link.json', {}, res => {
      this.all = res;
      this.provinces = this.setProvinces();
      this.formdata = JSON.parse(localStorage.getItem('user'));
      console.log(this.formdata);
      this.validateForm.patchValue({
        'id': this.formdata.id,
        'account': this.formdata.account,
        'avatar': this.formdata.avatar,
        'mobile': this.formdata.mobile,
        'province': this.formdata.province,
        'city': this.formdata.city,
        'district': this.formdata.district,
        'address': this.formdata.address,
      });

      if (JSON.parse(localStorage.getItem('user'))['avatar'] != '') {
        this.userAvatar = 'http://oss-api.nositek.com/oss-api/loccay/images/' + JSON.parse(localStorage.getItem('user'))['avatar'];
      } else {
        this.msg.info('暂未设置logo');
      }
    });
  }

//点击发送短信验证码的回调
  sendCaptcha() {
    this.countDown = true;
    this.countDownTime = 10;
    this.showButtonText = '验证码已发送（' + 10 + 's）'; // 设置按钮显示内容
    this.start = setInterval(() => {
      if (this.countDownTime >= 0) {
        this.showButtonText = '验证码已发送(' + this.countDownTime-- + 's)';     // 动态的进行倒计时
      } else {
        clearInterval(this.start);             // 如果超时则重新发送
        this.showButtonText = '重新发送';
        this.countDown = false;         // 按钮再次变成可点击状态
        this.countDownTime = 10;
      }
    }, 1000);

    this.http.get('http://oss-api.nositek.com/oss-api/loccay/captcha', {
        'mobile': this.validateForm.value.Mobile
      },
      res => {
        this.matchCaptchaIsShow = true;
        console.log('结果', res.data);
        localStorage.setItem('captcha', res.data);
      });

  }

// 点击换绑手机号的回调
  setSendMsgShow() {
    this.sendMsgIsShow = true;
    this.reBindMobileIsShow = false;
  }

// 点击确定验证码的回调
  matchCaptcha() {
    if (this.validateForm.value.Captcha != localStorage.getItem('captcha')) {
      //console.log(this.isDisabled);
      this.msg.error('验证码错误');
    } else if (this.validateForm.value.Captcha == localStorage.getItem('captcha')) {
      // this.isDisabled = false;
      this.mobileText = '新手机号';
      this.mobilePlaceHolderText = '请输入新的可用手机号';
      this.validateForm.patchValue({'mobile': '', 'captcha': ''});
      clearInterval(this.start);
      this.countDown = false;
      this.sendMsgIsShow = true;
      this.showButtonText = '发送短信验证码';
    }
  }

//省市联动
  setAll() {
    this.http.get('assets/tree-link.json', {}, res => {
      this.all = res;
      this.provinces = this.setProvinces();
      console.log(this.provinces);
    });
  }

  setProvinces() {
    if (this.all !== undefined) {
      const result = [];
      for (let i = 0; i < this.all.length; i++) {
        const value = this.all[i];
        if (value['item_code'].slice(2, 6) === '0000') {
          result.push(value);
        }
      }

      return result;
    }
  }

  setCity(province: string) {
    if (this.all !== undefined) {
      const result = [];
      for (let i = 0; i < this.all.length; i++) {
        const value = this.all[i];
        if (value['item_code'].slice(0, 2) === province.slice(0, 2)
          && value['item_code'] !== province && value['item_code'].slice(4, 6) === '00') {
          result.push(value);
        }
      }
      return result;
    }
  }

  setDistrict(city: string) {
    if (this.all !== undefined) {
      const result = [];
      for (let i = 0; i < this.all.length; i++) {
        const value = this.all[i];
        if (value['item_code'].slice(0, 4) === city.slice(0, 4)
          && value['item_code'] !== city && value['item_code'].slice(4, 6) != '00') {
          result.push(value);
        }
      }
      return result;
    }
  }

  selectProvince = (control: FormControl): any => {
    this.citys = this.setCity(control.value.slice(0, 6));
  };

  selectCity = (control: FormControl): any => {
    this.districts = this.setDistrict(control.value.slice(0, 6));
  };

  updateIsVisible = false;
  reBindMobileIsVisible = false;
  public prefixMobile: string;
  public suffixMobile: string;

  toUpdateMobile() {
    this.prefixMobile = (this.validateForm.value.mobile + '').slice(0, 3);
    this.suffixMobile = (this.validateForm.value.mobile + '').slice(7, 11);
    this.updateIsVisible = true;

  }

  handleMobileOk(): void {
    if (this.validateForm.value.captcha != localStorage.getItem('captcha')) {
      this.msg.error('验证码错误');
    } else if (this.validateForm.value.Captcha == localStorage.getItem('captcha')) {
      this.reBindMobileIsVisible = true;
      this.updateIsVisible = false;
      this.validateForm.patchValue({
        'mobile': ''
      });
    }
  }

  handleMobileCancel(): void {
    this.updateIsVisible = false;
    this.validateForm.patchValue({
      'captcha': ''
    });
  }

  handleReBindMobileOk(): void {
    if (this.validateForm.value.captcha != localStorage.getItem('captcha')) {
      this.msg.error('验证码错误');
    } else if (this.validateForm.value.captcha == localStorage.getItem('captcha')) {
      let tempMobile = this.validateForm.value.mobile;
      this.http.put('http://oss-api.nositek.com/oss-api/pcre_bind_mobile', {
        'id': JSON.parse(localStorage.getItem('user'))['id'],
        'mobile': this.validateForm.value.mobile
      }, res => {
        this.reBindMobileIsVisible = false;
        this.validateForm.patchValue({
          'mobile': tempMobile
        });
      });

    }
  }

  handleReBindMobileCancel(): void {
    this.updateIsVisible = true;
    this.reBindMobileIsVisible = false;
    this.validateForm.patchValue({
      'captcha': ''
    });

  }
}

import {Component, ViewChild, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzModalService} from 'ng-zorro-antd/modal';
import {selfHttp} from "@src/app/httpservice/http.service";
import {HttpHeaders} from '@angular/common/http';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NzTreeComponent} from 'ng-zorro-antd/tree';

@Component({
  selector: 'products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})


export class ProductsComponent implements OnInit {
  @ViewChild('nzTreeComponent') nzTreeComponent: NzTreeComponent;
  validateForm: FormGroup;
  // itemValidateForm: FormGroup;
  //权限相关
  addAccess = false;
  editAccess = false;
  //查询相关
  queryServiceTypeOptions = [{label: '全部', value: 0}, {label: '订阅服务', value: 1}, {label: '增值服务', value: 2}]
  // timeOptions = [
  //   {label: '不限制', value: 0},
  //   {label: '1个月', value: 1},
  //   {label: '2个月', value: 2},
  //   {label: '3个月', value: 3},
  //   {label: '4个月', value: 4},
  //   {label: '5个月', value: 5},
  //   {label: '半年', value: 6},
  //   {label: '7个月', value: 7},
  //   {label: '8个月', value: 8},
  //   {label: '9个月', value: 9},
  //   {label: '10个月', value: 10},
  //   {label: '11个月', value: 11},
  //   {label: '一年', value: 12},
  // ]
  queryServiceOptions = [];
  serviceType: string = '';
  serviceId: string = '';
  keyword: string = '';
  size: number = 10;
  page: number = 1;
  total: number = 0;

  //操作相关
  operateType: number = 1;//1-添加，2-编辑
  operateModalName = '新增产品'
  operateIsVisible = false;
  switchIsVisible = false;

  objects = [];
  skus = [];
  operateOptions = [];
  public objectInfo: any;
  public options: any;


  constructor(public router: Router,
              public fb: FormBuilder,
              public http: selfHttp,
              public modalService: NzModalService,
              public msg: NzMessageService,
  ) {
    this.loadObjectData();
    this.loadQueryOption();
    this.loadAccess();
    this.buildForm();
  }

  buildForm() {
    this.validateForm = this.fb.group({
      id: [0],
      service_id: [null, [Validators.required]],
      product_name: [null, [Validators.required]],
      single_price: [null, [Validators.required]],
      product_unit: [null, [Validators.required]],
      status: [true, [Validators.required]],
      scene_bind: [null, [Validators.required]],
    });
    // this.itemValidateForm = this.fb.group({
    //   arrayList: this.fb.array([]),
    //   product_number: [1, [Validators.required]],
    //   effective_time: [0, [Validators.required]],
    //   discount: [100, [Validators.required]],
    //   discounted_price: [0, [Validators.required]],
    //   vital_price: [0, [Validators.required]],
    //   vital_discounted_price: [0, [Validators.required]],
    // })
  }

  ngOnInit(): void {
  }

  //todo:边界测试-输入长度
  checkInputLength() {

  }

  loadObjectData() {
    this.http.get('http://oss-api.nositek.com/oss-api/pc/products', {
      page: this.page,
      size: this.size,
      service_type: this.serviceType,
      service_id: this.serviceId,
      keyword: this.keyword,
    }, res => {
      if (res.data['rows'] != null && res.data['rows'].length > 0) {
        this.objects = res.data['rows'];
        this.total = Number(res.data['total']);
      } else {
        this.objects = [];
        this.total = 0;
        this.msg.info('暂无数据');
      }
    });
  }

  loadQueryOption() {
    this.http.get('http://oss-api.nositek.com/oss-api/pc/services', {}, res => {
      if (res.data['rows'] != null && res.data['rows'].length > 0) {
        this.queryServiceOptions = res.data['rows'];
        this.queryServiceOptions.unshift({id: 0, service_name: '全部'})
      } else {
        this.msg.info('暂无数据');
      }
    });
  }

// 重置
  reset() {
    this.page = 1;
    this.size = 5;
    this.serviceType = '';
    this.serviceId = '';
    this.keyword = '';
    this.loadObjectData();
  }

  //页码切换
  indexChange() {
    this.loadObjectData();
  }

  /*
  新增/编辑产品
   */
  loadRelayData() {
    this.http.get('http://oss-api.nositek.com/oss-api/pc/services', {}, res => {
      if (res.data['rows'] != null && res.data['rows'].length > 0) {
        this.operateOptions = res.data['rows'];
      } else {
        this.msg.info('暂无数据');
      }
    });
  }

  preOperate(operateType, object) {
    // this.arrayList.clear();
    switch (operateType) {
      case 1://新增
        this.operateModalName = '新增产品'
        this.switchIsVisible = true;
        break
      case 2://编辑
        this.operateModalName = '编辑产品'
        this.switchIsVisible = false;
        this.validateForm.patchValue({
          id: object.id,
          service_id: object.service_id,
          product_name: object.product_name,
          product_unit: object.product_unit,
          single_price: object.single_price,
          scene_bind: '' + object.scene_bind,
        })
      // object.skus.forEach((item, index) => {
      //   this.arrayList.push(this.fb.group({
      //     product_number: item.product_number,
      //     effective_time: item.effective_time,
      //     discount: item.discount,
      //     discounted_price: item.discounted_price,
      //     vital_price: item.vital_price,
      //     vital_discounted_price: item.vital_discounted_price,
      //   }))
      // })
    }
    this.loadRelayData()
    this.operateIsVisible = true;
  }

  //添加取消
  operateCancel() {
    this.validateForm.reset();
    this.validateForm.patchValue({
      id: 0,
      status: true,
    });
    this.operateIsVisible = false;
  }

  //添加提交
  operateCommit() {
    // this.skus = []
    // this.arrayList.controls.forEach((item, index) => {
    //   item.patchValue({
    //     discounted_price: parseFloat(item['value']['discounted_price']),
    //     vital_price: parseFloat(item['value']['vital_price']),
    //     vital_discounted_price: parseFloat(item['value']['vital_discounted_price']),
    //   })
    //   this.skus.push(item['value'])
    // })
    let formData = this.validateForm.value;
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    if (this.validateForm.value['service_id'] && this.validateForm.value['product_name'] && this.validateForm.value['product_unit'] &&
      this.validateForm.value.scene_bind) {
      this.http.post('http://oss-api.nositek.com/oss-api/pc/products', {
        id: this.validateForm.value.id,
        service_id: this.validateForm.value.service_id,
        product_name: this.validateForm.value.product_name,
        single_price: this.validateForm.value.single_price,
        product_unit: this.validateForm.value.product_unit,
        status: this.validateForm.value.status,
        scene_bind: Number(this.validateForm.value.scene_bind),
        // skus: this.skus
      }, res => {
        if (res.code == 2000) {
          if (formData.id == 0) {
            this.msg.success('添加成功');
          } else {
            this.msg.success('编辑成功');
          }

        } else if (res.code == 100003) {
          this.msg.info(res.msg)
        } else {
          this.msg.error(res.msg)
        }
        this.loadObjectData();
      });
      this.validateForm.reset();
      this.validateForm.patchValue({
        id: 0,
        status: true,
      });
      this.operateIsVisible = false;
    }
  }


  /*
  上下架
   */
  preDownOrUp(object) {
    if (object.status) {
      this.modalService.confirm({
        nzTitle: '<i>确定下架产品 ？</i>',
        nzContent: '<b></b>',
        nzOkText: '确定',
        nzCancelText: '取消',
        nzOnOk: () => this.down2upCommit(object),
        nzOnCancel: () => this.down2upCancel()
      });
    } else {
      this.down2upCommit(object)
    }
  }

  // 上下架操作提交
  down2upCommit(object) {
    this.http.put('http://oss-api.nositek.com/oss-api/pc/products', {
      id: object.id,
      status: !object.status
    }, res => {
      if (res.code == 2000) {
        if (object.status) {
          this.msg.success('产品已下架');
        } else {
          this.msg.success('产品已上架');
        }
      } else {
        this.msg.info(res.msg);
      }
      this.loadObjectData();
    });
  }

  // 上/下架取消
  down2upCancel() {
    this.loadObjectData();
  }


  // inputChange(index: number, changeType: any) {
  //   if (this.validateForm.value.single_price) {
  //     switch (changeType) {
  //       case 1://1-SKU内部变动
  //         if (this.arrayList.controls[index]['value']['product_number'] || this.arrayList.controls[index]['value']['discount']) {
  //           this.arrayList.controls[index].patchValue({
  //             discounted_price: (this.validateForm.value.single_price * this.arrayList.controls[index]['value']['discount'] / 100).toFixed(2),
  //             vital_price: (this.validateForm.value.single_price * this.arrayList.controls[index]['value']['product_number']).toFixed(2),
  //             vital_discounted_price: (this.validateForm.value.single_price * this.arrayList.controls[index]['value']['product_number'] * this.arrayList.controls[index]['value']['discount'] / 100).toFixed(2),
  //           })
  //         }
  //         break
  //       case 2://2-外部单价变动
  //         this.arrayList.controls.forEach((item, index) => {
  //           if (item['value']['product_number'] || item['value']['discount']) {
  //             item.patchValue({
  //               discounted_price: (this.validateForm.value.single_price * item['value']['discount'] / 100).toFixed(2),
  //               vital_price: (this.validateForm.value.single_price * item['value']['product_number']).toFixed(2),
  //               vital_discounted_price: (this.validateForm.value.single_price * item['value']['product_number'] * item['value']['discount'] / 100).toFixed(2),
  //             })
  //           }
  //         })
  //         break
  //     }
  //   }
  // }

  // /**
  //  * 获取数组对象
  //  */
  // get arrayList() {
  //   // return this.itemValidateForm.get('arrayList') as FormArray;
  // }
  //
  // /**
  //  * 创建一行组件
  //  */
  // createRow() {
  //   return this.fb.group({
  //     product_number: [1],
  //     effective_time: [0],
  //     discount: [100],
  //     discounted_price: [this.validateForm.value.single_price],
  //     vital_price: [this.validateForm.value.single_price],
  //     vital_discounted_price: [this.validateForm.value.single_price],
  //   });
  // }
  //
  // /**
  //  * 增加一行事件
  //  */
  // addBtn() {
  //   this.arrayList.push(this.createRow());
  // }
  //
  // /**
  //  * 删除一行事件
  //  */
  // delBtn(i: number) {
  //   this.arrayList.removeAt(i);
  // }
  //
  // /**
  //  * 保存数据
  //  */
  // saveData() {
  //   this.skus = []
  //   this.arrayList.controls.forEach((item, index) => {
  //     console.log(item)
  //     this.skus.push(item['value'])
  //   })
  //   console.log(this.skus)
  // }
  //
  // /**
  //  * 清空数据
  //  */
  // clearData() {
  //   this.arrayList.controls.forEach(item => {
  //     item.patchValue({
  //       service_id: '',
  //       product_id: '',
  //       product_number: '',
  //     });
  //   });
  // }

  add_dept = false;
  edit_dept = false;
  delete_dept = false;
  add_user = true;
  edit_user = false;
  delete_user = false;

  loadAccess() {
    console.log('加载页面操作权限');
    // let tempArr = JSON.parse(localStorage.getItem('access'));
    // console.log(tempArr);
    // this.add_dept = tempArr.findIndex(v => v.name == 'add_dept') != -1;
    // this.edit_dept = tempArr.findIndex(v => v.name == 'edit_dept') != -1;
    // this.delete_dept = tempArr.findIndex(v => v.name == 'delete_dept') != -1;
    // this.add_user = tempArr.findIndex(v => v.name == 'add_user') != -1;
    // this.edit_user = tempArr.findIndex(v => v.name == 'edit_user') != -1;
    // this.delete_user = tempArr.findIndex(v => v.name == 'delete_user') != -1;
  }
}

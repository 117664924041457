import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzModalService} from 'ng-zorro-antd/modal';
import {selfHttp} from '../../httpservice/http.service';
import {HttpHeaders} from '@angular/common/http';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NzTreeNodeOptions} from 'ng-zorro-antd/tree';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})


export class NotificationComponent implements OnInit {
  validateForm: FormGroup;

  //权限相关
  addAccess = false;
  editAccess = false;
  deleteAccess = false;

  //查询相关
  allChecked: any = false;
  checkChild: any = false;
  keyword: any = '';
  msgType: number;
  msgTypes = [
    {
      'value': 0,
      'label': '系统',
    },
    {
      'value': 1,
      'label': '人工',
    }
  ];
  size = 10;
  page: any = 1;
  total: any = 1;
  objects = [];

  //操作相关
  operateOptions = [];
  operateItem: any;
  indeterminate = false;
  addIsVisible = false;
  editIsVisible = false;
  accessCodes: any = [];
  currentUser: any;

  constructor(public router: Router,
              public fb: FormBuilder,
              public http: selfHttp,
              public modalService: NzModalService,
              public msg: NzMessageService,
  ) {
    this.loadObjectData();
    this.loadAccess();
    this.buildForm();
  }

  buildForm() {
    this.validateForm = this.fb.group({
      title: [null, [Validators.required]],
      context: [null, [Validators.required]],
      visibleUserIds: [null, [Validators.required]],
    });
  }

  ngOnInit(): void {
  }

  //查询表格数据、
  loadObjectData() {
    this.http.get('http://oss-api.nositek.com/oss-api/pcnotifications', {
      'page': this.page,
      'size': this.size,
      'keyword': this.keyword,
      'msg_type': this.msgType,
    }, res => {
      if (res.data['rows'] != null && res.data['rows'].length > 0) {
        this.objects = res.data['rows'];
        this.total = Number(res.data['total']);
      } else {
        this.objects = [];
        this.total = 0;
        this.msg.info('暂无数据');
      }
    });
  }

  search() {
    this.page = 1;
    this.loadObjectData();
  }

  //跳转到添加角色页面
  preAdd() {
    this.addIsVisible = true;
    this.loadTreeData();
  }

  //添加角色取消
  addCancel() {
    this.validateForm.reset();
    this.addIsVisible = false;
  }


  //添加角色提交
  addCommit() {
    this.http.post('http://oss-api.nositek.com/oss-api/pcnotifications', {
      'title': this.validateForm.value.title,
      'context': this.validateForm.value.context,
      'visible_user_ids': this.visibleUserIds,
    }, res => {
      if (res.code != 2000) {
        this.msg.error(res.msg);
        this.validateForm.reset();
      } else {
        this.msg.success('发布成功');
        this.loadObjectData();
        this.validateForm.reset();
      }
    });
    this.addIsVisible = false;
  }

  visibleUserIds = [];

  onTreeCheckBoxChange(event: any): void {
    this.visibleUserIds = [];
    //获取所有level=2级别的key
    let tmpArr = event.checkedKeys;
    console.log(tmpArr);
    for (let i = 0; i < tmpArr.length; i++) {
      if (tmpArr[i].level == 0) {
        for (let h = 0; h < tmpArr[i].children.length; h++) {
          for (let j = 0; j < tmpArr[i].children[h].children.length; j++) {
            this.visibleUserIds.push(Number(tmpArr[i].children[h].children[j].key));
          }
        }
      } else if (tmpArr[i].level == 1) {
        for (let l = 0; l < tmpArr[i].children.length; l++) {
          this.visibleUserIds.push(Number(tmpArr[i].children[l].key));
        }
      } else if (tmpArr[i].level == 2) {
        this.visibleUserIds.push(Number(tmpArr[i].key));
      }
    }
    console.log(this.visibleUserIds);
  }


  reset() {
    this.page = 1;
    this.size = 10;
    this.keyword = '';
    this.loadObjectData();
  }

  treeObjects: NzTreeNodeOptions[];
  nodes: NzTreeNodeOptions[] = [];

  loadTreeData() {
    this.http.get('http://oss-api.nositek.com/oss-api/pctree', {}, res => {
      if (res.data != null) {
        this.treeObjects = res.data;
      } else {
        this.treeObjects = [];
        this.msg.info('暂无数据');
      }

      if (this.treeObjects.length > 0) {
        this.generateTree(this.treeObjects);
      }
    });

  }

  generateTree(arr: Array<any>) {
    let tmpNodes = [];
    let rootNodeChildren = [];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].children != null && arr[i].children.length > 0) {
        let children = [];
        for (let h = 0; h < arr[i].children.length; h++) {
          children.push({
            key: arr[i].children[h].key,
            title: arr[i].children[h].title,
            isLeaf: true
          });
        }

        rootNodeChildren.push({
          key: arr[i].key,
          title: arr[i].title,
          children: children,
        });
      } else {
        rootNodeChildren.push({
          key: arr[i].key,
          title: arr[i].title,
        });
      }
    }

    tmpNodes.push({
      key: '100',
      title: '全体员工',
      children: rootNodeChildren,
    });

    this.nodes = tmpNodes;
  }

  indexChange() {
    this.loadObjectData();
  }


  //设置按钮显示与否
  add_role = false;
  edit_role = false;
  delete_role = false;

  loadAccess() {
    console.log('加载页面操作权限');
    let tempArr = JSON.parse(localStorage.getItem('access'));
    console.log(tempArr);
    this.add_role = tempArr.findIndex(v => v.name == 'add_role') != -1;
    this.edit_role = tempArr.findIndex(v => v.name == 'edit_role') != -1;
    this.delete_role = tempArr.findIndex(v => v.name == 'delete_role') != -1;
  }
}

<nz-card>
  <div class="table-area">
    <div class="param-area">
      <nz-space class="table-query">
        <nz-space-item>
          <nz-range-picker></nz-range-picker>
        </nz-space-item>
        <nz-space-item>
          <nz-select nzShowSearch nzAllowClear nzPlaceHolder="请选择账号状态" [(ngModel)]="selectedValue">
            <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
            <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
            <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
          </nz-select>
        </nz-space-item>
        <nz-space-item>
          <nz-input-group nzSearch [nzAddOnAfter]="suffixButton">
            <input type="text" nz-input placeholder="输入关键字搜索" [(ngModel)]="keyword"/>
          </nz-input-group>
          <ng-template #suffixButton>
            <button nz-button nzType="primary" (click)="loadObjectData()">搜索</button>
          </ng-template>
        </nz-space-item>
        <nz-space-item>
          <button nz-button [nzType]="'default'" (click)="reset()">重置</button>
        </nz-space-item>
      </nz-space>
<!--      <div class="table-operation">-->
<!--        <button nz-button nzType="primary" (click)="preAdd()">-->
<!--          <i nz-icon nzType="audit" nzTheme="outline"></i>-->
<!--          <span>批量审批</span>-->
<!--        </button>-->
<!--      </div>-->
    </div>
    <div>
      <nz-table
        nzSize="small"
        [nzData]="objects"
        [(nzPageSize)]="size"
        [nzTotal]="total"
        [(nzPageIndex)]="page"
        [nzFrontPagination]="false"
        (nzPageIndexChange)="indexChange()"
        (nzPageSizeChange)="indexChange()"
        [nzShowSizeChanger]="true"
        [nzPageSizeOptions]="[5,10,15,20]"
        [nzShowTotal]="totalTemplate"
        [nzHideOnSinglePage]="true"
      >
        <ng-template #totalTemplate>共 <span style="color:cornflowerblue;margin:0 3px;">{{ total }}</span>
          条
        </ng-template>
        <thead style="background: yellow">
        <tr>
<!--          <th nzWidth="50px"></th>-->
          <th nzWidth="100px">模板编号</th>
          <th nzWidth="150px">客户名称</th>
          <th nzWidth="100px">模板名称</th>
          <th nzWidth="100px">模板类型</th>
          <th nzWidth="100px">申请时间</th>
          <th nzWidth="100px">申请人</th>
          <th nzWidth="100px">审批时间</th>
          <th nzWidth="100px">审批人</th>
          <th nzWidth="100px">审核状态</th>
          <th nzWidth="100px">说明</th>
          <th nzWidth="100px">操作</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let object of objects">
<!--          <td nzShowCheckbox [(nzChecked)]="object.checked"-->
<!--              (nzCheckedChange)="onChange(object,'single')"></td>-->
          <td>{{ object.id }}</td>
          <td>{{ object.company_name }}</td>
          <td>{{ object.template_name }}</td>
          <td>
            <span *ngIf="object.template_type==1">文本短信</span>
            <span *ngIf="object.template_type==2">视频短信</span>
          </td>
          <td>{{ object.create_time.slice(0,10) }}</td>
          <td>{{ object.create_user_name }}</td>
          <td>{{ object.audit_time.slice(0,10)=='2006-01-02'?'暂未审批': object.audit_time.slice(0,10)}}</td>
          <td>{{ object.audit_user_name }}</td>
          <td><span>
            <span *ngIf="object.status==0">待提交</span>
            <span *ngIf="object.status==1">审核中</span>
            <span *ngIf="object.status==2">已通过</span>
            <span *ngIf="object.status==3">未通过</span>
          </span></td>
          <td>{{ object.remark }}</td>
          <td>
            <span *ngIf="object.status==1"><a (click)="preEdit(object)"><i nz-icon nzType="audit" nzTheme="outline"></i>审核</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          </td>
        </tr>
        </tbody>
      </nz-table>
    </div>
  </div>
</nz-card>

<!--审核-->
<nz-modal [(nzVisible)]="editIsVisible" nzTitle="审批" [nzFooter]="modalFooter"
          [nzMaskClosable]="true" (nzOnCancel)="editCancel()">
  <form nz-form [formGroup]="validateForm">
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>审核状态</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24">
        <nz-switch formControlName="status" nzCheckedChildren="通过" nzUnCheckedChildren="驳回"></nz-switch>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>审核说明</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24">
        <textarea rows="4" nz-input placeholder="请输入审核说明" formControlName="remark"></textarea>
      </nz-form-control>
    </nz-form-item>
  </form>
  <ng-template #modalFooter>
    <button nz-button nzType="default" (click)="editCancel()">取消</button>
    <button nz-button nzType="primary" (click)="editCommit()">确定</button>
  </ng-template>
</nz-modal>

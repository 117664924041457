import {Component, OnInit,} from '@angular/core';
import {Router} from "@angular/router";
import {NzMessageService} from "ng-zorro-antd/message";
import {NzModalService} from "ng-zorro-antd/modal";
import {selfHttp} from "../../../httpservice/http.service";

@Component({
  selector: 'app-sidebarhistory',
  templateUrl: './sidebarhistory.component.html',
  styleUrls: ['./sidebarhistory.component.css']
})
export class SidebarhistoryComponent implements OnInit {


  ngOnInit(): void {
  }

  constructor(private router: Router,
              private http: selfHttp,
              private modalService: NzModalService,
              public msg: NzMessageService,
  ) {

  }




}

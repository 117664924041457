import {Component, ViewChild, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzModalService} from 'ng-zorro-antd/modal';
import {selfHttp} from "@src/app/httpservice/http.service";
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NzTreeComponent} from 'ng-zorro-antd/tree';

@Component({
  selector: 'server',
  templateUrl: './server.component.html',
  styleUrls: ['./server.component.css']
})


export class ServerComponent implements OnInit {
  @ViewChild('nzTreeComponent') nzTreeComponent: NzTreeComponent;
  validateForm: FormGroup;
  //权限相关
  addAccess = false;
  editAccess = false;
  //查询相关
  companyId: number = null;
  keyword: string = '';
  size: number = 5;
  page: number = 1;
  total: number = 0;

  //操作相关
  operateType: number = 1;//1-添加，2-编辑
  operateModalName = '新增配置'
  operateIsVisible = false;
  selectDisabled = false;

  objects = [];
  public objectInfo: any;
  public options: any;
  queryOptions = []
  smsTypeOptions = [{value: 1, label: '文本短信'}, {value: 2, label: '视频短信'}]

  constructor(public router: Router,
              public fb: FormBuilder,
              public http: selfHttp,
              public modalService: NzModalService,
              public msg: NzMessageService,
  ) {
    this.loadObjectData();
    this.loadRelyOptions();
    this.loadAccess();
    this.buildForm();
  }

  buildForm() {
    this.validateForm = this.fb.group({
      id: [0],
      company_id: [null, [Validators.required]],
      sms_type: [null, [Validators.required]],
      sms_config: [null, [Validators.required]],
      status: [true, [Validators.required]],
    });
  }

  ngOnInit(): void {
  }

  //todo:边界测试-输入长度
  checkInputLength() {

  }

  loadRelyOptions() {
    this.queryOptions = [];
    this.http.get('http://oss-api.nositek.com/oss-api/pc/ai-msg/companies', {}, res => {
      if (res.data['rows'] != null && res.data['rows'].length > 0) {
        this.queryOptions = res.data['rows'];
      } else {
        this.queryOptions = [];
        this.msg.info('暂无数据');
      }
    });
  }

  loadObjectData() {
    this.http.get('http://oss-api.nositek.com/oss-api/pc/ai-msg/config', {
      page: this.page,
      size: this.size,
      company_id: this.companyId,
    }, res => {
      if (res.data['rows'] != null && res.data['rows'].length > 0) {
        this.objects = res.data['rows'];
        this.total = Number(res.data['total']);
      } else {
        this.objects = [];
        this.total = 0;
        this.msg.info('暂无数据');
      }
    });
  }

// 重置
  reset() {
    this.page = 1;
    this.size = 5;
    this.companyId = null;
    this.keyword = '';
    this.loadObjectData();
  }

  //页码切换
  indexChange() {
    this.loadObjectData();
  }

  /*
  新增/编辑配置
   */
  preOperate(operateType, object) {
    this.validateForm.patchValue({
      id: 0,
      status: true,
    });
    this.loadRelyOptions();
    switch (operateType) {
      case 1://新增
        this.operateModalName = '新增配置'
        this.selectDisabled = false;
        break
      case 2://编辑
        this.operateModalName = '编辑配置'
        this.selectDisabled = true;
        let status: boolean
        if (object.status == 1) {
          status = true
        }
        this.validateForm.patchValue({
          id: object.id,
          company_id: object.company_id,
          sms_type: object.sms_type,
          sms_config: object.sms_config,
          status: status,
        })
    }
    this.operateIsVisible = true;
  }

  //添加取消
  operateCancel() {
    this.validateForm.reset();
    this.validateForm.patchValue({
      id: 0,
      status: true,
    });
    this.operateIsVisible = false;
  }

  //添加提交
  operateCommit() {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    let status: number;
    if (this.validateForm.value.status) {
      status = 1
    }
    if (this.validateForm.value.sms_type && this.validateForm.value.sms_config && this.validateForm.value.company_id) {
      this.http.post('http://oss-api.nositek.com/oss-api/pc/ai-msg/config', {
        id: this.validateForm.value.id,
        company_id: this.validateForm.value.company_id,
        sms_type: this.validateForm.value.sms_type,
        sms_config: this.validateForm.value.sms_config,
        status: status,
      }, res => {
        if (res.code == 2000) {
          if (this.validateForm.value.id == 0) {
            this.msg.success('添加成功');
          } else {
            this.msg.success('编辑成功');
          }

        } else if (res.code == 100003) {
          this.msg.info(res.msg)
        } else {
          this.msg.error(res.msg)
        }
        this.loadObjectData();
      });
      this.operateIsVisible = false;
    }
  }


  /*
  上下架
   */
  preDownOrUp(object) {
    if (object.status) {
      this.modalService.confirm({
        nzTitle: '<i>确定下架配置 ？</i>',
        nzContent: '<b></b>',
        nzOkText: '确定',
        nzCancelText: '取消',
        nzOnOk: () => this.down2upCommit(object),
        nzOnCancel: () => this.down2upCancel()
      });
    } else {
      this.down2upCommit(object)
    }
  }

  // 上下架操作提交
  down2upCommit(object) {
    this.http.put('http://oss-api.nositek.com/oss-api/pc/services', {
      id: object.id,
      status: !object.status
    }, res => {
      if (res.code == 2000) {
        if (object.status) {
          this.msg.success('配置已下架');
        } else {
          this.msg.success('配置已上架');
        }
      } else {
        this.msg.info(res.msg);
      }
      this.loadObjectData();
    });
  }

  // 上/下架取消
  down2upCancel() {
    this.loadObjectData();
  }


  add_dept = false;
  edit_dept = false;
  delete_dept = false;
  add_user = true;
  edit_user = false;
  delete_user = false;

  loadAccess() {
    console.log('加载页面操作权限');
    // let tempArr = JSON.parse(localStorage.getItem('access'));
    // console.log(tempArr);
    // this.add_dept = tempArr.findIndex(v => v.name == 'add_dept') != -1;
    // this.edit_dept = tempArr.findIndex(v => v.name == 'edit_dept') != -1;
    // this.delete_dept = tempArr.findIndex(v => v.name == 'delete_dept') != -1;
    // this.add_user = tempArr.findIndex(v => v.name == 'add_user') != -1;
    // this.edit_user = tempArr.findIndex(v => v.name == 'edit_user') != -1;
    // this.delete_user = tempArr.findIndex(v => v.name == 'delete_user') != -1;
  }
}

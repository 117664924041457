<!--开户-->
<nz-card class="card-body">
  <nz-steps [nzCurrent]="current" class="steps-title">
    <nz-step nzTitle="客户信息"></nz-step>
    <nz-step nzTitle="产品服务"></nz-step>
    <nz-step nzTitle="合同信息"></nz-step>
    <nz-step nzTitle="完成"></nz-step>
  </nz-steps>
  <div class="steps-content">
    <div *ngIf="current==0">
      <nz-card>
        <form nz-form [formGroup]="baseValidateForm" class="ant-advanced-search-form">
          <div class="permission" style="width: 100%">
            <div nz-row [nzGutter]="12">
              <div nz-col [nzSpan]="24">
                <nz-form-item nzFlex>
                  <nz-form-label style="width:10%;text-align: right;">企业logo</nz-form-label>
                  <nz-form-control>
                    <nz-upload
                      class="avatar-uploader"
                      nzListType="picture-card"
                      nzAction="http://localhost:8899:18467/nx/ignore"
                      [nzShowUploadList]="false"
                      (nzChange)="handleChangeLogo($event)"
                    >
                      <ng-container *ngIf="!tmpUrl">
                        <i nz-icon nzType="plus" nzTheme="outline"
                           [nzType]="uploadingLogo ? 'loading' : 'plus'"></i>
                        <div class="ant-upload-text">上传logo</div>
                      </ng-container>
                      <img *ngIf="tmpUrl" [src]="tmpUrl" style="width: 100%"/>
                    </nz-upload>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="12">
                <nz-form-item nzFlex>
                  <nz-form-label style="width: 20%;text-align: right;" nzRequired>企业名称</nz-form-label>
                  <nz-form-control nzErrorTip="请输入企业名称!">
                    <input type="text" nz-input placeholder="请输入企业名称,支持营业执照扫描" formControlName="company_name"
                           style="width: 75%;float: left" (ngModelChange)="companyNameChange($event)"/>
                    <nz-upload nzAction="http://localhost:8899:18467/nx/ignore" [nzShowUploadList]="false"
                               (nzChange)="handleImageOCR($event)" style="float: left;margin-left: 10px" nzDisabled>
                      <a disabled="true"><i nz-icon nzType="scan" nzTheme="outline" style="font-size: 28px;padding-top: 1px;"
                            [nzType]="ocrLoading ? 'loading' : 'scan'"></i></a>
                    </nz-upload>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="12">
                <nz-form-item nzFlex>
                  <nz-form-label style="width: 24%;text-align: right;" nzRequired>统一社会信用代码</nz-form-label>
                  <nz-form-control nzErrorTip="请输入统一社会信用代码!">
                    <input type="text" nz-input placeholder="请输入统一社会信用代码,支持营业执照扫描" formControlName="credit_code"
                           style="width: 75%;float: left"/>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="12">
                <nz-form-item nzFlex>
                  <nz-form-label style="width: 20%;text-align: right;" nzRequired>管理员姓名</nz-form-label>
                  <nz-form-control nzErrorTip="请输入管理员姓名!">
                    <input type="text" nz-input placeholder="请输入管理员姓名" formControlName="admin_name"
                           style="width: 75%;float: left"/>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="12">
                <nz-form-item nzFlex>
                  <nz-form-label style="width: 24%;text-align: right;" nzRequired>手机号码</nz-form-label>
                  <nz-form-control nzErrorTip="请输入手机号码!">
                    <input type="text" nz-input placeholder="请输入手机号码" formControlName="mobile"
                           style="width: 75%;float: left"/>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="12">
                <nz-form-item nzFlex>
                  <nz-form-label style="width: 20%;text-align: right;" nzRequired>登录密码</nz-form-label>
                  <nz-form-control nzErrorTip="请输入登录密码!">
                    <input nz-input type="password" placeholder="请输入登录密码" formControlName="password"
                           (ngModelChange)="updateConfirmValidator()" style="width: 75%;float: left"/>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="12">
                <nz-form-item nzFlex>
                  <nz-form-label style="width: 24%;text-align: right;" nzRequired>确认密码</nz-form-label>
                  <nz-form-control [nzErrorTip]="errorTpl">
                    <input nz-input type="password" placeholder="请确认密码" formControlName="check_password"
                           style="width: 75%;float: left"/>
                    <ng-template #errorTpl let-control>
                      <ng-container *ngIf="control.hasError('required')">
                        请输入确认密码!
                      </ng-container>
                      <ng-container *ngIf="control.hasError('confirm')">
                        两次输入密码不一致!
                      </ng-container>
                    </ng-template>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="12">
                <nz-form-item nzFlex>
                  <nz-form-label style="width: 20%;text-align: right;">所属行业</nz-form-label>
                  <nz-form-control>
                    <nz-cascader nzChangeOnSelect [nzPlaceHolder]="'请选择所属行业'" [nzOptions]="trades"
                                 formControlName="trades" (ngModelChange)="onCascaderChanges($event)"
                                 style="width: 75%;float: left"></nz-cascader>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="12">
                <nz-form-item nzFlex>
                  <nz-form-label style="width: 24%;text-align: right;">企业规模</nz-form-label>
                  <nz-form-control>
                    <nz-select nzShowSearch nzAllowClear formControlName="scale"
                               style="width: 75%;float: left">
                      <nz-option *ngFor="let option of scaleOptions" [nzValue]="option.value"
                                 [nzLabel]="option.label"></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="12">
                <nz-form-item nzFlex>
                  <nz-form-label style="width: 20%;text-align: right;" nzRequired>权限模板</nz-form-label>
                  <nz-form-control nzErrorTip="请选择权限模版!">
                    <nz-select formControlName="template_id" [nzPlaceHolder]="'请选择权限模版'"
                               style="width: 75%;float: left">
                      <nz-option *ngFor="let option of accessTemplateOptions" [nzValue]="option.id"
                                 [nzLabel]="option.template_name">
                      </nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="24">
                <nz-form-item nzFlex>
                  <nz-form-label style="width: 10%;text-align: right;">经营地址</nz-form-label>
                  <nz-form-control>
                    <input type="text" nz-input placeholder="请输入经营地址,支持营业执照扫描" formControlName="address"
                           style="width: 90%;float: left"/>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </div>
        </form>
      </nz-card>
    </div>
    <div *ngIf="current==1">
      <form nz-form [formGroup]="productConfigValidateForm">
        <nz-descriptions nzTitle="合计" style="margin-top:2vh">
          <nz-descriptions-item nzTitle="总价&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                                [nzSpan]="2" style="font-weight: bolder"><span
            style="color: red;font-size: 20px">{{vitalPrice}}</span>&nbsp;&nbsp;&nbsp;元
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="折后价&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" [nzSpan]="2"
                                style="font-weight: bolder"><span
            style="color: red;font-size: 20px">{{discountedVitalPrice}}</span>&nbsp;&nbsp;&nbsp;元
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="CRM座席总数" [nzSpan]="2" style="font-weight: bolder"><span
            style="color: red;font-size: 20px">{{vitalCRMNumber}}</span>&nbsp;&nbsp;&nbsp;个
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="CRM总金额" [nzSpan]="2" style="font-weight: bolder"><span
            style="color: red;font-size: 20px">{{vitalCrmPrice}}</span>&nbsp;&nbsp;&nbsp;元
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="外呼座席总数" [nzSpan]="2" style="font-weight: bolder"><span
            style="color: red;font-size: 20px">{{vitalSeatNumber}}</span>&nbsp;&nbsp;&nbsp;个
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="外呼座席总金额" [nzSpan]="2" style="font-weight: bolder"><span
            style="color: red;font-size: 20px">{{vitalSeatPrice}}</span>&nbsp;&nbsp;&nbsp;元
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="外呼号码总数" [nzSpan]="2" style="font-weight: bolder"><span
            style="color: red;font-size: 20px">{{vitalPhoneNumber}}</span>&nbsp;&nbsp;&nbsp;个
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="外呼号码总金额" [nzSpan]="2" style="font-weight: bolder"><span
            style="color: red;font-size: 20px">{{vitalPhonePrice}}</span>&nbsp;&nbsp;&nbsp;元
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="短信包总数" [nzSpan]="2" style="font-weight: bolder"><span
            style="color: red;font-size: 20px">{{vitalMsgNumber}}</span>&nbsp;&nbsp;&nbsp;条
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="短信包总金额" [nzSpan]="2" style="font-weight: bolder"><span
            style="color: red;font-size: 20px">{{vitalMsgPrice}}</span>&nbsp;&nbsp;&nbsp;元
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="语音包总数量" [nzSpan]="2" style="font-weight: bolder"><span
            style="color: red;font-size: 20px">{{vitalCallNumber}}</span>&nbsp;&nbsp;&nbsp;分钟
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="语音包总金额" [nzSpan]="2" style="font-weight: bolder"><span
            style="color: red;font-size: 20px">{{vitalCallPrice}}</span>&nbsp;&nbsp;&nbsp;元
          </nz-descriptions-item>
        </nz-descriptions>
        <nz-divider></nz-divider>
        <div nz-row [nzGutter]="12" style="margin-top: 2vh">
          <div nz-col [nzSpan]="24">
            <nz-form-item nzFlex>
              <nz-form-label style="width: 10%;text-align: right;">外呼绑定</nz-form-label>
              <nz-form-control>
                <input nz-input type='' formControlName="sdk_app_id" placeholder="外呼绑定" [readOnly]="tcccDisabled"
                       style="width: 75%;float: left"/>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="24">
            <nz-form-item nzFlex>
              <nz-form-label style="width: 10%;text-align: right;">短信配置</nz-form-label>
              <nz-form-control>
                <textarea rows="4" nz-input placeholder="请输入短信配置" formControlName="sms_config"
                          [readOnly]="smsDisabled" style="width: 90%;float: left"></textarea>
              </nz-form-control>
            </nz-form-item>
          </div>

        </div>
      </form>
      <nz-divider></nz-divider>
      <form nz-form [formGroup]="itemValidateForm" style="margin-top: 2vh">
        <div style="float: left;margin-bottom: 2.5vh">
          <button nz-button [nzType]="'primary'" [nzSize]="'small'" (click)="addBtn()">添加内容</button>&nbsp;&nbsp;
        </div>
        <table style="width: 100%;text-align: center;margin-top: 2.5vh;">
          <tr style="background: rgb(73, 126, 245)">
            <td style="width: 10%;font-weight: bolder">服务</td>
            <td style="width: 10%;font-weight: bolder">产品</td>
            <td style="width: 10%;font-weight: bolder">数量</td>
            <td style="width: 5%;font-weight: bolder">有效时长</td>
            <td style="width: 10%;font-weight: bolder">单位</td>
            <td style="width: 10%;font-weight: bolder">折扣（%）</td>
            <td style="width: 10%;font-weight: bolder">单价（元）</td>
            <td style="width: 10%;font-weight: bolder">折后单价（元）</td>
            <td style="width: 10%;font-weight: bolder">总价（元）</td>
            <td style="width: 10%;font-weight: bolder">折后总价（元）</td>
            <td style="width: 5%;font-weight: bolder">操作</td>
          </tr>
          <ng-container formArrayName='arrayList'>
            <ng-container *ngFor="let row of arrayList.controls;let i =index">
              <tr>
                <ng-container [formGroup]="row">
                  <td style="width: 10%;">
                    <nz-select [nzPlaceHolder]="'请选择服务'" [nzSize]="'small'" style="width: 100%"
                               (ngModelChange)="loadOperateProductData($event,i)" formControlName="service_id"
                               nzDisabled="{{row['value']['disabled']}}">
                      <nz-option *ngFor="let option of operateServiceOptions" [nzValue]="option.id"
                                 [nzLabel]="option.service_name"></nz-option>
                    </nz-select>
                  </td>
                  <td style="width: 10%;">
                    <nz-select [nzPlaceHolder]="'请选择产品'" [nzSize]="'small'" style="width: 100%"
                               formControlName="product_id" (ngModelChange)="fetchProductUnit($event,i)"
                               nzDisabled="{{row['value']['disabled']}}">
                      <nz-option *ngFor="let option of arrayList.controls[i].value.productOptions" [nzValue]="option.id"
                                 [nzLabel]="option.product_name"></nz-option>
                    </nz-select>
                  </td>
                  <td style="width: 10%;">
                    <input nz-input type="number" placeholder="请输入数量" [nzSize]="'small'" style="width: 100%"
                           formControlName="product_number" (ngModelChange)="inputChange(i)"/>
                  </td>
                  <td style="width: 5%;">
                    <nz-select [nzPlaceHolder]="'请选择时长'" [nzSize]="'small'" style="width: 100%"
                               formControlName="effective_time">
                      <nz-option *ngFor="let option of timeOptions" [nzValue]="option.value"
                                 [nzLabel]="option.label"></nz-option>
                    </nz-select>
                  </td>
                  <td style="width: 10%;">
                    <input nz-input type="text" [nzSize]="'small'" style="width: 100%" formControlName="product_unit"
                           readonly placeholder="自动获取"/>
                  </td>
                  <td style="width: 10%;">
                    <input nz-input type="number" placeholder="请输入折扣" min='0' max='100' step="1" [nzSize]="'small'"
                           style="width: 100%"
                           formControlName="discount" (ngModelChange)="inputChange(i)"/>
                  </td>
                  <td style="width: 10%;">
                    <input nz-input type="text" [nzSize]="'small'" style="width: 100%" formControlName="single_price"
                           placeholder="自动获取"
                           readonly/>
                  </td>
                  <td style="width: 10%;">
                    <input nz-input type="text" [nzSize]="'small'" style="width: 100%"
                           formControlName="discounted_single_price"
                           placeholder="自动计算"
                           readonly/>
                  </td>
                  <td style="width: 10%;">
                    <input nz-input type="text" [nzSize]="'small'" style="width: 100%" formControlName="vital_price"
                           placeholder="自动计算"
                           readonly/>
                  </td>
                  <td style="width: 10%;">
                    <input nz-input type="text" [nzSize]="'small'" style="width: 100%"
                           formControlName="discounted_vital_price" placeholder="自动计算" readonly/>
                  </td>
                  <td style="width: 5%">
                    <button nz-button [nzType]="'danger'" [nzSize]="'small'" (click)="delBtn(i)">删除</button>
                  </td>
                </ng-container>
              </tr>
            </ng-container>
          </ng-container>
        </table>
      </form>
    </div>
    <div *ngIf="current==2">
      <nz-card>
        <form nz-form [formGroup]="agreementValidateForm" class="ant-advanced-search-form">
          <div class="permission" style="width: 100%">
            <div nz-row [nzGutter]="24">
              <div nz-col [nzSpan]="12">
                <nz-form-item nzFlex>
                  <nz-form-label style="width: 20%;text-align: right;" nzRequired>合同名称</nz-form-label>
                  <nz-form-control nzErrorTip="请输入合同标题!">
                    <input nz-input placeholder="请输入合同标题" formControlName="title" style="width: 75%;float: left"/>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="12">
                <nz-form-item nzFlex>
                  <nz-form-label style="width: 20%;text-align: right;">合同类型</nz-form-label>
                  <nz-form-control>
                    <nz-select formControlName="agreement_type" [nzPlaceHolder]="'请选择合同类型'"
                               style="width: 75%;float: left" nzDisabled="true">
                      <nz-option *ngFor="let option of agreementTypeOptions" [nzValue]="option.value"
                                 [nzLabel]="option.label">
                      </nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="12">
                <nz-form-item nzFlex>
                  <nz-form-label style="width: 20%;text-align: right;">签约客户</nz-form-label>
                  <nz-form-control>
                    <input nz-input placeholder="自动获取" formControlName="company_name" readonly
                           style="width: 75%;float: left"/>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="12">
                <nz-form-item nzFlex>
                  <nz-form-label style="width: 20%;text-align: right;" nzRequired>签订日期</nz-form-label>
                  <nz-form-control nzErrorTip="请选择签订日期!">
                    <nz-date-picker [nzFormat]="'yyyy/MM/dd'"
                                    formControlName="signing_date" style="width: 75%;float: left"></nz-date-picker>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="12">
                <nz-form-item nzFlex>
                  <nz-form-label style="width: 20%;text-align: right;">合同金额</nz-form-label>
                  <nz-form-control>
                    <input nz-input placeholder="自动计算" formControlName="vital_price" [readOnly]="true"
                           [disabled]="true"
                           style="width: 75%;float: left"/>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="12">
                <nz-form-item nzFlex>
                  <nz-form-label style="width: 20%;text-align: right;" nzRequired>有效期</nz-form-label>
                  <nz-form-control nzErrorTip="请选择有效期!">
                    <nz-range-picker [nzFormat]="'yyyy/MM/dd'"
                                     formControlName="effective_dates" style="width: 75%;float: left"></nz-range-picker>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="12">
                <nz-form-item nzFlex>
                  <nz-form-label style="width: 20%;text-align: right;" nzRequired>甲方负责人</nz-form-label>
                  <nz-form-control nzErrorTip="请输入甲方负责人!">
                    <input type="text" nz-input placeholder="请输入甲方负责人" formControlName="company_charger"
                           style="width: 75%;float: left"/>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="12">
                <nz-form-item nzFlex>
                  <nz-form-label style="width: 20%;text-align: right;" nzRequired>联系电话</nz-form-label>
                  <nz-form-control nzErrorTip="请输入联系电话!">
                    <input type="text" nz-input placeholder="请输入联系电话" formControlName="mobile"
                           style="width: 75%;float: left"/>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="12">
                <nz-form-item nzFlex>
                  <nz-form-label style="width: 20%;text-align: right;">联系邮箱</nz-form-label>
                  <nz-form-control>
                    <input type="text" nz-input placeholder="请输入联系邮箱" formControlName="email"
                           style="width: 75%;float: left"/>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="12">
                <nz-form-item nzFlex>
                  <nz-form-label style="width: 20%;text-align: right;">联系地址</nz-form-label>
                  <nz-form-control>
                    <input type="text" nz-input placeholder="请输入联系地址" formControlName="address"
                           style="width: 75%;float: left"/>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col [nzSpan]="12">
                <nz-form-item nzFlex>
                  <nz-form-label style="width: 20%;text-align: right;" nzRequired>乙方负责人</nz-form-label>
                  <nz-form-control nzErrorTip="请选择乙方负责人!">
                    <nz-select formControlName="sk_charger_id" [nzPlaceHolder]="'请选择乙方负责人'"
                               style="width: 75%;float: left">
                      <nz-option *ngFor="let option of ossUserOptions" [nzValue]="option.id"
                                 [nzLabel]="option.user_name">
                      </nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="12">
                <nz-form-item nzFlex>
                  <nz-form-label style="width: 20%;text-align: right;">销售员</nz-form-label>
                  <nz-form-control>
                    <nz-select formControlName="sales_person_id" [nzPlaceHolder]="'请选择销售员'"
                               style="width: 75%;float: left">
                      <nz-option *ngFor="let option of ossUserOptions" [nzValue]="option.id"
                                 [nzLabel]="option.user_name">
                      </nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="12">
                <nz-form-item nzFlex>
                  <nz-form-label style="width: 20%;text-align: right;">合同附件</nz-form-label>
                  <nz-form-control style="width: 50%;">
                    <div style="display: flex">
                      <nz-upload [(nzFileList)]="fileList" [nzBeforeUpload]="beforeUpload" style="float: left">
                        <button nz-button><i nz-icon nzType="upload"></i>选择文件</button>
                      </nz-upload>
                      <button
                        nz-button
                        [nzType]="'primary'"
                        [nzLoading]="uploading"
                        (click)="handleUpload()"
                        [disabled]="fileList.length == 0"
                        style="margin-left:8%;float:right;">
                        {{ uploading ? '加载中' : '开始上传' }}
                      </button>
                    </div>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="24">
                <nz-form-item nzFlex>
                  <nz-form-label style="width: 10%;text-align: right;">备注</nz-form-label>
                  <nz-form-control>
                    <textarea rows="3" nz-input placeholder="请输入备注" formControlName="remark"
                              style="width: 90%"></textarea>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </div>
        </form>
      </nz-card>
    </div>
    <div *ngIf="current==3">
      <div *ngIf="successIsVisible" style="width:100%;height:20vh;margin-top:5vh;text-align: center">
        <div>
          <i class="icon-chenggong" style="font-size: 80px;font-weight: bolder;color: rgb(69,151,43)"></i>
          <h2>完成开户申请</h2>
          <p>审批人员将第一时间处理您的申请,</p>
          <p>请耐心等待。</p>
        </div>
        <nz-divider style="margin-top: 5vh"></nz-divider>
        <nz-descriptions style="width:40%;margin-left: 22.5%;margin-bottom: 10vh">
          <nz-descriptions-item nzTitle="合同编号"
                                [nzSpan]="3" style="font-weight: bolder"><span
            style="color: red;font-size: 20px">{{agreementNo}}</span>&nbsp;&nbsp;&nbsp;
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="签约客户" [nzSpan]="3"
                                style="font-weight: bolder"><span
            style="color: red;font-size: 20px">{{companyName}}</span>&nbsp;&nbsp;&nbsp;
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="签约时间" [nzSpan]="3"
                                style="font-weight: bolder"><span
            style="color: red;font-size: 20px">{{signingDate.slice(0, 10)}}</span>&nbsp;&nbsp;&nbsp;
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="合同金额" [nzSpan]="3" style="font-weight: bolder"><span
            style="color: red;font-size: 20px">{{discountedVitalPrice}}</span>&nbsp;&nbsp;&nbsp;
          </nz-descriptions-item>
        </nz-descriptions>
      </div>
      <div *ngIf="failedIsVisible" style="width:100%;height:20vh;margin-top:5vh;text-align: center">
        <i class="icon-cuowu" style="font-size: 80px;font-weight: bolder;color: rgb(229,80,49)"></i>
        <h2>哎呀！出错啦</h2>
        <p>成功就在眼前,不要功亏一篑哦</p>
        <p>请仔细检查。</p>
      </div>
    </div>
  </div>
  <div class="steps-action">
    <button nz-button nzType="default" class="steps-button" (click)="pre()" *ngIf="current <3&&current>0">
      <span>上一步</span>
    </button>
    <button nz-button nzType="default" class="steps-button" (click)="next()" *ngIf="current < 2">
      <span>下一步</span>
    </button>
<!--        <button nz-button nzType="primary" class="steps-button" (click)="save()" *ngIf="current <3">-->
<!--          <span>保存</span>-->
<!--        </button>-->
    <button nz-button nzType="primary" class="steps-button" (click)="done()" *ngIf="current === 2">
      <span>提交</span>
    </button>
<!--        <button nz-button nzType="primary" class="steps-button" (click)="preView()" *ngIf="current < 3">-->
<!--          <span>预览</span>-->
<!--        </button>-->
    <button nz-button nzType="primary" class="steps-button" (click)="reEdit()" *ngIf="current === 3&&failedIsVisible">
      <span>重新编辑</span>
    </button>
    <button nz-button nzType="primary" class="steps-button" (click)="continue()"
            *ngIf="current === 3&&successIsVisible">
      <span>继续开户</span>
    </button>
    <button nz-button nzType="default" class="steps-button" (click)="return()" *ngIf="current === 3">
      <span>返回列表</span>
    </button>
  </div>
  <!--  </form>-->
  <!--  <div class="footer">-->
  <!--    <button type="button" (click)="editCancel()" class="ant-btn" style="margin-right: 8px;"><span>取消</span>-->
  <!--    </button>-->
  <!--    <button type="button" (click)="editCommit()" class="ant-btn ant-btn-primary"><span>提交</span></button>-->
  <!--  </div>-->
</nz-card>

<nz-modal [(nzVisible)]="preViewIsVisible" nzTitle="开户详情" (nzOnCancel)="preViewCancel()" [nzFooter]="null"
          [nzStyle]="{height: '100%',width:'80%'}">
  <nz-collapse [nzBordered]="false">
    <nz-collapse-panel [nzHeader]="'客户信息'" [nzActive]="true">
      <form nz-form [formGroup]="baseValidateForm" class="ant-advanced-search-form">
        <div class="permission" style="width: 100%">
          <div nz-row [nzGutter]="12">
            <div nz-col [nzSpan]="24">
              <nz-form-item nzFlex>
                <nz-form-label style="width:10%;text-align: right;">企业logo</nz-form-label>
                <nz-form-control>
                  <nz-upload
                    class="avatar-uploader"
                    nzListType="picture-card"
                    nzAction="http://localhost:8899:18467/nx/ignore"
                    [nzShowUploadList]="false"
                    (nzChange)="handleChangeLogo($event)"
                  >
                    <ng-container>
                      <i nz-icon nzType="plus" nzTheme="outline"
                         [nzType]="uploadingLogo ? 'loading' : 'plus'"></i>
                      <div class="ant-upload-text">上传logo</div>
                    </ng-container>
                    <img *ngIf="tmpUrl" [src]="tmpUrl" style="width: 100%"/>
                  </nz-upload>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="12">
              <nz-form-item nzFlex>
                <nz-form-label style="width: 20%;text-align: right;" nzRequired>企业名称</nz-form-label>
                <nz-form-control nzErrorTip="请输入企业名称!">
                  <input type="text" nz-input placeholder="请输入企业名称,支持营业执照扫描" formControlName="company_name"
                         style="width: 75%;float: left" (ngModelChange)="companyNameChange($event)"/>
                  <nz-upload nzAction="http://localhost:8899:18467/nx/ignore" [nzShowUploadList]="false"
                             (nzChange)="handleImageOCR($event)" style="float: left;margin-left: 10px">
                    <a><i nz-icon nzType="scan" nzTheme="outline" style="font-size: 28px;padding-top: 1px;"
                          [nzType]="ocrLoading ? 'loading' : 'scan'"></i></a>
                  </nz-upload>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="12">
              <nz-form-item nzFlex>
                <nz-form-label style="width: 24%;text-align: right;" nzRequired>统一社会信用代码</nz-form-label>
                <nz-form-control nzErrorTip="请输入统一社会信用代码!">
                  <input type="text" nz-input placeholder="请输入统一社会信用代码,支持营业执照扫描" formControlName="credit_code"
                         style="width: 75%;float: left"/>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="12">
              <nz-form-item nzFlex>
                <nz-form-label style="width: 20%;text-align: right;" nzRequired>管理员姓名</nz-form-label>
                <nz-form-control nzErrorTip="请输入管理员姓名!">
                  <input type="text" nz-input placeholder="请输入管理员姓名" formControlName="admin_name"
                         style="width: 75%;float: left"/>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="12">
              <nz-form-item nzFlex>
                <nz-form-label style="width: 24%;text-align: right;" nzRequired>手机号码</nz-form-label>
                <nz-form-control nzErrorTip="请输入手机号码!">
                  <input type="text" nz-input placeholder="请输入手机号码" formControlName="mobile"
                         style="width: 75%;float: left"/>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="12">
              <nz-form-item nzFlex>
                <nz-form-label style="width: 20%;text-align: right;" nzRequired>登录密码</nz-form-label>
                <nz-form-control nzErrorTip="请输入登录密码!">
                  <input nz-input type="password" placeholder="请输入登录密码" formControlName="password"
                         (ngModelChange)="updateConfirmValidator()" style="width: 75%;float: left"/>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="12">
              <nz-form-item nzFlex>
                <nz-form-label style="width: 24%;text-align: right;" nzRequired>确认密码</nz-form-label>
                <nz-form-control [nzErrorTip]="errorTpl">
                  <input nz-input type="password" placeholder="请确认密码" formControlName="check_password"
                         style="width: 75%;float: left"/>
                  <ng-template #errorTpl let-control>
                    <ng-container *ngIf="control.hasError('required')">
                      请输入确认密码!
                    </ng-container>
                    <ng-container *ngIf="control.hasError('confirm')">
                      两次输入密码不一致!
                    </ng-container>
                  </ng-template>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="12">
              <nz-form-item nzFlex>
                <nz-form-label style="width: 20%;text-align: right;">所属行业</nz-form-label>
                <nz-form-control>
                  <nz-cascader nzChangeOnSelect [nzPlaceHolder]="'请选择所属行业'" [nzOptions]="trades"
                               formControlName="trades" (ngModelChange)="onCascaderChanges($event)"
                               style="width: 75%;float: left"></nz-cascader>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="12">
              <nz-form-item nzFlex>
                <nz-form-label style="width: 24%;text-align: right;">企业规模</nz-form-label>
                <nz-form-control>
                  <nz-select nzShowSearch nzAllowClear formControlName="scale"
                             style="width: 75%;float: left">
                    <nz-option *ngFor="let option of scaleOptions" [nzValue]="option.value"
                               [nzLabel]="option.label"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="12">
              <nz-form-item nzFlex>
                <nz-form-label style="width: 20%;text-align: right;" nzRequired>权限模板</nz-form-label>
                <nz-form-control nzErrorTip="请选择权限模版!">
                  <nz-select formControlName="template_id" [nzPlaceHolder]="'请选择权限模版'"
                             style="width: 75%;float: left">
                    <nz-option *ngFor="let option of accessTemplateOptions" [nzValue]="option.id"
                               [nzLabel]="option.template_name">
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="24">
              <nz-form-item nzFlex>
                <nz-form-label style="width: 10%;text-align: right;">经营地址</nz-form-label>
                <nz-form-control>
                  <input type="text" nz-input placeholder="请输入经营地址,支持营业执照扫描" formControlName="address"
                         style="width: 90%;float: left"/>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </div>
      </form>
    </nz-collapse-panel>
    <nz-collapse-panel [nzHeader]="'产品服务'" [nzActive]="false">
      <form nz-form [formGroup]="productConfigValidateForm">
        <nz-descriptions nzTitle="合计" style="margin-top:2vh">
          <nz-descriptions-item nzTitle="总价&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                                [nzSpan]="2" style="font-weight: bolder"><span
            style="color: red;font-size: 20px">{{vitalPrice}}</span>&nbsp;&nbsp;&nbsp;元
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="折后价&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" [nzSpan]="2"
                                style="font-weight: bolder"><span
            style="color: red;font-size: 20px">{{discountedVitalPrice}}</span>&nbsp;&nbsp;&nbsp;元
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="CRM座席总数" [nzSpan]="2" style="font-weight: bolder"><span
            style="color: red;font-size: 20px">{{vitalCRMNumber}}</span>&nbsp;&nbsp;&nbsp;个
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="CRM总金额" [nzSpan]="2" style="font-weight: bolder"><span
            style="color: red;font-size: 20px">{{vitalCrmPrice}}</span>&nbsp;&nbsp;&nbsp;元
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="外呼座席总数" [nzSpan]="2" style="font-weight: bolder"><span
            style="color: red;font-size: 20px">{{vitalSeatNumber}}</span>&nbsp;&nbsp;&nbsp;个
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="外呼座席总金额" [nzSpan]="2" style="font-weight: bolder"><span
            style="color: red;font-size: 20px">{{vitalSeatPrice}}</span>&nbsp;&nbsp;&nbsp;元
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="外呼号码总数" [nzSpan]="2" style="font-weight: bolder"><span
            style="color: red;font-size: 20px">{{vitalPhoneNumber}}</span>&nbsp;&nbsp;&nbsp;个
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="外呼号码总金额" [nzSpan]="2" style="font-weight: bolder"><span
            style="color: red;font-size: 20px">{{vitalPhonePrice}}</span>&nbsp;&nbsp;&nbsp;元
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="短信包总数" [nzSpan]="2" style="font-weight: bolder"><span
            style="color: red;font-size: 20px">{{vitalMsgNumber}}</span>&nbsp;&nbsp;&nbsp;条
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="短信包总金额" [nzSpan]="2" style="font-weight: bolder"><span
            style="color: red;font-size: 20px">{{vitalMsgPrice}}</span>&nbsp;&nbsp;&nbsp;元
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="语音包总数量" [nzSpan]="2" style="font-weight: bolder"><span
            style="color: red;font-size: 20px">{{vitalCallNumber}}</span>&nbsp;&nbsp;&nbsp;分钟
          </nz-descriptions-item>
          <nz-descriptions-item nzTitle="语音包总金额" [nzSpan]="2" style="font-weight: bolder"><span
            style="color: red;font-size: 20px">{{vitalCallPrice}}</span>&nbsp;&nbsp;&nbsp;元
          </nz-descriptions-item>
        </nz-descriptions>
        <nz-divider></nz-divider>
        <div nz-row [nzGutter]="12" style="margin-top: 2vh">
          <div nz-col [nzSpan]="24">
            <nz-form-item nzFlex>
              <nz-form-label style="width: 10%;text-align: right;">外呼绑定</nz-form-label>
              <nz-form-control>
                <input nz-input type="number" formControlName="sdk_app_id" placeholder="外呼绑定" [readOnly]="tcccDisabled"
                       style="width: 75%;float: left"/>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="24">
            <nz-form-item nzFlex>
              <nz-form-label style="width: 10%;text-align: right;">短信配置</nz-form-label>
              <nz-form-control>
                <textarea rows="4" nz-input placeholder="请输入短信配置" formControlName="sms_config"
                          [readOnly]="smsDisabled" style="width: 90%;float: left"></textarea>
              </nz-form-control>
            </nz-form-item>
          </div>

        </div>
      </form>
      <nz-divider></nz-divider>
      <form nz-form [formGroup]="itemValidateForm" style="margin-top: 2vh">
        <div style="float: left;margin-bottom: 2.5vh">
          <button nz-button [nzType]="'primary'" [nzSize]="'small'" (click)="addBtn()">添加内容</button>&nbsp;&nbsp;
        </div>
        <table style="width: 100%;text-align: center;margin-top: 2.5vh;">
          <tr style="background: rgb(73, 126, 245)">
            <td style="width: 10%;font-weight: bolder">服务</td>
            <td style="width: 10%;font-weight: bolder">产品</td>
            <td style="width: 10%;font-weight: bolder">数量</td>
            <td style="width: 5%;font-weight: bolder">有效时长</td>
            <td style="width: 10%;font-weight: bolder">单位</td>
            <td style="width: 10%;font-weight: bolder">折扣（%）</td>
            <td style="width: 10%;font-weight: bolder">单价（元）</td>
            <td style="width: 10%;font-weight: bolder">折后单价（元）</td>
            <td style="width: 10%;font-weight: bolder">总价（元）</td>
            <td style="width: 10%;font-weight: bolder">折后总价（元）</td>
            <td style="width: 5%;font-weight: bolder">操作</td>
          </tr>
          <ng-container formArrayName='arrayList'>
            <ng-container *ngFor="let row of arrayList.controls;let i =index">
              <tr>
                <ng-container [formGroup]="row">
                  <td style="width: 10%;">
                    <nz-select [nzPlaceHolder]="'请选择服务'" [nzSize]="'small'" style="width: 100%"
                               (ngModelChange)="loadOperateProductData($event,i)" formControlName="service_id"
                               nzDisabled="{{row['value']['disabled']}}">
                      <nz-option *ngFor="let option of operateServiceOptions" [nzValue]="option.id"
                                 [nzLabel]="option.service_name"></nz-option>
                    </nz-select>
                  </td>
                  <td style="width: 10%;">
                    <nz-select [nzPlaceHolder]="'请选择产品'" [nzSize]="'small'" style="width: 100%"
                               formControlName="product_id" (ngModelChange)="fetchProductUnit($event,i)"
                               nzDisabled="{{row['value']['disabled']}}">
                      <nz-option *ngFor="let option of arrayList.controls[i].value.productOptions" [nzValue]="option.id"
                                 [nzLabel]="option.product_name"></nz-option>
                    </nz-select>
                  </td>
                  <td style="width: 10%;">
                    <input nz-input type="number" placeholder="请输入数量" [nzSize]="'small'" style="width: 100%"
                           formControlName="product_number" (ngModelChange)="inputChange(i)"/>
                  </td>
                  <td style="width: 5%;">
                    <nz-select [nzPlaceHolder]="'请选择时长'" [nzSize]="'small'" style="width: 100%"
                               formControlName="effective_time">
                      <nz-option *ngFor="let option of timeOptions" [nzValue]="option.value"
                                 [nzLabel]="option.label"></nz-option>
                    </nz-select>
                  </td>
                  <td style="width: 10%;">
                    <input nz-input type="text" [nzSize]="'small'" style="width: 100%" formControlName="product_unit"
                           readonly placeholder="自动获取"/>
                  </td>
                  <td style="width: 10%;">
                    <input nz-input type="number" placeholder="请输入折扣" min='0' max='100' step="1" [nzSize]="'small'"
                           style="width: 100%"
                           formControlName="discount" (ngModelChange)="inputChange(i)"/>
                  </td>
                  <td style="width: 10%;">
                    <input nz-input type="text" [nzSize]="'small'" style="width: 100%" formControlName="single_price"
                           placeholder="自动获取"
                           readonly/>
                  </td>
                  <td style="width: 10%;">
                    <input nz-input type="text" [nzSize]="'small'" style="width: 100%"
                           formControlName="discounted_single_price"
                           placeholder="自动计算"
                           readonly/>
                  </td>
                  <td style="width: 10%;">
                    <input nz-input type="text" [nzSize]="'small'" style="width: 100%" formControlName="vital_price"
                           placeholder="自动计算"
                           readonly/>
                  </td>
                  <td style="width: 10%;">
                    <input nz-input type="text" [nzSize]="'small'" style="width: 100%"
                           formControlName="discounted_vital_price" placeholder="自动计算" readonly/>
                  </td>
                  <td style="width: 5%">
                    <button nz-button [nzType]="'danger'" [nzSize]="'small'" (click)="delBtn(i)">删除</button>
                  </td>
                </ng-container>
              </tr>
            </ng-container>
          </ng-container>
        </table>
      </form>
    </nz-collapse-panel>
    <nz-collapse-panel [nzHeader]="'合同信息'" [nzActive]="false">
      <form nz-form [formGroup]="agreementValidateForm" class="ant-advanced-search-form">
        <div class="permission" style="width: 100%">
          <div nz-row [nzGutter]="24">
            <div nz-col [nzSpan]="12">
              <nz-form-item nzFlex>
                <nz-form-label style="width: 20%;text-align: right;" nzRequired>合同名称</nz-form-label>
                <nz-form-control nzErrorTip="请输入合同标题!">
                  <input nz-input placeholder="请输入合同标题" formControlName="title" style="width: 75%;float: left"/>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="12">
              <nz-form-item nzFlex>
                <nz-form-label style="width: 20%;text-align: right;">合同类型</nz-form-label>
                <nz-form-control>
                  <nz-select formControlName="agreement_type" [nzPlaceHolder]="'请选择合同类型'"
                             style="width: 75%;float: left" nzDisabled="true">
                    <nz-option *ngFor="let option of agreementTypeOptions" [nzValue]="option.value"
                               [nzLabel]="option.label">
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="12">
              <nz-form-item nzFlex>
                <nz-form-label style="width: 20%;text-align: right;">签约客户</nz-form-label>
                <nz-form-control>
                  <input nz-input placeholder="自动获取" formControlName="company_name" readonly
                         style="width: 75%;float: left"/>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="12">
              <nz-form-item nzFlex>
                <nz-form-label style="width: 20%;text-align: right;" nzRequired>签订日期</nz-form-label>
                <nz-form-control nzErrorTip="请选择签订日期!">
                  <nz-date-picker [nzFormat]="'yyyy/MM/dd'"
                                  formControlName="signing_date" style="width: 75%;float: left"></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="12">
              <nz-form-item nzFlex>
                <nz-form-label style="width: 20%;text-align: right;">合同金额</nz-form-label>
                <nz-form-control>
                  <input nz-input placeholder="自动计算" formControlName="vital_price" [readOnly]="true"
                         [disabled]="true"
                         style="width: 75%;float: left"/>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="12">
              <nz-form-item nzFlex>
                <nz-form-label style="width: 20%;text-align: right;" nzRequired>有效期</nz-form-label>
                <nz-form-control nzErrorTip="请选择有效期!">
                  <nz-range-picker [nzFormat]="'yyyy/MM/dd'"
                                   formControlName="effective_dates" style="width: 75%;float: left"></nz-range-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="12">
              <nz-form-item nzFlex>
                <nz-form-label style="width: 20%;text-align: right;" nzRequired>甲方负责人</nz-form-label>
                <nz-form-control nzErrorTip="请输入甲方负责人!">
                  <input type="text" nz-input placeholder="请输入甲方负责人" formControlName="company_charger"
                         style="width: 75%;float: left"/>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="12">
              <nz-form-item nzFlex>
                <nz-form-label style="width: 20%;text-align: right;" nzRequired>联系电话</nz-form-label>
                <nz-form-control nzErrorTip="请输入联系电话!">
                  <input type="text" nz-input placeholder="请输入联系电话" formControlName="mobile"
                         style="width: 75%;float: left"/>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="12">
              <nz-form-item nzFlex>
                <nz-form-label style="width: 20%;text-align: right;">联系邮箱</nz-form-label>
                <nz-form-control>
                  <input type="text" nz-input placeholder="请输入联系邮箱" formControlName="email"
                         style="width: 75%;float: left"/>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="12">
              <nz-form-item nzFlex>
                <nz-form-label style="width: 20%;text-align: right;">联系地址</nz-form-label>
                <nz-form-control>
                  <input type="text" nz-input placeholder="请输入联系地址" formControlName="address"
                         style="width: 75%;float: left"/>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col [nzSpan]="12">
              <nz-form-item nzFlex>
                <nz-form-label style="width: 20%;text-align: right;" nzRequired>乙方负责人</nz-form-label>
                <nz-form-control nzErrorTip="请选择乙方负责人!">
                  <nz-select formControlName="sk_charger_id" [nzPlaceHolder]="'请选择乙方负责人'"
                             style="width: 75%;float: left">
                    <nz-option *ngFor="let option of ossUserOptions" [nzValue]="option.id"
                               [nzLabel]="option.user_name">
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="12">
              <nz-form-item nzFlex>
                <nz-form-label style="width: 20%;text-align: right;">销售员</nz-form-label>
                <nz-form-control>
                  <nz-select formControlName="sales_person_id" [nzPlaceHolder]="'请选择销售员'"
                             style="width: 75%;float: left">
                    <nz-option *ngFor="let option of ossUserOptions" [nzValue]="option.id"
                               [nzLabel]="option.user_name">
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="12">
              <nz-form-item nzFlex>
                <nz-form-label style="width: 20%;text-align: right;">合同附件</nz-form-label>
                <nz-form-control style="width: 50%;">
                  <div style="display: flex">
                    <nz-upload [(nzFileList)]="fileList" [nzBeforeUpload]="beforeUpload" style="float: left">
                      <button nz-button><i nz-icon nzType="upload"></i>选择文件</button>
                    </nz-upload>
                    <button
                      nz-button
                      [nzType]="'primary'"
                      [nzLoading]="uploading"
                      (click)="handleUpload()"
                      [disabled]="fileList.length == 0"
                      style="margin-left:8%;float:right;">
                      {{ uploading ? '加载中' : '开始上传' }}
                    </button>
                  </div>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="24">
              <nz-form-item nzFlex>
                <nz-form-label style="width: 10%;text-align: right;">备注</nz-form-label>
                <nz-form-control>
                    <textarea rows="3" nz-input placeholder="请输入备注" formControlName="remark"
                              style="width: 90%"></textarea>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </div>
      </form>
    </nz-collapse-panel>
  </nz-collapse>
</nz-modal>

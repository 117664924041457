import {Component, ViewChild, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzModalService} from 'ng-zorro-antd/modal';
import {selfHttp} from "@src/app/httpservice/http.service";
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NzTreeComponent} from 'ng-zorro-antd/tree';

@Component({
  selector: 'fee',
  templateUrl: './fee.component.html',
  styleUrls: ['./fee.component.css']
})


export class FeeComponent implements OnInit {
  @ViewChild('nzTreeComponent') nzTreeComponent: NzTreeComponent;
  validateForm: FormGroup;
  //权限相关
  addAccess = false;
  editAccess = false;
  //查询相关
  serviceType: string = '';
  keyword: string = '';
  size: number = 5;
  page: number = 1;
  total: number = 0;

  //操作相关
  operateType: number = 1;//1-添加，2-编辑
  operateModalName = '新增服务'
  operateIsVisible = false;
  switchIsVisible = false;

  objects = [];
  public objectInfo: any;
  public options: any;
  queryOptions = [{label: '全部', value: 0}, {label: '订阅服务', value: 1}, {label: '增值服务', value: 2}]
  operateOptions = [{label: '订阅服务', value: 1}, {label: '增值服务', value: 2}]

  constructor(public router: Router,
              public fb: FormBuilder,
              public http: selfHttp,
              public modalService: NzModalService,
              public msg: NzMessageService,
  ) {
    this.loadObjectData();
    this.loadAccess();
    this.buildForm();
  }

  buildForm() {
    this.validateForm = this.fb.group({
      id: [0],
      service_name: [null, [Validators.required]],
      service_type: [null, [Validators.required]],
      status: [true, [Validators.required]],
    });
  }

  ngOnInit(): void {
  }

  //todo:边界测试-输入长度
  checkInputLength() {

  }

  loadObjectData() {
    this.http.get('http://oss-api.nositek.com/oss-api/pc/services', {
      page: this.page,
      size: this.size,
      service_type: this.serviceType,
      keyword: this.keyword,
    }, res => {
      if (res.data['rows'] != null && res.data['rows'].length > 0) {
        this.objects = res.data['rows'];
        this.total = Number(res.data['total']);
      } else {
        this.objects = [];
        this.total = 0;
        this.msg.info('暂无数据');
      }
    });
  }

// 重置
  reset() {
    this.page = 1;
    this.size = 5;
    this.serviceType = '';
    this.keyword = '';
    this.loadObjectData();
  }

  //页码切换
  indexChange() {
    this.loadObjectData();
  }

  /*
  新增/编辑服务
   */
  preOperate(operateType, object) {
    switch (operateType) {
      case 1://新增
        this.operateModalName = '新增服务'
        this.switchIsVisible = true;
        break
      case 2://编辑
        this.operateModalName = '编辑服务'
        this.switchIsVisible = false;
        this.validateForm.patchValue({
          id: object.id,
          service_name: object.service_name,
          service_type: object.service_type,
        })
    }
    this.operateIsVisible = true;
  }

  //添加取消
  operateCancel() {
    this.validateForm.reset();
    this.validateForm.patchValue({
      id: 0,
      status: true,
    });
    this.operateIsVisible = false;
  }

  //添加提交
  operateCommit() {
    let formData = this.validateForm.value;
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    if (this.validateForm.value.service_type && this.validateForm.value.service_type) {
      this.http.post('http://oss-api.nositek.com/oss-api/pc/services', formData, res => {
        if (res.code == 2000) {
          if (formData.id == 0) {
            this.msg.success('添加成功');
          } else {
            this.msg.success('编辑成功');
          }

        } else if (res.code == 100003) {
          this.msg.info(res.msg)
        } else {
          this.msg.error(res.msg)
        }
        this.loadObjectData();
      });
      this.validateForm.reset();
      this.validateForm.patchValue({
        id: 0,
        status: true,
      });
      this.operateIsVisible = false;
    }
  }


  /*
  上下架
   */
  preDownOrUp(object) {
    if (object.status) {
      this.modalService.confirm({
        nzTitle: '<i>确定下架服务 ？</i>',
        nzContent: '<b></b>',
        nzOkText: '确定',
        nzCancelText: '取消',
        nzOnOk: () => this.down2upCommit(object),
        nzOnCancel: () => this.down2upCancel()
      });
    } else {
      this.down2upCommit(object)
    }
  }

  // 上下架操作提交
  down2upCommit(object) {
    this.http.put('http://oss-api.nositek.com/oss-api/pc/services', {
      id: object.id,
      status: !object.status
    }, res => {
      if (res.code == 2000) {
        if (object.status) {
          this.msg.success('服务已下架');
        } else {
          this.msg.success('服务已上架');
        }
      } else {
        this.msg.info(res.msg);
      }
      this.loadObjectData();
    });
  }

  // 上/下架取消
  down2upCancel() {
    this.loadObjectData();
  }


  add_dept = false;
  edit_dept = false;
  delete_dept = false;
  add_user = true;
  edit_user = false;
  delete_user = false;

  loadAccess() {
    console.log('加载页面操作权限');
    // let tempArr = JSON.parse(localStorage.getItem('access'));
    // console.log(tempArr);
    // this.add_dept = tempArr.findIndex(v => v.name == 'add_dept') != -1;
    // this.edit_dept = tempArr.findIndex(v => v.name == 'edit_dept') != -1;
    // this.delete_dept = tempArr.findIndex(v => v.name == 'delete_dept') != -1;
    // this.add_user = tempArr.findIndex(v => v.name == 'add_user') != -1;
    // this.edit_user = tempArr.findIndex(v => v.name == 'edit_user') != -1;
    // this.delete_user = tempArr.findIndex(v => v.name == 'delete_user') != -1;
  }
}

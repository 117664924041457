import {RouteReuseStrategy, ActivatedRouteSnapshot, DetachedRouteHandle} from '@angular/router';
import { Injectable } from "@angular/core";

interface IRouteConfigData {
  reuse: boolean;
}

interface ICachedRoute {
  handle: DetachedRouteHandle;
  data: IRouteConfigData;
}


/**
 * 路由复用策略
 */
@Injectable()
export class SimpleReuseStrategy implements RouteReuseStrategy {
  public static routeCache = new Map<string, ICachedRoute>();
  public static waitDelete: string; // 当前页未进行存储时需要删除
  public static currentDelete: string;  // 当前页存储过时需要删除
  /** 进入路由触发，判断是否是同一路由 */
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === curr.routeConfig;
  }

  /** 表示对所有路由允许复用 如果你有路由不想利用可以在这加一些业务逻辑判断，这里判断是否有data数据判断是否复用 */
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    const data = this.getRouteData(route);
    if (data) {
      return true;
    }
    return false;
  }

  /** 当路由离开时会触发。按path作为key存储路由快照&组件当前实例对象 */
  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    const url = this.getFullRouteUrl(route);
    const data = this.getRouteData(route);
    if (SimpleReuseStrategy.waitDelete && SimpleReuseStrategy.waitDelete === url) {
      // 如果待删除是当前路由，且未存储过则不存储快照
      SimpleReuseStrategy.waitDelete = null;
      return null;
    }else {
      // 如果待删除是当前路由，且存储过则不存储快照
      if (SimpleReuseStrategy.currentDelete && SimpleReuseStrategy.currentDelete === url) {
        SimpleReuseStrategy.currentDelete = null;
        return null;
      }else {
        SimpleReuseStrategy.routeCache.set(url, { handle, data });
        this.addRedirectsRecursively(route);
      }
    }
  }

  /** 若 path 在缓存中有的都认为允许还原路由 */
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const url = this.getFullRouteUrl(route);
    return SimpleReuseStrategy.routeCache.has(url);
  }

  /** 从缓存中获取快照，若无则返回nul */
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    const url = this.getFullRouteUrl(route);
    const data = this.getRouteData(route);
    return data  && SimpleReuseStrategy.routeCache.has(url)
      ? SimpleReuseStrategy.routeCache.get(url).handle
      : null;
  }

  public addRedirectsRecursively(route: ActivatedRouteSnapshot): void {
    const config = route.routeConfig;
    if (config) {
      if (!config.loadChildren) {
        const routeFirstChild = route.firstChild;
        const routeFirstChildUrl = routeFirstChild ? this.getRouteUrlPaths(routeFirstChild).join('/') : '';
        const childConfigs = config.children;
        if (childConfigs) {
          const childConfigWithRedirect = childConfigs.find(c => c.path === '' && !!c.redirectTo);
          if (childConfigWithRedirect) {
            childConfigWithRedirect.redirectTo = routeFirstChildUrl;
          }
        }
      }
      route.children.forEach(childRoute => this.addRedirectsRecursively(childRoute));
    }
  }

  public getFullRouteUrl(route: ActivatedRouteSnapshot): string {
    return this.getFullRouteUrlPaths(route).filter(Boolean).join('/').replace('/', '_');
  }

  public getFullRouteUrlPaths(route: ActivatedRouteSnapshot): string[] {
    const paths = this.getRouteUrlPaths(route);
    return route.parent ? [ ...this.getFullRouteUrlPaths(route.parent), ...paths ] : paths;
  }

  public getRouteUrlPaths(route: ActivatedRouteSnapshot): string[] {
    return route.url.map(urlSegment => urlSegment.path);
  }

  public getRouteData(route: ActivatedRouteSnapshot): IRouteConfigData {
    return route.routeConfig && route.routeConfig.data as IRouteConfigData;
  }

  /** 用于删除路由快照*/
  public static deleteRouteSnapshot(url: string): void {
    if (url[0] === '/') {
      url = url.substring(1);
    }
    url = url.replace('/', '_');
    if (SimpleReuseStrategy.routeCache.has(url)) {
      SimpleReuseStrategy.routeCache.delete(url);
      SimpleReuseStrategy.currentDelete = url;
    }else {
      SimpleReuseStrategy.waitDelete = url;
    }
  }

  // public static handlers: { [key: string]: DetachedRouteHandle } = {};
  // public static waitDelete: string;

  // /** 表示对所有路由允许复用 如果你有路由不想利用可以在这加一些业务逻辑判断 */
  // public shouldDetach(route: ActivatedRouteSnapshot): boolean {
  //   return true;
  // }
  //
  // /** 当路由离开时会触发。按path作为key存储路由快照&组件当前实例对象 */
  // public store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
  //   if (SimpleReuseStrategy.waitDelete && SimpleReuseStrategy.waitDelete === this.getRouteUrl(route)) {
  //     // 如果待删除是当前路由则不存储快照
  //     SimpleReuseStrategy.waitDelete = null;
  //     return;
  //   }
  //   SimpleReuseStrategy.handlers[this.getRouteUrl(route)] = handle;
  // }
  //
  // /** 若 path 在缓存中有的都认为允许还原路由 */
  // public shouldAttach(route: ActivatedRouteSnapshot): boolean {
  //   return !!SimpleReuseStrategy.handlers[this.getRouteUrl(route)];
  // }
  //
  // /** 从缓存中获取快照，若无则返回nul */
  // public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
  //   if (!route.routeConfig) {
  //     return null;
  //   }
  //
  //   return SimpleReuseStrategy.handlers[this.getRouteUrl(route)];
  // }
  //
  // /** 进入路由触发，判断是否同一路由 */
  // public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
  //   return future.routeConfig === curr.routeConfig &&
  //     JSON.stringify(future.params) === JSON.stringify(curr.params);
  // }
  //
  // public getRouteUrl(route: ActivatedRouteSnapshot) {
  //   return route['_routerState'].url.replace(/\//g, '_')+ '_' + (route.routeConfig.loadChildren || route.routeConfig.component.toString().split('(')[0].split(' ')[1] );
  // }
  //
  // public static deleteRouteSnapshot(url: string): void {
  //   const key = url.replace(/\//g, '_');
  //   if (SimpleReuseStrategy.handlers[key]) {
  //     delete SimpleReuseStrategy.handlers[key];
  //   } else {
  //     SimpleReuseStrategy.waitDelete = key;
  //   }
  // }
}

import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {SidebarModule} from './layout/sidebar/sidebar.module';
import {NgZorroAntdModule} from 'ng-zorro-antd';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppRouterModule} from './app-router.module';
import {SidebarComponent} from './layout/sidebar/sidebar.component';
import {LoginComponent} from './login/login.component';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {TabComponent} from './common/tab/tab.component';
import {HttpClientModule, HttpRequest} from '@angular/common/http';
import {InterceptorService} from './Interceptor/InterceptorService';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {selfHttp} from './httpservice/http.service';
import {OpensComponentService} from './pages/opens/opens.component.service';
import {NgxEchartsModule} from 'ngx-echarts';
import * as echarts from 'echarts';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {IconDefinition} from '@ant-design/icons-angular';
import * as AllIcons from '@ant-design/icons-angular/icons';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {NZ_I18N} from 'ng-zorro-antd/i18n';
import zh_CN from '@angular/common/locales/zh';
import {registerLocaleData} from '@angular/common';

registerLocaleData(zh_CN, LOCALE_ID);

const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};

const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key])


export function tokenGetter() {
  return localStorage.getItem('token');
}

@NgModule({
  //根目录的子级中的组建，仅仅是子级
  declarations: [
    AppComponent,
    SidebarComponent,
    LoginComponent,
    TabComponent,
  ],
  //根目录下的子级模块，仅仅是子级
  imports: [
    NgZorroAntdModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRouterModule,
    ReactiveFormsModule,
    SidebarModule,
    HttpClientModule,
    NgxEchartsModule.forRoot({echarts}),
    FormsModule,
    NzIconModule,
    NzIconModule.forRoot(icons),
    DragDropModule,
  ],
  //服务的创建者，全局都可以使用的服务
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true},
    selfHttp,
    OpensComponentService,
    // {provide: LOCALE_ID, useValue: zh_CN },
    // {provide: NZ_I18N, useValue: zh_CN},
  ],

  //bootstrap 是应用的主视图，也叫根组建，只有根模块才设置这个属性。
  bootstrap: [AppComponent]
})

//导出 app模块
export class AppModule {

}

import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzModalService} from 'ng-zorro-antd/modal';
import {TransferChange} from 'ng-zorro-antd/transfer';
import {NzTreeNode, NzTreeNodeOptions,} from 'ng-zorro-antd/tree';
import {selfHttp} from "@src/app/httpservice/http.service";
import {HttpHeaders} from '@angular/common/http';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'access-template',
  templateUrl: './access-template.component.html',
  styleUrls: ['./access-template.component.css']
})


export class AccessTemplateComponent implements OnInit {
  validateForm: FormGroup;

  //权限相关
  addAccess = false;
  editAccess = false;
  deleteAccess = false;

  //查询相关
  allChecked: any = false;
  checkChild: any = false;
  keyword: any = '';
  size = 5;
  page: any = 1;
  objects: any = [];
  total: any = 1;


  //操作相关
  addIsVisible = false;
  editIsVisible = false;
  menuInterfaces: any = [];
  public objectInfo: any;
  public options: any;

  constructor(public router: Router,
              public fb: FormBuilder,
              public http: selfHttp,
              public modalService: NzModalService,
              public msg: NzMessageService,
  ) {
    this.loadObjectData();
    // this.loadAccess();
    this.buildForm();
  }

  buildForm() {
    this.validateForm = this.fb.group({
      id: [0],
      template_name: ['', [Validators.required]],
      status: [true, [Validators.required]],
      remark: [''],
      tmpTreeKeys: [[]]
    });
  }

  ngOnInit(): void {
  }

  //查询表格数据
  loadObjectData() {
    this.http.get('http://oss-api.nositek.com/oss-api/pc/access_template', {
      'page': this.page,
      'size': this.size,
      'keyword': this.keyword,
    }, res => {
      if (res.data['rows'] != null) {
        this.objects = res.data['rows'];
        this.total = res.data['total'];
      } else {
        this.objects = [];
        this.msg.info('暂无数据');
      }

    });
  }

  //复选框
  // 选中父多选框时，选中所有子复选框
  onChange(data: any, che: any) {
    this.objectInfo = data;
    if (che === 'single') {
      if (data.checked) {
        // this.operateOptions.push(data.id);
      } else {
        // let index = this.operateOptions.indexOf(data.id);
        // this.operateOptions.splice(index, 1);
      }
    }
  }

  loadData() {
    //获取模版权限信息
    this.http.get('http://oss-api.nositek.com/oss-api/pc/access_template/tree', {}, res => {
        if (res.data != null) {
          this.list = res.data['rows'];
          this.treeData = this.generateTree(this.list);
        }
      }
    );
  }

  /*
  添加模版
   */
  preAdd() {
    this.list = [];
    this.treeData = [];
    this.menuInterfaces = [];
    this.addIsVisible = true;
    this.validateForm.reset();
    this.validateForm.patchValue({
      status: true,
    });
    this.loadData();
  }

  //添加取消
  addCancel() {
    this.addIsVisible = false;
  }

  //添加提交
  addCommit() {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    this.validateForm.value.tmpTreeKeys.forEach(node => {
      let index = this.list.map(item => item.key).indexOf(node);
      this.menuInterfaces.push({menu_id: Number(this.list[index].parent_code), interface_unique_key: node})
    });
    if (this.validateForm.value.template_name) {
      this.http.post('http://oss-api.nositek.com/oss-api/pc/access_template', {
        'id': this.validateForm.value.id,
        'template_name': this.validateForm.value.template_name,
        'menu_interfaces': this.menuInterfaces,
        'remark': this.validateForm.value.remark,
        'status': this.validateForm.value.status,
      }, res => {
        if (res.code == 2000) {
          this.msg.success('添加成功');
        } else {
          this.msg.error(res.msg)
        }
        this.addIsVisible = false;
        this.loadObjectData();
      });
    }
  }

  /*
  修改模版
   */
  preEdit(object: any) {
    console.log(object)
    this.list = [];
    this.treeData = [];
    this.menuInterfaces = [];
    this.editIsVisible = true;
    this.validateForm.reset();
    this.loadData();
    let tmpArr = []
    object.details.forEach(detail => {
      tmpArr.push(detail.interface_key)
    });
    this.validateForm.patchValue({
      id: object.id,
      template_name: object.template_name,
      status: object.status,
      tmpTreeKeys: tmpArr,
      remark: object.remark,
    })

  }

  //修改取消
  editCancel() {
    this.editIsVisible = false;
  }

  //修改提交
  editCommit() {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    this.validateForm.value.tmpTreeKeys.forEach(node => {
      let index = this.list.map(item => item.key).indexOf(node);
      this.menuInterfaces.push({menu_id: Number(this.list[index].parent_code), interface_unique_key: node})
    });
    if (this.validateForm.value.template_name) {
      this.http.post('http://oss-api.nositek.com/oss-api/pc/access_template', {
        'id': this.validateForm.value.id,
        'template_name': this.validateForm.value.template_name,
        'menu_interfaces': this.menuInterfaces,
        'remark': this.validateForm.value.remark,
        'status': this.validateForm.value.status,
      }, res => {
        this.editIsVisible = false;
        this.msg.success('修改成功');
        this.loadObjectData();
      });

    }

  }

  /*
  删除模版
   */
  preDelete(object: any) {
    this.modalService.confirm({
      nzTitle: '<i>确定删除数据 ？</i>',
      nzContent: '<b></b>',
      nzOkText: '确定',
      nzCancelText: '取消',
      nzOnOk: () => this.deleteCommit(object.id),
      nzOnCancel: () => this.deleteCancel()
    });
  }

  // 删除提交
  deleteCommit(id: number) {
    this.options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        id: id
      }
    };
    this.http.delete('http://oss-api.nositek.com/oss-api/pc/access_template', this.options, res => {
      if (res.code == 2000) {
        this.msg.success('删除成功');
      } else {
        this.msg.info(res.msg);
      }
      this.loadObjectData();
    });
  }

  // 删除取消
  deleteCancel() {
    this.loadObjectData();
  }

  reset() {
    this.page = 1;
    this.size = 10;
    this.keyword = '';
    this.loadObjectData();
  }

  indexChange() {
    this.loadObjectData();
  }

  list: NzTreeNodeOptions[] = [];
  treeData: NzTreeNodeOptions[] = [];
  // checkedNodeList: NzTreeNode[] = [];

  //树结构函数
  generateTree(arr: NzTreeNodeOptions[]): NzTreeNodeOptions[] {
    const tree: NzTreeNodeOptions[] = [];
    const mappedArr: any = {};
    let arrElem: NzTreeNodeOptions;
    let mappedElem: NzTreeNodeOptions;

    for (let i = 0, len = arr.length; i < len; i++) {
      arrElem = arr[i];
      mappedArr[arrElem.key] = {...arrElem};
      mappedArr[arrElem.key].children = [];
    }

    for (const key in mappedArr) {
      if (mappedArr.hasOwnProperty(key)) {
        mappedElem = mappedArr[key];
        if (mappedElem.parent_code != "0") {
          mappedArr[mappedElem.parent_code].children.push(mappedElem);
        } else {
          tree.push(mappedElem);
        }
      }
    }
    return tree;
  }

  loadAccess() {
    console.log('加载页面操作权限');
    let tempArr = JSON.parse(localStorage.getItem('access'));
    this.addAccess = tempArr.findIndex(v => v.name == 'add_role') != -1;
    this.editAccess = tempArr.findIndex(v => v.name == 'edit_role') != -1;
    this.deleteAccess = tempArr.findIndex(v => v.name == 'delete_role') != -1;
  }
}

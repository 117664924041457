import {Component, ViewChild, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzModalService} from 'ng-zorro-antd/modal';
import {selfHttp} from "@src/app/httpservice/http.service";
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NzTreeComponent} from 'ng-zorro-antd/tree';

@Component({
  selector: 'packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.css']
})


export class PackagesComponent implements OnInit {
  @ViewChild('nzTreeComponent') nzTreeComponent: NzTreeComponent;
  validateForm: FormGroup;
  itemValidateForm: FormGroup;
  //权限相关
  addAccess = false;
  editAccess = false;
  //查询相关
  // queryServiceTypeOptions = [{label: '全部', value: 0}, {label: '订阅服务', value: 1}, {label: '增值服务', value: 2}]
  queryServiceOptions = [];
  queryProductOptions = [];
  serviceType: string = '';
  serviceId: string = '';
  productId: string = '';
  keyword: string = '';
  size: number = 5;
  page: number = 1;
  total: number = 0;

  //操作相关
  selectedDisabled: boolean = false
  operateType: number = 1;//1-添加，2-编辑
  operateModalName = '新增套餐'
  operateIsVisible = false;
  switchIsVisible = false;
  operateServiceOptions = [];
  operateProductOptions = [];
  timeOptions = [
    {label: '不限制', value: 0},
    {label: '1个月', value: 1},
    {label: '2个月', value: 2},
    {label: '3个月', value: 3},
    {label: '4个月', value: 4},
    {label: '5个月', value: 5},
    {label: '半年', value: 6},
    {label: '7个月', value: 7},
    {label: '8个月', value: 8},
    {label: '9个月', value: 9},
    {label: '10个月', value: 10},
    {label: '11个月', value: 11},
    {label: '一年', value: 12},
  ]
  objects = [];
  packageItems: any = [];
  package_price: number = 0.000
  discounted_package_price: number = 0.000


  constructor(public router: Router,
              public fb: FormBuilder,
              public http: selfHttp,
              public modalService: NzModalService,
              public msg: NzMessageService,
  ) {
    this.loadObjectData();
    this.loadQueryOption();
    this.loadAccess();
    this.buildForm();
  }

  buildForm() {
    this.validateForm = this.fb.group({
      id: [0],
      package_name: [null, [Validators.required]],
      package_price: [null, [Validators.required]],
      discounted_package_price: [0, [Validators.required]],
      status: [0, [Validators.required]],
    });
    this.itemValidateForm = this.fb.group({
      arrayList: this.fb.array([]),
      service_id: [null, [Validators.required]],
      product_id: [null, [Validators.required]],
      product_unit: [null],
      product_number: [0, [Validators.required]],
      effective_month: [0, [Validators.required]],
      discount: [100, [Validators.required]],
      single_price: [0],
      vital_price: [0, [Validators.required]],
      discounted_single_price: [0, [Validators.required]],
      discounted_vital_price: [0, [Validators.required]],
      scene_bind:[],
      productOptions: [[]]
    })
  }

  ngOnInit(): void {
  }

  //todo:边界测试-输入长度
  checkInputLength() {

  }

  loadObjectData() {
    this.http.get('http://oss-api.nositek.com/oss-api/pc/packages', {
      page: this.page,
      size: this.size,
      service_type: this.serviceType,
      service_id: this.serviceId,
      product_id: this.productId,
      keyword: this.keyword,
    }, res => {
      if (res.data['rows'] != null && res.data['rows'].length > 0) {
        this.objects = res.data['rows'];
        this.total = Number(res.data['total']);
      } else {
        this.objects = [];
        this.total = 0;
        this.msg.info('暂无数据');
      }
    });
  }

  loadQueryOption() {
    this.http.get('http://oss-api.nositek.com/oss-api/pc/services', {service_type: 2}, res => {
      if (res.data['rows'] != null && res.data['rows'].length > 0) {
        this.queryServiceOptions = res.data['rows'];
        this.queryServiceOptions.unshift({id: 0, service_name: '全部'})
      } else {
        this.msg.info('暂无数据');
      }
    });
    this.http.get('http://oss-api.nositek.com/oss-api/pc/products', {service_type: 2}, res => {
      if (res.data['rows'] != null && res.data['rows'].length > 0) {
        this.queryProductOptions = res.data['rows'];
        this.queryProductOptions.unshift({id: 0, product_name: '全部'})
      } else {
        this.msg.info('暂无数据');
      }
    });
  }

// 重置
  reset() {
    this.page = 1;
    this.size = 5;
    this.serviceType = '';
    this.serviceId = '';
    this.productId = '';
    this.keyword = '';
    this.loadObjectData();
  }

  //页码切换
  indexChange() {
    this.loadObjectData();
  }

  /*
  新增/编辑套餐
   */
  preOperate(operateType, object) {
    this.package_price = 0.000;
    this.discounted_package_price = 0.000;
    this.arrayList.clear();
    this.itemValidateForm.reset()
    this.validateForm.reset()
    this.validateForm.patchValue({
      id: 0,
      status: true
    })
    switch (operateType) {
      case 1://新增
        this.operateModalName = '新增套餐'
        this.switchIsVisible = true;
        break
      case 2://编辑
        this.http.get('http://oss-api.nositek.com/oss-api/pc/products', {}, res => {
          if (res.data['rows'] != null && res.data['rows'].length > 0) {
            this.operateProductOptions = res.data['rows'];
          } else {
            this.msg.info('暂无数据');
          }
        });
        this.operateModalName = '编辑套餐'
        this.switchIsVisible = false;
        this.validateForm.patchValue({
          id: object.id,
          package_name: object.package_name,
          package_price: object.package_price,
          discounted_package_price: object.discounted_package_price,
          status: object.status,
        })
        object.items.forEach((item, index) => {
          this.arrayList.push(this.fb.group({
            service_id: item.service_id,
            product_id: item.product_id,
            product_unit: item.product_unit,
            product_number: item.product_number,
            effective_month: item.effective_month,
            discount: item.discount,
            single_price: item.single_price,
            vital_price: item.vital_price,
            discounted_single_price: item.discounted_single_price,
            discounted_vital_price: item.discounted_vital_price,
            scene_bind:item.scene_bind,
            disabled: true
          }))
        })
    }
    this.loadOperateServiceData()
    this.operateIsVisible = true;
  }

  loadOperateServiceData() {
    this.http.get('http://oss-api.nositek.com/oss-api/pc/services', {service_type: 2}, res => {
      if (res.data['rows'] != null && res.data['rows'].length > 0) {
        this.operateServiceOptions = res.data['rows'];
      } else {
        this.msg.info('暂无数据');
      }
    });
  }

  loadOperateProductData(object: any, index: number) {
    this.http.get('http://oss-api.nositek.com/oss-api/pc/products', {service_id: object}, res => {
      if (res.data['rows'] != null && res.data['rows'].length > 0) {
        this.operateProductOptions = res.data['rows'];
        this.arrayList.controls[index].patchValue({
          product_id: null,
          product_unit: null,
          product_number: 1,
          effective_month: 0,
          discount: 100,
          single_price: null,
          vital_price: null,
          discounted_vital_price: null,
          discounted_single_price: null,
          productOptions: this.operateProductOptions,
        })
      } else {
        this.msg.info('暂无数据');
      }
    });
  }


  fetchProductUnit(optionId: any, index: number) {
    if (optionId) {
      let i = this.operateProductOptions.map(item => item.id).indexOf(optionId);
      this.arrayList.controls[index].patchValue({
        product_number: 1,
        effective_month: 0,
        discount: 100,
        product_unit: this.operateProductOptions[i].product_unit,
        single_price: this.operateProductOptions[i].single_price,
        vital_price: this.operateProductOptions[i].single_price,
        discounted_single_price: this.operateProductOptions[i].single_price,
        discounted_vital_price: this.operateProductOptions[i].single_price,
        scene_bind: this.operateProductOptions[i].scene_bind,
        productOptions: this.operateProductOptions,
      })
    }
  }

  toService() {
    this.router.navigateByUrl('/sidebar/services')
  }

  toProduct() {
    this.router.navigateByUrl('/sidebar/products')
  }

  inputChange(index: number) {
    let single_price = this.arrayList.controls[index]['value']['single_price']
    if (single_price) {
      if (this.arrayList.controls[index]['value']['product_number'] || this.arrayList.controls[index]['value']['discount']) {
        let currentRow = this.arrayList.controls[index]['value']
        this.arrayList.controls[index].patchValue({
          vital_price: (single_price * currentRow['product_number']).toFixed(3),
          discounted_single_price: (single_price * currentRow['discount'] / 100).toFixed(3),
          discounted_vital_price: (single_price * currentRow['product_number'] * currentRow['discount'] / 100).toFixed(3),
        })
        let package_price = null
        let discounted_package_price = null
        this.arrayList.controls.forEach((item, index) => {
          package_price = package_price + parseFloat(item['value']['vital_price'])
          discounted_package_price = discounted_package_price + parseFloat(item['value']['discounted_vital_price'])
        })
        this.validateForm.patchValue({
          package_price: package_price,
          discounted_package_price: discounted_package_price,
        })
        this.package_price = package_price;
        this.discounted_package_price = discounted_package_price;
      }
    }

  }

  /**
   * 获取数组对象
   */
  get arrayList() {
    return this.itemValidateForm.get('arrayList') as FormArray;
  }

  /**
   * 创建一行组件
   */
  createRow() {
    return this.fb.group({
      service_id: [''],
      product_id: [''],
      product_unit: [''],
      product_number: [1],
      effective_month: [0],
      discount: [100],
      single_price: [null],
      vital_price: [null],
      discounted_single_price: [null],
      discounted_vital_price: [null],
      scene_bind:[],
      productOptions: [[]],
      disabled: false,
    });
  }

  /**
   * 增加一行事件
   */
  addBtn() {
    this.arrayList.push(this.createRow());
  }

  /**
   * 删除一行事件
   */
  delBtn(index: number) {
    if (this.arrayList.controls[index]['value']['product_number'] || this.arrayList.controls[index]['value']['discount']) {
      let currentRow = this.arrayList.controls[index]['value']
      let currentPackagePrice = this.validateForm.value.package_price - currentRow['vital_price'];
      let currentDiscountedPackagePrice = this.validateForm.value.discounted_package_price - currentRow['discounted_vital_price'];
      this.validateForm.patchValue({
        package_price: currentPackagePrice,
        discounted_package_price: currentDiscountedPackagePrice,
      })
      this.package_price = currentPackagePrice;
      this.discounted_package_price = currentDiscountedPackagePrice;
      this.arrayList.removeAt(index);
    }
  }

  /**
   * 保存数据
   */
  saveData() {
    this.packageItems = []
    this.arrayList.controls.forEach((item, index) => {
      console.log(item)
      this.packageItems.push(item['value'])
    })
    console.log(this.packageItems)
  }

  /**
   * 清空数据
   */
  clearData() {
    this.arrayList.controls.forEach(item => {
      item.patchValue({
        service_id: '',
        product_id: '',
        product_number: '',
      });
    });
  }

  //添加取消
  operateCancel() {
    this.validateForm.reset();
    this.validateForm.patchValue({
      id: 0,
      status: true,
    });
    this.operateIsVisible = false;
  }

  //添加提交
  operateCommit() {
    this.packageItems = []
    this.arrayList.controls.forEach((item, index) => {
      item.patchValue({
        single_price: parseFloat(item['value']['single_price']),
        vital_price: parseFloat(item['value']['vital_price']),
        discounted_single_price: parseFloat(item['value']['discounted_single_price']),
        discounted_vital_price: parseFloat(item['value']['discounted_vital_price']),
        productOptions: null
      })
      this.packageItems.push(item['value'])
    })
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    if (this.validateForm.value['package_name'] && this.validateForm.value['package_price'] && this.validateForm.value['discounted_package_price']) {
      this.http.post('http://oss-api.nositek.com/oss-api/pc/packages', {
        id: this.validateForm.value.id,
        package_name: this.validateForm.value.package_name,
        package_price: this.validateForm.value.package_price,
        discounted_package_price: this.validateForm.value.discounted_package_price,
        status: this.validateForm.value.status,
        items: this.packageItems
      }, res => {
        if (res.code == 2000) {
          if (this.validateForm.value.id == 0) {
            this.msg.success('添加成功');
          } else {
            this.msg.success('编辑成功');
          }

        } else if (res.code == 100003) {
          this.msg.info(res.msg)
        } else {
          this.msg.error(res.msg)
        }
        this.loadObjectData();
      });
      this.operateIsVisible = false;
    }
  }


  /*
  上下架
   */
  preDownOrUp(object) {
    if (object.status) {
      this.modalService.confirm({
        nzTitle: '<i>确定下架套餐 ？</i>',
        nzContent: '<b></b>',
        nzOkText: '确定',
        nzCancelText: '取消',
        nzOnOk: () => this.down2upCommit(object),
        nzOnCancel: () => this.down2upCancel()
      });
    } else {
      this.down2upCommit(object)
    }
  }

  // 上下架操作提交
  down2upCommit(object) {
    this.http.put('http://oss-api.nositek.com/oss-api/pc/packages', {
      id: object.id,
      status: !object.status
    }, res => {
      if (res.code == 2000) {
        if (object.status) {
          this.msg.success('套餐已下架');
        } else {
          this.msg.success('套餐已上架');
        }
      } else {
        this.msg.info(res.msg);
      }
      this.loadObjectData();
    });
  }

  // 上/下架取消
  down2upCancel() {
    this.loadObjectData();
  }


  add_dept = false;
  edit_dept = false;
  delete_dept = false;
  add_user = true;
  edit_user = false;
  delete_user = false;

  loadAccess() {
    console.log('加载页面操作权限');
    // let tempArr = JSON.parse(localStorage.getItem('access'));
    // console.log(tempArr);
    // this.add_dept = tempArr.findIndex(v => v.name == 'add_dept') != -1;
    // this.edit_dept = tempArr.findIndex(v => v.name == 'edit_dept') != -1;
    // this.delete_dept = tempArr.findIndex(v => v.name == 'delete_dept') != -1;
    // this.add_user = tempArr.findIndex(v => v.name == 'add_user') != -1;
    // this.edit_user = tempArr.findIndex(v => v.name == 'edit_user') != -1;
    // this.delete_user = tempArr.findIndex(v => v.name == 'delete_user') != -1;
  }
}

<nz-card>
  <nz-tabset style="margin-top: 1vh" [nzTabPosition]="'top'" [nzType]="'card'" nzSize="default"
             [nzSelectedIndex]="currentTabIndex" (nzSelectChange)="tabChange($event)">
    <nz-tab nzTitle="客户统计（实时）">
      <div class="param-area">
        <nz-space class="table-query">
          <nz-space-item>
            <nz-radio-group [(ngModel)]="dailyConsumptionRadio" nzButtonStyle="solid"
                            (ngModelChange)="loadDailyConsumptionObject()">
              <label nz-radio-button nzValue="current_month">本月</label>
              <label nz-radio-button nzValue="last_month">上月</label>
              <label nz-radio-button nzValue="current_week">本周</label>
              <label nz-radio-button nzValue="last_week">上周</label>
            </nz-radio-group>
          </nz-space-item>
          <nz-space-item>
            <nz-range-picker [nzFormat]="'yyyy-MM-dd'" [(ngModel)]="dailyConsumptionRanges"
                             (ngModelChange)="dailyConsumptionDatePickChange($event)"></nz-range-picker>
          </nz-space-item>
          <nz-space-item>
            <nz-cascader nzChangeOnSelect [nzPlaceHolder]="'请选择服务产品'" [nzOptions]="tree"
                         [(ngModel)]="dailyConsumptionCascader"
                         (ngModelChange)="onDailyConsumptionCascaderChanges($event)"></nz-cascader>
          </nz-space-item>
          <nz-space-item>
            <nz-input-group nzSearch [nzAddOnAfter]="suffixButton0">
              <input type="text" nz-input placeholder="输入公司搜索" [(ngModel)]="dailyConsumptionKeyword"/>
            </nz-input-group>
            <ng-template #suffixButton0>
              <button nz-button nzType="primary" (click)="loadDailyConsumptionObject()">搜索</button>
            </ng-template>
          </nz-space-item>
          <nz-space-item>
            <button nz-button [nzType]="'default'" (click)="dailyConsumptionReset()">重置</button>
          </nz-space-item>
        </nz-space>
        <div class="table-operation">
          <button nz-button nzType="primary" (click)="preEdit(null)">
            <i nz-icon nzType="download" nzTheme="outline"></i>
            <span>导出报表</span>
          </button>
        </div>
      </div>
      <nz-table
        #firstTable
        nzSize="small"
        [nzData]="dailyConsumptionObjects"
        [(nzPageSize)]="dailyConsumptionSize"
        [nzTotal]="dailyConsumptionTotal"
        [(nzPageIndex)]="dailyConsumptionPage"
        [nzFrontPagination]="false"
        (nzPageIndexChange)="loadDailyConsumptionObject()"
        (nzPageSizeChange)="loadDailyConsumptionObject()"
        [nzShowSizeChanger]="true"
        [nzPageSizeOptions]="[5,10,15,20]"
        [nzShowTotal]="totalTemplate"
        [nzHideOnSinglePage]="true"
      >
        <ng-template #totalTemplate>共 <span
          style="color:cornflowerblue;margin:0 3px;">{{ dailyConsumptionTotal }}</span>
          条
        </ng-template>
        <thead style="background: yellow">
        <tr>
          <th>客户名称</th>
          <th>开始日期</th>
          <th>截止日期</th>
          <th>期初余额</th>
          <th>收款</th>
          <th>扣款</th>
          <th>退款</th>
          <th>期末余额</th>
          <!--          <th>操作</th>-->
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let object of firstTable.data">
          <ng-container *ngIf="object.row_type==0">
            <td>{{object.company_name}}</td>
            <td>{{ object.start_date| date: 'yyyy年MM月dd日' }}</td>
            <td>{{ object.end_date| date: 'yyyy年MM月dd日' }}</td>
            <td>¥{{object.begin_balance.toFixed(3)}}</td>
            <td>¥{{object.collection_balance.toFixed(3)}}</td>
            <td>¥{{object.deduction_balance.toFixed(3)}}</td>
            <td>¥{{object.refund_balance.toFixed(3)}}</td>
            <td>¥{{object.end_balance.toFixed(3)}}</td>
          </ng-container>
          <ng-container *ngIf="object.row_type==1">
            <td><span style="font-weight: bolder">汇总：</span></td>
            <td>--</td>
            <td>--</td>
            <td>¥{{object.begin_balance.toFixed(3)}}</td>
            <td>¥{{object.collection_balance.toFixed(3)}}</td>
            <td>¥{{object.deduction_balance.toFixed(3)}}</td>
            <td>¥{{object.refund_balance.toFixed(3)}}</td>
            <td>¥{{object.end_balance.toFixed(3)}}</td>
          </ng-container>
          <!--          <td>-->
          <!--            <a (click)="preEdit(object)" disabled="!object.audit_status==1"><i nz-icon nzType="audit"-->
          <!--                                                                               nzTheme="outline"></i>审核</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-->
          <!--          </td>-->
        </tr>
        </tbody>
      </nz-table>
    </nz-tab>
    <nz-tab nzTitle="产品量价统计（实时）">
      <div class="param-area">
        <nz-space class="table-query">
          <nz-space-item>
            <nz-radio-group [(ngModel)]="dailyVitalRadio" nzButtonStyle="solid" (ngModelChange)="loadVitalObject()">
              <label nz-radio-button nzValue="current_month">本月</label>
              <label nz-radio-button nzValue="last_month">上月</label>
              <label nz-radio-button nzValue="current_week">本周</label>
              <label nz-radio-button nzValue="last_week">上周</label>
            </nz-radio-group>
          </nz-space-item>
          <nz-space-item>
            <nz-range-picker [nzFormat]="'yyyy-MM-dd'" [(ngModel)]="dailyVitalRanges"
                             (ngModelChange)="dailyVitalDatePickChange($event)"></nz-range-picker>
          </nz-space-item>
          <nz-space-item>
            <nz-cascader nzChangeOnSelect [nzPlaceHolder]="'请选择服务产品'" [nzOptions]="tree"
                         [(ngModel)]="dailyVitalCascader"
                         (ngModelChange)="onDailyVitalCascaderChanges($event)"></nz-cascader>
          </nz-space-item>
          <nz-space-item>
            <nz-input-group nzSearch [nzAddOnAfter]="suffixButton1">
              <input type="text" nz-input placeholder="输入公司搜索" [(ngModel)]="dailyVitalKeyword"/>
            </nz-input-group>
            <ng-template #suffixButton1>
              <button nz-button nzType="primary" (click)="loadVitalObject()">搜索</button>
            </ng-template>
          </nz-space-item>
          <nz-space-item>
            <button nz-button [nzType]="'default'" (click)="dailyVitalReset()">重置</button>
          </nz-space-item>
        </nz-space>
      </div>
      <nz-table
        #secondTable
        [nzData]="dailyVitalObjects"
        nzBordered
        nzSize="small"
        [nzScroll]="{ x: '800px' }"
        [nzTotal]="dailyVitalTotal"
        [(nzPageSize)]="dailyVitalSize"
        [(nzPageIndex)]="dailyVitalPage"
        [nzFrontPagination]="false"
        (nzPageIndexChange)="loadVitalObject()"
        (nzPageSizeChange)="loadVitalObject()"
        [nzShowSizeChanger]="true"
        [nzPageSizeOptions]="[5,10,15,20]"
        [nzShowTotal]="totalTemplate1"
        [nzHideOnSinglePage]="true"
      >
        <thead>
        <tr>
          <th rowspan="2" nzLeft nzWidth="120px">客户名称</th>
          <th rowspan="2">开始日期</th>
          <th rowspan="2">截止日期</th>
          <th rowspan="2">服务</th>
          <th rowspan="2">产品</th>
          <th colspan="2">期初余额</th>
          <th colspan="2">收款</th>
          <th colspan="2">扣费</th>
          <th colspan="2">退款</th>
          <th colspan="2">期末余额</th>
          <!--          <th rowspan="2" nzRight nzWidth="120px">操作</th>-->
        </tr>
        <ng-template #totalTemplate1>共 <span style="color:cornflowerblue;margin:0 3px;">{{ dailyVitalTotal }}</span>
          条
        </ng-template>
        <tr>
          <th>数量</th>
          <th>金额</th>
          <th>数量</th>
          <th>金额</th>
          <th>数量</th>
          <th>金额</th>
          <th>数量</th>
          <th>金额</th>
          <th>数量</th>
          <th>金额</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let object of secondTable.data">
          <ng-container
            *ngIf="object.row_type==0&&object.object_index==0&&object.service_index==0">
            <td [attr.rowspan]="object.company_column_rowspan">{{ object.company_name }}</td>
            <td [attr.rowspan]="object.company_column_rowspan">{{ object.start_date| date: 'yy/MM/dd'}}</td>
            <td [attr.rowspan]="object.company_column_rowspan">{{ object.end_date| date: 'yy/MM/dd'}}</td>
            <td [attr.rowspan]="object.service_column_rowspan">{{ object.service_name}}</td>
            <td>{{object.product_name}}</td>
            <td><span style="float: right">{{object.begin_number}}</span></td>
            <td><span style="float: right">¥{{object.begin_balance.toFixed(3)}}</span></td>
            <td><span style="float: right">{{object.collection_number}}</span></td>
            <td><span style="float: right">¥{{object.collection_balance.toFixed(3)}}</span></td>
            <td><span style="float: right">{{object.deduction_number}}</span></td>
            <td><span style="float: right">¥{{object.deduction_balance.toFixed(3)}}</span></td>
            <td><span style="float: right">{{object.refund_number}}</span></td>
            <td><span style="float: right">¥{{object.refund_balance.toFixed(3)}}</span></td>
            <td><span style="float: right">{{object.end_number}}</span></td>
            <td><span style="float: right">¥{{object.end_balance.toFixed(3)}}</span></td>
            <!--            <td [attr.rowspan]="object.company_column_rowspan+1">-->
            <!--              <a (click)="preDetail(object.id)"><i nz-icon nzType="monitor" nzTheme="outline"></i>详情</a>&nbsp;&nbsp;&nbsp;&nbsp;-->
            <!--            </td>-->
          </ng-container>
          <ng-container
            *ngIf="object.row_type==0&&object.object_index!=0&&object.service_index==0">
            <td [attr.rowspan]="object.service_column_rowspan">{{ object.service_name}}</td>
            <td>{{object.product_name}}</td>
            <td><span style="float: right">{{object.begin_number}}</span></td>
            <td><span style="float: right">¥{{object.begin_balance.toFixed(3)}}</span></td>
            <td><span style="float: right">{{object.collection_number}}</span></td>
            <td><span style="float: right">¥{{object.collection_balance.toFixed(3)}}</span></td>
            <td><span style="float: right">{{object.deduction_number}}</span></td>
            <td><span style="float: right">¥{{object.deduction_balance.toFixed(3)}}</span></td>
            <td><span style="float: right">{{object.refund_number}}</span></td>
            <td><span style="float: right">¥{{object.refund_balance.toFixed(3)}}</span></td>
            <td><span style="float: right">{{object.end_number}}</span></td>
            <td><span style="float: right">¥{{object.end_balance.toFixed(3)}}</span></td>
          </ng-container>
          <ng-container
            *ngIf="object.row_type==0&&object.service_index!=0">
            <td>{{object.product_name}}</td>
            <td><span style="float: right">{{object.begin_number}}</span></td>
            <td><span style="float: right">¥{{object.begin_balance.toFixed(3)}}</span></td>
            <td><span style="float: right">{{object.collection_number}}</span></td>
            <td><span style="float: right">¥{{object.collection_balance.toFixed(3)}}</span></td>
            <td><span style="float: right">{{object.deduction_number}}</span></td>
            <td><span style="float: right">¥{{object.deduction_balance.toFixed(3)}}</span></td>
            <td><span style="float: right">{{object.refund_number}}</span></td>
            <td><span style="float: right">¥{{object.refund_balance.toFixed(3)}}</span></td>
            <td><span style="float: right">{{object.end_number}}</span></td>
            <td><span style="float: right">¥{{object.end_balance.toFixed(3)}}</span></td>
          </ng-container>
          <!--          <ng-container-->
          <!--            *ngIf="object.row_type==0&&object.company_column_merge&&!object.service_column_merge">-->
          <!--            <td>{{ object.service_name}}</td>-->
          <!--            <td>{{object.product_name}}</td>-->
          <!--            <td>{{object.begin_number}}</td>-->
          <!--            <td>¥{{object.begin_balance}}</td>-->
          <!--            <td>{{object.collection_number}}</td>-->
          <!--            <td>¥{{object.collection_balance}}</td>-->
          <!--            <td>{{object.deduction_number}}</td>-->
          <!--            <td>¥{{object.deduction_balance}}</td>-->
          <!--            <td>{{object.refund_number}}</td>-->
          <!--            <td>¥{{object.refund_balance}}</td>-->
          <!--            <td>{{object.end_number}}</td>-->
          <!--            <td>¥{{object.end_balance}}</td>-->
          <!--          </ng-container>-->
          <!--          <ng-container *ngIf="object.row_type==0&&!object.company_column_merge&&!object.service_column_merge">-->
          <!--            <td>{{ object.company_name }}</td>-->
          <!--            <td>{{ object.start_date| date: 'yy/MM/dd'}}</td>-->
          <!--            <td>{{ object.end_date| date: 'yy/MM/dd'}}</td>-->
          <!--            <td>{{object.product_name}}</td>-->
          <!--            <td>{{object.begin_number}}</td>-->
          <!--            <td>¥{{object.begin_balance}}</td>-->
          <!--            <td>{{object.collection_number}}</td>-->
          <!--            <td>¥{{object.collection_balance}}</td>-->
          <!--            <td>{{object.deduction_number}}</td>-->
          <!--            <td>¥{{object.deduction_balance}}</td>-->
          <!--            <td>{{object.refund_number}}</td>-->
          <!--            <td>¥{{object.refund_balance}}</td>-->
          <!--            <td>{{object.end_number}}</td>-->
          <!--            <td>¥{{object.end_balance}}</td>-->
          <!--          </ng-container>-->
          <ng-container *ngIf="object.row_type==1">
            <td style="font-weight: bolder">小计:</td>
            <td style="text-align: center">--</td>
            <td><span style="float: right">¥{{object.sub_begin_balance.toFixed(3)}}</span></td>
            <td style="text-align: center">--</td>
            <td><span style="float: right">¥{{object.sub_collection_balance.toFixed(3)}}</span></td>
            <td style="text-align: center">--</td>
            <td><span style="float: right">¥{{object.sub_deduction_balance.toFixed(3)}}</span></td>
            <td style="text-align: center">--</td>
            <td><span style="float: right">¥{{object.sub_refund_balance.toFixed(3)}}</span></td>
            <td style="text-align: center">--</td>
            <td><span style="float: right">¥{{object.sub_end_balance.toFixed(3)}}</span></td>
          </ng-container>
          <ng-container *ngIf="object.row_type==2">
            <td style="font-weight: bolder">合计:</td>
            <td style="text-align: center">--</td>
            <td style="text-align: center">--</td>
            <td style="text-align: center">--</td>
            <td style="text-align: center">--</td>
            <td style="text-align: center">--</td>
            <td><span style="float: right">¥{{object.total_begin_balance.toFixed(3)}}</span></td>
            <td style="text-align: center">--</td>
            <td><span style="float: right">¥{{object.total_collection_balance.toFixed(3)}}</span></td>
            <td style="text-align: center">--</td>
            <td><span style="float: right">¥{{object.total_deduction_balance.toFixed(3)}}</span></td>
            <td style="text-align: center">--</td>
            <td><span style="float: right">¥{{object.total_refund_balance.toFixed(3)}}</span></td>
            <td style="text-align: center">--</td>
            <td><span style="float: right">¥{{object.total_end_balance.toFixed(3)}}</span></td>
          </ng-container>
          <ng-container *ngIf="object.row_type==3">
            <td style="font-weight: bolder">汇总:</td>
            <td style="text-align: center">--</td>
            <td style="text-align: center">--</td>
            <td style="text-align: center">--</td>
            <td style="text-align: center">--</td>
            <td style="text-align: center">--</td>
            <td><span style="float: right">¥{{object.collect_begin_balance.toFixed(3)}}</span></td>
            <td style="text-align: center">--</td>
            <td><span style="float: right">¥{{object.collect_collection_balance.toFixed(3)}}</span></td>
            <td style="text-align: center">--</td>
            <td><span style="float: right">¥{{object.collect_deduction_balance.toFixed(3)}}</span></td>
            <td style="text-align: center">--</td>
            <td><span style="float: right">¥{{object.collect_refund_balance.toFixed(3)}}</span></td>
            <td style="text-align: center">--</td>
            <td><span style="float: right">¥{{object.collect_end_balance.toFixed(3)}}</span></td>
            <!--            <td></td>-->
          </ng-container>
        </tr>
        </tbody>
      </nz-table>
    </nz-tab>
    <nz-tab nzTitle="账单流水（实时）">
      <div class="param-area">
        <nz-space class="table-query">
          <nz-space-item>
            <nz-radio-group [(ngModel)]="billFlowRadio" nzButtonStyle="solid"
                            (ngModelChange)="loadBillFlowObject()">
              <label nz-radio-button nzValue="current">本月</label>
              <label nz-radio-button nzValue="last">上月</label>
            </nz-radio-group>
          </nz-space-item>
          <nz-space-item>
            <nz-range-picker [nzShowTime]="{ nzFormat: 'HH:mm' }"
                             nzFormat="yyyy-MM-dd HH:mm"
                             [nzPlaceHolder]="['开始时间', '结束时间']"
                             [(ngModel)]="billTimeRangeValues"
                             (ngModelChange)="billFlowTimePickChange($event)"></nz-range-picker>
          </nz-space-item>
          <nz-space-item>
            <nz-cascader nzChangeOnSelect [nzPlaceHolder]="'请选择服务产品'" [nzOptions]="tree"
                         [(ngModel)]="billFlowCascader"
                         (ngModelChange)="onBillFlowCascaderChanges($event)"></nz-cascader>
          </nz-space-item>
          <nz-space-item>
            <nz-input-group nzSearch [nzAddOnAfter]="suffixButton2">
              <input type="text" nz-input placeholder="输入关键字搜索" [(ngModel)]="billFlowKeyword"/>
            </nz-input-group>
            <ng-template #suffixButton2>
              <button nz-button nzType="primary" (click)="loadBillFlowObject()">搜索</button>
            </ng-template>
          </nz-space-item>
          <nz-space-item>
            <button nz-button [nzType]="'default'" (click)="billFlowReset()">重置</button>
          </nz-space-item>
        </nz-space>
      </div>
      <nz-table
        #threeTable
        nzSize="small"
        [nzData]="billFlowObjects"
        [(nzPageSize)]="billFlowSize"
        [nzTotal]="billFlowTotal"
        [(nzPageIndex)]="billFlowPage"
        [nzFrontPagination]="false"
        (nzPageIndexChange)="loadBillFlowObject()"
        (nzPageSizeChange)="loadBillFlowObject()"
        [nzShowSizeChanger]="true"
        [nzPageSizeOptions]="[10,20,25,50]"
        [nzShowTotal]="totalTemplate2"
        [nzHideOnSinglePage]="true"
      >
        <ng-template #totalTemplate2>共 <span style="color:cornflowerblue;margin:0 3px;">{{ billFlowTotal }}</span>
          条
        </ng-template>
        <thead style="background: yellow">
        <tr>
          <th>交易时间</th>
          <th>交易类型</th>
          <th>客户名称</th>
          <th>用户</th>
          <th>交易流水号</th>
          <th>服务</th>
          <th>产品</th>
          <th>交易数量</th>
          <th>交易金额</th>
          <th>剩余数量</th>
          <th>剩余金额</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let object of threeTable.data">
          <td>{{ object.trade_time.split(".")[0]| date: 'yy/MM/dd hh:mm' }}</td>
          <td>
            <span *ngIf="object.trade_type==1">充值</span>
            <span *ngIf="object.trade_type==2">退款</span>
            <span *ngIf="object.trade_type==3">扣款</span>
          </td>
          <td>{{ object.company_name }}</td>
          <td>{{object.trade_type == 1 ? '-' : object.user_name}}</td>
          <td>{{object.trade_no}}</td>
          <td>{{ object.service_name }}</td>
          <td>{{ object.product_name }}</td>
          <td>{{ object.trade_number }}</td>
          <td>¥{{ object.trade_balance.toFixed(3) }}</td>
          <td>{{ object.rest_number }}</td>
          <td>¥{{ object.rest_balance.toFixed(3) }}</td>
        </tr>
        </tbody>
      </nz-table>
    </nz-tab>
    <nz-tab nzTitle="月对账单（周期）">
      <div class="param-area">
        <nz-space class="table-query1">
          <nz-space-item>
            <nz-radio-group [(ngModel)]="billRadio" nzButtonStyle="solid"
                            (ngModelChange)="loadBillObject()">
              <label nz-radio-button nzValue="current">今年</label>
              <label nz-radio-button nzValue="last">去年</label>
            </nz-radio-group>
          </nz-space-item>
          <nz-space-item>
            <nz-month-picker [nzFormat]="'yyyy/MM'" nzPlaceHolder="账期" [(ngModel)]="billDateValue"
                             (ngModelChange)="loadBillObject()"></nz-month-picker>
          </nz-space-item>
          <nz-space-item>
            <nz-select class="param-select" [(ngModel)]="billCheckStatus" [nzPlaceHolder]="'请选择核验状态'"
                       (ngModelChange)="loadBillObject()">
              <nz-option *ngFor="let s of selectOptions" [nzValue]="s.value" [nzLabel]="s.label"></nz-option>
            </nz-select>
          </nz-space-item>
          <nz-space-item>
            <nz-input-group nzSearch [nzAddOnAfter]="suffixButton3">
              <input type="text" nz-input placeholder="输入公司搜索" [(ngModel)]="billKeyword"/>
            </nz-input-group>
            <ng-template #suffixButton3>
              <button nz-button nzType="primary" (click)="loadBillObject()">搜索</button>
            </ng-template>
          </nz-space-item>
          <nz-space-item>
            <button nz-button [nzType]="'default'" (click)="billReset()">重置</button>
          </nz-space-item>
        </nz-space>
      </div>
      <nz-table
        #fourTable
        nzSize="small"
        [nzData]="billObjects"
        [(nzPageSize)]="billSize"
        [nzTotal]="billTotal"
        [(nzPageIndex)]="billPage"
        [nzFrontPagination]="false"
        (nzPageIndexChange)="loadBillObject()"
        (nzPageSizeChange)="loadBillObject()"
        [nzShowSizeChanger]="true"
        [nzPageSizeOptions]="[5,10,15,20]"
        [nzShowTotal]="totalTemplate3"
        [nzHideOnSinglePage]="true"
      >
        <ng-template #totalTemplate3>共 <span style="color:cornflowerblue;margin:0 3px;">{{ billTotal }}</span>
          条
        </ng-template>
        <thead style="background: yellow">
        <tr>
          <th>客户名称</th>
          <th>账期</th>
          <th>月初余额</th>
          <th>收款</th>
          <th>扣款</th>
          <th>退款</th>
          <th>月末余额</th>
          <th>已开票金额</th>
          <th>可开票金额</th>
          <th>发送时间</th>
          <th>核验状态</th>
          <th>操作</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let object of fourTable.data">
          <td>{{object.company_name}}</td>
          <td>{{ object.bill_period }}</td>
          <td>¥{{ object.begin_balance.toFixed(3) }}</td>
          <td>¥{{ object.collection_balance.toFixed(3) }}</td>
          <td>¥{{ object.deduction_balance.toFixed(3) }}</td>
          <td>¥{{ object.refund_balance.toFixed(3) }}</td>
          <td>¥{{object.end_balance.toFixed(3)}}</td>
          <td>¥{{object.invoiced_balance.toFixed(3)}}</td>
          <td>¥{{object.invoicable_balance.toFixed(3)}}</td>
          <td>{{object.check_status == 1 ? '暂未发送' : object.send_time.split(".")[0]| date: 'yy/MM/dd hh:mm'}}</td>
          <td>
            <nz-badge *ngIf="object.check_status==1" nzStatus="processing"></nz-badge>
            <nz-badge *ngIf="object.check_status==2" nzStatus="default"></nz-badge>
            <nz-badge *ngIf="object.check_status==3" nzStatus="success"></nz-badge>
            <nz-badge *ngIf="object.check_status==3" nzStatus="error"></nz-badge>
            <span *ngIf="object.check_status==1">待发送</span>
            <span *ngIf="object.check_status==2">待核对</span>
            <span *ngIf="object.check_status==3">已确认</span>
            <span *ngIf="object.check_status==4">已纠正</span>
          </td>
          <td>
            <a (click)="preEdit(object)" disabled="!object.audit_status==1"><i nz-icon nzType="audit"
                                                                               nzTheme="outline"></i>审核</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </td>
        </tr>
        </tbody>
      </nz-table>
    </nz-tab>
    <nz-tab nzTitle="临时对账单（偶发）">
      <div class="param-area">
        <nz-space class="table-query1">
          <nz-space-item>
            <nz-radio-group [(ngModel)]="tmpBillRadio" nzButtonStyle="solid"
                            (ngModelChange)="loadTmpBillObject()">
              <label nz-radio-button nzValue="current">本月</label>
              <label nz-radio-button nzValue="last">上月</label>
            </nz-radio-group>
          </nz-space-item>
          <nz-space-item>
            <nz-month-picker [nzFormat]="'yyyy/MM'" nzPlaceHolder="账期" [(ngModel)]="tmpBillDateValue"
                             (ngModelChange)="loadTmpBillObject()"></nz-month-picker>
          </nz-space-item>
          <nz-space-item>
            <nz-select class="param-select" [(ngModel)]="billCheckStatus" [nzPlaceHolder]="'请选择核验状态'"
                       (ngModelChange)="loadTmpBillObject()">
              <nz-option *ngFor="let s of selectOptions" [nzValue]="s.value" [nzLabel]="s.label"></nz-option>
            </nz-select>
          </nz-space-item>
          <nz-space-item>
            <nz-input-group nzSearch [nzAddOnAfter]="suffixButton4">
              <input type="text" nz-input placeholder="输入公司搜索" [(ngModel)]="tmpBillKeyword"/>
            </nz-input-group>
            <ng-template #suffixButton4>
              <button nz-button nzType="primary" (click)="loadTmpBillObject()">搜索</button>
            </ng-template>
          </nz-space-item>
          <nz-space-item>
            <button nz-button [nzType]="'default'" (click)="tmpBillReset()">重置</button>
          </nz-space-item>
        </nz-space>
        <div class="table-operation">
          <button nz-button nzType="primary" (click)="preAddTmpBill()">
            <i nz-icon nzType="plus" nzTheme="outline"></i>
            <span>新增对账</span>
          </button>
        </div>
      </div>
      <nz-table
        #fiveTable
        nzSize="small"
        [nzData]="tmpBillObjects"
        [(nzPageSize)]="tmpBillSize"
        [nzTotal]="tmpBillTotal"
        [(nzPageIndex)]="tmpBillPage"
        [nzFrontPagination]="false"
        (nzPageIndexChange)="loadTmpBillObject()"
        (nzPageSizeChange)="loadTmpBillObject()"
        [nzShowSizeChanger]="true"
        [nzPageSizeOptions]="[5,10,15,20]"
        [nzShowTotal]="totalTemplate4"
        [nzHideOnSinglePage]="true"
      >
        <ng-template #totalTemplate4>共 <span style="color:cornflowerblue;margin:0 3px;">{{ tmpBillTotal }}</span>
          条
        </ng-template>
        <thead style="background: yellow">
        <tr>
          <th>客户名称</th>
          <th>产品服务</th>
          <th>账期</th>
          <th>月初余额</th>
          <th>收款</th>
          <th>扣款</th>
          <th>退款</th>
          <th>月末余额</th>
          <th>已开票金额</th>
          <th>可开票金额</th>
          <th>发送时间</th>
          <th>核验状态</th>
          <th>操作</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let object of fiveTable.data">
          <td>{{object.company_name}}</td>
          <td>{{object.service_name}}--{{object.product_name}}</td>
          <td>{{ object.period_start| date: 'yy/MM/dd'}}-{{ object.period_end| date: 'yy/MM/dd'}}</td>
          <td>¥{{ object.begin_balance.toFixed(3) }}</td>
          <td>¥{{ object.collection_balance.toFixed(3) }}</td>
          <td>¥{{ object.deduction_balance.toFixed(3) }}</td>
          <td>¥{{ object.refund_balance.toFixed(3) }}</td>
          <td>¥{{object.end_balance.toFixed(3)}}</td>
          <td>¥{{object.invoiced_balance.toFixed(3)}}</td>
          <td>¥{{object.invoicable_balance.toFixed(3)}}</td>
          <td>{{object.check_status == 1 ? '暂未发送' : object.send_time.split(".")[0]| date: 'yy/MM/dd hh:mm'}}</td>
          <td>
            <nz-badge *ngIf="object.check_status==1" nzStatus="processing"></nz-badge>
            <nz-badge *ngIf="object.check_status==2" nzStatus="default"></nz-badge>
            <nz-badge *ngIf="object.check_status==3" nzStatus="success"></nz-badge>
            <nz-badge *ngIf="object.check_status==3" nzStatus="error"></nz-badge>
            <span *ngIf="object.check_status==1">待发送</span>
            <span *ngIf="object.check_status==2">待核对</span>
            <span *ngIf="object.check_status==3">已确认</span>
            <span *ngIf="object.check_status==4">已纠正</span>
          </td>
          <td>
            <a (click)="preEdit(object)" disabled="!object.audit_status==1"><i nz-icon nzType="audit"
                                                                               nzTheme="outline"></i>审核</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </td>
        </tr>
        </tbody>
      </nz-table>
    </nz-tab>
    <nz-tab nzTitle="对账稽核（偶发）">
      <div class="param-area">
        <nz-space class="table-query2">
          <nz-space-item>
            <nz-radio-group [(ngModel)]="billCheckRadio" nzButtonStyle="solid"
                            (ngModelChange)="loadBillCheckObject()">
              <label nz-radio-button nzValue="current">本月</label>
              <label nz-radio-button nzValue="last">上月</label>
            </nz-radio-group>
          </nz-space-item>
          <nz-space-item>
            <nz-range-picker [nzFormat]="'yyyy-MM-dd'" [(ngModel)]="billCheckTimeRangeValues"
                             (ngModelChange)="billCheckTimePickChange($event)"></nz-range-picker>
          </nz-space-item>
          <nz-space-item>
            <nz-select class="param-select" [(ngModel)]="billCheckType" [nzPlaceHolder]="'请选择申请类型'"
                       (ngModelChange)="loadBillCheckObject()">
              <nz-option *ngFor="let s of selectOptions" [nzValue]="s.value" [nzLabel]="s.label"></nz-option>
            </nz-select>
          </nz-space-item>
          <nz-space-item>
            <nz-input-group nzSearch [nzAddOnAfter]="suffixButton">
              <input type="text" nz-input placeholder="输入关键字搜索" [(ngModel)]="billCheckKeyword"/>
            </nz-input-group>
            <ng-template #suffixButton>
              <button nz-button nzType="primary" (click)="loadBillCheckObject()">搜索</button>
            </ng-template>
          </nz-space-item>
          <nz-space-item>
            <button nz-button [nzType]="'default'" (click)="billCheckReset()">重置</button>
          </nz-space-item>
        </nz-space>
        <div class="table-operation">
          <button nz-button nzType="primary" (click)="preEdit(null)">
            <i nz-icon nzType="plus" nzTheme="outline"></i>
            <span>新增稽核</span>
          </button>
        </div>
      </div>
      <nz-table
        #lastTable
        nzSize="small"
        [nzData]="billCheckObjects"
        [(nzPageSize)]="billCheckSize"
        [nzTotal]="billCheckTotal"
        [(nzPageIndex)]="billCheckPage"
        [nzFrontPagination]="false"
        (nzPageIndexChange)="loadBillCheckObject()"
        (nzPageSizeChange)="loadBillCheckObject()"
        [nzShowSizeChanger]="true"
        [nzPageSizeOptions]="[5,10,15,20]"
        [nzShowTotal]="totalTemplate5"
        [nzHideOnSinglePage]="true"
      >
        <ng-template #totalTemplate5>共 <span style="color:cornflowerblue;margin:0 3px;">{{ billCheckTotal }}</span>
          条
        </ng-template>
        <thead style="background: yellow">
        <tr>
          <th>客户名称</th>
          <th>关联账单</th>
          <th>发起方</th>
          <th>发起人</th>
          <th>稽核类型</th>
          <th>收款账户</th>
          <th>开户行</th>
          <th>开户账号</th>
          <th>稽核费用</th>
          <th>稽核数量</th>
          <th>备注</th>
          <th>操作</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let object of lastTable.data">
          <td>
            <span *ngIf="object.apply_type==1">客户开户</span>
            <span *ngIf="object.apply_type==2">客户充值</span>
            <span *ngIf="object.apply_type==3">客户退款</span>
            <span *ngIf="object.apply_type==4">客户开票</span>
            <span *ngIf="object.apply_type==5">内部采购</span>
            <span *ngIf="object.apply_type==6">发票抬头</span>
            <span *ngIf="object.apply_type==7">AI模块</span>
          </td>
          <td>{{ object.apply_content }}&nbsp;&nbsp;&nbsp;
            <a nz-popover nzPopoverContent="点击复制文本">
              <i nz-icon nzType="copy" nzTheme="outline"></i>
            </a></td>
          <td>{{ object.create_time.split(".")[0]| date: 'yy/MM/dd hh:mm' }}</td>
          <td>{{object.apply_company_name}}</td>
          <td>{{object.apply_user_name}}</td>
          <td>{{ object.audit_time.slice(0, 10) == '2006-01-02' ? '审批中' : object.audit_time.split(".")[0]| date: 'yy/MM/dd hh:mm'}}</td>
          <td>{{ object.audit_user_name }}</td>
          <td>
            <nz-badge *ngIf="object.audit_status==1" nzStatus="processing"></nz-badge>
            <nz-badge *ngIf="object.audit_status==2" nzStatus="success"></nz-badge>
            <nz-badge *ngIf="object.audit_status==3" nzStatus="error"></nz-badge>
            <span *ngIf="object.audit_status==1">审批中</span>
            <span *ngIf="object.audit_status==2">已通过</span>
            <span *ngIf="object.audit_status==3">未通过</span>
          </td>
          <td>{{ object.remark }}</td>
          <td>{{ object.remark }}</td>
          <td>{{ object.remark }}</td>
          <td>
            <a (click)="preEdit(object)" disabled="!object.audit_status==1"><i nz-icon nzType="audit"
                                                                               nzTheme="outline"></i>审核</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </td>
        </tr>
        </tbody>
      </nz-table>
    </nz-tab>
  </nz-tabset>
</nz-card>

<nz-modal [(nzVisible)]="addTmpBillIsVisible" nzTitle="新增对账" [nzFooter]="modalFooter"
          [nzMaskClosable]="true" (nzOnCancel)="addTmpBillCancel()">
  <form nz-form [formGroup]="tmpBillValidateForm">
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>对账客户</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="请选择客户!">
        <nz-select [nzPlaceHolder]="'请选择客户'" formControlName="company_id">
          <nz-option *ngFor="let option of companyOptions" [nzValue]="option.value"
                     [nzLabel]="option.label"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>服务产品</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="请选择服务类型!">
        <nz-cascader nzChangeOnSelect [nzPlaceHolder]="'请选择服务产品'" [nzOptions]="tree" formControlName="serviceProducts"
                     (ngModelChange)="onAddTmpBillCascaderChanges($event)"></nz-cascader>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>账单时间</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="请选择上架状态!">
        <nz-range-picker [nzFormat]="'yyyy-MM-dd'" formControlName="tmpBillDates"
                         (ngModelChange)="addTmpBillDatePickChange($event)"></nz-range-picker>
      </nz-form-control>
    </nz-form-item>
  </form>
  <ng-template #modalFooter>
    <button nz-button nzType="default" (click)="addTmpBillCancel()">取消</button>
    <button nz-button nzType="primary" (click)="addTmpBillCommit()">确定</button>
  </ng-template>
</nz-modal>

<!--&lt;!&ndash;<nz-card>&ndash;&gt;-->
<!--&lt;!&ndash;  <div id="front_home">&ndash;&gt;-->
<!--&lt;!&ndash;    <div id="left">&ndash;&gt;-->
<!--&lt;!&ndash;      <div id="left_header">&ndash;&gt;-->
<!--&lt;!&ndash;        <nz-card style="width:100%;height: 100%" nzTitle="系统公告" nzSize="small" [nzExtra]="notify">&ndash;&gt;-->
<!--&lt;!&ndash;          <p>公告1...</p>&ndash;&gt;-->
<!--&lt;!&ndash;          <p>公告2...</p>&ndash;&gt;-->
<!--&lt;!&ndash;          <p>公告3...</p>&ndash;&gt;-->
<!--&lt;!&ndash;        </nz-card>&ndash;&gt;-->
<!--&lt;!&ndash;        <ng-template #notify>&ndash;&gt;-->
<!--&lt;!&ndash;          <a>查看更多>></a>&ndash;&gt;-->
<!--&lt;!&ndash;        </ng-template>&ndash;&gt;-->
<!--&lt;!&ndash;      </div>&ndash;&gt;-->
<!--&lt;!&ndash;      <div id="left_body">&ndash;&gt;-->
<!--&lt;!&ndash;        <div id="left_header_row">&ndash;&gt;-->
<!--&lt;!&ndash;          <div class="echart_cell">&ndash;&gt;-->
<!--&lt;!&ndash;            <div style="height: 100%;width: 100%" echarts [options]="weekTrend"></div>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--&lt;!&ndash;          <div class="echart_cell">&ndash;&gt;-->
<!--&lt;!&ndash;            <div style="height: 100%;width: 100%" echarts [options]="monthTrend"></div>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--&lt;!&ndash;          <div class="echart_cell">&ndash;&gt;-->
<!--&lt;!&ndash;            <div style="height: 100%;width: 100%" echarts [options]="quarterTrend"></div>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--&lt;!&ndash;        <div id="left_middle_row">&ndash;&gt;-->
<!--&lt;!&ndash;          <div class="echart_cell">&ndash;&gt;-->
<!--&lt;!&ndash;            <div style="height: 100%;width: 100%" echarts [options]="staffScale"></div>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--&lt;!&ndash;          <div class="echart_cell">&ndash;&gt;-->
<!--&lt;!&ndash;            <div style="height: 100%;width: 100%" echarts [options]="campusScale"></div>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--&lt;!&ndash;          <div class="echart_cell">&ndash;&gt;-->
<!--&lt;!&ndash;            <div style="height: 100%;width: 100%" echarts [options]="projectScale"></div>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--&lt;!&ndash;      </div>&ndash;&gt;-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->
<!--&lt;!&ndash;    <div id="right">&ndash;&gt;-->
<!--&lt;!&ndash;      <div id="right_header">&ndash;&gt;-->
<!--&lt;!&ndash;        <div style="height: 33%;padding-left: 15%; padding-header: 5%">&ndash;&gt;-->
<!--&lt;!&ndash;          <span style="width: 50%">当前权重：</span>&ndash;&gt;-->
<!--&lt;!&ndash;          <i class="icon-iconcj" style="font-size: 30px;color: #7ed321;padding-left: 20%;"></i>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--&lt;!&ndash;        <div style="height: 33%;padding-left: 15%; padding-header: 10%">&ndash;&gt;-->
<!--&lt;!&ndash;          <span style="width: 50%">分配状态：</span>&ndash;&gt;-->
<!--&lt;!&ndash;          <nz-switch [(ngModel)]="distributeStatus" style="padding-left: 20%;" nzDisabled="true"></nz-switch>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--&lt;!&ndash;        <div style="height: 33%;padding-left: 15%; padding-header: 2%">&ndash;&gt;-->
<!--&lt;!&ndash;          <span style="width: 50%">已分配数量：</span>&ndash;&gt;-->
<!--&lt;!&ndash;          <span style="padding-left: 20%;">30</span>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--&lt;!&ndash;      </div>&ndash;&gt;-->
<!--&lt;!&ndash;      <div id="right_body">&ndash;&gt;-->
<!--&lt;!&ndash;        <div id="right_body_line1">&ndash;&gt;-->
<!--&lt;!&ndash;          <div echarts [options]="staffheader"></div>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--&lt;!&ndash;        <div id="right_body_line2">&ndash;&gt;-->
<!--&lt;!&ndash;          <div echarts [options]="campusheader"></div>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--&lt;!&ndash;      </div>&ndash;&gt;-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->
<!--&lt;!&ndash;  </div>&ndash;&gt;-->
<!--&lt;!&ndash;</nz-card>&ndash;&gt;-->
<!--&lt;!&ndash;<nz-card>&ndash;&gt;-->
<!--&lt;!&ndash;  <div id="header_area">&ndash;&gt;-->
<!--&lt;!&ndash;    <div id="header_area_left">&ndash;&gt;-->
<!--&lt;!&ndash;      <nz-card style="width:100%;height: 100%" nzTitle="系统公告" nzSize="small" [nzExtra]="notify">&ndash;&gt;-->
<!--&lt;!&ndash;        <p>公告1...</p>&ndash;&gt;-->
<!--&lt;!&ndash;        <p>公告2...</p>&ndash;&gt;-->
<!--&lt;!&ndash;        <p>公告3...</p>&ndash;&gt;-->
<!--&lt;!&ndash;      </nz-card>&ndash;&gt;-->
<!--&lt;!&ndash;      <ng-template #notify>&ndash;&gt;-->
<!--&lt;!&ndash;        <a>查看更多>></a>&ndash;&gt;-->
<!--&lt;!&ndash;      </ng-template>&ndash;&gt;-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->
<!--&lt;!&ndash;    <div id="header_area_right">&ndash;&gt;-->
<!--&lt;!&ndash;      <nz-countdown [nzValue]="deadline" [nzTitle]="'Day Level'" [nzFormat]="'D 天 H 时 m 分 s 秒'"></nz-countdown>&ndash;&gt;-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->
<!--&lt;!&ndash;  </div>&ndash;&gt;-->
<!--&lt;!&ndash;  <div class="body_area">&ndash;&gt;-->
<!--&lt;!&ndash;    <div class="middle_area_body">&ndash;&gt;-->
<!--&lt;!&ndash;      <nz-descriptions nzTitle="机器信息" nzLayout="vertical" [nzBordered]="true">&ndash;&gt;-->
<!--&lt;!&ndash;        <nz-descriptions-item&ndash;&gt;-->
<!--&lt;!&ndash;          nzTitle="开机时间">{{machineData.boot_time| date: 'yyyy-MM-dd hh:mm:ss'}}</nz-descriptions-item>&ndash;&gt;-->
<!--&lt;!&ndash;        <nz-descriptions-item nzTitle="内核版本">{{machineData.kernel_version}}</nz-descriptions-item>&ndash;&gt;-->
<!--&lt;!&ndash;        <nz-descriptions-item nzTitle="系统属性">{{machineData.family}}</nz-descriptions-item>&ndash;&gt;-->
<!--&lt;!&ndash;        <nz-descriptions-item nzTitle="系统名称">{{machineData.platform}}</nz-descriptions-item>&ndash;&gt;-->
<!--&lt;!&ndash;        <nz-descriptions-item nzTitle="系统版本">{{machineData.version}}</nz-descriptions-item>&ndash;&gt;-->
<!--&lt;!&ndash;      </nz-descriptions>&ndash;&gt;-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->
<!--&lt;!&ndash;    <div class="middle_area_body">&ndash;&gt;-->
<!--&lt;!&ndash;      <nz-descriptions nzTitle="系统参数" nzLayout="vertical" [nzBordered]="true">&ndash;&gt;-->
<!--&lt;!&ndash;        <nz-descriptions-item nzTitle="操作系统">{{osData.goos}}</nz-descriptions-item>&ndash;&gt;-->
<!--&lt;!&ndash;        <nz-descriptions-item nzTitle="核心数量">{{osData.numCpu}}</nz-descriptions-item>&ndash;&gt;-->
<!--&lt;!&ndash;        <nz-descriptions-item nzTitle="编译器">{{osData.compiler}}</nz-descriptions-item>&ndash;&gt;-->
<!--&lt;!&ndash;        <nz-descriptions-item nzTitle="go版本">{{osData.goVersion}}</nz-descriptions-item>&ndash;&gt;-->
<!--&lt;!&ndash;        <nz-descriptions-item nzTitle="go程数量">&ndash;&gt;-->
<!--&lt;!&ndash;          {{osData.numGoroutine}}&ndash;&gt;-->
<!--&lt;!&ndash;        </nz-descriptions-item>&ndash;&gt;-->
<!--&lt;!&ndash;      </nz-descriptions>&ndash;&gt;-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->
<!--&lt;!&ndash;  </div>&ndash;&gt;-->
<!--&lt;!&ndash;  <div class="body_area">&ndash;&gt;-->
<!--&lt;!&ndash;    <div class="echart_cell">&ndash;&gt;-->
<!--&lt;!&ndash;      <div style="height: 100%;width: 100%" echarts [options]="staffScale"></div>&ndash;&gt;-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->
<!--&lt;!&ndash;    <div class="echart_cell">&ndash;&gt;-->
<!--&lt;!&ndash;      <div style="height: 100%;width: 100%" echarts [options]="campusScale"></div>&ndash;&gt;-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->
<!--&lt;!&ndash;    <div class="echart_cell">&ndash;&gt;-->
<!--&lt;!&ndash;      <div style="height: 100%;width: 100%" echarts [options]="projectScale"></div>&ndash;&gt;-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->
<!--&lt;!&ndash;    <div class="echart_cell">&ndash;&gt;-->
<!--&lt;!&ndash;      <div style="height: 100%;width: 100%" echarts [options]="consultFrom"></div>&ndash;&gt;-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->
<!--&lt;!&ndash;  </div>&ndash;&gt;-->
<!--&lt;!&ndash;</nz-card>&ndash;&gt;-->
<nz-card>
  <div id="header">
    <nz-row [nzGutter]="16">
      <nz-col [nzSpan]="12">
        <nz-card>
          <nz-statistic
            [nzValue]="(11.28 | number: '1.0-2')!"
            [nzTitle]="'今日活跃人数'"
            [nzSuffix]="'%'"
            [nzValueStyle]="{ color: '#3F8600' }"
          >
          </nz-statistic>
        </nz-card>
      </nz-col>
      <nz-col [nzSpan]="12">
        <nz-card>
          <nz-statistic
            [nzValue]="(9.3 | number: '1.0-2')!"
            [nzTitle]="'总代办任务'"
            [nzSuffix]="'%'"
            [nzValueStyle]="{ color: '#CF1322' }"
          >
          </nz-statistic>
        </nz-card>
      </nz-col>
      <nz-col [nzSpan]="12">
        <nz-card style="margin-top: 1.5vh">
          <nz-statistic
            [nzValue]="(11.28 | number: '1.0-2')!"
            [nzTitle]="'进行中任务'"
            [nzSuffix]="'%'"
            [nzValueStyle]="{ color: '#3F8600' }"
          >
          </nz-statistic>
        </nz-card>
      </nz-col>
      <nz-col [nzSpan]="12">
        <nz-card style="margin-top: 1.5vh">
          <nz-statistic
            [nzValue]="(9.3 | number: '1.0-2')!"
            [nzTitle]="'今日发布任务'"
            [nzSuffix]="'%'"
            [nzValueStyle]="{ color: '#CF1322' }"
          >
          </nz-statistic>
        </nz-card>
      </nz-col>
    </nz-row>
    <!--      <nz-col [nzSpan]="4">-->
    <!--&lt;!&ndash;        <nz-statistic [nzValue]="(1949101 | number)!" [nzTitle]="'今日活跃人数'"></nz-statistic>&ndash;&gt;-->
    <!--      </nz-col>-->
    <!--      <nz-col [nzSpan]="4">-->
    <!--        <nz-statistic [nzValue]="(1949101 | number)!" [nzTitle]="'今日活跃人数'"></nz-statistic>-->
    <!--      </nz-col>-->
    <!--      <nz-col [nzSpan]="4">-->
    <!--        <nz-statistic [nzValue]="(2019.111 | number: '1.0-2')!" [nzTitle]="'总代办任务'"></nz-statistic>-->
    <!--      </nz-col>-->
    <!--      <nz-col [nzSpan]="4">-->
    <!--        <nz-statistic [nzValue]="(2019.111 | number: '1.0-2')!" [nzTitle]="'进行中任务'"></nz-statistic>-->
    <!--      </nz-col>-->
    <!--      <nz-col [nzSpan]="4">-->
    <!--        <nz-statistic [nzValue]="(2019.111 | number: '1.0-2')!" [nzTitle]="'推送消息数量'"></nz-statistic>-->
    <!--      </nz-col>-->
    <!--    </nz-row>-->
  </div>
  <div id="body">
    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>服务器信息</nz-page-header-title>
      <!--      <nz-page-header-extra>-->
      <!--        <a>详情>></a>-->
      <!--      </nz-page-header-extra>-->
      <nz-page-header-content>
        <div class="body-row">
          <div class="echart_cell">
            <nz-descriptions nzTitle="机器信息" nzLayout="vertical" [nzBordered]="true">
              <nz-descriptions-item
                nzTitle="开机时间">{{machineData.boot_time.split(".")[0]| date: 'yyyy-MM-dd hh:mm:ss'}}</nz-descriptions-item>
              <nz-descriptions-item nzTitle="内核版本">{{machineData.kernel_version}}</nz-descriptions-item>
              <nz-descriptions-item nzTitle="系统属性">{{machineData.family}}</nz-descriptions-item>
              <nz-descriptions-item nzTitle="系统名称">{{machineData.platform}}</nz-descriptions-item>
              <nz-descriptions-item nzTitle="系统版本">{{machineData.version}}</nz-descriptions-item>
            </nz-descriptions>
          </div>
          <div class="echart_cell">
            <nz-descriptions nzTitle="系统参数" nzLayout="vertical" [nzBordered]="true">
              <nz-descriptions-item nzTitle="操作系统">{{osData.goos}}</nz-descriptions-item>
              <nz-descriptions-item nzTitle="核心数量">{{osData.numCpu}}</nz-descriptions-item>
              <nz-descriptions-item nzTitle="编译器">{{osData.compiler}}</nz-descriptions-item>
              <nz-descriptions-item nzTitle="go版本">{{osData.goVersion}}</nz-descriptions-item>
              <nz-descriptions-item nzTitle="go程数量">
                {{osData.numGoroutine}}
              </nz-descriptions-item>
            </nz-descriptions>
          </div>
        </div>
        <div class="body-row" style="margin-top: 5vh;height: 10vh">
<!--          <div class="echart_cell">-->
<!--            <div style="height: 100%;width: 100%" echarts [options]="cpuOption" class="chart"-->
<!--                 (chartInit)="onCpuChartInit($event)"></div>-->
<!--          </div>-->
          <div class="echart_cell">
            <div style="height: 100%;width: 100%" echarts [options]="diskOption" class="chart"
                 (chartInit)="onDiskChartInit($event)"></div>
          </div>
          <div class="echart_cell">
            <div style="height: 100%;width: 100%" echarts [options]="memExchangeOption" class="chart"
                 (chartInit)="onMemExchangeChartInit($event)"></div>
          </div>
        </div>
<!--        <div class="body-row">-->
<!--&lt;!&ndash;          <div class="echart_cell">&ndash;&gt;-->
<!--&lt;!&ndash;            <div style="height: 100%;width: 100%" echarts [options]="ioOption" class="chart"&ndash;&gt;-->
<!--&lt;!&ndash;                 (chartInit)="onIoChartInit($event)"></div>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--          <div class="echart_cell">-->
<!--            <div style="height: 100%;width: 100%" echarts [options]="memExchangeOption" class="chart"-->
<!--                 (chartInit)="onMemExchangeChartInit($event)"></div>-->
<!--          </div>-->


<!--        </div>-->


      </nz-page-header-content>
    </nz-page-header>
  </div>
</nz-card>



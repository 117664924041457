import {Injectable} from '@angular/core';
import {interval, Subject} from 'rxjs';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import jwtDecode from "jwt-decode";

/**
 * websocket服务
 * @author loccay
 * @date 2019/1/25
 */
@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  msgSubject;                                 // subject对象,用于发送事件
  private url = 'ws://oss-api.nositek.com/oss-api/ws/notifications';                                    // 默认请求的url
  // private url = 'ws://localhost:8899/ws/notifications';                                    // 默认请求的url
  public webSocket: WebSocket;                   // websocket对象
  connectSuccess = false;                         // websocket 连接成功
  period = 5 * 1000 * 1;                        // 5s检查一次
  serverTimeoutSubscription = null;               // 定时检测连接对象
  reconnectFlag = false;                          // 重连
  reconnectPeriod = 5 * 1000;                     // 重连失败,则5秒钟重连一次
  reconnectSubscription = null;                   // 重连订阅对象
  runTimeSubscription;                            // 记录运行连接subscription
  runTimePeriod = 60 * 10000;                     // 记录运行连接时间

  constructor(
    private notification: NzNotificationService
  ) {

    this.msgSubject = new Subject();
    console.log('开始心跳检测');
    // 进入程序就进行心跳检测,避免出现开始就连接中断,后续不重连
    this.heartCheckStart();
    this.calcRunTime();
  }

  /**
   * 发送消息
   * @author loccay
   * @date 2019/1/22
   * @param msg 发送消息
   */
  sendmsg(msg) {
    this.webSocket.send(msg);
  }

  /**
   * 创建新连接
   * @author loccay
   * @date 2019/1/22
   * @param url 要连接的url
   */
  connect(url) {
    if (!!url) {
      this.url = url;
    }
    // 创建websocket对象
    this.createWebSocket();
  }

  /**
   * 创建连接
   * @author loccay
   * @date 2019/1/22
   */
  public createWebSocket() {
    // 如果没有建立过连接，才建立连接并且添加时间监听
    if (!this.webSocket) {
      this.webSocket = new WebSocket(this.url);
    } else {
      console.log('存在socket,并没有重新创建socket对象')
      this.connectSuccess = false;
      this.webSocket.close();
      this.webSocket = new WebSocket(this.url);
    }
    // 建立连接成功
    this.webSocket.onopen = (e) => this.onOpen(e);
    // 接收到消息
    this.webSocket.onmessage = (e) => this.onmsg(e);
    // 连接关闭
    this.webSocket.onclose = (e) => this.onClose(e);
    // 异常
    this.webSocket.onerror = (e) => this.onError(e);
  }

  /**
   * 连接打开
   * @author loccay
   * @date 2019/1/22
   * @param e 打开事件
   */
  onOpen(e) {
    console.log('websocket 已连接');
    //链接成功后，发送token，进行用户认证
    this.webSocket.send(localStorage.getItem('token'));
    // 设置连接成功
    this.connectSuccess = true;
    // 如果是重连中
    if (this.reconnectFlag) {
      // 1.停止重连
      this.stopReconnect();
      // 2.重新开启心跳
      this.heartCheckStart();
      // 3.重新开始计算运行时间
      this.calcRunTime();
    }
  }

  /**
   * 接受到消息
   * @author loccay
   * @date 2019/1/22
   * @param event 接受消息事件
   */
  onmsg(event) {
    // // 每一次均获取个人配置信息决定提示类型
    let config = JSON.parse(localStorage.getItem('config'));
    console.log(config)
    // // 将接收到的消息发布出去
    const msg = JSON.parse(event.data);
    // // 接收到消息之后，根据对消息可见的用户ID字符串判断当前用户是否可见，若可见，判断是否需要触发音频提示和弹窗
    let currentUserID = jwtDecode(localStorage.getItem('token'))['user_id'];
    // console.log('当前用户的ID是:', currentUserID);
    if (msg.user_ids.indexOf(currentUserID) != -1 && config.msg_notify) {
      if (config.voice) {
        let noticeAudio = '../../../assets/audios/2478.wav';
        let audio = new Audio(noticeAudio);
        audio.play();
      }

      if (config.blank) {
        this.notification.blank(
          msg.title,
          msg.content
        );
      }
    }
    this.msgSubject.next(msg);
  }

  /**
   * 连接关闭
   * @author loccay
   * @date 2019/1/22
   * @scene 1、刷新页面；2、重定向新页面--PS:所有离开当前页面的行为均会触发关闭动作
   */
  public onClose(e) {
    this.connectSuccess = false;
    this.webSocket.close();
    console.log('连接关闭', e);
    // 关闭时开始重连
    // this.reconnect();
    // this.stopRunTime();
  }

  /**
   * 连接异常
   * @author loccay
   * @date 2019/1/22
   */
  private onError(e) {
    // 出现异常时一定会进onClose,所以只在onClose做一次重连动作
    console.log('连接异常', e);
    this.connectSuccess = false;
    // throw new Error('webSocket connection error:)');
  }

  /**
   * 开始重新连接
   * @author loccay
   * @date 2019/1/22
   */
  reconnect() {
    // 如果已重连,则直接return,避免重复连接
    if (this.connectSuccess) {
      this.stopReconnect();
      console.log('已经连接成功,停止重连');
      return;
    }
    // 如果正在连接中,则直接return,避免产生多个轮训事件
    if (this.reconnectFlag) {
      console.log('正在重连,直接返回');
      return;
    }
    // 开始重连
    this.reconnectFlag = true;
    // 如果没能成功连接,则定时重连
    this.reconnectSubscription = interval(this.reconnectPeriod).subscribe(async (val) => {
      // 重新连接
      this.connect(this.url);
      console.log(`重连:${val + 1}次`);
    });
  }

  /**
   * 停止重连
   * @author loccay
   * @date 2019/1/22
   */
  stopReconnect() {
    // 连接标识置为false
    this.reconnectFlag = false;
    // 取消订阅
    if (typeof this.reconnectSubscription !== 'undefined' && this.reconnectSubscription != null) {
      this.reconnectSubscription.unsubscribe();
    }
  }

  /**
   * 开始心跳检测
   * @author loccay
   * @date 2019/1/22
   */
  heartCheckStart() {
    this.serverTimeoutSubscription = interval(this.period).subscribe((val) => {
      // 保持连接状态,重置下
      if (this.webSocket != null && this.webSocket.readyState === 1) {
        // console.log(val, '心跳监测');
      } else {
        // 停止心跳
        this.heartCheckStop();
        // 开始重连
        this.reconnect();
        console.log('连接已断开,重新连接');
      }
    });
  }

  /**
   * 停止心跳检测
   * @author loccay
   * @date 2019/1/22
   */
  heartCheckStop() {
    // 取消订阅停止心跳
    if (typeof this.serverTimeoutSubscription !== 'undefined' && this.serverTimeoutSubscription != null) {
      this.serverTimeoutSubscription.unsubscribe();
    }
  }

  /**
   * 开始计算运行时间
   * @author loccay
   * @date 2019/1/25
   */
  calcRunTime() {
    this.runTimeSubscription = interval(this.runTimePeriod).subscribe(period => {
      console.log('运行时间', `${period}分钟`);
    });
  }

  /**
   * 停止计算运行时间
   * @author loccay
   * @date 2019/1/25
   */
  stopRunTime() {
    if (typeof this.runTimeSubscription !== 'undefined' && this.runTimeSubscription !== null) {
      this.runTimeSubscription.unsubscribe();
    }
  }
}

import {Component, ViewChild, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzModalService} from 'ng-zorro-antd/modal';
import {TransferChange, TransferItem} from 'ng-zorro-antd/transfer';
import {NzTreeNode,} from 'ng-zorro-antd/tree';
import {NzTableModule} from 'ng-zorro-antd/table';
import {selfHttp} from "@src/app/httpservice/http.service";
import {HttpHeaders} from '@angular/common/http';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NzTreeComponent, NzFormatEmitEvent, NzTreeNodeOptions} from 'ng-zorro-antd/tree';

@Component({
  selector: 'interface',
  templateUrl: './interface.component.html',
  styleUrls: ['./interface.component.css']
})


export class InterfaceComponent implements OnInit {
  @ViewChild('nzTreeComponent') nzTreeComponent: NzTreeComponent;
  validateForm: FormGroup;
  //权限相关
  addAccess = false;
  editAccess = false;
  deleteAccess = false;
  //查询相关
  checkChild: any = false;
  keyword: string = '';
  size = 5;
  page: number = 1;
  total: number = 0;

  //操作相关
  selectOptions = [];
  selectedMethodOptions = [
    {id: 'GET', label: 'GET（查询）'},
    {id: 'PUT', label: 'PUT（全部修改）'},
    {id: 'POST', label: 'POST（添加）'},
    {id: 'PATCH', label: 'PATCH（局部修改）'},
    {id: 'DELETE', label: 'DELETE（删除）'}]
  operateOptions = [];
  operateItem = null;
  operateIsVisible = false;
  editIsVisible = false;
  accessCodes: any = [];
  currentUser: any;
  operateModalName = '添加接口'


  objects = [];
  public objectInfo: any;
  public options: any;

  constructor(public router: Router,
              public fb: FormBuilder,
              public http: selfHttp,
              public modalService: NzModalService,
              public msg: NzMessageService,
  ) {
    this.loadObjectData();
    this.loadAccess();
    this.buildForm();
  }

  buildForm() {
    this.validateForm = this.fb.group({
      id: [0],
      menu_id: [null, [Validators.required]],
      interface_name: [null, [Validators.required]],
      uri: [null, [Validators.required]],
      request_method: [null, [Validators.required]],
      realized: [true],
      remark: [null],
    });
  }

  ngOnInit(): void {
  }

  loadObjectData() {
    this.http.get('http://oss-api.nositek.com/oss-api/pc/interfaces', {
      page: this.page,
      size: this.size,
      keyword: this.keyword,
    }, res => {
      if (res.data['rows'] != null && res.data['rows'].length > 0) {
        this.objects = res.data['rows'];
        this.total = Number(res.data['total']);
      } else {
        this.objects = [];
        this.total = 0;
        this.msg.info('暂无数据');
      }
    });
  }

  // 复选框
  onChange(data: any, checked: any) {
    this.objectInfo = data;
    if (checked === 'single') {
      if (data.checked) {
        this.operateOptions.push(data.id);
      } else {
        let index = this.operateOptions.indexOf(data.id);
        this.operateOptions.splice(index, 1);
      }
    }
  }

// 重置
  reset() {
    this.page = 1;
    this.size = 10;
    this.keyword = '';
    this.loadObjectData();
  }

  //页码切换
  indexChange() {
    console.log(this.page, this.size);
    this.loadObjectData();
  }

  /*
新增/编辑服务
 */
  preOperate(operateType, object) {
    this.loadOptionData();
    this.validateForm.patchValue({
      id: 0,
      realized: true
    })
    switch (operateType) {
      case 1://新增
        this.operateModalName = '新增接口'
        break
      case 2://编辑
        this.operateModalName = '编辑接口'
        this.validateForm.patchValue({
          id: object.id,
          menu_id: object.menu_id,
          interface_name: object.interface_name,
          uri: object.uri,
          request_method: object.request_method,
          realized: object.realized,
          remark: object.remark,
        })
    }
    this.operateIsVisible = true;
  }

  //添加取消
  operateCancel() {
    this.validateForm.reset();
    this.operateIsVisible = false;
  }

  //添加提交
  operateCommit() {
    let formData = this.validateForm.value
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    /*
     menu_id: [null, [Validators.required]],
      interface_name: [null, [Validators.required]],
      uri: [null, [Validators.required]],
      request_method: [null, [Validators.required]],
     */
    if (this.validateForm.value.menu_id && this.validateForm.value.interface_name && this.validateForm.value.uri && this.validateForm.value.request_method) {
      this.http.post('http://oss-api.nositek.com/oss-api/pc/interfaces', {
        id: this.validateForm.value.id,
        menu_id: this.validateForm.value.menu_id,
        interface_name: this.validateForm.value.interface_name,
        uri: this.validateForm.value.uri,
        request_method: this.validateForm.value.request_method,
        realized: this.validateForm.value.realized,
        remark: this.validateForm.value.remark,
      }, res => {
        if (res.code == 2000) {
          if (formData.id == 0) {
            this.msg.success('添加成功');
          } else {
            this.msg.success('编辑成功');
          }

        } else if (res.code == 100003) {
          this.msg.info(res.msg)
        } else {
          this.msg.error(res.msg)
        }
        this.loadObjectData();
      });
      this.validateForm.reset();
      this.validateForm.patchValue({
        id: 0,
        status: true,
      });
      this.operateIsVisible = false;
    }
  }

  loadOptionData() {
    this.selectOptions = []
    this.http.get('http://oss-api.nositek.com/oss-api/pc/menus/select', {is_full_tree: -1}, res => {
      if (res.data['rows'] != null) {
        this.selectOptions = res.data['rows'];
      } else {
        this.selectOptions = []
      }

    });
  }

  /*
  删除用户
   */
  preDelete(object) {
    this.modalService.confirm({
      nzTitle: '<i>确定删除数据 ？</i>',
      nzContent: '<b></b>',
      nzOkText: '确定',
      nzCancelText: '取消',
      nzOnOk: () => this.deleteCommit(object.id),
      nzOnCancel: () => this.deleteCancel()
    });
  }

  // 删除提交
  deleteCommit(id: number) {
    this.options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        id: id
      }
    };
    this.http.delete('http://oss-api.nositek.com/oss-api/pc/interfaces', this.options, res => {
      if (res.code == 2000) {
        this.msg.success('删除成功');
      } else {
        this.msg.info(res.msg);
      }
      this.loadObjectData();
    });
    this.operateOptions = [];
  }

  // 删除取消
  deleteCancel() {
    this.loadObjectData();
  }


  add_dept = false;
  edit_dept = false;
  delete_dept = false;
  add_user = true;
  edit_user = false;
  delete_user = false;

  loadAccess() {
    console.log('加载页面操作权限');
    // let tempArr = JSON.parse(localStorage.getItem('access'));
    // console.log(tempArr);
    // this.add_dept = tempArr.findIndex(v => v.name == 'add_dept') != -1;
    // this.edit_dept = tempArr.findIndex(v => v.name == 'edit_dept') != -1;
    // this.delete_dept = tempArr.findIndex(v => v.name == 'delete_dept') != -1;
    // this.add_user = tempArr.findIndex(v => v.name == 'add_user') != -1;
    // this.edit_user = tempArr.findIndex(v => v.name == 'edit_user') != -1;
    // this.delete_user = tempArr.findIndex(v => v.name == 'delete_user') != -1;
  }
}

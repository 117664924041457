import {Component, OnInit,} from '@angular/core';
import {Router} from "@angular/router";
import {NzMessageService} from "ng-zorro-antd/message";
import {NzModalService} from "ng-zorro-antd/modal";
import {selfHttp} from "../../../httpservice/http.service";

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {


  ngOnInit(): void {
  }

  constructor(public router: Router,
              public http: selfHttp,
              public modalService: NzModalService,
              public msg: NzMessageService,
  ) {

  }




}

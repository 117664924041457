<nz-card class="card-body">
  <!--  <div id="data-area">-->
  <div style="display: flex">
    <img [src]="'../../../assets/login/1.png'" style="text-align: center;margin-bottom: 2%;
                      width: 110px;height: 110px;border: 1px solid cornflowerblue;" alt="">
    <div style="margin-left: 2%;height: 110px;width: 18%">
      <div style="display: flex;height:70%;">
        <div><span>江苏诺翔科技有限公司</span></div>
        <div style="margin-left: 5%">
          <nz-tag [nzColor]="''">未认证</nz-tag>
        </div>
      </div>
      <div>
        <nz-tag [nzColor]="'blue'">互联网</nz-tag>
        <nz-tag [nzColor]="'geekblue'">A轮</nz-tag>
        <nz-tag [nzColor]="'purple'">创业</nz-tag>
        <nz-tag [nzColor]="'cyan'">中小企业</nz-tag>
      </div>
    </div>

  </div>
  <button nz-button nzType="primary">&nbsp;&nbsp;&nbsp;&nbsp;冻结&nbsp;&nbsp;&nbsp;&nbsp;</button>
  <div>
    <nz-tabset style="margin: 5vh 0 5vh 0">
      <nz-tab nzTitle="基本信息">
        <form nz-form [formGroup]="validateForm" class="ant-advanced-search-form">
          <nz-descriptions nzTitle="客户信息" nzBordered style="margin-top:2vh">
            <nz-descriptions-item nzTitle="企业名称" [nzSpan]="2">江苏诺翔科技有限公司</nz-descriptions-item>
            <nz-descriptions-item nzTitle="统一社会信用代码" [nzSpan]="2">923485375828XTLA</nz-descriptions-item>
            <nz-descriptions-item nzTitle="所属行业" [nzSpan]="2">科技-软件</nz-descriptions-item>
            <nz-descriptions-item nzTitle="企业规模" [nzSpan]="2">50-99人</nz-descriptions-item>
            <nz-descriptions-item nzTitle="法人姓名" [nzSpan]="2">刘翔</nz-descriptions-item>
            <nz-descriptions-item nzTitle="法人电话" [nzSpan]="2">17768015066</nz-descriptions-item>
            <nz-descriptions-item nzTitle="审核状态" [nzSpan]="2">
              <nz-badge nzStatus="success" nzText="待审核"></nz-badge>
              <nz-switch formControlName="status" nzCheckedChildren="开通" nzUnCheckedChildren="冻结"
                         style="float: right"></nz-switch>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="账号状态" [nzSpan]="2">
              <nz-badge nzStatus="processing" nzText="开通"></nz-badge>
              <nz-switch formControlName="status" nzCheckedChildren="开通" nzUnCheckedChildren="冻结"
                         style="float: right"></nz-switch>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="审核人" [nzSpan]="2">张佳</nz-descriptions-item>
            <nz-descriptions-item nzTitle="审核时间" [nzSpan]="2">2023-03-01 09：54：55</nz-descriptions-item>
            <nz-descriptions-item nzTitle="经营地址" [nzSpan]="2">苏州市渔郎桥浜1幢201室二层</nz-descriptions-item>
            <nz-descriptions-item nzTitle="办公地址" [nzSpan]="2">苏州市渔郎桥浜1幢201室二层</nz-descriptions-item>
            <nz-descriptions-item nzTitle="营业执照" [nzSpan]="3">
              <img [src]="'../../assets/login/sk.jpg'"
                   style="margin-left: 5%;margin-top: 2%;width: 140px;height: 58.3px;" alt="">
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="企业简介" [nzSpan]="2">
              <p>{{inputValue1}}</p>
              <p>{{inputValue2}}</p>
              <p>{{inputValue3}}</p>
            </nz-descriptions-item>
          </nz-descriptions>
          <nz-descriptions nzTitle="管理员信息" nzBordered style="margin-top: 10vh">
            <nz-descriptions-item nzTitle="管理员" [nzSpan]="2">刘奇</nz-descriptions-item>
            <nz-descriptions-item nzTitle="账号" [nzSpan]="2">loccay</nz-descriptions-item>
            <nz-descriptions-item nzTitle="来源" [nzSpan]="2">智能名片</nz-descriptions-item>
            <nz-descriptions-item nzTitle="账号状态" [nzSpan]="2">
              <nz-badge nzStatus="success" nzText="正常"></nz-badge>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="开通时间" [nzSpan]="2">2018-04-24 18:00:00</nz-descriptions-item>
            <nz-descriptions-item nzTitle="截止时间" [nzSpan]="2">
              2018-04-24 18:00:00
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="最近登录时间" [nzSpan]="3">2018-04-24 18:00:00</nz-descriptions-item>
          </nz-descriptions>
        </form>
      </nz-tab>
      <nz-tab nzTitle="意向动态">
        <div class="data-area">
          <nz-table
            nzSize="small"
            [nzData]="objects"
            [(nzPageSize)]="size"
            [nzTotal]="total"
            [(nzPageIndex)]="page"
            [nzFrontPagination]="false"
            (nzPageIndexChange)="indexChange()"
            (nzPageSizeChange)="indexChange()"
            [nzShowSizeChanger]="true"
            [nzPageSizeOptions]="[5,10,15,20]"
            [nzShowTotal]="totalTemplate"
            [nzHideOnSinglePage]="true"
          >
            <ng-template #totalTemplate>共 <span style="color:cornflowerblue;margin:0 3px;">{{ total }}</span>
              条
            </ng-template>
            <thead style="background: yellow">
            <tr>
              <th nzWidth="100px">序号</th>
              <th nzWidth="100px">意向来源</th>
              <th nzWidth="100px">意向产品</th>
              <th nzWidth="100px">提交账户</th>
              <th nzWidth="100px">提交时间</th>
              <th nzWidth="100px">备注</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let object of objects">
              <td>{{ object.user_name }}</td>
              <td>{{ object.mobile }}</td>
              <td>{{ object.role_name }}</td>
              <td>{{ object.status == 0 ? '禁用' : '启用' }}</td>
              <td>{{ object.update_time.slice(0, 10) }}</td>
              <td>{{ object.last_login.slice(0, 10) == '2006-01-02' ? '暂未登录' : object.last_login.split(".")[0]| date: 'MM月dd日 hh:mm'}}</td>
            </tr>
            </tbody>
          </nz-table>
        </div>
      </nz-tab>
      <nz-tab nzTitle="合同列表">
        <div class="data-area">
          <nz-table
            nzSize="small"
            [nzData]="objects"
            [(nzPageSize)]="size"
            [nzTotal]="total"
            [(nzPageIndex)]="page"
            [nzFrontPagination]="false"
            (nzPageIndexChange)="indexChange()"
            (nzPageSizeChange)="indexChange()"
            [nzShowSizeChanger]="true"
            [nzPageSizeOptions]="[5,10,15,20]"
            [nzShowTotal]="totalTemplate"
            [nzHideOnSinglePage]="true"
          >
            <ng-template #totalTemplate>共 <span style="color:cornflowerblue;margin:0 3px;">{{ total }}</span>
              条
            </ng-template>
            <thead style="background: yellow">
            <tr>
              <th nzWidth="100px">合同编号</th>
              <th nzWidth="100px">合同标题</th>
              <th nzWidth="100px">合同金额</th>
              <th nzWidth="100px">支付状态</th>
              <th nzWidth="100px">审核状态</th>
              <th nzWidth="100px">销售员</th>
              <th nzWidth="100px">建档人</th>
              <th nzWidth="100px">建档时间</th>
              <th nzWidth="100px">备注</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let object of objects">
              <td>{{ object.user_name }}</td>
              <td>{{ object.mobile }}</td>
              <td>{{ object.role_name }}</td>
              <td>{{ object.status == 0 ? '禁用' : '启用' }}</td>
              <td>{{ object.update_time.slice(0, 10) }}</td>
              <td>{{ object.last_login.slice(0, 10) == '2006-01-02' ? '暂未登录' : object.last_login.split(".")[0]| date: 'MM月dd日 hh:mm'}}</td>
              <td>{{ object.user_name }}</td>
              <td>{{ object.mobile }}</td>
              <td>{{ object.role_name }}</td>
            </tr>
            </tbody>
          </nz-table>
        </div>
      </nz-tab>
      <nz-tab nzTitle="顺氪座席">
        <nz-descriptions nzTitle="使用情况" style="margin-top:2vh;margin-bottom: 3vh">
          <nz-descriptions-item nzTitle="总座席数">30</nz-descriptions-item>
          <nz-descriptions-item nzTitle="已用数量">18</nz-descriptions-item>
        </nz-descriptions>
        <div class="data-area">
          <nz-table
            nzSize="small"
            [nzData]="objects"
            [(nzPageSize)]="size"
            [nzTotal]="total"
            [(nzPageIndex)]="page"
            [nzFrontPagination]="false"
            (nzPageIndexChange)="indexChange()"
            (nzPageSizeChange)="indexChange()"
            [nzShowSizeChanger]="true"
            [nzPageSizeOptions]="[5,10,15,20]"
            [nzShowTotal]="totalTemplate"
            [nzHideOnSinglePage]="true"
          >
            <ng-template #totalTemplate>共 <span style="color:cornflowerblue;margin:0 3px;">{{ total }}</span>
              条
            </ng-template>
            <thead style="background: yellow">
            <tr>
              <th nzWidth="100px">ID</th>
              <th nzWidth="100px">用户姓名</th>
              <th nzWidth="100px">用户账号</th>
              <th nzWidth="100px">邮箱</th>
              <th nzWidth="100px">部门</th>
              <th nzWidth="100px">岗位</th>
              <th nzWidth="100px">关联智能名片</th>
              <th nzWidth="100px">启用状态</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let object of objects">
              <td>{{ object.user_name }}</td>
              <td>{{ object.mobile }}</td>
              <td>{{ object.role_name }}</td>
              <td>{{ object.role_name }}</td>
              <td>{{ object.role_name }}</td>
              <td>{{ object.status == 0 ? '禁用' : '启用' }}</td>
              <td>{{ object.update_time.slice(0, 10) }}</td>
              <td>{{ object.last_login.slice(0, 10) == '2006-01-02' ? '暂未登录' : object.last_login.split(".")[0]| date: 'MM月dd日 hh:mm'}}</td>
            </tr>
            </tbody>
          </nz-table>
        </div>
      </nz-tab>
      <nz-tab nzTitle="呼叫中心">
        <div style="margin:3vh 0;display: flex;justify-content: space-between">
          <div>
            <button nz-button nzType="default">呼叫应用</button>
            <button nz-button nzType="default">座席管理</button>
            <button nz-button nzType="default">号码管理</button>
            <button nz-button nzType="default">通信套餐</button>
            <button nz-button nzType="default">在线客服</button>
          </div>
          <div>
            <button *ngIf="addCallAppVisible" nz-button nzType="primary"><i nz-icon nzType="plus" nzTheme="outline"></i>新增应用</button>
            <button *ngIf="addSeatVisible" nz-button nzType="primary"><i nz-icon nzType="plus" nzTheme="outline"></i>新增座席</button>
            <button *ngIf="addPhoneVisible" nz-button nzType="primary"><i nz-icon nzType="plus" nzTheme="outline"></i>新增号码</button>
            <button *ngIf="addPackageVisible" nz-button nzType="primary"><i nz-icon nzType="plus" nzTheme="outline"></i>新增套餐</button>
            <button *ngIf="addServicerVisible" nz-button nzType="primary"><i nz-icon nzType="plus" nzTheme="outline"></i>新增客服</button>
          </div>
        </div>
        <nz-table
          nzSize="small"
          [nzData]="objects"
          [(nzPageSize)]="size"
          [nzTotal]="total"
          [(nzPageIndex)]="page"
          [nzFrontPagination]="false"
          (nzPageIndexChange)="indexChange()"
          (nzPageSizeChange)="indexChange()"
          [nzShowSizeChanger]="true"
          [nzPageSizeOptions]="[5,10,15,20]"
          [nzShowTotal]="totalTemplate"
          [nzHideOnSinglePage]="true"
        >
          <ng-template #totalTemplate>共 <span style="color:cornflowerblue;margin:0 3px;">{{ total }}</span>
            条
          </ng-template>
          <thead style="background: yellow">
          <tr>
            <th nzWidth="100px">订单编号</th>
            <th nzWidth="100px">资源ID</th>
            <th nzWidth="100px">购买数量</th>
            <th nzWidth="100px">通信座席状态</th>
            <th nzWidth="100px">生效时间</th>
            <th nzWidth="100px">到期时间</th>
            <th nzWidth="100px">操作</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let object of objects">
            <td>{{ object.user_name }}</td>
            <td>{{ object.mobile }}</td>
            <td>{{ object.role_name }}</td>
            <td>{{ object.status == 0 ? '禁用' : '启用' }}</td>
            <td>{{ object.update_time.slice(0, 10) }}</td>
            <td>{{ object.last_login.slice(0, 10) == '2006-01-02' ? '暂未登录' : object.last_login.split(".")[0]| date: 'MM月dd日 hh:mm'}}</td>
            <td>
              <a (click)="preDetail(object)"><i nz-icon nzType="monitor" nzTheme="outline"></i>详情</a>&nbsp;&nbsp;&nbsp;&nbsp;
              <a (click)="preEdit()"><i nz-icon nzType="stop" nzTheme="outline"></i>冻结</a>&nbsp;&nbsp;&nbsp;&nbsp;
            </td>
          </tr>
          </tbody>
        </nz-table>
        <nz-table
          nzSize="small"
          [nzData]="objects"
          [(nzPageSize)]="size"
          [nzTotal]="total"
          [(nzPageIndex)]="page"
          [nzFrontPagination]="false"
          (nzPageIndexChange)="indexChange()"
          (nzPageSizeChange)="indexChange()"
          [nzShowSizeChanger]="true"
          [nzPageSizeOptions]="[5,10,15,20]"
          [nzShowTotal]="totalTemplate"
          [nzHideOnSinglePage]="true"
        >
          <ng-template #totalTemplate>共 <span style="color:cornflowerblue;margin:0 3px;">{{ total }}</span>
            条
          </ng-template>
          <thead style="background: yellow">
          <tr>
            <th nzWidth="100px">订单编号</th>
            <th nzWidth="100px">号码详情</th>
            <th nzWidth="100px">类型</th>
            <th nzWidth="100px">呼叫类型</th>
            <th nzWidth="100px">归属地</th>
            <th nzWidth="100px">生效时间</th>
            <th nzWidth="100px">到期时间</th>
            <th nzWidth="100px">操作</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let object of objects">
            <td>{{ object.user_name }}</td>
            <td>{{ object.mobile }}</td>
            <td>{{ object.role_name }}</td>
            <td>{{ object.status == 0 ? '禁用' : '启用' }}</td>
            <td>{{ object.update_time.slice(0, 10) }}</td>

            <td>{{ object.last_login.slice(0, 10) == '2006-01-02' ? '暂未登录' : object.last_login.split(".")[0]| date: 'MM月dd日 hh:mm'}}</td>
            <td>{{ object.update_time.slice(0, 10) }}</td>
            <td>
              <a (click)="preDetail(object)"><i nz-icon nzType="monitor" nzTheme="outline"></i>详情</a>&nbsp;&nbsp;&nbsp;&nbsp;
              <a (click)="preEdit()"><i nz-icon nzType="stop" nzTheme="outline"></i>冻结</a>&nbsp;&nbsp;&nbsp;&nbsp;
            </td>
          </tr>
          </tbody>
        </nz-table>
        <nz-table
          nzSize="small"
          [nzData]="objects"
          [(nzPageSize)]="size"
          [nzTotal]="total"
          [(nzPageIndex)]="page"
          [nzFrontPagination]="false"
          (nzPageIndexChange)="indexChange()"
          (nzPageSizeChange)="indexChange()"
          [nzShowSizeChanger]="true"
          [nzPageSizeOptions]="[5,10,15,20]"
          [nzShowTotal]="totalTemplate"
          [nzHideOnSinglePage]="true"
        >
          <ng-template #totalTemplate>共 <span style="color:cornflowerblue;margin:0 3px;">{{ total }}</span>
            条
          </ng-template>
          <thead style="background: yellow">
          <tr>
            <th nzWidth="100px">订单编号</th>
            <th nzWidth="100px">套餐包编号</th>
            <th nzWidth="100px">呼出套餐状态</th>
            <th nzWidth="100px">总量</th>
            <th nzWidth="100px">剩余量</th>
            <th nzWidth="100px">归属地</th>
            <th nzWidth="100px">生效时间</th>
            <th nzWidth="100px">到期时间</th>
            <th nzWidth="100px">操作</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let object of objects">
            <td>{{ object.user_name }}</td>
            <td>{{ object.user_name }}</td>
            <td>{{ object.mobile }}</td>
            <td>{{ object.role_name }}</td>
            <td>{{ object.status == 0 ? '禁用' : '启用' }}</td>
            <td>{{ object.update_time.slice(0, 10) }}</td>
            <td>{{ object.update_time.slice(0, 10) }}</td>
            <td>{{ object.last_login.slice(0, 10) == '2006-01-02' ? '暂未登录' : object.last_login.split(".")[0]| date: 'MM月dd日 hh:mm'}}</td>
            <td>
              <a (click)="preDetail(object)"><i nz-icon nzType="monitor" nzTheme="outline"></i>详情</a>&nbsp;&nbsp;&nbsp;&nbsp;
              <a (click)="preEdit()"><i nz-icon nzType="stop" nzTheme="outline"></i>冻结</a>&nbsp;&nbsp;&nbsp;&nbsp;
            </td>
          </tr>
          </tbody>
        </nz-table>
        <nz-table
          nzSize="small"
          [nzData]="objects"
          [(nzPageSize)]="size"
          [nzTotal]="total"
          [(nzPageIndex)]="page"
          [nzFrontPagination]="false"
          (nzPageIndexChange)="indexChange()"
          (nzPageSizeChange)="indexChange()"
          [nzShowSizeChanger]="true"
          [nzPageSizeOptions]="[5,10,15,20]"
          [nzShowTotal]="totalTemplate"
          [nzHideOnSinglePage]="true"
        >
          <ng-template #totalTemplate>共 <span style="color:cornflowerblue;margin:0 3px;">{{ total }}</span>
            条
          </ng-template>
          <thead style="background: yellow">
          <tr>
            <th nzWidth="100px">序号</th>
            <th nzWidth="100px">用户姓名</th>
            <th nzWidth="100px">手机号码</th>
            <th nzWidth="100px">邮箱</th>
<!--            <th nzWidth="100px">部门</th>-->
<!--            <th nzWidth="100px">角色</th>-->
            <th nzWidth="100px">状态</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let object of objects">
            <td>{{ object.user_name }}</td>
            <td>{{ object.mobile }}</td>
<!--            <td>{{ object.role_name }}</td>-->
<!--            <td>{{ object.role_name }}</td>-->
            <td>{{ object.status == 0 ? '禁用' : '启用' }}</td>
            <td>{{ object.update_time.slice(0, 10) }}</td>
            <td>{{ object.last_login.slice(0, 10) == '2006-01-02' ? '暂未登录' : object.last_login.split(".")[0]| date: 'MM月dd日 hh:mm'}}</td>
          </tr>
          </tbody>
        </nz-table>
      </nz-tab>
      <nz-tab nzTitle="智能短信">
        <div style="margin:3vh 0;float: right">
            <button *ngIf="addSeatVisible" nz-button nzType="primary"><i nz-icon nzType="plus" nzTheme="outline"></i>购买套餐</button>
        </div>
        <div class="data-area">
          <nz-table
            nzSize="small"
            [nzData]="objects"
            [(nzPageSize)]="size"
            [nzTotal]="total"
            [(nzPageIndex)]="page"
            [nzFrontPagination]="false"
            (nzPageIndexChange)="indexChange()"
            (nzPageSizeChange)="indexChange()"
            [nzShowSizeChanger]="true"
            [nzPageSizeOptions]="[5,10,15,20]"
            [nzShowTotal]="totalTemplate"
            [nzHideOnSinglePage]="true"
          >
            <ng-template #totalTemplate>共 <span style="color:cornflowerblue;margin:0 3px;">{{ total }}</span>
              条
            </ng-template>
            <thead style="background: yellow">
            <tr>
              <th nzWidth="100px">订单编号</th>
              <th nzWidth="100px">产品名称</th>
              <th nzWidth="100px">总量</th>
              <th nzWidth="100px">剩余量</th>
              <th nzWidth="100px">申请时间</th>
              <th nzWidth="100px">申请人</th>
              <th nzWidth="100px">经办时间</th>
              <th nzWidth="100px">经办人</th>
              <th nzWidth="100px">有效时间</th>
              <th nzWidth="100px">状态</th>
              <th nzWidth="100px">说明</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let object of objects">
              <td>{{ object.user_name }}</td>
              <td>{{ object.mobile }}</td>
              <td>{{ object.role_name }}</td>
              <td>{{ object.role_name }}</td>
              <td>{{ object.role_name }}</td>
              <td>{{ object.update_time.slice(0, 10) }}</td>
              <td>{{ object.last_login.slice(0, 10) == '2006-01-02' ? '暂未登录' : object.last_login.split(".")[0]| date: 'MM月dd日 hh:mm'}}</td>
              <td>{{ object.status == 0 ? '禁用' : '启用' }}</td>
              <td>{{ object.role_name }}</td>
              <td>{{ object.role_name }}</td>
              <td>{{ object.role_name }}</td>
            </tr>
            </tbody>
          </nz-table>
        </div>
      </nz-tab>
      <nz-tab nzTitle="发票抬头">
        <div style="margin:3vh 0;">
            <button nz-button nzType="default">发票抬头</button>
            <button nz-button nzType="default">收件地址</button>
        </div>
        <div class="data-area">
          <nz-table
            nzSize="small"
            [nzData]="objects"
            [(nzPageSize)]="size"
            [nzTotal]="total"
            [(nzPageIndex)]="page"
            [nzFrontPagination]="false"
            (nzPageIndexChange)="indexChange()"
            (nzPageSizeChange)="indexChange()"
            [nzShowSizeChanger]="true"
            [nzPageSizeOptions]="[5,10,15,20]"
            [nzShowTotal]="totalTemplate"
            [nzHideOnSinglePage]="true"
          >
            <ng-template #totalTemplate>共 <span style="color:cornflowerblue;margin:0 3px;">{{ total }}</span>
              条
            </ng-template>
            <thead style="background: yellow">
            <tr>
              <th nzWidth="100px">发票类型</th>
              <th nzWidth="100px">发票抬头</th>
              <th nzWidth="100px">纳税人识别号</th>
              <th nzWidth="100px">开户行</th>
              <th nzWidth="100px">开户账号</th>
              <th nzWidth="100px">注册地址</th>
              <th nzWidth="100px">注册电话</th>
              <th nzWidth="100px">申请时间</th>
              <th nzWidth="100px">申请人</th>
              <th nzWidth="100px">经办时间</th>
              <th nzWidth="100px">经办人</th>
              <th nzWidth="100px">状态</th>
              <th nzWidth="100px">说明</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let object of objects">
              <td>{{ object.user_name }}</td>
              <td>{{ object.mobile }}</td>
              <td>{{ object.role_name }}</td>
              <td>{{ object.role_name }}</td>
              <td>{{ object.role_name }}</td>
              <td>{{ object.update_time.slice(0, 10) }}</td>
              <td>{{ object.last_login.slice(0, 10) == '2006-01-02' ? '暂未登录' : object.last_login.split(".")[0]| date: 'MM月dd日 hh:mm'}}</td>
              <td>{{ object.status == 0 ? '禁用' : '启用' }}</td>
              <td>{{ object.mobile }}</td>
              <td>{{ object.role_name }}</td>
              <td>{{ object.role_name }}</td>
              <td>{{ object.role_name }}</td>
              <td>{{ object.role_name }}</td>
            </tr>
            </tbody>
          </nz-table>
          <nz-table
            nzSize="small"
            [nzData]="objects"
            [(nzPageSize)]="size"
            [nzTotal]="total"
            [(nzPageIndex)]="page"
            [nzFrontPagination]="false"
            (nzPageIndexChange)="indexChange()"
            (nzPageSizeChange)="indexChange()"
            [nzShowSizeChanger]="true"
            [nzPageSizeOptions]="[5,10,15,20]"
            [nzShowTotal]="totalTemplate"
            [nzHideOnSinglePage]="true"
          >
            <ng-template #totalTemplate>共 <span style="color:cornflowerblue;margin:0 3px;">{{ total }}</span>
              条
            </ng-template>
            <thead style="background: yellow">
            <tr>
              <th nzWidth="100px">收件人</th>
              <th nzWidth="100px">联系方式</th>
              <th nzWidth="100px">收件地址</th>
              <th nzWidth="100px">申请时间</th>
              <th nzWidth="100px">申请人</th>
              <th nzWidth="100px">经办时间</th>
              <th nzWidth="100px">经办人</th>
              <th nzWidth="100px">状态</th>
              <th nzWidth="100px">说明</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let object of objects">
            <tr *ngFor="let object of objects">
              <td>{{ object.user_name }}</td>
              <td>{{ object.mobile }}</td>
              <td>{{ object.role_name }}</td>
              <td>{{ object.role_name }}</td>
              <td>{{ object.role_name }}</td>
              <td>{{ object.role_name }}</td>
              <td>{{ object.update_time.slice(0, 10) }}</td>
              <td>{{ object.last_login.slice(0, 10) == '2006-01-02' ? '暂未登录' : object.last_login.split(".")[0]| date: 'MM月dd日 hh:mm'}}</td>
              <td>{{ object.status == 0 ? '禁用' : '启用' }}</td>
            </tr>
            </tbody>
          </nz-table>
        </div>
      </nz-tab>
    </nz-tabset>
  </div>
  <!--  </div>-->
</nz-card>

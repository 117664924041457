<nz-tabset id="action-tab" [nzAnimated]="true"
           [nzSelectedIndex]="currentIndex"
           [nzShowPagination]="true"
           (nzSelectChange)="nzSelectChange($event)"
           [nzType]="'card'"
>
  <nz-tab *ngFor="let menu of menuList" [nzTitle]="nzTabHeading">
    <ng-template #nzTabHeading>
      <div>
        {{menu.title}}
        <i *ngIf="menu.isRemove" nz-icon nzType="close" nzTheme="outline" (click)="closeUrl(menu.url)"></i>
        <!--        <i *ngIf="menu.isRemove" (click)="closeUrl(menu.url)" class="anticon anticon-cross"></i>-->
      </div>
    </ng-template>
  </nz-tab>
</nz-tabset>
<div>
  <ng-content></ng-content>
</div>
